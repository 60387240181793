import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function DylanEnnis() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Hash, Bash, Cash: How Change Happens in Decentralised Web3 Cultures</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Paul Dylan-Ennis 
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089007">Published version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            A Web3 lifeworld consists of an imaginary and a shared commons. A Web3 imaginary is shown to include most, if not all, of the following: (i) the stated goal or purpose of the community, (ii) the behavioural norms, (iii) the lore or history, and (iv) what is opposed. A typical Web3 commons is shown to involve three elements: hash (technical), bash (social) and cash (finance). When changes come in Web3 the response is enacted using an available lever from the hash, bash, cash model of decentralised organisation, but the response must not be in friction with the community’s imaginary, or it will most likely grind to a halt. Effective response to change becomes part of the Web3 lifeworld’s toolkit.
            </p>
            <hr />      
            <h1>
            Web3 Cultures
            </h1>
            <p>
                Culture, rather than finance or technology, drives change in Web3. Before
                there is a blockchain or a token there is a cultural drive to create them.
                When the blockchain has a hiccup, it is culture Web3 communities fall back
                to.
            </p>
            <p>
                Culture is understood here as the ‘lifeworld’ of the community (Carr, 1970).
                The lifeworld is what a community takes to be self-evident at a “slice of
                time.” It is not meant as a cultural essence people are imbued with.
                Instead, the lifeworld is self-evident, but also always evolving little by
                little, constantly negotiated with (Williams, 1989: 13). Culture is the
                currently accepted self-evident context held by a community.
            </p>
            <p>
                The concept of the lifeworld is introduced to first help put form on Web3
                culture and second to explain how change is enacted in Web3 cultures using
                the hash, bash, cash model of decentralised organisation.
            </p>
            <p>
                Let’s start with the first goal, putting form on Web3 cultures. A typical
                Web3 culture consists of an <em>imaginary</em>(or imaginaries) and a
                <em> commons</em>. Taken together these form a Web3 lifeworld.
            </p>
            <p>
                Charles Taylor provides a broad summary of an imaginary:
            </p>
            <p class="displayquotation">
                …a sense of the normal expectations that we have of one another, the kind of
                common understanding which enables us to carry out the collective practices
                that make up our social life. This incorporates some sense of how we all fit
                together in carrying out the common practice. This understanding is both
                factual and ‘normative’; that is, we have a sense of how things usually go,
                but this is interwoven with an idea of how they ought to go, of what
                missteps would invalidate the practice. (2002: 106)
            </p>
            <p>
                In the social science literature on cryptocurrencies imaginaries have played
                an important role. At first, the concept was implicit. Maurer et al. (2013)
                highlighted the paradox of how the Bitcoin community is sustained by a
                social discourse about removing the social and political dimensions from
                money (p. 274). Dodd (2018), Schneider (2019) and Caliskan (2020)
                consolidated this argument and all claim Bitcoin’s proposed asocial system
                of money is, in practice, sustained by its sociality. Lustig &amp; Nardi (2015) showed how users of Bitcoin had become aware of these social paradoxes of
                Bitcoin over time, but still valued its core principles.
            </p>
            <p>
                As Bitcoin matured, the literature turned to the question of whether its
                commitment to decentralisation had been delivered upon. Böhme et al. (2015)
                were the first to comprehensively chart the complicated nexus of
                stakeholders present in the Bitcoin system and how in its practical
                implementation Bitcoin was full of centralised chokepoints. De Filippi &amp;
                Loveluck (2016) went further and proposed that Bitcoin’s governance can be
                divided between a visible blockchain infrastructural layer and an invisible
                developer management layer that shapes it, suggesting the developers
                sometimes operate in an undemocratic manner. A later article by Reijers et
                al. (2018) calls this the distinction between on-chain and off-chain
                governance, in contrast to the Bitcoin rhetoric that emphasises only the
                former.
            </p>
            <p>
                It is in the work of Swartz (2018) that the association of Bitcoin with
                imaginaries was consolidated. She argued that Bitcoin’s culture emerged from
                a blend of crypto-anarchism and cypherpunk. Crypto-anarchism is expressed in
                Bitcoin through the imaginary of digital metallism, which proposes Bitcoin
                as a sound money alternative to state-controlled fiat currencies. Bitcoin is
                scarce digital gold, controlled, not by the state, but by algorithmic code
                (in theory). In turn, the cypherpunk imaginary is infrastructural mutualism,
                which draws on the free software tradition concerned with privacy and online
                surveillance.
            </p>
            <p>
                Kow &amp; Lustig (2018) focused on the distinction between the <em>abstract</em>
                imaginaries guiding Bitcoin culture (decentralisation, removal of
                intermediaries) and the <em>concrete</em> imaginaries (the actual
                implemented software solutions) that move the project forward. They found
                that tensions between the abstract and the concrete were productive and
                forced different stakeholders to converge or ‘crystallise’ a solution (p.
                225). Husain et al. (2020) uncovered three imaginaries operative in the
                wider ecosystem: crypto-libertarians, crypto-commonists,
                crypto-governmentalisms, while recognising they shared a prefigurative<em>ethos</em>. 
                Most recently, Brody &amp; Couture (2021) argued that when it comes to
                imaginaries, Ethereum ‘is best characterized as a space where different
                ideological horizons cohabitate while leaning decidedly toward the left’ (p.
                14).
            </p>
            <h1>
                Web3 Imaginaries
            </h1>
            <p>
                Adapting to local conditions, a Web3 imaginary includes most, if not all, of
                the following: (i) the stated goal or purpose of the community, (ii) the
                behavioural norms, (iii) the lore or history, and (iv) what is opposed.
            </p>
            <p>
                Let’s apply this to four examples within the broader story of cryptocurrency
                and Web3.
            </p>
            <p>
                <em>Bitcoin:</em> (i) Bitcoin’s (current) purpose is to be digital gold.
                (ii) It is expected this will be achieved in a decentralised manner. (iii)
                This is in keeping with the early ideals of its missing founder Satoshi
                Nakamoto. (iv) Bitcoin opposes the inherited fiat monetary system. Note the
                purpose has evolved over time. Originally Bitcoin was presented as an
                electronic cash with gold-like features, but this conception of Bitcoin has
                changed over time, now leaning more to the gold motif than cash.
            </p>
            <p>
                <em>Ethereum:</em> (i) Ethereum’s purpose is to be a world computer. (ii) It
                is expected this will be achieved in a decentralised manner. (iii) This
                ideal has been negotiated among influential leaders and members over time.
                (iv) Ethereum opposes the centralisation characteristic of Web 2.0.
            </p>
            <p>
                <em>DAO culture:</em>(i) DAOs allow distributed online communities to
                self-organise. (ii) It is expected this will be achieved in a decentralised
                manner. (iii) DAOs were once set up to be purely algorithmic (see DuPont,
                2017), but were forced to change by <em>The DAO</em> hack, enabling a new
                social conception of DAOs. (iv) DAOs oppose traditional hierarchical forms
                of organisation, such as corporate models.
            </p>
            <p>
                <em>DeFi culture:</em> (i) DeFi allows for permissionless finance. (ii) It
                is expected this will be achieved in a decentralised manner. (iii) DeFi
                exploded during DeFi Summer, amidst the pandemic. (iv) DeFi opposes
                traditional financial institutions, such as banks and Wall Street.
            </p>
            <p>
                <em>NFT culture:</em>(i) NFTs enable the creative ownership economy. (ii) It
                is (somewhat) expected this will be achieved in a decentralised manner.
                (iii) NFTs exploded in popularity through the creation of profile picture
                collections such as CryptoPunks. (iv) NFTs present an alternative to the
                established art world.
            </p>
            <p>
                Most researchers will naturally be operating at a more granular level and
                tell us about the imaginaries of say <em>Uniswap</em> or <em>Bored Ape Yacht Club</em>. Grounding a project as a Web3 imaginary helps put cultural form on Web3
                projects. It tells us what is important to the specific community, but
                rooting this importance within the wider Web3 meta-culture. For example, it
                becomes readily apparent when performing analyses of Web imaginaries that
                decentralisation is the most consistent meta-principle, but just as much
                that each community spins the meaning of decentralisation their own way:
                reinventing money, reinventing the Web, reinventing art, etc.
            </p>
            <p>
                Imaginaries are the cultural bedrock underlying these Web3 worlds, but
                imaginaries are in a constant negotiation with both their inner and outer
                worlds. When an imaginary is prodded it responds. Responses are usually
                habitual, built on accumulated know-how, but also each new event is adding
                to that know-how, an update. On occasion the prod will be major and may
                occasion major reflection and reinvention.
            </p>
            <p>
                The situation at any moment is akin to Heidegger’s workshop (Cerbone, 1999).
                In this scene, the tradesman is hammering away without a thought in the
                world. For Heidegger, we are most at home when we are in the unconscious
                flow of doing or when things are ready-to-hand. Readiness-to-hand means a
                state when the context recedes from view and everything is working without
                friction (Overgaard, 2004).
            </p>
            <p>
                Then there is a knock on the door, there is a phone call, the hammer breaks.
                When the flow-state is broken we enter a state of conscious reflection,
                where things appear present-at-hand (Dreyfus, 1990). In this more abstract
                and theoretical mindset, we seek to fix the situation in order to get back
                to the flow of readiness-to-hand.
            </p>
            <p>
                In contemporary technology studies, Heidegger’s
                ready-to-hand/present-at-hand model has been transfigured onto
                infrastructural terms by Star and Ruhleder (1996). In this translation,
                infrastructures that work well disappear from view and conscious thought.
                When the infrastructure breaks, such as the Internet suddenly not working,
                it emerges starkly in view as present-at-hand, a problem to be fixed.
            </p>
            <p>
                Unlike the slow-and-steady examples that populate Heidegger or Star and
                Ruhleder, Web3 infrastructure does not recede from view most of the time.
                Infrastructure is called up early and often, prodded by a teenage, angsty
                ecosystem constantly on the edge of chaos. It is therefore necessary for
                Web3 cultures to have highly distributed and well-known mechanisms that the
                community can leverage, <em>quick</em>!
            </p>
            <h1>
                The Hash, Bash, Cash Model of Decentralised Organisation
            </h1>
            <p>
                In Web3, this model soon becomes apparent to those immersed in it (or those
                who observe as academic researchers). It does not have a native term. There
                is not often reflection on the model or how it works. Instead, the model
                exists as a prefigurative praxis that can be called upon at any moment. I
                call it the hash, bash, cash model of decentralised organisation
                (Dylan-Ennis, 2021).
            </p>
            <p>
                Let’s take Ethereum as a meta-example of a Web3 culture. When Ethereum needs
                to enact change, this change must flow through its technical (hash), social
                (bash), and financial (cash) spaces. These three spaces are <em>the commons</em>, the second dimension of a Web3 lifeworld.
            </p>
            <p>
                Every Web3 culture has a commons, a shared space each community member cares
                for, albeit to different degrees. I use the word care here carefully. It is
                meant in Heidegger’s sense as investment in a world. Investment in the
                broadest possible sense: psychological, social, empathetic, financial, etc.
                Not everything is financial, not even in Web3.
            </p>
            <p>
                In our Ethereum example, we find a community that cares about a shared
                digital commons that comprises three spaces: hash, bash, cash.
            </p>
            <p>
                <em>Hash</em> refers to the technical dimension of a Web3 project. It
                usually involves core infrastructure such as a blockchain, smart contracts,
                voting tools (like Snapshot), etc.. Web3 communities imbue on-chain
                decisions with a true decisiveness because what happens on-chain is as close
                to the truth as one can get. On-chain is truth-adjacent. In Ethereum’s case,
                the technical dimension is the Ethereum blockchain as the canonical state of
                the “world computer.”
            </p>
            <p>
                <em>Bash</em> refers to the social dimension of a Web3 project. It usually
                involves a set of community discussions outlets, such as Reddit, a Discord
                server, Twitter, governance forums, etc. The social world is the town hall
                where the community generates chatter about the latest drama, but also where
                community consensus organically emerges. It is the “eye test” where debates
                occur a little <em>ad hoc</em>, but slowly people begin to gain a sense of
                the popularity of certain ideas. When these ideas gain a backing -
                qualitatively sensed, not quantitatively measured - they will often be
                formalised and put into proposals, such as Ethereum’s Ethereum Improvement
                Proposal (EIP) system.
            </p>
            <p>
                <em>Cash</em> refers to the financial dimension of a Web3 project. It
                typically concerns the relevant micro-economy impacting community members.
                It can be a native blockchain token (like Ether), a token built on another
                blockchain (like Shiba Inu built on Ethereum), or it might mean the
                community treasury overseen by a DAO. As in the real world, economic
                concerns diminish during the good times, known as bull runs, but then become
                contentious in the bad times, known as bear markets or Crypto Winter. In the
                case of Ethereum, the cash dimension relates to the management of the native
                currency Ether, but the Ether price also acts as a barometer for the overall
                health of the Web3 ecosystem.
            </p>
            <h1>
                How Change Happens in Decentralised Web3 Cultures
            </h1>
            <p>
                Internal and external shocks to the system are routine in Web3 cultures.
                Change, volatility, chaos, are the norm. Stability is unusual, suspicious
                even, like the calm before the storm. Let’s take a look at a classic DeFi
                internal shock, courtesy of SushiSwap.
            </p>
            <p>
                SushiSwap is a protocol for swapping tokens on Ethereum. It originated in
                2020 out of an infamous “vampire attack,” where SushiSwap copied the
                codebase of the established token-swapping protocol Uniswap, but added an
                extra token as incentive for users to migrate their liquidity over to
                Sushiswap’s liquidity pools (Baker, 2020).
            </p>
            <p>
                In terms of its <em>imaginary</em>, SushiSwap contains all the traits of a
                pure DeFi project, arguably even its paradigmatic case. It allows for
                permissionless finance, its community believe this is to be achieved in a
                decentralised manner, its lore is deeply rooted in the original 2020 DeFi
                summer, and it takes a populist stance against traditional financial
                institutions.
            </p>
            <p>
                In terms of its <em>commons</em>, SushiSwap is a smart contract protocol for
                token swapping. It is run as a DAO through blockchain governance (hash), a
                community that organises on a dedicated Discord and Telegram, with a
                governance forum (bash) (see Sushi.com for links), and finally a community
                treasury, currently $30 million in various crypto assets (DeepDAO, 2022).
            </p>
            <p>
                Yet, SushiSwap almost collapsed before it even got going. Back in September
                2020, its leader, known only by the pseudonym Chef Nomi, decided to transfer
                $14 million worth of Ether from the SushiSwap “developer fund” to a personal
                wallet (Palmer and De, 2020). In this early era of DeFi culture, it was not uncommon for a founder to
                have unilateral power to do this. This is about as internal a shock as it
                gets; the founder has stolen the community treasury!
            </p>
            <p>
                How to enact the appropriate response in a decentralised Web3 culture like
                SushiSwap? Response travels through the commons. Not linearly, of course,
                but messily, allowing emergent consensus within the chaotic conditions of a
                distributed online culture.
            </p>
            <p>
                In SushiSwap’s case, the job was to get the <em>cash</em> (the community
                “developer fund”) back into the <em>hash</em> (an Ethereum wallet under the
                control of the community, ideally now a multisig or multi-person wallet).
                The solution can only arise from within the community since there is no
                central authority who could intervene on their behalf.
            </p>
            <p>
                In Web3 cultures, the difficulty of the response’s travels through the
                commons depends on how much friction it generates with the imaginary. This
                is the key to understanding how change happens in decentralised Web3
                cultures. Too many sparks and a response grinds to a halt.
            </p>
            <p>
                The successful response within the SushiSwap community came from the <em>bash</em>
                part of the commons. The solution could not be technical because - per the
                DeFi imaginary - it is not possible to simply reverse the transaction
                through centralised mechanisms. They don’t exist. Instead, the community
                applied pressure where it could, socially.
            </p>
            <p>
                The SushiSwap community first started to dig into the identity of Chef Nomi.
                This did not result in a clear answer, but made apparent to Chef Nomi that
                his (or her or their) life would be one of the fugitive, with the community
                constantly on their tail.
            </p>
            <p>
                This pressure caused Chef Nomi to transfer the money over to cryptocurrency
                exchange owner Sam Bankman-Fried, who then helped migrate the funds over to
                a multisig wallet, restoring the old order. From <em>bash</em> to <em>hash</em>.
            </p>
            <p>
                This solution travelled through the commons frictionlessly because it did
                not violate any of the core principles of the DeFi imaginary. Contrary to
                popular conception, Web3 cultures do not suddenly become inert when faced
                with the lack of a technical solution. Or the lack of a centralised
                mechanism. Instead, they are content to evoke social solutions so long as
                they are not in contradiction with their cultural beliefs, their imaginary.
            </p>
            <h1>
                Conclusion: Web3 Lifeworlds
            </h1>
            <p>
                A Web3 lifeworld consists of an imaginary and a shared commons. To the
                community the life world is a self-evident slice of time, but it’s also an
                evolving world, usually incrementally.
            </p>
            <p>
                When changes come in Web3, as it constantly does, the response is enacted
                using an available lever from the hash, bash, cash model of decentralised
                organisation. This shared commons of tools, techniques, and methods (the
                know-how) must not be in friction with the community’s imaginary, or it will
                most likely grind to a halt.
            </p>
            <p>
                Once the response has flowed through the commons without friction the
                habitual know-how is updated and refined. The issue settled, the community
                went back to unconscious flow.
            </p>
            <p>
                The accumulated know-how is deepened. Next time the SushiSwap community
                needed it, the <em>bash</em> tool-box includes social pressure based on
                crowdsourced investigation.
            </p>
            <h1>
                References
            </h1>
            <p>Böhme R, Christin N, Edelman B, et al. (2015) Bitcoin: Economics, Technology, and Governance. Journal of Economic Perspectives 29(2). 2: 213–238.</p>
            <p>Brody A and Couture S (2021) Ideologies and Imaginaries in Blockchain Communities: The Case of Ethereum. Canadian Journal of Communication 46(3). 3: 1–19.</p>
            <p>Caliskan K (2020) Data money: The socio-technical infrastructure of cryptocurrency blockchains. Economy and Society 49(4). 4. Routledge: 540–561.</p>
            <p>Carr D (1970) Husserl’s Problematic Concept of the Life-World. American Philosophical Quarterly 7(4): 331–339.</p>
            <p>Dodd N (2018) The Social Life of Bitcoin. Theory, Culture & Society 35(3). 3. SAGE Publications Ltd: 35–56.</p>
            <p>Dreyfus HL (1990) Being-in-the-World: A Commentary on Heidegger’s Being in Time, Division I . Massachusetts: MIT Press.</p>
            <p>DuPont Q (2017) Experiments in algorithmic governance: A history and ethnography of “The DAO,” a failed decentralized autonomous organization. In: Campbell-Verduyn M (ed.) Bitcoin and Beyond: Cryptocurrencies, Blockchains, and Global Governance . London: Routledge, pp. 157–177.</p>
            <p>Dylan-Ennis P (2021) Teaching cryptocurrencies as cryptocultures. Journal of Applied Learning and Teaching 4(2). 2.</p>
            <p>Kow YM and Lustig C (2018) Imaginaries and Crystallization Processes in Bitcoin Infrastructuring. Computer Supported Cooperative Work (CSCW) 27(2). 2: 209–232.</p>
            <p>Lustig C and Nardi B (2015) Algorithmic Authority: The Case of Bitcoin. In: 2015 48th Hawaii International Conference on System Sciences, January 2015, pp. 743–752.</p>
            <p>Maurer B, Nelms TC and Swartz L (2013) “When perhaps the real problem is money itself!”: the practical materiality of Bitcoin. Social Semiotics 23(2). 2. Routledge: 261–277.</p>
            <p>Overgaard S (2004) Husserl and Heidegger on Being in the World. Dordrecht: Springer Science & Business Media.</p>
            <p>Reijers W, Wuisman I, Mannan M, et al. (2018) Now the Code Runs Itself: On-Chain and Off-Chain Governance of Blockchain Technologies. Topoi 37: 1–11.</p>
            <p>Star SL and Ruhleder K (1996) Steps Toward an Ecology of Infrastructure: Design and Access for Large Information Spaces. Information Systems Research 7(1). 1: 25.</p>
            <p>Schneider N (2019) Decentralization: an incomplete ambition. Journal of Cultural Economy 12(4). 4. Routledge: 265–285.</p>
            <p>Swartz L (2018) What was Bitcoin, what will it be? The techno-economic imaginaries of a new money technology. Cultural Studies 32(4). 4: 623–650.</p>
            <p>Taylor C (2002) Modern Social Imaginaries. Public Culture 14(1). 1. Duke University Press: 91–124.</p>
            <p>Williams R (1989) Culture. Great Britain: Fontana Press.</p>  



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default DylanEnnis;
