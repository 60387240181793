import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import schneider1 from "../../assets/images/content/schneider1.png";
import schneider2 from "../../assets/images/content/schneider2.png";
import schneider3 from "../../assets/images/content/schneider3.png";
import schneider4 from "../../assets/images/content/schneider4.png";
import schneider5 from "../../assets/images/content/schneider5.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function schneider() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Web3 Is the Opportunity We Have Had All Along: Innovation Amnesia and Economic Democracy</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Nathan Schneider
            </h3>
            <div className="dsc">
                <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089002">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/51dc7819-880c-4e41-a172-236e7088df22">Preprint self-archived version</a>
            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            The class of technology variously referred to as Web3 or crypto has been heralded as a democratizing force for economics and governance. This essay argues that, to the extent such hype is justified, it is only partly due to the affordances of the technology itself. Perhaps more important is the amnesia it has induced, as an innovative paradigm whose novelty inclines people to neglect once-stable norms. In both economics and governance, crypto offers opportunities for greater democracy, but following through on them is guaranteed by neither the technology nor the amnesia it invites.
            </p>
            <hr />
            <p>
                Web3 is an opportunity to do what could have been done before but wasn’t
                done, which is already being squandered with what <em>is</em> being done.
                Nevertheless, I will argue, it is an opportunity we need.
            </p>
            <p>
                The kind of opportunity I refer to is not necessarily the opportunity
                everybody sees in Web3—which I will subsequently include in the broader
                terminology of <em>crypto</em>. Common accounts of what crypto is good for
                include keywords such as trustlessness (Nakamoto 2009; De Filippi, Mannan,
                and Reijers 2020), openness (Caliskan 2020), coordination (Swartz 2017;
                Soleimani et al. 2019; Owocki 2022), decentralization (Schneider 2019; Walch
                2019), and freedom (Golumbia 2016). But the opportunity I am most interested
                in would be better phrased with another floating signifier (Mehlman 1972): <em>democracy</em> — specially, the pursuit of equitable economics and
                decision-making. Democracy is also widely cited among crypto’s possible or
                actual affordances (e.g., Reijers, O’Brolcháin, and Haynes 2016; Buterin,
                Hitzig, and Weyl 2018; Linares and Cabaña 2019; Allen, Berg, and Lane 2019;
                Magnuson 2020), and for some it is the end for which the other keywords are
                means. Crypto comes by these democratic associations honestly; its
                distributed ledger technology enables novel means of circulating wealth and
                power among a system’s participants. Unlike Internet technology that
                operates through a central server, shared ownership and control are the
                default setting. Crypto meanwhile has the wherewithal to enable new degrees
                of consolidation in wealth and power.
            </p>
            <p>
            <img src={schneider1} class="img-fluid" alt="Figure 1: Real Democracy is Finally Possible"/>
            </p>
            <p class="figure">
                Figure 1: Real Democracy is Finally Possible.
            </p>
            <p>
                The democratic opportunities of crypto are real. But those opportunities are
                not unique to the technology at hand. They could have been taken before,
                although for the most part they were not.
            </p>
            <p>
                The most salient opportunity that crypto presents, more than any particular
                technical feature or affordance, arises from the amnesia of innovation: that
                deer-in-the-headlights effect as a new technological paradigm shines
                brightly enough that people freeze and forget to apply once-stable, and
                still applicable, social contracts to it. Much as gig platforms made
                regulators forget labor laws (Cherry 2015–2016) and cloud services did not
                face the same privacy rules governing older telecoms (Zuboff 2019), the
                strange new tech of crypto has made space for breaking and remaking norms
                about how networked assets are to be owned and governed. What once seemed
                (but did not have to be) fixed is now (by no particular necessity) in flux.
            </p>
            <p>
                As Langdon Winner put it, in the final paragraph of his seminal essay “Do
                Artifacts Have Politics?” (1980, 135):
            </p>
            <p>
                In our times people are often willing to make drastic changes in the way
                they live to accord with technological innovation at the same time they
                would resist similar kinds of changes justified on political grounds.
            </p>
            <p>
                That is: What was always possible for humans to do in politics, but never
                quite feasible, can happen all of a sudden if it can be made to seem the
                inevitable result of technology.
            </p>
            <p>
            <img src={schneider2} class="img-fluid" alt="Figure 2: Norm Enforcement"/>
            </p>
            <p class="figure">
            Figure 2: Norm Enforcement.
            </p>
            <p>
                With Web3 we find ourselves in a throwback to Web1, which is to say the days
                when John Perry Barlow (1996) could proclaim a “Declaration of the
                Independence of Cyberspace.” The years since have insisted that cyberspace
                was never its own space and was certainly not independent from its
                surroundings. Meatspace regimes of law, finance, wealth, and power have had
                their way with Internet technology, turning that open and decentralized
                technology into unprecedented concentrations of corporate power. So goes the
                story again with incipient crypto.
            </p>
            <p>
                Once again, under the cover of decentralization-talk, the centralization
                power grab is well underway. Venture capitalists have recognized that crypto
                protocols represent an even greater chance for value capture than platforms
                did in Web1 or Web2 (Wilson 2016). Platforms operate within rules; protocols
                write the rules (Galloway 2006). A further advantage for the power grabbers
                lies in the amnesia: crypto’s ability to operate ambiguously with respect to
                territorial law and regulation, carrying out forms of ownership, governance,
                and financial chicanery that could not be gotten away with using more
                familiar technologies. In this respect, the disruption of crypto runs the
                risk enabling of “disaster capitalism” (Klein 2008): the use of a rupture in
                the way of things to enact an anti-democratic power grab by capital holders,
                enabling them to accomplish what would otherwise face too much popular
                resistance.
            </p>
            <p>
                So far, actually existing practice appears to be making good on that risk.
                Available data on blockchain activity is plentiful and precise, but it
                rarely reveals much about the effects on real human lives. Viral tokens
                attract hopeful retail investors, who may reap astonishing gains or see
                large chunks of their savings wiped away in hours. Venture capitalists
                meanwhile hold large stakes in important DAOs and protocols, through which
                they can dominate governance processes (Buterin 2018; Ferreira, Li, and
                Nikolowa 2019; Jensen, von Wachter, and Ross 2021). The crypto advocates’
                longstanding promise of financial inclusion for the world’s poor appears to
                remain mostly deferred. Any opportunities that crypto presents on behalf of
                democracy, therefore, accompany opportunities for democracy’s enemies.
            </p>
            <p>
                Capital, and crypto-capital especially, is itself be a form of media
                (Schneider 2020b)—malleable, transmissible, vulnerable to subversion, and a
                vessel for culture. Like any other media, it shapes us, and perhaps even
                more than we recognize, we shape it. We can bend it beyond recognition, even
                to the point of its abolition.
            </p>
            <p>
                I offer this essay as an explanation for the cautious but determined hope I
                have for the opportunity that crypto and its cognates present—even if we
                shouldn’t need this opportunity, because the possibility for more democratic
                ownership and governance has been with us all along. As Kei Kreutler puts
                it, those earlier chances represent a kind of “prehistory” for crypto
                (Kreutler 2021). I will consider crypto’s contexts, prospects, and potential
                tactics for ownership and governance, centered too much on my particular
                life-world of the United States tech economy, which is at once parochial and
                outsized in influence. Despite my ambivalence about turning to any
                technology as a lever of social change—can’t we humans do this on our own?—I
                write in order that, through the amnesia, we might better remember the
                stakes and defeat exploitation in all its forms.
            </p>
            <h1>
                <a name="ownership">Ownership</a>
            </h1>
            <p>
            <img src={schneider3} class="img-fluid" alt="Figure 3: Ownership"/>
            </p>
            <p class="figure">
            Figure 3: Ownership
            </p>
            <p>
                In capitalist economies, wealth accumulation tends to derive from business
                ownership (Smith et al. 2019). Particularly since the 1970s in the United
                States, the returns to capital ownership have continued to grow while wage
                income has stagnated (Michel 2012; Alvaredo et al. 2013). There are many
                contributing reasons for this. Labor-union membership has declined while the
                financial sector grew. Public policies in much of the world have privileged
                accumulations of investor wealth and discouraged widespread participation in
                early-stage businesses. Taxes on capital gains are generally lower than on
                wages. Stock ownership is highly concentrated, and the bottom half of
                American income-earners own almost none of it (Wolff 2017). Redlining and
                other exclusions have prevented people racialized as non-White from
                accessing ownership. Private equity funds can access financing to purchase a
                business, but its own employees rarely can do the same. When even the likes
                of Uber and Airbnb seek to distribute company stock to their contract
                workers, the US Securities and Exchange Commission declines to permit it
                (Robbins, Schlaefer, and Lutrin 2018). Business structures based on shared
                ownership such as cooperatives have consistently faced barriers to growth,
                due to a lack of capital access and other signs of structural neglect (Molk
                2014; Spicer 2021). Cooperatives have been particularly rare in the platform
                economy, where venture capital has excelled in deploying large quantities of
                investment that co-ops cannot access.
            </p>
            <p>
                This is not a natural or inevitable condition. In sectors such as credit
                unions, agricultural cooperatives, and rural electrification, public policy
                has enabled shared ownership to flourish by enabling frameworks for
                financing it (Schneider 2018). Economic democracy works when the system
                allows it to happen. Tools such as dividends from sovereign wealth funds and
                “baby bonds” could have spread the benefits of capital gains much more
                universally. Programs for expanding homeownership could have targeted,
                rather than excluded, marginalized populations. And policy could have
                ensured that workers who want to buy their factory, or neighborhoods that
                want to set up their own broadband networks, can access financing just like
                wealthy investors do (Kelso and Kelso 1986; Schneider 2020a).
            </p>
            <p>
                Those things didn’t happen. Now, crypto presents another chance to try.
            </p>
            <p>
                Shared ownership is the basic value proposition of a blockchain—a database
                that distinct stakeholders agree on together. Achieving the hallowed goal of
                decentralization requires having many participants jointly managing their
                network. Users of a blockchain or a DAO typically hold its associated
                tokens, which can accrue value with the system as a whole. Use and equity,
                roughly, go hand in hand; to be a user is to be an owner. The classic
                distinction between labor and capital could thus be poised to dissolve into
                an alignment through a common token. Where regulators blocked Uber and
                Airbnb, crypto seems to find a way. When Gitcoin conducted a financing round
                in 2021 and created a DAO, it distributed tokens to users (Owocki 2021).
                When the Ethereum Name Service released its own token, it distributed
                thousands of dollars’ worth to anyone who had bought a domain (Thurman
                2021). In the culture of crypto early adopters, co-ownership is an
                expectation and a norm.
            </p>
            <p>
                The old and new are beginning to learn from each other. Present-day
                cooperatives, recognizing the constraints that the mainstream economy
                imposes on them, are turning to tokens and DAOs to open new financing
                opportunities (Prado 2021; Robey 2022). Some DAOs are legally incorporating
                as cooperatives (Radebaugh and Muchnik 2021). Even investors such as Variant
                Fund have adopted widespread user ownership as an investment thesis, citing
                the cooperative tradition as an inspiration (Walden 2019). The narrative of
                “exit to community” (Mannan and Schneider 2021)—transitioning to community
                ownership, in one form or another—has become a byword in some corners of
                crypto culture.
            </p>
            <p>
                None of this presents a guarantee of equity, fairness, or justice. Achieving
                those will require intention. For instance, protocols can ensure that token
                allocations accrue to contributors of labor and use-value, rather than just
                to those who can afford to pay for them at the outset. Mechanisms akin to
                antitrust enforcement may be necessary to counteract consolidation and
                excessive inequality. Perhaps most importantly, financial instruments should
                ensure that communities of less-capitalized people can participate in this
                new economy with relative safety. Whereas the imagined user of many DeFi
                apps seems to be the speculative day-trader, the lone gambler, truly
                decentralized finance needs to focus on enabling groups of under-capitalized
                people to access capital for reasonable, practical undertakings.
            </p>
            <p>
                If more equitable ownership arrangements arise through crypto, however, it
                is not because they were impossible before. For instance, non-fungible token
                (NFT) enthusiasts celebrate that the technology enables artists to receive
                royalties from the resale of their works. However, few seem to recognize
                that resale royalties have been in use in Europe since the 1920s and more
                recently in the United States (van Haaften-Schick and Whitaker 2022). No
                NFTs required. Still, if crypto can make fair pay for artists easier to
                enforce and more widespread, all the better.
            </p>
            <h1>
                <a name="governance">Governance</a>
            </h1>
            <p>
            <img src={schneider4} class="img-fluid" alt="Figure 4: Evolving Governance with Emerging Needs and Tools"/>
            </p>
            <p class="figure">
            Figure 4: Evolving Governance with Emerging Needs and Tools
            </p>
            <p>
                Those of us in the United States experience periodic dismay about
                institutions like the Electoral College and the Senate filibuster; these are
                reminders that the government’s basic mechanisms date to an
                eighteenth-century experiment of aristocratic slaveholders. Their experiment
                has thus far lasted longer than any other regime of modern republicanism,
                and yet it joins countries around the world in a general pattern of
                declining efficacy and public confidence (Diamond 2022; Papacharissi 2021;
                Silva-Leander 2021). Polarization and gridlock have become increasingly
                synonymous with democracy.
            </p>
            <p>
                This is not for lack of opportunities. Recent decades have seen social
                movements and technologists develop numerous experiments in more textured,
                responsive, and participatory forms of collective decision-making. These
                include participatory budgeting (Cabannes 2004), liquid democracy (Hardt and
                Lopes 2015), sortition (Gastil 2000; Bouricius 2013; Pek 2019; Fan and Zhang
                2020), citizens’ assemblies (Niemeyer 2014; Chwalisz 2017; Giraudet et al.
                2022), crowdsourcing (Hsiao et al. 2018; Bernal 2019), and various
                alternative voting systems (Posner and Weyl 2014; Emmett 2019). A growing
                field of platforms for online citizen engagement has emerged to facilitate
                these processes (Stempeck 2020). Yet in even the most advanced applications
                of technology-enabled governance, from Madrid to Taiwan (Hsiao et al. 2018;
                Smith and Martín 2021; Tseng 2022), the new mechanisms serve in solely
                advisory roles; participatory budgeting processes, while more likely to be
                binding, apply to only small fractions of public budgets.
            </p>
            <p>
                Governments could be eagerly transforming themselves into the vibrant,
                creative, networked institutions that the networked world arguably needs
                them to be, but they are not.
            </p>
            <p>
                Meanwhile, governance in everyday online life has been guided by a design
                pattern of “implicit feudalism” (Schneider 2021), according to which
                platforms and user-moderators alike wield power with little accountability
                to the communities they govern. Forms of governance common in offline civic
                associations—such as elected boards, bylaws binding on leaders, and clearly
                defined rights—are almost nonexistent in Facebook Groups, “creator economy”
                platforms, and group chats, for instance. If political thinkers like Alexis
                Tocqueville ([1840] 2006) and Robert Putnam (Putnam, Leonardi, and Nanetti
                1994) are right that small-scale democratic experience contributes to the
                possibility of mass democracy, then what happens in these online spaces may
                be contributing to the decline of confidence in democratic government.
            </p>
            <p>
                Online democracy certainly could have become far more widespread
                before—though with crypto, it may be easier. Implicit feudalism emerged
                partly as a result of a particular socio-technical confluence: From early
                bulletin-board systems to Web2’s corporate social media platforms, central
                servers and their associated legal entities have been inescapable facts of
                online social life. Democratic power-sharing, therefore, would probably be
                infeasible without something like cooperative ownership of a platform’s
                servers. Whoever owns the server holds important legal responsibility for
                what occurs on it—and most often that means a platform’s profit-seeking
                investors. To the extent that blockchains bypass such sites of central
                control through user ownership and control, Web3 represents an historic
                opportunity to break from platform feudalism to digital democracy. In this
                specific sense, Web3 boosters’ proclamations about the democratizing power
                of their innovations is true.
            </p>
            <p>
                Crypto may also help short-circuit the logjam that has hobbled
                experimentation among democratic governments. In a promotional video for
                Aragon, a platform for blockchain governance, co-founder Luis Cuende boasts,
                “Today, we are in the first time in history that we can actually try out new
                governance models without the need of people getting killed” (Aragon 2018).
                It is actually the case that many of the governance innovations listed
                above—the ones being used either as mere advisors or not at all by
                governments—are already at work in crypto protocols, organizing decisions
                and moving resources. Programmable smart contracts enable mechanisms to be
                tried without asking whether an external legal system will enforce them.
                Experimentation becomes easier to imagine when it doesn’t require a
                political revolution, just a tweak to the source code.
            </p>
            <p>
                Crypto’s democratic opportunities, however, are not guarantees. Software
                protocols can become as entrenched and inflexible as political constitutions
                (Alston 2019). The dominant design logic for blockchain governance today is
                “cryptoeconomics,” which relies on economic incentives in ways that risk
                making democratic deliberation on the common good inadmissible (Schneider
                2022). Too often, the actual practice of participatory governance that
                crypto makes possible would be better described as plutocracy (Buterin 2018)
                or technocracy (Postman 1993), as opposed to democracy.
            </p>
            <p>
                For crypto to usher in a democratic renaissance, it needs to be designed
                that way. For example, even leading advocates of cryptoeconomic designs have
                begun calling for systems with “soulbound” features that center people, not
                tokens, as their basic unit (Buterin 2022; Weyl, Ohlhaver, and Buterin
                2022). This is an important prerequisite for fostering spaces of political,
                rather than just economic, deliberation and decision-making—for imagining a
                common good, not just an optimal return on investment. Drawing on the much
                longer tradition of cooperative business (Schneider 2018), crypto systems
                might more intentionally allocate governance rights accordingly: perhaps
                only to the workers who build value in their systems, or to people making
                the most widely appreciated contributions, rather than flattening all forms
                of citizenship into token-holding.
            </p>
            <p>
                In the meantime, it is a predictable irony that the fate of crypto
                governance may lie with old-world governments. As policy-makers scramble to
                regulate crypto—or strategically not regulate it—they also decide whether to
                turn crypto into a subsidiary or an autonomous zone. Will widespread use of
                blockchains depend on their adherence to territorial governments’ laws? Or
                will the technology enable new governance layers, as distinct from existing
                governments as the most libertarian partisans have hoped? In either case,
                the reshuffling of social contracts underway presents a chance to catch up
                on the democratic experimentation that has otherwise gone neglected.
            </p>
            <h1>
                <a name="conclusion">Conclusion</a>
            </h1>
            <p>
                There is a tendency among some crypto enthusiasts to imagine that, thanks to
                the marvelous new technology at hand, a green solarpunk paradise of abundant
                wealth and close-to-effortless coordination is at hand (e.g., Wenger 2016;
                Swartz 2017; Owocki 2022). It is an echo, amplified with a new kind of
                hyper-volatile money, of the “solutionism” familiar from the heyday of Web2
                (Morozov 2014). The disappointments of Web2, combined with a steady stream
                of Web3 cataclysms (White n.d.), give cause to dismiss such utopianism. Yet
                it remains undeniable that Web2 produced a kind of rupture with its round of
                innovation and amnesia—reshaping society, economics, and politics in ways we
                are still grasping to understand. The result is hardly utopian. To a greater
                degree than early enthusiasts expected, the rupture was shaped by external
                forces: venture capital, racism, colonial power relations, and authoritarian
                politics, for instance.
            </p>
            <p>
                If Web3 ushers in a similar rupture, it runs a similar risk of amplifying
                outside forces that do not necessarily contribute to human and ecological
                flourishing. From the carbon footprint of Bitcoin mining to the surge in
                crypto-enabled ransomware attacks, the negative externalities are well
                underway. But with every change comes opportunity. In this essay I have
                sought to identify very real opportunities that crypto presents to radically
                advance democratic ownership and governance—in ways that both were possible
                before crypto and are well-suited for crypto’s particular affordances.
            </p>
            <p>
            <img src={schneider5} class="img-fluid" alt="Figure 5: Democracy is Losing"/>
            </p>
            <p class="figure">
            Figure 5: Democracy is Losing
            </p>
            <p>
                What is missing from the crypto-optimist culture, as with Web2 solutionism,
                is a sober recognition of the forces arrayed against happy outcomes. The
                rewards are great for a small minority to capture the wealth and power of
                this new paradigm, and to prevent democratic possibilities from taking hold.
                Many contenders seeking to be that small minority are already actively at
                work in doing so. If people building, regulating, and using crypto are
                serious about advancing democracy, they will need to design for democracy
                with determination comparable to what they now apply, for instance, to
                system security. Tremendous energy—intellectual and computational—goes
                toward securing property rights on blockchains. What if similar energy were
                also devoted to ensuring these systems produced more democratic outcomes
                than their predecessors? What if designers’ threat models were not just
                double-spending and DDoS attacks but also plutocracy and wealth inequality?
            </p>
            <p>
                This is not the first time advancing democracy has been an option, an
                opportunity. Too often, other forces won out, and they are well on their way
                to doing so again.
            </p>
            <h1>
                <a name="references">References</a>
            </h1>
            <p><a>Allen, Darcy W. E., Chris Berg, and Aaron M. Lane. 2019. Cryptodemocracy: How Blockchain Can Radically Expand Democratic Choice . Lanham: Lexington Books.</a></p>
            <p>Alston, Eric. 2019. “Constitutions and Blockchains: Competitive Governance of Fundamental Rule Sets.” Center for Growth and Opportunity, Utah State University . <a href="https://www.growthopportunity.org/research/working-papers/constitutions-and-blockchains">https://www.growthopportunity.org/research/working-papers/constitutions-and-blockchains</a> .</p>
            <p>Alvaredo, Facundo, Anthony B. Atkinson, Thomas Piketty, and Emmanuel Saez. 2013. “The Top 1 Percent in International and Historical Perspective.” Journal of Economic Perspectives 27 (3): 3–20. <a href="https://doi.org/10.1257/jep.27.3.3">https://doi.org/10.1257/jep.27.3.3</a> .</p>
            <p>Aragon. 2018. “The Fight for Freedom.” March 29, 2018. <a href="https://www.youtube.com/watch?v=AqjIWmiAidw">https://www.youtube.com/watch?v=AqjIWmiAidw</a> .</p>
            <p>Barlow, John Perry. 1996. “A Declaration of the Independence of Cyberspace.” Wired, June 1, 1996. <a href="https://www.wired.com/1996/06/declaration-independence-cyberspace/">https://www.wired.com/1996/06/declaration-independence-cyberspace/</a> .</p>
            <p>Bernal, Carlos. 2019. “How Constitutional Crowdsourcing Can Enhance Legitimacy in Constitution Making.” In Comparative Constitution Making , edited by David Landau and Hanna Lerner, 235–56. Cheltenham, UK: Edward Elgar Publishing. <a href="https://www.elgaronline.com/view/edcoll/9781785365256/9781785365256.00017.xml">https://www.elgaronline.com/view/edcoll/ 9781785365256/9781785365256.00017.xml</a> .</p>
            <p>Bouricius, Terrill. 2013. “Democracy Through Multi-Body Sortition: Athenian Lessons for the Modern Day.” Journal of Public Deliberation 9 (1). <a href="https://www.publicdeliberation.net/jpd/vol9/iss1/art11">https://www.publicdeliberation.net/jpd/vol9/iss1/art11</a> .</p>
            <p>Buterin, Vitalik. 2018. “Governance, Part 2: Plutocracy Is Still Bad.” March 28, 2018. <a href="https://vitalik.ca/general/2018/03/28/plutocracy.html">https://vitalik.ca/general/2018/03/28/plutocracy.html</a> .</p>
            <p>———. 2022. “Soulbound.” January 26, 2022. <a href="https://vitalik.ca/general/2022/01/26/soulbound.html">https://vitalik.ca/general/2022/01/26/soulbound.html</a> .</p>
            <p>Buterin, Vitalik, Zoë Hitzig, and E. Glen Weyl. 2018. “Liberal Radicalism: A Flexible Design for Philanthropic Matching Funds.” ID 3243656. Social Science Research Network . <a href="https://papers.ssrn.com/abstract=3243656">https://papers.ssrn.com/abstract=3243656</a> .</p>
            <p>Cabannes, Yves. 2004. “Participatory Budgeting: A Significant Contribution to Participatory Democracy.” Environment and Urbanization 16 (1): 27–46. <a href="https://doi.org/10.1177/095624780401600104">https://doi.org/10.1177/095624780401600104</a> .</p>
            <p>Caliskan, Koray. 2020. “Data Money: The Socio-Technical Infrastructure of Cryptocurrency Blockchains.” Economy and Society 49 (4): 540–61. <a href="https://doi.org/10.1080/03085147.2020.1774258">https://doi.org/10.1080/03085147.2020.1774258</a> .</p>
            <p>Cherry, Miriam A. 2015–2016. “Beyond Misclassification: The Digital Transformation of Work.” Comparative Labor Law & Policy Journal 37: 577–602. <a href="https://heinonline.org/HOL/P?h=hein.journals/cllpj37&amp;i=613">https://heinonline.org/HOL/P?h=hein.journals/cllpj37&amp;i=613</a> .</p>
            <p>Chwalisz, Claudia. 2017. The People’s Verdict: Adding Informed Citizen Voices to Public Decision-Making . London: Rowman & Littlefield International.</p>
            <p>De Filippi, Primavera, Morshed Mannan, and Wessel Reijers. 2020. “Blockchain as a Confidence Machine: The Problem of Trust & Challenges of Governance.” Technology in Society 62: 101284. <a href="https://doi.org/10.1016/j.techsoc.2020.101284">https://doi.org/10.1016/j.techsoc.2020.101284</a> .</p>
            <p>Diamond, Larry. 2022. “Democracy’s Arc: From Resurgent to Imperiled.” Journal of Democracy 33 (1). <a href="https://journalofdemocracy.org/articles/democracys-arc-from-resurgent-to-imperiled/">https://journalofdemocracy.org/articles/democracys-arc-from-resurgent-to-imperiled/</a> .</p>
            <p>Emmett, Jeff. 2019. “Conviction Voting: A Novel Continuous Decision Making Alternative to Governance.” Medium. November 18, 2019. <a href="https://medium.com/commonsstack/conviction-voting-a-novel-continuous-decision-making-alternative-to-governance-62e215ad2b3d">https://medium.com/commonsstack/conviction-voting-a-novel-continuous-decision-making-alternative-to-governance-62e215ad2b3d</a> .</p>
            <p>Fan, Jenny, and Amy X Zhang. 2020. “Digital Juries: A Civics-Oriented Approach to Platform Governance.” In Proceedings of the 2020 Conference on Fairness, Accountability, and Transparency , 14. Honolulu, HI: Association for Computing Machinery. <a href="http://dx.doi.org/10.1145/3313831.3376293">http://dx.doi.org/10.1145/3313831.3376293</a> .</p>
            <p>Ferreira, Daniel, Jin Li, and Radoslawa Nikolowa. 2019. “Corporate Capture of Blockchain Governance.” 593/2019. Finance Working Paper. European Corporate Governance Institute. <a href="https://www.ssrn.com/abstract=3320437">https://www.ssrn.com/abstract=3320437</a> .</p>
            <p>Galloway, Alexander R. 2006. Protocol: How Control Exists After Decentralization . Cambridge, Mass.: MIT Press.</p>
            <p>Gastil, John. 2000. By Popular Demand: Revitalizing Representative Democracy Through Deliberative Elections . University of California Press. <a href="http://books.google.com?id=SH_TSsKpQfoC">http://books.google.com?id=SH_TSsKpQfoC</a> .</p>
            <p>Giraudet, Louis-Gaëtan, Bénédicte Apouey, Hazem Arab, Simon Baeckelandt, Philippe Begout, Nicolas Berghmans, Nathalie Blanc, et al. 2022. “‘Co-construction’ in Deliberative Democracy: Lessons from the French Citizens’ Convention for Climate.” <a href="https://hal-enpc.archives-ouvertes.fr/hal-03119539">https://hal-enpc.archives-ouvertes.fr/hal-03119539</a> .</p>
            <p>Golumbia, David. 2016. The Politics of Bitcoin: Software as Right-Wing Extremism . Minneapolis: University of Minnesota Press.</p>
            <p>Haaften-Schick, Lauren van, and Amy Whitaker. 2022. “From the Artist’s Contract to the Blockchain Ledger: New Forms of Artists’ Funding Using Equity and Resale Royalties.” Journal of Cultural Economics, February. <a href="https://doi.org/10.1007/s10824-022-09445-8">https://doi.org/10.1007/s10824-022-09445-8</a> .</p>
            <p>Hardt, Steve, and Lia C. R. Lopes. 2015. “Google Votes: A Liquid Democracy Experiment on a Corporate Social Network.” Technical Disclosure Commons, Defensive Publications Series , June. <a href="https://www.tdcommons.org/dpubs_series/79">https://www.tdcommons.org/dpubs_series/79</a> .</p>
            <p>Hsiao, Yu-Tang, Shu-Yang Lin, Audrey Tang, Darshana Narayanan, and Claudina Sarahe. 2018. “vTaiwan: An Empirical Study of Open Consultation Process in Taiwan.” SocArXiv, July. <a href="https://doi.org/10.31235/osf.io/xyhft">https://doi.org/10.31235/osf.io/xyhft</a> .</p>
            <p>Jensen, Johannes Rude, Victor von Wachter, and Omri Ross. 2021. “How Decentralized Is the Governance of Blockchain-based Finance: Empirical Evidence from Four Governance Token Distributions.” arXiv. <a href="https://doi.org/10.48550/arXiv.2102.10096">https://doi.org/10.48550/arXiv.2102.10096</a> .</p>
            <p>Kelso, Louis O., and Patricia Hetter Kelso. 1986. Democracy and Economic Power: Extending the ESOP Revolution Through Binary Economics . Cambridge, MA: Ballinger Publishing. <a href="http://books.google.com?id=_R5XDQAAQBAJ">http://books.google.com?id=_R5XDQAAQBAJ</a> .</p>
            <p>Klein, Naomi. 2008. The Shock Doctrine: The Rise of Disaster Capitalism . Picador.</p>
            <p>Kreutler, Kai. 2021. “A Prehistory of DAOs.” Mirror. Gnosis Guild. July 21, 2021. <a href="https://gnosisguild.mirror.xyz/t4F5rItMw4-mlpLZf5JQhElbDfQ2JRVKAzEpanyxW1Q">https://gnosisguild.mirror.xyz/t4F5rItMw4-mlpLZf5JQhElbDfQ2JRVKAzEpanyxW1Q</a> .</p>
            <p>Linares, Julio, and Gabriela Cabaña. 2019. “Towards an Ecology of Care: Basic Income Beyond the Nation-State.” In Basic Income: The Material Conditions of Freedom , 56–61. Hyderabad, India. <a href="https://basicincome.org/wp-content/uploads/2020/01/Julio-Linares-Gabriela-Caba%C3%B1a.pdf">https://basicincome.org/wp-content/uploads/2020/01/Julio-Linares-Gabriela-Cabaña.pdf</a> .</p>
            <p>Magnuson, William. 2020. Blockchain Democracy: Technology, Law and the Rule of the Crowd . Cham, Switzerland: Palgrave Macmillan. <a href="https://doi.org/10.1007/978-3-030-25602-8">https://doi.org/10.1007/978-3-030-25602-8</a> .</p>
            <p>Mannan, Morshed, and Nathan Schneider. 2021. “Exit to Community: Strategies for Multi-Stakeholder Ownership in the Platform Economy.” Georgetown Law Technology Review 5 (1). <a href="https://georgetownlawtechreview.org/exit-to-community-strategies-for-multi-stakeholder-ownership-in-the-platform-economy/GLTR-05-2021/">https://georgetownlawtechreview.org/exit-to-community-strategies-for-multi-stakeholder-ownership-in-the-platform-economy/GLTR-05-2021/</a> .</p>
            <p><a>Mehlman, Jeffrey. 1972. “The "Floating Signifier": From Lévi-Strauss to Lacan.” Yale French Studies, no. 48: 10–37.</a> <a href="https://doi.org/10.2307/2929621">https://doi.org/10.2307/2929621</a>.</p>
            <p><a>Michel, Lawrence. 2012. “The Wedges Between Productivity and Median Compensation Growth.” 330. Issue Brief. Economic Policy Institute.</a></p>
            <p><a>Molk, Peter. 2014. “The Puzzling Lack of Cooperatives.” Tulane Law Review 88 (5): 899–958.</a></p>
            <p><a>Morozov, Evgeny. 2014. To Save Everything, Click Here. Reprint edition. New York: PublicAffairs.</a></p>
            <p><a>Nakamoto, Satoshi. 2009. “Bitcoin Open Source Implementation of P2P Currency.” P2P Foundation on Ning. February 11, 2009.</a> <a href="http://p2pfoundation.ning.com/forum/topics/bitcoin-open-source">http://p2pfoundation.ning.com/forum/topics/bitcoin-open-source</a>.</p>
            <p><a>Niemeyer, Simon. 2014. “Scaling up Deliberation to Mass Publics: Harnessing Mini-Publics in a Deliberative System.” In Deliberative Mini-Publics: Involving Citizens in the Democratic Process, edited by André Bächtiger, 177–202. ECPR Press.</a></p>
            <p><a>Owocki, Kevin. 2022. GreenPilled: How Crypto Can Regenerate the World. Gitcoin.</a></p>
            <p><a>———. 2021. “A Brief History of Gitcoin from 2017 - 2022.” Gitcoin Governance. December 15, 2021.</a> <a href="https://gov.gitcoin.co/t/a-brief-history-of-gitcoin-from-2017-2022/9431">https://gov.gitcoin.co/t/a-brief-history-of-gitcoin-from-2017-2022/9431</a>.</p>
            <p><a>Papacharissi, Zizi. 2021. After Democracy: Imagining Our Political Future. New Haven: Yale University Press.</a></p>
            <p><a>Pek, Simon. 2019. “Drawing Out Democracy: The Role of Sortition in Preventing and Overcoming Organizational Degeneration in Worker-Owned Firms.” Journal of Management Inquiry, August, 105649261986803.</a> <a href="https://doi.org/10.1177/1056492619868030">https://doi.org/10.1177/1056492619868030</a>.</p>
            <p><a>Posner, Eric A., and E. Glen Weyl. 2014. “Quadratic Voting as Efficient Corporate Governance.” The University of Chicago Law Review 81 (1): 251–72.</a> <a href="http://www.jstor.org/stable/23646377">http://www.jstor.org/stable/23646377</a>.</p>
            <p><a>Postman, Neil. 1993. Technopoly: The Surrender of Culture to Technology. New York: Vintage.</a></p>
            <p><a>Prado, Jason Barrett. 2021. “DAOs Are Interesting, Likely, and Terrifying.” Substack newsletter. venturecommune. November 28, 2021.</a> <a href="https://venturecommune.substack.com/p/daos-are-interesting-likely-and-terrifying">https://venturecommune.substack.com/p/daos-are-interesting-likely-and-terrifying</a>.</p>
            <p><a>Putnam, Robert D., Robert Leonardi, and Raffaella Y. Nanetti. 1994. “Making Democracy Work.” In Making Democracy Work. Princeton university press.</a></p>
            <p><a>Radebaugh, Jacqueline, and Yev Muchnik. 2021. “Solving the Riddle of the DAO with Colorado’s Cooperative Laws.” The Defiant, December 16, 2021.</a> <a href="https://thedefiant.io/solving-the-riddle-of-the-dao-with-colorados-cooperative-laws/">https://thedefiant.io/solving-the-riddle-of-the-dao-with-colorados-cooperative-laws/</a>.</p>
            <p><a>Reijers, Wessel, Fiachra O’Brolcháin, and Paul Haynes. 2016. “Governance in Blockchain Technologies & Social Contract Theories.” Ledger 1 (December): 134–51.</a> <a href="https://doi.org/10.5195/ledger.2016.62">https://doi.org/10.5195/ledger.2016.62</a>.</p>
            <p><a>Robbins, Robert B., Cindy V. Schlaefer, and Jessica Lutrin. 2018. “From Home Sharing and Ride Sharing to Shareholding.” Pillsbury Law. October 25, 2018.</a> <a href="https://www.pillsburylaw.com/en/news-and-insights/rule-701-revision-uber-airbnb.html">https://www.pillsburylaw.com/en/news-and-insights/rule-701-revision-uber-airbnb.html</a>.</p>
            <p><a>Robey, Austin. 2022. “What Co-ops and DAOs Can Learn from Each Other.” Friends with Benefits blog. 2022.</a> <a href="https://www.fwb.help/wip/what-co-ops-and-daos-can-learn-from-each-other">https://www.fwb.help/wip/what-co-ops-and-daos-can-learn-from-each-other</a>.</p>
            <p><a>Schneider, Nathan. 2018. Everything for Everyone: The Radical Tradition That Is Shaping the Next Economy. New York: Nation Books.</a></p>
            <p><a>———. 2020a. “Digital Kelsoism: Employee Stock Ownership as a Pattern for the Online Economy.” In Reimagining the Governance of Work and Employment, edited by Dionne Pohler, 234–46. Ithaca, NY: Cornell University Press.</a> <a href="https://osf.io/7wrab/">https://osf.io/7wrab/</a>.</p>
            <p><a>———. 2021. “Admins, Mods, and Benevolent Dictators for Life: The Implicit Feudalism of Online Communities.” New Media & Society.</a> <a href="https://doi.org/10.1177/1461444820986553">https://doi.org/10.1177/1461444820986553</a>.</p>
            <p><a>———. 2022. “Cryptoeconomics as a Limitation on Governance.” Working paper.</a> <a href="https://ntnsndr.in/Cryptoecon">https://ntnsndr.in/Cryptoecon</a>.</p>
            <p><a>———. 2019. “Decentralization: An Incomplete Ambition.” Journal of Cultural Economy 12 (4): 1–21.</a> <a href="https://doi.org/10.1080/17530350.2019.1589553">https://doi.org/10.1080/17530350.2019.1589553</a>.</p>
            <p><a>———. 2020b. “Mediated Ownership: Capital as Media.” Media, Culture & Society 42 (3): 449–59.</a> <a href="https://doi.org/10.1177/0163443719899035">https://doi.org/10.1177/0163443719899035</a>.</p>
            <p><a>Silva-Leander, Annika. 2021. “Global State of Democracy Report 2021: Building Resilience in a Pandemic Era.” Stockholm, Sweden: International IDEA.</a> <a href="https://www.idea.int/gsod/global-report">https://www.idea.int/gsod/global-report</a>.</p>
            <p><a>Smith, Adrian, and Pedro Prieto Martín. 2021. “Going Beyond the Smart City? Implementing Technopolitical Platforms for Urban Democracy in Madrid and Barcelona.” Journal of Urban Technology 28 (1-2): 311–30.</a> <a href="https://doi.org/10.1080/10630732.2020.1786337">https://doi.org/10.1080/10630732.2020.1786337</a>.</p>
            <p><a>Smith, Matthew, Danny Yagan, Owen Zidar, and Eric Zwick. 2019. “Capitalists in the Twenty-First Century.” Quarterly Journal of Economics.</a></p>
            <p><a>Soleimani, Ameen, Arjun Bhuptani, James Young, Layne Haber, and Rahul Sethuram. 2019. “The Moloch DAO: Beating the Tragedy of the Commons Using Decentralized Autonomous Organizations.”</a> <a href="https://raw.githubusercontent.com/MolochVentures/Whitepaper/master/Whitepaper.pdf">https://raw.githubusercontent.com/MolochVentures/Whitepaper/master/Whitepaper.pdf</a>.</p>
            <p><a>Spicer, Jason. 2021. “Cooperative Enterprise at Scale: Comparative Capitalisms and the Political Economy of Ownership.” Socio-Economic Review, October, mwab010.</a> <a href="https://doi.org/10.1093/ser/mwab010">https://doi.org/10.1093/ser/mwab010</a>.</p>
            <p><a>Stempeck, Matt. 2020. “Next-Generation Engagement Platforms, and How They Are Useful Right Now (Part 1).” Civicist. May 12, 2020.</a> <a href="https://web.archive.org/web/20201101012224/https:/civichall.org/civicist/next-generation-engagement-platforms-and-how-are-they-useful-right-now-part-1/">https://web.archive.org/web/20201101012224/https://civichall.org/civicist/next-generation-engagement-platforms-and-how-are-they-useful-right-now-part-1/</a>.</p>
            <p><a>Swartz, Lana. 2017. “Blockchain Dreams: Imagining Techno-E­conomic Alternatives After Bitcoin.” In Another Economy Is Possible: Culture and Economy in a Time of Crisis, edited by Manuel Castells, 82–105. Cambridge, UK: Polity.</a></p>
            <p><a>Thurman, Andrew. 2021. “Ethereum Name Service Tokens Soar After $500M+ Airdrop.” November 9, 2021.</a> <a href="https://www.coindesk.com/business/2021/11/09/ethereum-name-service-tokens-soar-after-500m-airdrop/">https://www.coindesk.com/business/2021/11/09/ethereum-name-service-tokens-soar-after-500m-airdrop/</a>.</p>
            <p><a>Tocqueville, Alexis de. (1840) 2006. Democracy in America. Translated by Henry Reeve. Project Gutenberg.</a> <a href="https://www.gutenberg.org/ebooks/815%20&amp;%20https:/www.gutenberg.org/ebooks/816">https://www.gutenberg.org/ebooks/815 & https://www.gutenberg.org/ebooks/816</a>.</p>
            <p><a>Tseng, Yu-Shan. 2022. “Rethinking Gamified Democracy as Frictional: A Comparative Examination of the Decide Madrid and vTaiwan Platforms.” Social & Cultural Geography 0 (0): 1–18.</a> <a href="https://doi.org/10.1080/14649365.2022.2055779">https://doi.org/10.1080/14649365.2022.2055779</a>.</p>
            <p>Mannan, Morshed, and Nathan Schneider. 2021. “Exit to Community: Strategies for Multi-Stakeholder Ownership in the Platform Economy.” Georgetown Law Technology Review 5, no. 1 (2021): <a href="https://georgetownlawtechreview.org/exit-to-community-strategies-for-multi-stakeholder-ownership-in-the-platform-economy/GLTR-05-2021/">https://georgetownlawtechreview.org/exit-to-community-strategies-for-multi-stakeholder-ownership-in-the-platform-economy/GLTR-05-2021/</a> .</p>
            <p>Mehlman, Jeffrey. 1972. “The "Floating Signifier": From Lévi-Strauss to Lacan.” Yale French Studies, no. 48 (1972): 10–37. <a href="https://doi.org/10.2307/2929621">https://doi.org/10.2307/2929621</a> .</p>
            <p>Michel, Lawrence. 2012. “The Wedges Between Productivity and Median Compensation Growth.” Economic Policy Institute: Issue Brief 330.</p>
            <p>Molk, Peter. 2014. “The Puzzling Lack of Cooperatives.” Tulane Law Review 88, no. 5 (2014): 899–958.</p>
            <p>Morozov, Evgeny. 2014. To Save Everything, Click Here. Reprint edition. New York: PublicAffairs.</p>
            <p>Nakamoto, Satoshi. 2009. “Bitcoin Open Source Implementation of P2P Currency.” P2P Foundation on Ning, February 11, 2009. <a href="http://p2pfoundation.ning.com/forum/topics/bitcoin-open-source">http://p2pfoundation.ning.com/forum/topics/bitcoin-open-source</a> .</p>
            <p>Niemeyer, Simon. 2014. “Scaling up Deliberation to Mass Publics: Harnessing Mini-Publics in a Deliberative System.” In Deliberative Mini-Publics: Involving Citizens in the Democratic Process , edited by André Bächtiger, 177–202. ECPR Press.</p>
            <p>Owocki, Kevin. 2022. GreenPilled: How Crypto Can Regenerate the World . Gitcoin.</p>
            <p>———. 2021. “A Brief History of Gitcoin from 2017 - 2022.” Gitcoin Governance , December 15, 2021. <a href="https://gov.gitcoin.co/t/a-brief-history-of-gitcoin-from-2017-2022/9431">https://gov.gitcoin.co/t/a-brief-history-of-gitcoin-from-2017-2022/9431</a> .</p>
            <p>Papacharissi, Zizi. 2021. After Democracy: Imagining Our Political Future . New Haven: Yale University Press.</p>
            <p>Pek, Simon. 2019. “Drawing Out Democracy: The Role of Sortition in Preventing and Overcoming Organizational Degeneration in Worker-Owned Firms.” Journal of Management Inquiry, August, 105649261986803. <a href="https://doi.org/10.1177/1056492619868030">https://doi.org/10.1177/1056492619868030</a> .</p>
            <p>Posner, Eric A., and E. Glen Weyl. 2014. “Quadratic Voting as Efficient Corporate Governance.” The University of Chicago Law Review 81, no. 1 (2014): 251–72. <a href="http://www.jstor.org/stable/23646377">http://www.jstor.org/stable/23646377</a> .</p>
            <p>Postman, Neil. 1993. Technopoly: The Surrender of Culture to Technology . New York: Vintage.</p>
            <p>Prado, Jason Barrett. 2021. “DAOs Are Interesting, Likely, and Terrifying.” Substack newsletter. venturecommune, November 28, 2021. <a href="https://venturecommune.substack.com/p/daos-are-interesting-likely-and-terrifying">https://venturecommune.substack.com/p/daos-are-interesting-likely-and-terrifying</a> .</p>
            <p>Putnam, Robert D., Robert Leonardi, and Raffaella Y. Nanetti. 1994. Making Democracy Work . Princeton university press.</p>
            <p>Radebaugh, Jacqueline, and Yev Muchnik. 2021. “Solving the Riddle of the DAO with Colorado’s Cooperative Laws.” The Defiant, December 16, 2021. <a href="https://thedefiant.io/solving-the-riddle-of-the-dao-with-colorados-cooperative-laws/">https://thedefiant.io/solving-the-riddle-of-the-dao-with-colorados-cooperative-laws/</a> .</p>
            <p>Reijers, Wessel, Fiachra O’Brolcháin, and Paul Haynes. 2016. “Governance in Blockchain Technologies & Social Contract Theories.” Ledger 1 (December): 134–51. <a href="https://doi.org/10.5195/ledger.2016.62">https://doi.org/10.5195/ledger.2016.62</a> .</p>
            <p>Robbins, Robert B., Cindy V. Schlaefer, and Jessica Lutrin. 2018. “From Home Sharing and Ride Sharing to Shareholding.” Pillsbury Law, October 25, 2018. <a href="https://www.pillsburylaw.com/en/news-and-insights/rule-701-revision-uber-airbnb.html">https://www.pillsburylaw.com/en/news-and-insights/rule-701-revision-uber-airbnb.html</a> .</p>
            <p>Robey, Austin. 2022. “What Co-ops and DAOs Can Learn from Each Other.” Friends with Benefits blog , 2022. <a href="https://www.fwb.help/wip/what-co-ops-and-daos-can-learn-from-each-other">https://www.fwb.help/wip/what-co-ops-and-daos-can-learn-from-each-other</a> .</p>
            <p>Schneider, Nathan. 2018. Everything for Everyone: The Radical Tradition That Is Shaping the Next Economy . New York: Nation Books.</p>
            <p>———. 2020a. “Digital Kelsoism: Employee Stock Ownership as a Pattern for the Online Economy.” In Reimagining the Governance of Work and Employment , edited by Dionne Pohler, 234–46. Ithaca, NY: Cornell University Press. <a href="https://osf.io/7wrab/">https://osf.io/7wrab/</a> .</p>
            <p>———. 2021. “Admins, Mods, and Benevolent Dictators for Life: The Implicit Feudalism of Online Communities.” New Media & Society. <a href="https://doi.org/10.1177/1461444820986553">https://doi.org/10.1177/1461444820986553</a> .</p>
            <p>———. 2022. “Cryptoeconomics as a Limitation on Governance.” Working paper. <a href="https://ntnsndr.in/Cryptoecon">https://ntnsndr.in/Cryptoecon</a>.</p>
            <p>———. 2019. “Decentralization: An Incomplete Ambition.” Journal of Cultural Economy 12, no. 4 (2019): 1–21. <a href="https://doi.org/10.1080/17530350.2019.1589553">https://doi.org/10.1080/17530350.2019.1589553</a> .</p>
            <p>———. 2020b. “Mediated Ownership: Capital as Media.” Media, Culture & Society 42, no. 3 (2020): 449–59. <a href="https://doi.org/10.1177/0163443719899035">https://doi.org/10.1177/0163443719899035</a> .</p>
            <p>Silva-Leander, Annika. 2021. “Global State of Democracy Report 2021: Building Resilience in a Pandemic Era.” Stockholm, Sweden: International IDEA. <a href="https://www.idea.int/gsod/global-report">https://www.idea.int/gsod/global-report</a> .</p>
            <p>Smith, Adrian, and Pedro Prieto Martín. 2021. “Going Beyond the Smart City? Implementing Technopolitical Platforms for Urban Democracy in Madrid and Barcelona.” Journal of Urban Technology 28, no. 1-2 (2021): 311–30. <a href="https://doi.org/10.1080/10630732.2020.1786337">https://doi.org/10.1080/10630732.2020.1786337</a> .</p>
            <p>Smith, Matthew, Danny Yagan, Owen Zidar, and Eric Zwick. 2019. “Capitalists in the Twenty-First Century.” Quarterly Journal of Economics.</p>
            <p>Soleimani, Ameen, Arjun Bhuptani, James Young, Layne Haber, and Rahul Sethuram. 2019. “The Moloch DAO: Beating the Tragedy of the Commons Using Decentralized Autonomous Organizations.” <a href="https://raw.githubusercontent.com/MolochVentures/Whitepaper/master/Whitepaper.pdf">https://raw.githubusercontent.com/MolochVentures/Whitepaper/master/Whitepaper.pdf</a> .</p>
            <p>Spicer, Jason. 2021. “Cooperative Enterprise at Scale: Comparative Capitalisms and the Political Economy of Ownership.” Socio-Economic Review , October, mwab010. <a href="https://doi.org/10.1093/ser/mwab010">https://doi.org/10.1093/ser/mwab010</a> .</p>
            <p>Stempeck, Matt. 2020. “Next-Generation Engagement Platforms, and How They Are Useful Right Now (Part 1).” Civicist, May 12, 2020. <a href="https://web.archive.org/web/20201101012224/https:/civichall.org/civicist/next-generation-engagement-platforms-and-how-are-they-useful-right-now-part-1/">https://web.archive.org/web/20201101012224/https://civichall.org/civicist/next-generation-engagement-platforms-and-how-are-they-useful-right-now-part-1/</a> .</p>
            <p>Swartz, Lana. 2017. “Blockchain Dreams: Imagining Techno-E­conomic Alternatives After Bitcoin.” In Another Economy Is Possible: Culture and Economy in a Time of Crisis , edited by Manuel Castells, 82–105. Cambridge, UK: Polity.</p>
            <p>Thurman, Andrew. 2021. “Ethereum Name Service Tokens Soar After $500M+ Airdrop.” November 9, 2021. <a href="https://www.coindesk.com/business/2021/11/09/ethereum-name-service-tokens-soar-after-500m-airdrop/">https://www.coindesk.com/business/2021/11/09/ethereum-name-service-tokens-soar-after-500m-airdrop/</a> .</p>
            <p>Tocqueville, Alexis de. (1840) 2006. Democracy in America. Translated by Henry Reeve. Project Gutenberg. <a href="https://www.gutenberg.org/ebooks/815">https://www.gutenberg.org/ebooks/815</a> & <a href="https://www.gutenberg.org/ebooks/816">https://www.gutenberg.org/ebooks/816</a> .</p>
            <p>Tseng, Yu-Shan. 2022. “Rethinking Gamified Democracy as Frictional: A Comparative Examination of the Decide Madrid and vTaiwan Platforms.” Social & Cultural Geography 0, no. 0 (2022): 1–18. <a href="https://doi.org/10.1080/14649365.2022.2055779">https://doi.org/10.1080/14649365.2022.2055779</a> .</p>
            <p>Walch, Angela. 2019. “Deconstructing ‘Decentralization’: Exploring the Core Claim of Crypto Systems.” In Cryptoassets, edited by Chris Burniske and Jack Tatar, 41-54. New York: Oxford University Press. <a href="https://doi.org/10.1093/oso/9780190077310.003.0003">https://doi.org/10.1093/oso/9780190077310.003.0003</a> .</p>
            <p>Walden, Jesse. 2019. “Past, Present, Future: From Co-ops to Cryptonetworks.” Variant Fund blog, March 2, 2019. <a href="https://variant.fund/past-present-future-from-co-ops-to-cryptonetworks/">https://variant.fund/past-present-future-from-co-ops-to-cryptonetworks/</a> .</p>
            <p>Wenger, Albert. 2016. World After Capital. <a href="http://archive.org/details/WorldAfterCapital">http://archive.org/details/WorldAfterCapital</a> .</p>
            <p>Weyl, E. Glen, Puja Ohlhaver, and Vitalik Buterin. 2022. “Decentralized Society: Finding Web3’s Soul.” 4105763. Social Science Research Network. <a href="https://doi.org/10.2139/ssrn.4105763">https://doi.org/10.2139/ssrn.4105763</a> .</p>
            <p>White, Molly. n.d. “Web3 Is Going Just Great.” Accessed June 8, 2022. <a href="https://web3isgoinggreat.com/">https://web3isgoinggreat.com/</a> .</p>
            <p>Wilson, Fred. 2016. “The Golden Age of Open Protocols.” AVC, July 31, 2016. <a href="https://avc.com/2016/07/the-golden-age-of-open-protocols/">https://avc.com/2016/07/the-golden-age-of-open-protocols/</a> .</p>
            <p>Winner, Langdon. 1980. “Do Artifacts Have Politics?” Daedalus 109 (1): 121-136. <a href="http://www.jstor.org/stable/20024652">http://www.jstor.org/stable/20024652</a> .</p>
            <p>Wolff, Edward N. 2017. “Household Wealth Trends in the United States, 1962 to 2016: Has Middle Class Wealth Recovered?” Working Paper 24085. National Bureau of Economic Research. <a href="https://doi.org/10.3386/w24085">https://doi.org/10.3386/w24085</a> .</p>
            <p>Zuboff, Shoshana. 2019. The Age of Surveillance Capitalism: The Fight for a Human Future at the New Frontier of Power . New York: PublicAffairs.</p>



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default schneider;
