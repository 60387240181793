import image1 from "../../assets/images/homeV3/joker.png";
import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import image19 from "../../assets/images/kycimg/location.svg";
import image20 from "../../assets/images/kycimg/call-outgoing.svg";
import image21 from "../../assets/images/kycimg/sms-notification.svg";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function About() {
  return (
    <div>
      <PageLoaderCircle delay={1500} />

      <div id="sc-banner" className="sc_banner_v3 banner-bg position-relative">
        <div className="container">
          <div className="banner-content v3_banner_content">
            <div className="row">
              <div className="col-md-6">
                <div className="sc_banner_v3_left">
                  <h2
                    className="wow fadeInUp"
                    data-wow-delay="0.4s"
                    data-wow-duration="0.6s"
                  >
                    <span>Colophon & About</span>
                  </h2>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay="0.5s"
                    data-wow-duration="0.7s"
                  >
                    CryptoCarnival is a collaboration between three editors,
                    twenty-two authors, and the Web3 community.
                  </p>

                  <p class="displaytext">
                    <a href="https://iqdupont.com">Quinn DuPont</a>, <a href="https://people.ucd.ie/paul.dylan-ennis">Paul Dylan-Ennis</a>, and <a href="https://people.ucd.ie/donncha.kavanagh">Donncha Kavanagh</a> are the editors. See the <a href="/introduction">Introduction</a> for more information about our motivations.
                  </p>
                  <p>
                    This website was designed and built by Quinn DuPont. It is a
                    crude edit of a commercially available Web3 theme that was
                    then converted into react by a freelancer who also helped mint our token, build <a href="/faucet">the faucet</a>, and develop the <a href="/dice">Gambler Dice Game</a>.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sc_banner_v3_right">
                  <div className="animetion_avater">
                    <img src={image1} alt="img" className="img-fluid" />
                  </div>
                  <ul>
                    <li>
                      <h3>Word count</h3>
                      <h4 className="counter-number">
                        <span className="counter">79,000</span>{" "}
                      </h4>
                    </li>
                    <li>
                      <h3>Tokens Minted</h3>
                      <h4 className="counter-number">
                        <span className="counter">350,000,000</span>{" "}
                      </h4>
                    </li>
                    <li className="m-0">
                      <h3>Funding Raised</h3>
                      <h4 className="counter-number">
                        <span className="counter">1.1</span> eth
                      </h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kyc_form_sect">
        <div className="container">
          <div className="row m-0">
            <h3>Contact the editors</h3>
            <div className="col-md-6 p-0">
              <div className="contact_form">
                <div className="input__group__between">
                  <form
                    action="https://public.herotofu.com/v1/5d2859d0-5098-11ed-8970-6943e4ac8982"
                    method="post"
                  >
                    <div className="input__group mb-23">
                      <label for="name">Your Name</label>
                      <input
                        size="30"
                        name="Name"
                        id="name"
                        type="text"
                        placeholder="e.g. Smith"
                        required
                      />
                    </div>
                    <div className="input__group mb-23">
                      <label for="email">Your Email</label>
                      <input
                        size="30"
                        name="Email"
                        id="email"
                        type="email"
                        placeholder="e.g. example@gmail.com"
                        required
                      />
                    </div>
                    <div className="theme-btn blue">
                      <input type="submit" value="Contact Us" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="get_in_touch_contact_sect">
                <div className="get_in_touch_contact_content">
                  <h2 id="contact">Contact Info</h2>
                  <div className="contact_info_content">
                    <div className="contact_info_symbol">
                      <img src={image19} alt="icon" className="img-fluid" />
                    </div>
                    <div className="contact_info_symbol">
                      <h5>CryptoCarnival HQ</h5>
                      <h6>Somewhere in the Metaverse</h6>
                      <a href="https://cryptocarnival.wtf">
                        https://cryptocarnival.wtf
                      </a>
                    </div>
                  </div>
                  <div className="contact_info_content">
                    <div className="contact_info_symbol">
                      <img src={image20} alt="icon" className="img-fluid" />
                    </div>
                    <div className="contact_info_symbol">
                      <h5>Telegram</h5>
                      <h6>
                        <a href="https://t.me/cryptocarnivalz">
                          https://t.me/cryptocarnivalz
                        </a>
                      </h6>
                    </div>
                  </div>
                  <div className="contact_info_content">
                    <div className="contact_info_symbol">
                      <img src={image21} alt="icon" className="img-fluid" />
                    </div>
                    <div id="donate" className="contact_info_symbol">
                      <h5>Donate</h5>
                      <h6>
                        Ethereum:{" "}
                        <a href="https://etherscan.io/address/0x9837c2370f3321C7a07927BD3819C100eF8fC20f">
                          0x9837c2370f3321C7a07927BD3819C100eF8fC20f
                        </a>
                      </h6>
                      <h6>
                        Bitcoin:{" "}
                        <a href="https://live.blockcypher.com/btc/address/bc1pxkmr7cfy4ykxz0vz53kyz7jc8m00c95fdp6qk0rec4dcg2xg08rskwva6g/">
                          bc1pxkmr7cfy4ykxz0vz53kyz7jc8m00c95fdp6qk0rec4dcg2xg08rskwva6g
                        </a>
                      </h6>
                      <h6>
                        Binance-Peg Avalanche (BEP20):{" "}
                        <a href="https://bscscan.com/address/0x9837c2370f3321C7a07927BD3819C100eF8fC20f">
                          0x9837c2370f3321C7a07927BD3819C100eF8fC20f
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FOOTER */} 
      <div className="gamfi-footer-section">
        <div className="footer-area">
            <div className="sec-heading text-center">
                <div className="sub-inner mb-52">
                    <img
                    className="heading-right-image"
                    src={image16}
                    alt="Steps-Image"
                    />
                    <span id="shareSocial" className="sub-title white-color">
                    Share on Social
                    </span>
                    <img
                    className="heading-left-image"
                    src={image2}
                    alt="Steps-Image"
                    />
                </div>
                <div className="footer-listing text-center mb-100 md-mb-70">
                    <ul className="footer-icon-list">
                        <li>
                        <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                            <i className="icon-twitter"></i>
                        </a>
                        </li>
                        <li>
                        <a href="https://t.me/cryptocarnivalz">
                            <i className="icon-telegram"></i>
                        </a>
                        </li>
                        <li>
                        <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                            <i className="icon-medium"></i>
                        </a>
                        </li>
                        <li>
                        <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                            <i className="icon-linkedin"></i>
                        </a>
                        </li>
                        <li>
                        <a href="#">
                            <i className="icon-instagram"></i>
                        </a>
                        </li>
                        <li>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                            <i className="icon-facebook"></i>
                        </a>
                        </li>
                    </ul>
                </div>
                <div className="footer-logo text-center mb-45">
                    <a href="/index">
                        <img src={image18} alt="Footer-logo" />
                    </a>
                </div>
                <div className="footer-mainmenu text-center mb-20">
                    <ul>
                        <li>
                        <a href="/index#Whatiscryptocarnival">How it works</a>
                        </li>
                        <li>
                        <a href="/essays">Read essays</a>
                        </li>
                        <li>
                        <a href="/dice">Play games</a>
                        </li>
                        <li>
                        <a href="/faucet">Receive tokens</a>
                        </li>
                        <li>
                        <a href="/NFT">Receive NFT</a>
                        </li>
                        <li>
                        <a href="/about">About</a>
                        </li>
                        <li>
                        <a href="/about#contact">Contact</a>
                        </li>
                    </ul>
                </div>
                <div className="copyright-area text-center mb-0">
                    <p className="dsc mb-37">
                    Copyright © 2022. All Rights Reserved by
                    <a target="_blank" className="gafi" href="#">
                        {" "}
                        the Authors
                    </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    {/* END FOOTER */}
    </div>
  );
}

export default About;
