import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function dupont() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>A Progressive Web3: From Social Coproduction to Digital Polycentric Governance</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Quinn DuPont
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089005">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/f528db25-7ff0-4fed-a99c-65402442044e">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            This essay critically evaluates the political economy of Web3 and offers a neo-institutional model to explain qualitative observations of contemporary digital social movements. By starting to develop a sociological model of Web3 rooted in micro-organizational practices, including trust mediation and social coproduction, this essay re-evaluates assumptions of scarcity, economic value, and social belonging. It concludes by introducing a novel research programme to study digital polycentric governance that focuses on community self-governance of digital common pool resources (DCPRs) and looks forward to empirical research using on-chain datasets from Decentralized Autonomous Organizations (DAOs).
            </p>
            <hr />
            <p class="displayquotation">
            Now, should we trust these people, no matter how well-intentioned they might be, to guide society into the future… I’d say “no.” ~ Evgeny Morozov, “Web3: A Map in Search of a Territory”
            </p>


            <p>
                In a recent essay, Evgeny Morozov (2022) offers a critical biography of
                Web3 by tracing its popular rhetoric through Tim O’Reilly and Marc
                Andreessen. Inhabiting the spirit of arche-critic David Golumbia, he
                discovers vast “linguistic and analytical pollution” in Web3,
                underpinned by opportunistic hustlers. He accuses Web3 advocates of
                self-referentiality and performativity, questioning whether Web3 is even
                a meaningful analytical concept. The issue, Morozov emphasizes, is not
                that “Web3” or “metaverse” are poorly defined words (though they might
                be), nor that some “academic, intellectuals, and policy experts… [will]
                accept cash for lending their names and reputations” (they have; see
                DuPont, 2020), rather, the value of even “well-intentioned” Web3
                projects is pretty much nothing (“it’s spin all the way down,” Morozov
                chides). Worse still, these well-intentioned rubes act like capitalism’s
                yeomen and are basically responsible for “left-washing” the Web3 brand.
            </p>
            <p>
                Intuitively supported by a decade’s worth of careful observation and
                scientific research on cryptocurrencies and blockchains, I agree with
                many of Morozov’s criticisms. I consider critics like Morozov, Golumbia,
                Gerard, and more recently, White astute in their analyses and much
                needed voices in a conversation that tends towards credulity. But, I’ve
                also found that critics sometimes paint with too broad of a brush,
                lumping their Bitcoiners and frens into the same lazy category. And over
                the years I’ve also witnessed dramatic social evolution in “the scene,”
                of which Web3 is but one frenetic little corner. Specificity matters.
            </p>
            <p>
                So, I committed to explore Web3 and to live as close to the code as my
                lifestyle permits (no degen for me, thanks). I have been out on the DeFi
                farms in search of yield, I joined a chorus of Discord servers
                incessantly pinging with new notifications, I cruised the metaverse,
                bought some virtual art and property, and turned my digital wallet into
                a multitool for any situation. During my travels, I maintained a virtual
                open-door policy that led to dozens of conversations with dreamers,
                enthusiasts, hustlers, builders, and money-makers (I rounded out the
                haphazard selection by strategically interviewing Web3 developers and
                community members). Continuing my search for the holy grail of
                decentralized autonomous organizations (DAOs; DuPont, 2018), I also dug
                into the latest cryptoeconomics, blockchain-based organizational
                designs, and novel forms of distributed governance. I return from my
                Web3 sojourn with measured optimism, for I found a progressive Web3 rich
                with innovative ideas, designs, and technologies.
            </p>
            <h1>
                Progressive Design Beyond the Nation State
            </h1>
            <p>
                In this essay, I’m ultimately describing progressive <em>design goals</em>, but Morozov says we need to address the lack of “engagement with the
                political economy of global capitalism.” So let’s do that.
            </p>

            <p>
                We can start by problematizing the role of the nation state.
            </p>
            <p>
                Now a decade since the invention of Bitcoin, I’m surprised daily at how
                a faddish 1990s-era cypherpunk ideology has managed to reify into
                technologies that offer nascent social infrastructures—platforms—for
                life “without” a nation state. Cypherpunk, Solarpunk, or not, it is
                possible, perhaps likely, for Web3 to exist <em>independently</em> of
                the state—to make a land with no government. This does not mean Web3
                will be independent of the nation state, even Bitcoin isn’t, but the
                technological trajectory is pretty clear. However, this life isn’t for
                everyone: for some people, living in a land where “code is law” sounds
                both unrealistic and risky, borderline scary. One Web3 champion,
                Tracheopteryx, describes this new progressive vista as “confronting” to
                those still steeped in traditional culture and neoclassical economics
                (Bankless, 2022). Indeed, this is the ultimate gambit of a progressive
                Web3: that through novel technology, culture, and economic innovation we
                have powerful new tools to design the future of progressive social
                movements.
            </p>
            <p>
                Of course, the critics know the history of crypto as well as I do. We
                all know that crypto shook awake national governments because it
                threatened taxation and economic sovereignty.<a href='#_edn1' name='_ednref1' title=''>[i]</a> The neoliberal state responded as best it could by opening vast
                regulatory mechanisms (while subjugating democracy), which in turn
                accelerated private and public investment in—well, not exactly the
                radical technology promised by Bitcoin, but a sanitized, rentier version
                with well monitored on and off ramps (an intelligence agent once joked
                to me that crypto is just “prosecution futures”). And so, in the
                subsequent years the radical spirit of crypto has been all but smothered
                by the warm embrace of the state. In its place a booming Silicon Valley
                brogrammer culture emerged and infected everything good and holy in the
                critic’s imagination of an open Internet—incidentally, not the Internet
                that was <em>actually</em> developed by the US state and military for
                intelligence, surveillance, and the global spread of American values.<a href='#_edn2' name='_ednref2' title=''>[ii]</a>
            </p>
            <p>
                From the perspective of the ‘closed’ Internet of 2022, largely comprised
                of rent-seeking platforms with supranational influence, Graeber and
                Wengrow’s recent book (2021) is a helpful reminder to think beyond the
                inevitability of Hobbesian and Rousseauian political theory and its
                claim to Westphalian sovereignty. They invite progressives to dream of
                new organizational forms capable of supporting flourishing across human
                scales so that people meaningfully self-organize, in ways where good
                governance becomes essential.
            </p>
            <p>
                Thus, one history of Web3 starts with the opportunistic labelling of a
                technological evolution of computer networking towards a regulated
                (state monitored) infrastructure for the exchange of value. This
                technological evolution implies that the political economy of Web3 is
                defined by money that is exchanged on a programmable network at a
                supranational scale (Swartz, 2020). But importantly Web3 is a <em>programmable</em> money (Caliskan, 2020), having the characteristics of an “immutable
                mobile” because it enables “both mobilization and immutability… at the
                same time” (Latour, 1986, p.10). Like the Internet and dozens of other
                (notational) technologies before it, blockchain technology is <em>mobile</em> because it supports the circulation of value (an economy)<a href='#_edn3' name='_ednref3' title=''>[iii]</a> and is <em>immutable</em> because transaction records are accepted as
                authoritative.
            </p>
            <p>
                Immutable mobiles are not just academic curiosities—they are important
                because they serve to <em>convince</em>, exhibiting the same influence
                as maps and books did for earlier media. An important consequence of
                blockchain technology is that it makes things visible (DuPont and
                Maurer, 2015)—so that, as Latour (1986) offers, “no matter how
                inaccurate these traces might be at first, they will all become accurate
                just as a consequence of more mobilization and more immutability”
                (p.12). Moreover, Latour’s insight shows why any reasonable account of
                the political economy of Web3 must accept that materialist explanations
                cannot “kneel before one specific science, that of economics” (Latour,
                1986, p.3).
            </p>
            <h1>
                Prefiguration: A map for a progressive Web3
            </h1>
            <p>
                In this essay I offer a map—my map—to a progressive Web3, an evolution
                of social form from social coproduction to digital polycentric
                governance. Over the last year I’ve been humbled by a groundswell of
                progressive Web3 efforts that have emerged—e.g., Gitcoin’s quadratic
                cryptoeconomics, Common Stack’s bonding curves, Gnosis’ explorations of
                polycentric governance, Tribute Lab’s open legal framework, and Colony’s
                DAO tooling, to name a few. While inspired by these wild experiments, I
                depart from other theorizations of Web3 insofar as I situate it in the
                discourse of non-state governance and apply lessons from social
                movements with the design goal of evolving social coproduction.
                <a href='#_edn4' name='_ednref4' title=''>
                    [iv]
                </a>
            </p>
            <p>
                A progressive Web3 must exist beyond the nation state to address the
                power vacuum in global governance that emerged with neoliberalism in the
                late twentieth century. Due to the inefficiencies and epistemological
                limitations of centralized planning and failures of regulatory and
                representative democratic politics, neoliberal nation states have been
                unable to address many “local” and collective action contexts. As a
                decentralized social infrastructure, Web3 offers the possibility of
                cultivating social belonging and community self-governance by
                aggregating intelligence from the edges of the network (through
                consensus, voting, and markets).
            </p>
            <h2>
                The trouble with crypto
            </h2>
            <p>
                Some critics still think crypto is a scam. Aside from being woefully
                uninformed about the preceding decade’s history of crypto successes,
                challenges, and disasters, this view fails to register the meaningful
                and real issues and instead focuses on outdated criticisms of
                technology, regulation, and culture.
            </p>
            <p>
                The history of crypto’s first decade demonstrates that technological,
                regulatory, and cultural challenges are surmountable but require
                effective social embedding. For example, the heart-breaking
                environmental impact of Bitcoin could be fixed if it was more thoroughly
                embedded in a dynamic organizational structure capable of leadership and
                effective change management. Indeed, many modern blockchains have moved
                on from energy-intensive proof of work consensus mechanisms, adopting
                proof of stake or truly next-gen PBFT protocols that use robust
                sub-sampling and sortition techniques for leaderless consensus in
                permissionless networks (e.g., Algorand, Avalanche, Cardano, EOSIO,
                Stellar, and Tron). Likewise, while the regulatory and legal landscape
                continues to evolve, crypto is no longer the state’s bogeyman. Between
                novel uses by traditional financial institutions, e-government, and blue
                chip sectors, crypto has become an inextricable part of dominant capital
                and therefore inherits regulatory “protection” (indeed, crypto’s recent
                move from periphery to core seems to be fuelling another set of
                reappraisals by the Left, like Morozov’s). And finally, for better or
                worse, crypto is no longer characterized by a monolithic culture of
                white, libertarian men—now everyone is in crypto (Perrin, 2021),
                although obviously not equally.
            </p>
            <p>
                Similarly, many of the “old” arguments against Bitcoin no longer
                meaningfully apply to Web3. David Golumbia’s trenchant analysis of
                Bitcoin comes up short today because his critique focuses on Bitcoin’s
                anti-inflation economics, early enthusiast’s weird banking conspiracy
                theories, and the difficulty of fitting Bitcoin into the standard
                tripartite model of money (Golumbia, 2016). Web3 inherits none of these
                issues: novel and sophisticated token engineering in DeFi is the norm,
                depressingly few of the people adopting crypto today are even aware of
                Bitcoin’s unsavoury political past, and I think it is obvious that
                crypto doesn’t fit in the standard definition of money because it is an
                evolution of the very idea of money. David Gerard (2017) is another
                vocal critic and expands Golumbia’s critique by focusing on technical
                and usability issues. Some of these issues remain (e.g., usability is
                still wanting), but next-generation blockchain platforms have largely
                resolved the issues that Gerard focused on or have engineering roadmaps
                for their solution. With software, technological criticisms are usually
                addressed in version upgrades.
            </p>
            <p>
                While some of these challenges remain, they are also, I believe, an
                opportunity for a progressive Web3. But a progressive Web3 must also
                address three, much more difficult criticisms: 1) financialization,
                assetization, and quantification, 2) commodity fetishism, and 3) digital
                inequality. Financialization, assetization, and quantification. This
                assemblage of critique is intimately related to modern economics and the
                impact of computing—which is obviously not unique to Web3—but is often
                callously celebrated within its culture. Zook and Grote (2020) describe
                crypto financialization as an endogenous change to financial
                institutions that results in the increasing prevalence of monetary and
                financial considerations by way of the “cultural process through which
                individuals are reimagined as investors.” More generically, Birch and
                Muniesa (2020) describe assetization as the dominant form of
                technoscientific capitalism, marking a movement away from commodities
                formerly grounded in a material reality. In both descriptions, the
                technosocial processes of financialization and assetization result from
                the circulation of value which requires a prefigured quantification of
                things (i.e., blocks of meaning indexed to code) (DuPont, 2017). These
                are, of course, not new issues. They are foundational to computer
                history more generally and, arguably, some of these processes arose out
                of organizational rationalization that complemented bureaucratization
                (Beniger, 1986).
            </p>
            <p>
                In an already fair and just society, financialization, assetization, and
                quantification don’t pose any really troubling ethical issues, but when
                these institutional “logics” are embedded into real systems they have
                the power to dramatically shape social relations. For instance, failed
                efforts to depoliticize money with crypto illustrate the ways that
                algorithmic control (still) cannot autonomously control macroeconomic
                forces.<a href='#_edn5' name='_ednref5' title=''>[v]</a> Moreover,
                these issues are not exclusively “economic” and may also apply beyond
                cryptocurrencies to social relations in Web3. In an astute analysis, the
                artist Geraldine Juárez echoes Morozov’s worries that even
                well-intentioned “[Decentralized Autonomous Organizations] DAOs are
                text-book [examples of] assetization as they manifest the imperative of
                investment as a social relation” (Juárez, 2022). The blurring of work by
                technologies like DAOs extends the issues of financialization,
                assetization, and quantification to all social realities. Ultimately,
                the worry is that, like King Midas, everything a DAO touches turns into
                a digital asset.
            </p>
            <p>
                Commodity fetishism is an old Marxist concern emerging out of a labour
                theory of value. The concern with commodity fetishism is that when
                people come to believe an economic abstraction (value) is to be found in
                an object they often, but mistakenly, come to think the object has
                intrinsic value. Marxists are troubled by commodity fetishism in crypto
                because it contradicts their belief that value originates in labour, as
                part of use-value in exchange. According to John Holloway’s (2010)
                reading of commodity fetishism—“the core of Marx’s discussion of
                power”—social relations of labour are presented as fungible commodities
                measured by price (Holloway, 2010). Extending this argument, these
                commodities are hostile and antagonistic, “devourers of living labour”
                according to Marx, and simultaneously illusory and efficacious. When
                commodities devour labour they alienate workers, but they also obfuscate
                the social character of the origin of objects and in turn separate the
                subject from the object, such that those who have ownership of objects
                have power over subjects. Web3 cannot escape the obfuscated power of
                commodities, but it does complicate the relationship between subjects
                and objects. As I describe below, work in Web3 is intimately tied to
                leisure (a social origin of value) and can be represented by tokens.
            </p>
            <p>
                Nick Szabo’s (1997) pioneering work on smart contracts offered an idea
                of “vending machine” fairness with low transaction costs, high
                “observability,” and automatic execution. A smart contract, Szabo
                imagined, is like a vending machine—all interactions are secure,
                automatic, and fair. He then went on to imagine how contractual clauses
                “can be embedded in the hardware and software” to make breach of
                contract nearly impossible. When Szabo’s dreams were later realized in
                Bitcoin, it became immediately obvious that “fair” does not
                automatically produce equality or justice. Today, the economic
                inequalities in crypto networks are profound and a poor basis for social
                cohesion.
            </p>
            <p>
                Nonetheless, vending machine fairness has proven useful for a small—but
                important—set of human relations; for example, DeFi is built on this
                promise and has successfully mitigated online counterparty risk. But
                Web3 is much bigger than DeFi; it promises hypergovernance, virtual
                social relations, and new kinds of work and play. For these richer
                experiences, often embedded in DAOs today, critics rightly argue that
                social relations built on market-like “vending machines” may actually
                exacerbate inequality and stymie the development of a just Web3 society.
            </p>
            <h1>
                A sociological model for Web3
            </h1>
            <p>
                Web3 is a petri dish for our future online lives. In it, decentralized
                autonomous organizations (DAOs) have emerged as challengers to
                traditional forms of organizational design. Due to their unusual
                architecture and strategic goals, DAOs have been at the forefront of
                digital governance and a small research community has emerged to support
                them. Yet, while most DAOs claim sophisticated governance, in reality
                they tend to use simple forms of coin voting (Buterin, 2021), organize
                and discuss on chaotic Discord and Telegram channels, and use
                quasi-statist apparatuses for issue resolution (e.g., Aragon’s ‘court’
                or Kleros’ ‘justice protocol’). Despite these shortcomings, collectively
                and imperfectly DAOs offer examples of digital, decentralized,
                polycentric governance in the wild.
            </p>
            <h2>
                Good governance
            </h2>
            <p>
                Elinor Ostrom’s ground-breaking work on polycentric governance showed
                how tough collective action problems with resource constraints can be
                solved without state or market solutions. In <em>Governing the Commons</em> (1990), Ostrom discussed how a potential tragedy of the commons can
                often be avoided “by voluntary organizations rather than by a coercive
                state.” Ostrom was keen to find alternatives to rational egoism that, it
                was presumed, necessarily devolved into over-utilization of scarce
                resources (the so-called tragedy of the commons). She challenged those
                who, failing to see past the examples outlined by Hobbes and Rousseau,
                thought that only a government “using what- ever force may be required”
                could save the commons. Others felt that, if state violence was to be
                avoided, it would require privatizing the commons by erecting
                surveillance and security features to partition resources. Ostrom
                pointed out that both approaches assumed institutional change must come
                from the outside and be imposed on the individuals affected. Ostrom’s
                approach to polycentric governance rejects this assumption and trades
                state and market solutions for “difficult, time- consuming,
                conflict-invoking” processes (p. 14). Later, Dietz, Ostrom, and Stern
                (2003) proposed a list of criteria for polycentric governance that
                includes: dialogue between resource users (analytic deliberation);
                complex, redundant, layered institutions (nesting); mixed institutional
                types (e.g., market- and state-based); and institutional designs that
                facilitate experimentation, learning, and change (see also Chaffin,
                Gosnell, and Cosens, 2014). Moreover, these processes rely on
                negotiated, self-enforcing contracts, which ultimately require
                communication and trust. Remarkably, Ostrom demonstrated that when all
                these pieces are in place, at least according to a natural resource
                management model (Frey, Frafft, and Keegan, 2019), self-organized
                collective action is often more effective than state and market
                alternatives, potentially making way for a still unrealized ‘new
                institutionalism.’
            </p>
            <p>
                While Ostrom et al.’s model of polycentric governance demonstrates the
                possibility of efficient, self-organized collective action, the contexts
                and empirical situations they analyze are quite remote to virtual Web3
                goods (see also Dylan-Ennis, this volume). This is because the tragedy
                of the commons is limited to material constraints—situations where
                over-extraction of fish, water, or grazing have deleterious effects
                (Chaffin, Gosnell, and Cosens, 2014). But Web3 has only artificial
                constraints, as represented by secure token ownership. So, we need to
                absorb the lessons of polycentric governance—negotiated governance is
                possible—but look beyond a political economy of material constraints to
                the community management of <em>de novo</em> Digital Common Pool
                Resources (DCPRs). In Web3, resource constraints are reconfigured by the
                environment that is <em>already and automatically secure</em> and <em>value laden</em> with token economics. Indeed, many newer environments of virtual life,
                such as metaverses, are rich expressions of this digital ‘security
                environment.’ Unavoidably, we must also acknowledge that Web3 is also a
                platform for producing narrow forms of private property with
                non-fungible tokens (NFTs), the exchange of cryptoassets, and
                participation in game-like social interactions (Kreutler, 2021).
            </p>
            <p>
                By using these artificial security constraints to create unique
                configurations of private property, DAOs offer sophisticated
                opportunities for robust social infrastructure. In recent years,
                theories of dynamic games have been developed to model bounded and
                artificially constrained environments, which, I suggest below, may prove
                helpful to understand the continuum of property rights in these digital
                environments. This approach builds on a long history of game theory and
                microeconomic modelling but, as complex systems with emergent
                properties, new models must also consider a variable security parameter
                (<em>k</em>). These fields of research collide to produce
                cryptoeconomics, the importance of which I return to later.
            </p>
            <p>
                On the other hand, nascent theories of blockchain governance have
                focused on how technological consensus protocols support voting and
                economic staking to represent the views of relevant stakeholders (De
                Filippi, Mannan, and Reijers, 2020; Rozas, Tenorio-Fornés, and Hassan,
                2020). Theorists of blockchain governance often argue that legitimate
                representation occurs by giving “voice” to individual interests with an
                opportunity for “exit” (Frey and Schneider, 2020; Mannan and Schneider,
                2021; and Hirschman, 1970). In the same vein, some authors have focused
                on the quasi-legal nature of blockchain smart contracts, even suggesting
                that they represent a new social contract (Schneider, 2021). In any
                case, these theories of governance and responsibility must ultimately
                portray a new set of values: negotiation and commitment, incentives,
                democratic discourse, and consensus are ultimately reconstituted by the
                very real “algorithmic authority” of automated systems (Pasquale, 2011).
                But, when algorithmic authority is reified in organizational
                technologies, an obvious worry is that techno-social infrastructures may
                nurture problematic forms of power, as Kavanagh and Dylan-Ennis describe
                in their Weberian account of “blockocracy” (2020).
            </p>
            <h2>
                Trust and Technology
            </h2>
            <p>
                As we move from DeFi to DAOs—from financial mechanisms to organizational
                technology (Tan, 2021)—we also need to reconsider the role and impact of
                trust. Most literature on the role of trust in blockchain technology
                presupposes that its function is to lower transaction costs within
                organizations (Werbach, 2018; Coase, 1937), but empirical evidence from
                traditional organizations to contemporary DAOs does not show strong
                support for this conclusion.<a href='#_edn6' name='_ednref6' title=''>[vi] </a> Rather, together with markets and hierarchy, trust is a key mechanism
                for managing significant vertical relationships under varying
                conditions. Evidence from traditional organizations also shows that
                trust is mostly used in the transaction process rather than during
                contract constitution or execution (Macaulay, 1963), although trust
                surely also emerges in new and unexpected ways in DAOs.
            </p>
            <p>
                One description of the multiple conditions under which trust operates in
                blockchain environments is offered by Lemieux (2022), who adopts Russell
                Hardin’s tripartite model of trust as “encapsulated interest.” According
                to Hardin (2002), trust is “grounded in the truster’s assessment of the
                intentions of the trusted with respect to some action,” which is,
                typically, based in self-interest, moral commitment, or other
                idiosyncratic factors. In contrast, Werbach (2018) offers a
                structuralist account of “trustless trust”—a technology impact model of
                trust. Adapted from the venture capitalist Reid Hoffman, this notion of
                “trustless trust” attempts to bootstrap trust from executing code, in
                effect paraphrasing “code is law” as “code is trust” (see also “lex
                cryptographia” by Wright and De Filippi, 2015). A commonality among
                these distinct accounts is that all agree that trust does not disappear
                in the midst of blockchain technologies, but rather is transformed.
                Looking forward, exactly how trust is transformed ought to be a primary
                research question for DAOs in particular because, as Ostrom et al.
                argue, trust supports consensus and consensus is necessary for good
                governance.
            </p>
            <p>
                Not only is trust foundational for effective polycentric governance but
                in Web3 it can be systematically manipulated using cryptoeconomic
                mechanisms. Cryptoeconomics is a nascent field of research and
                development that combines algorithms, techniques, and design patterns
                from cryptography and information security engineering with (usually
                token-based) microeconomics to achieve the goal of network-scale
                behavioural control. As a complex system (Voshmgir and Zargham, 2019),
                cryptoeconomic mechanisms help structure but do not determine
                organizational evolution; rather, they enable individual actions to
                scale across decentralized networks to produce consensus and other
                emergent, relational social behaviours (like trust and cooperation but
                also crime; see Baumol, 1996). Cryptoeconomics also supports digital,
                decentralized polycentric governance because it offers organizational
                designers a growing menu of technologies to support broader strategic
                goals, such as circular or regenerative economics (Fritsch, et al.,
                2021; Borreani, 2021; Owocki, 2022), or as I outline below, forms of
                social coproduction. For the progressive Web3 designer, cryptoeconomics
                opens the door to virtual environments that inhibit the formation of
                poles of power and therein help prevent the violence and injustice that
                necessarily follow.
            </p>
            <h2>
                Social coproduction
            </h2>
            <p>
                DAOs are an organizational technology that, on account of being digital
                and decentralized, specialize in limited contact with state functions,
                especially state-controlled monetary functions. They are <em>post-operaismo</em> in the parlance of critical theory (Steinhoff, 2021) but emerged out of
                a complex socio-technical bundle of practices and people (Schatzki,
                2009), not from “the workers,” as with progressive movements from the
                last millennia.
            </p>



            <p>
                In the utopian version, DAOs are the culmination of two hundred years of
                post-Marxist technological automation, an evolution of the institutional
                form towards autonomist social goals, and an organizational technology
                where people directly make decisions affecting their everyday lives and
                seek to break free from political structures imposed from the outside, a
                form of agorism (Dylan- Ennis and Barlowe, 2022). Implicitly, DAOs would
                return autonomy to the individual with the goal of directly overcoming
                power relations—not as a revolutionary or vanguard movement wresting
                control from the state, but (perhaps) as an anti-power (Holloway, 2010).
            </p>
            <p>
                However, in my experience of nascent Web3, sophisticated organizational
                designs and behavioural technologies are still rare and remain highly
                experimental. The technologies supposedly underpinning autonomist social
                goals may be vital infrastructure (see Nabben, this volume) but still
                play a limited role in social interactions. Unfortunately, the bulk of
                extant scholarly work, including my own, has focused too narrowly on the
                imagined disruption brought about by the integration of immutable
                records and automatic software execution, at the expense of developing
                sociologically rich models of human behaviour capable of explaining how
                Web3 designs, builds, uses, and maintains real socio-technical
                infrastructure. As a gesture towards thinking beyond the ways code
                simply replaces complex social behaviours like legal order, social
                trust, and cooperation, I adopt Hardt and Negri’s model (2017) of social
                coproduction and extend it to Web3. This model reintroduces agency
                (Jasper, 2006), collective identity (Poletta and Jasper, 2001), and
                social dynamics associated with collective decision making (Ostrom,
                1990) by making contributions to neo-institutional theory (Schneiberg
                and Lounsbury, 2017).
            </p>
            <p>
                According to Hardt and Negri (2017), the political economy of social
                coproduction faces two challenges: how to create organizations without
                hierarchy and how to create institutions without centralization.
                Specifically, they call on social movement leaders to “invert the
                roles,” by giving strategic decision-making powers to the movement (the
                “assembly”) and tactics to the leadership. This inverted governance
                relationship destroys the sovereign. Of course, the sovereign must be
                destroyed so that the people can represent themselves, but as Rousseau
                (1762/2003) argues, representing oneself is an oxymoron: “sovereignty
                cannot be represented for the same reason that it cannot be alienated;
                it consists essentially in the general will, and the will does not admit
                of being represented: either it is the same or it is different; there is
                no middle ground.” But, when Rousseau celebrates the general will in
                contrast to the will of all he “underwrites sovereign power,” Hardt and
                Negri admonish (2017, p. 27). They go on to point out that in in these
                sovereign arrangements, “what belongs to everyone and to no one really
                belongs to the state” (p.29).
            </p>
            <p>
                As a sociological model, social coproduction draws attention to the
                expansive nature of biopolitics. Hardt and Negri emphasize that “all
                life is subject to threat and exploitation,” not just those domains
                traditionally labelled as work. Indeed, global neoliberal capitalism has
                made the idea of an autonomous political realm implausible. Most
                obviously, sovereign nation states have been unable or unwilling to
                fulfil important environmental and social goals, leaving corporations
                with Corporate Social Responsibility (CSR) mandates to assume
                responsibility (Scherer and Palazzo, 2011), a form of neoliberalism. The
                social coproduction model, rather, suggests an expanded role for
                economics, wherein cooperation and composition naturally arise in place
                of political representation. Finally, in-the-streets social movements
                and new kinds of cooperative work organizations (Mannan, 2018) provide
                further evidence of the many ways that labour is ultimately cooperative
                (Hardt and Negri argue that “the <em>one</em> never produces”). They
                conclude that private property fetters social productivity and
                undermines social relations and that neoliberalism did not restore the
                freedom of the market, but rather reinvented the state.
            </p>
            <h2>
                Social Movements
            </h2>
            <p>
                Hardt and Negri defend the highly practical nature of social
                coproduction through many rich examples and case studies of social
                movements, which they describe as durable social configurations
                reminiscent of terrorist cells and netwars (Arquilla and Ronfeldt,
                2001). While the long-term durability of DAOs remains an open question
                (stalwarts like MakerDAO only emerged in 2017), the dynamism of their
                formation and transformation is well captured by social movement theory.
            </p>
            <p>
                Social movement theory spans sociology and political science to explain
                contestation and collective mobilization processes (Schneiberg and
                Lounsbury, 2017). In particular, the sociology of collective identity
                has been useful in explaining the emergence, trajectories, and impacts
                of social movements. Polletta and Jasper (2001) point out that
                collective identity serves as an alternative to structuralist accounts
                by accounting for the ways that people mobilize, why they participate,
                and the choices they make.
            </p>
            <p>
                In their review of social movement theory, they ask:
            </p>
            <p class="displayquotation">
                To what extent are collective identities constructed in and through
                protest rather than preceding it? Is the identity a group projects
                publicly the same one that its members experience? Are collective
                identities imposed on groups or invented by them? Do individuals choose
                collective identities to maximize their self-interest or do interests
                flow from identities? How is collective identity different from
                ideology? From interest? From solidarity? (p.285)
            </p>
            <p>
                Recent examples of popular DAOs, like Friends with Benefits, emphasize
                the ways that collective identities can emerge in social movements
                (Kitts 2000). Friends with Benefits is a kind of VIP lounge or social
                club with an access token and a treasury worth millions of dollars. In
                their frothy Discord server, over a million messages have been posted by
                thousands of members who self-organize and self-promote in a riotous
                fashion, collectively selling merchandise, artwork, and making group
                investments (including purchasing brick and mortar assets). Friends with
                Benefits members organize parties and meetups around the globe, listen
                to music together in virtual rooms, and socialize with a distinct Web3
                argot. In my ethnography I found feminisms, diversity, and hustle
                culture abound. Notably, there is little evidence of a scarce resource
                debate among Friends with Benefits.
            </p>
            <p>
                Collective identity is especially important for in-group strategic
                planning and communication, weathering organizational uncertainty,
                supporting prosocial behaviours, cultivating psychological affect, and
                setting social norms and limits. Most visibly, collective identities in
                DAOs are produced and shared through postmodern storytelling, by way of
                memes (Yogarajah, 2022).
            </p>
            <h2>
                Surplus and social money
            </h2>
            <p>
                In Web3, work is inextricably connected to leisure because the political
                economy of the Web3 social movement is defined by surplus—not scarcity,
                as in traditional capitalist models. Why surplus? Because “magic
                Internet money,” of course (if you are unsatisfied with economic
                relations, just create your own!), but also because in Web3 there are no
                <em>natural</em>constraints. This re-imagination of value is
                particularly visible in the collective identity associated with Gitcoin,
                which valourizes reciprocity in opposition to scarcity, to claim that
                “in a virtual world, the scarcity of goods is just a shared fiction.”
                Gitcoin is hardly alone in cultivating these new norms and values; at
                ETHDenver 2022 (one of the largest Web3 industry conferences), the
                organizer John Paller remarked on stage, “It’s not about money. We don’t
                care about that;” and a recent Wired article by Gilad Edelman (2022)
                came to the same conclusion, finding that “Web3 is a realm where coders
                can feel good about working in tech.” But perhaps the most persuasive
                evidence can be found in the many governance fora of DAOs, where an
                earlier Free and Open Source model of software production has
                transformed into small scale digital villages with diverse economic
                relations, each founded on a private money where “everyone gets paid.”
                However, unlike the traditional Free and Open Source model of software
                production—that drew in participants looking for fun, technical
                challenges, and work—participation in DAOs is far more diverse, social,
                and voluntaristic.
            </p>
            <p>
                Of course, some people will find this naïve—as if Web3 isn’t <em>really</em> about getting rich and being an egoist—but as I discussed above, these
                same people also struggle to understand any sense of value in digital
                currencies. Most critics simply assume Web3 must be a scam and move on,
                because <em>they cannot imagine a world of surplus</em>. Moreover,
                modern, neoclassical economics is built on the foundational belief that
                scarcity is necessary. However, their underlying assumptions about money
                and economic relations fail to see how crypto tokens can be used for
                purposes outside of exchange, a unit of account, or a store of value.
            </p>
            <p>
                What does it mean to have a political economy of surplus? Does this
                imply that everyone is (equally) rich? This is an unhelpful and unlikely
                image—a political economy of surplus does not naturally imply equality
                or freedom. Rather, surplus may even lock its benefactors into a
                resource curse or trap, as evidenced by resource-abundant national
                governments like my own Canada or many African nations that founder
                despite great natural wealth. Unlike rationalized models of narrow
                “economic” relations of the sort familiar to traditional
                “scarcity-generating institutions” (Hoeschele, 2010), in Web3 surplus
                seems to be unevenly distributed through complex practices of 1)
                earmarking and the social use of money, and 2) the schismogenetic
                emergence of a subculture that defines itself through refusal and
                rejection of crypto’s dominant culture of greed and hoarding.
            </p>
            <h2>
                1) Earmarking and money’s social context.
            </h2>
            <p>
                Viviana Zelizer’s (1989) observation that money is often earmarked and
                used in decidedly social ways suggests that it is not simply a store of
                value or a means of exchange, but that money is also a way to signal
                value. According to Zelizer, people engage in different sorts of
                economic transactions — gifts, taxes, or discretionary funds — to
                earmark money for specific purposes. This allows them to set aside funds
                for future use or to allocate resources according to their own
                priorities; they may choose to save or invest this money, earmark some
                of these funds for specific purposes, or use it to interact socially.
            </p>
            <p>
                Modern monetary controls tend to flatten economic exchange, reducing the
                value of things to a purely numerical, exchangeable form (Nelms and
                Maurer, 2014). However, when we look at how money tends to be <em>actually</em> used in many different cultures — for example, as a ritual offering or
                as a token of access to exclusive virtual spaces — we see that value is
                not so straightforwardly reducible to rational economic exchange.
                According to Zelizer, money often retains some indexical link to its
                sources and owners, even as it circulates within different spheres of
                influence.
            </p>
            <p>
                The Web3 software service Coordinape offers an example of the social
                embeddedness of money in Web3. With Coordinape, DAOs can “autonomously
                allocate and reward contributors with funds,… via sybil resistant social
                graphs.” Unlike traditional forms of human resource management that
                carefully structure work and compensation practices from the top down in
                a vertical bureaucracy, Coordinape relies on social properties to
                establish and validate patterns of work. As well, members of DAOs can
                use Coordinape’s tokens to give gifts, reward prosocial behaviours, and
                incentivize good work. While Coordinape is an admittedly niche example,
                closer analysis affords an intriguing view of the ongoing and
                increasingly intense blurring of work. In this case, work seems to exist
                somewhere on a continuum of leisure—part gift economy and part social
                co-production.
            </p>
            <p>
                Looking beyond money and towards forms of plural property, Weyl,
                Ohlhaver, and Buterin (2022) propose a design for socially embedded
                tokens that index context-specific human relations. They offer ideas for
                how a “decentralized society” with indelible trust and identity assets
                might work, wherein they claim “economic value... is generated by humans
                and their relationships” by establishing social provenance across
                communities or clubs. Weyl et al. claim that goods exist on a continuum between public and private which invites opportunities to
                think about plural property regimes in place of scarcity-generating
                private property regimes (see also Posner and Weyl’s <em>Radical Markets</em>).
            </p>


            <h2>
                2) Schizmogenesis and Web3’s subculture
            </h2>
            <p>
                One of the most consistent findings of my digital ethnography of Web3 is
                opposition to the collective identity of Bitcoin specifically, and
                crypto more generally. In many cases, I spoke with people who rejected
                Bitcoin’s culture of greed, HODLing, and its programmed designs for
                generating scarcity; others rejected its bro culture and combative
                discourse. Importantly, these rejections define Web3’s collective
                identity, and since identity fundamentally determines the status and
                meaning of revolt, the Web3 collective identity emerges from processes
                of schizmogenesis.
            </p>
            <p>
                As first described by the anthropologist Gregory Bateson, schizmogenesis
                is a theory to explain how inverted or anti-social patterns can compound
                and grow to form an organic solidarity (Durkheim, 1893/2014).
                Eventually, anti-social patterns begin to challenge dominant ideology.
                According to Dick Hebdige (1991), evidence of these anti-social patterns
                can be found “reflected in the surfaces” of subculture as “expressive
                forms and rituals” where “objects take on a symbolic dimension” (p.2).
                These subcultural symbols, he continues, warn the “straight” world of a
                “sinister presence — the presence of difference” (p.3).
            </p>
            <p>
                Using Hebdige’s (1991) analysis of subculture as a guide, one approach
                is to read the dominant ideology by looking for connotative codes
                (Barthes, 1975), and then find signs of refusal in Web3. We already know
                many of the connotative codes associated with crypto — ‘whales,’
                ‘lambos,’ and ‘going to the moon,’ for example—each marking the
                subconscious “maps of meaning” that are “traced and re-traced along the
                lines laid down by the dominant discourses about reality, the dominant
                ideologies” (Hebdige, 1991, p.15). Importantly, dominant ideologies
                serve hegemony to create consent (p.16). Building on Gramsci’s
                definition of hegemony as the play of relations of force comprising a
                “moving equilibrium,” Hebdige notes that hegemony is only ever a
                provisional alliance between dominant groups to exert “total social
                authority” over other subordinate groups. Specifically, hegemony does
                not result from coercion or the direct imposition of ruling ideas, but
                by “winning and shaping consent so that the power of the dominant
                classes appears both legitimate and natural.” (Hall, 1977 quoted in
                Hebdige, 1979). What we find is that mass movements emerge in refusal,
                to disrupt what appears legitimate and natural not by a dialectical
                overthrowing of the dominant classes, but through collective refusal. As
                Eric Hoffer (2010) remarked, “mass movements can rise and spread without
                belief in a god, but never without belief in a devil.”
            </p>
            <p>
                Perhaps Web3 only signals a refusal of crypto’s greed and need for
                scarcity. But even if the practices of refusal are “just the darker
                side… of regulations, just so much graffiti on a prison wall,” they are
                worth careful study. After all, Hebdige continues, “graffiti can make
                fascinating reading… they are an expression both of impotence and a kind
                of power — the power to disfigure” (1991, p.3).
            </p>
            <h1>
                Conclusion: From social coproduction to digital polycentric governance
            </h1>
            <p>
                This essay critically evaluated the political economy of Web3 and
                offered a neo-institutional model to better explain observations of
                contemporary digital social movements.
            </p>
            <p>
                Looking forward to future research, this neo-institutional model also
                suggests pathways to understanding new organizational forms emerging
                with Web3. With this lens, rather than assume scarcity, work, and profit
                maximization, I pointed to some lessons we can learn by studying DAOs in
                terms of the social coproduction of digital common pool resources
                (DPCRs). Armed with rich datasets of on-chain governance proposals, voting, and token economics, in
            the future we can turn to data science and deep learning techniques to
            discover “successful” strategies of digital polycentric governance.
            </p>

        <p>
            By drawing connections to systems modelling and data analysis in the “online
            communities” literature in the Information Systems field we find
            complementary methodologies to search DAOs for further signs of digital
            polycentric governance. For instance, if governance can be meaningfully
            represented in an analytical model (perhaps as a time-dependent partial
            differential equation), safety engineering and verification become tractable
            goals for autonomous systems like DAOs. One approach, drawn from control
            system theory, recommends methods for computing backwards reachable sets.
            Mitchell, Bayen, and Tomlin  (2005) introduced a differential game
            formulation that utilizes a viscosity solution to reach safe states, which
            could be explored in governance contexts. Future research might explore how
            control engineering could use analytical solutions like this to solve
            dynamic governance games, opening up new vistas for digital polycentric
            governance.
        </p>
        <p>
            Still, many questions emerge out the micro-organizational structure of
            social movements. In addition to safety engineering, does a differential
            game model offer the possibility of a Nash equilibrium in governance?
            Furthermore, what role does revenue management play in governance, given
            what we now know about scarcity in DCPRs? That is, without relying on
            foundational theories of economic utility maximization, what are appropriate
            preference functions for choosing governance rules? What other constraints
            and limitations must a model consider to gain predictive power?
        </p>
        <h1>
            References
        </h1>
        <p>Arquilla, J. and D. Ronfeldt (2001). Networks and Netwars: The Future of Terror, Crime, and Militancy. Santa Monica, CA: Rand Corporation.</p>
        <p>Bankless (2022, April 12). The Divine DAO with Tracheopteryx | Layer Zero. <a href="https://www.youtube.com/watch?v=18vB70P2YE4">https://www.youtube.com/watch?v=18vB70P2YE4</a></p>
        <p>Barthes, R. and R. Howard (trans.) (1975). S/Z: An Essay. New York: Hill and Wang.</p>
        <p>Baumol, W. J. (1996). Entrepreneurship: Productive, Unproductive, and Destructive. Journal of Business Venturing 11 (1), 3–22. 06687.</p>
        <p>Beniger, J. R. (1986). The Control Revolution: Technological and Economic Origins of the Information Society. Cambridge, MA: Harvard University Press.</p>
        <p>Birch, K., and F. Muniesa, (eds.) (2020). Assetization: Turning Things into Assets in Technoscientific Capitalism. The MIT Press.</p>
        <p>Borreani, L. (2021, October). Applied Regenerative Economics. <a href="https://blog.curvelabs.eu/applied-regenerative-economics-2219d2e1087b">https://blog.curvelabs.eu/applied-regenerative-economics-2219d2e1087b</a></p>
        <p>Brooks, S. (2021, December). Revisiting the Monetary Sovereignty Rationale for CBDCs. Technical report, Bank of Canada, Ottawa, ON.</p>
        <p>Buterin, V. (2021, August). Moving beyond coin voting governance. <a href="https://vitalik.ca/general/2021/10/31/cities.html">https://vitalik.ca/general/2021/10/31/cities.html</a></p>
        <p>Caliskan, K. (2020). Data Money: The Socio-Technical Infrastructure of Cryptocurrency Blockchains. Economy and Society 49 (4), 22.</p>
        <p>Chaffin, B., H. Gosnell, and B. Cosens (2014). A decade of adaptive governance scholarship: synthesis and future directions. Ecology and Society 19 (3). Publisher: The Resilience Alliance.</p>
        <p>Coase, R. H. (1937). The Nature of the Firm. Economica 4 (16), 386–405.</p>
        <p>De Filippi, P., M. Mannan, and W. Reijers (2020, June). Blockchain as a confidence machine: The problem of trust & challenges of governance. <a href="https://doi.org/10.1016/j.techsoc.2020.101284">https://doi.org/10.1016/j.techsoc.2020.101284</a></p>
        <p>Dietz, T., Ostrom, E., & Stern, P. C. (2003). The Struggle to Govern the Commons. Science, 302(5652), 1907–1912. <a href="https://doi.org/10.1126/science.1091015">https://doi.org/10.1126/science.1091015</a></p>
        <p>DuPont, Q. (2017, October). Blockchain Identities: Notational Technologies for Control and Management of Abstracted Entities. Metaphilosophy 48 (5), 634–653.</p>
        <p>DuPont, Q. (2018). Experiments in Algorithmic Governance: An ethnography of ”The DAO,” a failed Decentralized Autonomous Organization. In M. Campbell-Verduyn (Ed.), Bitcoin and Beyond: The Challenges and Opportunities of Blockchains for Global Governance, pp. 157–177. New York: Routledge.</p>
        <p>DuPont, Q. (2020). Guiding Principles for Ethical Cryptocurrency, Blockchain, and DLT Research. Cryptoeconomic Systems 1 (1).</p>
        <p>DuPont, Q. and B. Fidler (2016). Edge Cryptography and the Co-Development of Computer Networks and Cybersecurity. IEEE Annals of the History of Computing 38 (2), 55–73.</p>
        <p>DuPont, Q., &amp; Maurer, B. (2015). Ledgers and Law in the Blockchain. Kings Review, 23(June 23). <a href="https://www.kingsreview.co.uk/essays/ledgers-and-law-in-the-blockchain">https://www.kingsreview.co.uk/essays/ledgers-and-law-in-the-blockchain</a></p>
        <p>Durkheim, E. (1893/2014). The Division of Labor in Society. New York: Free Press.</p>
        <p>Dylan-Ennis, P. and W. Barlowe (2022). An Introduction to Agorism in Both Theory and Practice. Agorism in the 21st Century: A Philosophical Journal 1 (1), 23–29.</p>
        <p>Edelman, G. (2022, May). The Web3 Movement’s Quest to Build a ‘Can’t Be Evil’ Internet. Wired. <a href="https://www.wired.com/story/web3-paradise-crypto-arcade/">https://www.wired.com/story/web3-paradise-crypto-arcade/</a></p>
        <p>Foucault, M. (2007). Security, Territory, Population, Lectures at the Collége De France, 1977-78. New York: Picador.</p>
        <p>Frey, S., P. M. Krafft, and B. C. Keegan (2019, December). “This Place Does What It Was Built For”: Designing Digital Institutions for Participatory Change. Proceedings of ACM Human Computing Interaction 3, 31.</p>
        <p>Frey, S. and N. Schneider (2020). Effective Voice: Beyond Exit and Affect in Online Communities. arXiv preprint arXiv:2009.12470.</p>
        <p>Fritsch, F., J. Emmett, E. Friedman, R. Kranjc, S. Manski, M. Zargham, and M. Bauwens (2021). Challenges and Approaches to Scaling the Global Commons. Frontiers in Blockchain 4. <a href='https://doi.org/10.3389/fbloc.2021.578721'>https://doi.org/10.3389/fbloc.2021.578721</a></p>
        <p>Gerard, David. Attack of the 50 Foot Blockchain: Bitcoin, Blockchain, Ethereum and Smart Contracts , 2017.</p>
        <p>Golumbia, D. (2016). The Politics of Bitcoin: Software as Right-Wing Extremism . Minneapolis MN: University of Minnesota Press.</p>
        <p>Graeber, D. and D. Wengrow (2021). The Dawn of Everything: A New History of Humanity . New York: Farrar, Straus and Giroux.</p>
        <p>Hardin, R. (2002). Trust and trustworthiness. Number 4 in The Russell Sage Foundation series on trust. New York: Russell Sage Foundation.</p>
        <p>Hardt, M. and A. Negri (2017). Assembly. New York: Oxford University Press.</p>
        <p>Hebdige, D. (1991). Subculture: The Meaning of Style. New accents. London: Routledge.</p>
        <p>Hirschman, A. O. (1970). Exit, Voice, and Loyalty: Responses to Decline in Firms, Organizations, and States. Cambridge MA: Harvard University Press.</p>
        <p>Hoeschele, W. (2010). The economics of abundance: a political economy of freedom, equity, and sustainability. Gower green economics and sustainable growth series. Farnham ; Burlington, VT: Gower.</p>
        <p>Hoffer, E. (2010). The True Believer: Thoughts on the Nature of Mass Movements (Reissue edition ed.). New York: Harper Perennial Modern Classics.</p>
        <p>Holloway, J. (2010). Change the world without taking power. London: Pluto Press.</p>
        <p>Jasper, J. (2006, February). A Strategic Approach to Collective Action: Looking for Agency in Social-Movement Choices. Mobilization: An International Quarterly 9 (1), 1–16.</p>
        <p>Juárez, G. (2022). The Ghostchain. (Or taking things for what they are). Paletten (325).</p>
        <p>Kavanagh, D., &amp; Ennis, P. J. (2020). Cryptocurrencies and the emergence of blockocracy. The Information Society, 36(5), 290–300. <a href="https://doi.org/10.1080/01972243.2020.1795958">https://doi.org/10.1080/01972243.2020.1795958</a></p>
        <p>Kitts, J. (2006, February). Mobilizing in Black Boxes: Social Networks and Participation in Social Movement Organizations. Mobilization: An International Quarterly 5 (2), 241–257.</p>
        <p>Kreutler, K. (2021, July). A Prehistory of DAOs. Gnosis Guild. <a href="https://gnosisguild.mirror.xyz/t4F5rItMw4-mlpLZf5JQhElbDfQ2JRVKAzEpanyxW1Q">https://gnosisguild.mirror.xyz/t4F5rItMw4-mlpLZf5JQhElbDfQ2JRVKAzEpanyxW1Q</a></p>
        <p>Latour, B. (1986). Visualisation and Cognition: Drawing Things Together. In E. Long and H. Kuklick (Eds.), Knowledge and society. Greenwich, Conn.: JAI Pres.</p>
        <p>Lemieux, V. (2022). Searching for Trust: Blockchain Technology in an Age of Disinformation. Cambridge UK: Cambridge University Press.</p>
        <p>Levine, Y. (2018). Surveillance Valley: The Rise of the Military-Digital Complex. New York: PublicAffairs.</p>
        <p>Macaulay, S. (1963). Non-Contractual Relations in Business: A Preliminary Study. American sociological review 28 (1), 55–67.</p>
        <p>Mannan, M. (2018, December). Fostering Worker Cooperatives with Blockchain Technology: Lessons from the Colony Project. Erasmus Law Review 11 (3), 190–203.</p>
        <p>Mannan, M. and N. Schneider (2021). Exit to Community: Strategies for Multi-Stakeholder Ownership in the Platform Economy. Georgetown Law Technology Review 5 (1), 1–71.</p>
        <p>Manski, S. and B. Manski (2018, July). No Gods, No Masters, No Coders? The Future of Sovereignty in a Blockchain World. Law and Critique29 (2), 151–162.</p>
        <p>Mitchell, I., A. Bayen, and C. Tomlin (2005, July). A time-dependent Hamilton-Jacobi formulation of reachable sets for continuous dynamic games. IEEE Transactions on Automatic Control 50 (7), 947–957.</p>
        <p>Morozov, E. (2022, January 13). Web3: A Map in Search of Territory. The Crypto Syllabus. <a href="https://the-crypto-syllabus.com/web3-a-map-in-search-of-territory/">https://the-crypto-syllabus.com/web3-a-map-in-search-of-territory/</a></p>
        <p>Nelms, T. C. and B. Maurer (2014). Materiality, Symbol, and Complexity in the Anthropology of Money. In The psychological science of money, pp. 37–70. New York: Springer.</p>
        <p>Ostrom, E. (1990). Governing the Commons.Cambridge UK: Cambridge University Press.</p>
        <p>Owocki, K. (2022). Greenpilled: How crypto can regenerate the world. Blurb.</p>
        <p>Pasquale, F. (2011). Restoring Transparency to Automated Authority. Journal on Telecommunications & High Technology Law 9 (235), 235–256.</p>
        <p>Perrin, A. (2021, November 11). 16% of Americans say they have ever invested in, traded or used cryptocurrency. Pew Research Center. <a href="https://www.pewresearch.org/short-reads/2021/11/11/16-of-americans-say-they-have-ever-invested-in-traded-or-used-cryptocurrency/">https://www.pewresearch.org/short-reads/2021/11/11/16-of-americans-say-they-have-ever-invested-in-traded-or-used-cryptocurrency/</a></p>
        <p>Polletta, F. and J. M. Jasper (2001). Collective Identity and Social Movements. Annual Review of Sociology 27 (1), 283–305.</p>
        <p>Posner, E. A. and E. G. Weyl (2018). Radical markets: uprooting capitalism and democracy for a just society . Princeton: Princeton University Press.</p>
        <p>Rousseau, Jean-Jacques (1762/2003). On the Social Contract. Translated by G. D. H Cole. New York: Dover Publications.</p>
        <p>Rozas, D., A. Tenorio-Fornés, and S. Hassan (2020). Analysis of the Potentials of Blockchain for the Governance of Global Commons. Frontiers in Blockchain, 24.</p>
        <p>Schatzki, T. R. (2009). Social Practices. Cambridge: Cambridge University Press.</p>
        <p>Scherer, A. G. and G. Palazzo (2011). The New Political Role of Business in a Globalized World: A Review of a New Perspective on CSR and its Implications for the Firm, Governance, and Democracy. Journal of Management Studies 48 (4), 899–931.</p>
        <p>Schneiberg, M. and M. Lounsbury (2017). Social Movements and the Dynamics of Institutions and Organizations. In The SAGE Handbook of Organizational Institutionalism , pp. 281–310. London: SAGE Publications Ltd.</p>
        <p>Schneider, N. (2021, August). Cryptoeconomics as a Limitation on Governance. <a href="https://files.osf.io/v1/resources/dzasq/providers/osfstorage/6121d9ded2109701fc6b49af?view_only=a10581ae9a804aa197ac39ebbba05766&direct&version=8">https://files.osf.io/v1/resources/dzasq/providers/osfstorage/</a></p>
        <p>Steinhoff, J. (2021). Post-Operaismo and the New Autonomy of Immaterial Labour. In Automation and Autonomy: Labour, Capital and Machines in the Artificial Intelligence Industry (pp. 75–97). Cham: Springer International Publishing.</p>
        <p>Swartz, L. (2020). New Money: How Payment Became Social Media. New Haven: Yale University Press.</p>
        <p>Szabo, N. (1997, September). Formalizing and Securing Relationships on Public Networks. First Monday 2 (9).</p>  
        <p>Tan, J. (2021, March 24). Exploring DAOs as a new kind of institution. Commons Stack . <a href="https://medium.com/commonsstack/exploring-daos-as-a-new-kind-of-institution-8103e6b156d4">https://medium.com/commonsstack/exploring-daos-as-a-new-kind-of-institution-8103e6b156d4</a></p>
        <p>Varoufakis, Y. (2021, July 28). A Central Bank Cryptocurrency to Democratize Money. Project Syndicate. <a href="https://www.project-syndicate.org/commentary/central-bank-cryptocurrency-democratize-money-by-yanis-varoufakis-2021-07">https://www.project-syndicate.org/commentary/central-bank-cryptocurrency-democratize-money-by-yanis-varoufakis-2021-07</a></p>
        <p>Voshmgir, S. and M. Zargham (2019, November). Foundations of Cryptoeconomic Systems. Technical Report 1, WU Vienna University of Economics and Business.</p>
        <p>Werbach, K. (2018, November). The Blockchain and the New Architecture of Trust . Cambridge, MA: The MIT Press.</p>
        <p>Weyl, E. G., P. Ohlhaver, and V. Buterin (2022, May). Decentralized Society: Finding Web3’s Soul. (SSRN Scholarly Paper No. 4105763). Social Science Research Network. <a href='https://papers.ssrn.com/abstract=4105763'>https://papers.ssrn.com/abstract=4105763</a></p>
        <p>Wright, A. and P. De Filippi (2015). Decentralized Blockchain Technology and the Rise of Lex Cryptographia. Available at SSRN: <a href="http://ssrn.com/abstract=2580664">http://ssrn.com/abstract=2580664</a>.</p>
        <p>Yogarajah, Y. (2022, July). ‘Hodling’ on: Memetic storytelling and digital folklore within a cryptocurrency world. Economy and Society 0 (0), 1–22.</p>
        <p>Zelizer, V. A. (1989). The Social Meaning of Money: ”Special Monies”. American Journal of Sociology 95 (2), 342–377. Publisher: University of Chicago Press.</p>
        <p>Zook, M. and M. H. Grote (2020, November). Initial coin offerings: Linking technology and financialization. Environment and Planning A: Economy and Space 52 (8), 1560–1582.</p>

        <div>
            <br clear='all'/>
            <hr
            align='left' size='1' width='33%' />
            <div id='edn1'>
                <p>
                    <a href='#_ednref1' name='_edn1' title=''>[i]</a> Skylar Brooks’
                    Bank of Canada report “Revisiting the Monetary Sovereignty Rationale
                    for CBDCs” offers a rich description of the real economic threats
                    crypto poses to nation states. Alternatively, Manski and Manski
                    (2018) theorize the impact of blockchain technology on various
                    (popular, economic, and state) sovereignties in terms of seven
                    structural elements.
                </p>
            </div>
            <div id='edn2'>
                <p>
                    <a href='#_ednref2' name='_edn2' title=''>[ii]</a> See Yasha
                    Levine’s Surveillance Valley: The Secret Military History of the
                    Internet and DuPont and Fidler’s “Edge Cryptography and the
                    Codevelopment of Computer Networks and Cybersecurity.”
                </p>
            </div>
            <div id='edn3'>
                <p>
                    <a href='#_ednref3' name='_edn3' title=''>[iii]</a> Foucault links
                    circulation of value (grain, specie, gold, etc.) to the emergence of
                    the “apparatus of security.” Whereas “discipline regulates
                    everything,” the apparatus of security “lets things happen” by
                    discriminating between “details that are not valued as good or evil
                    in themselves, that are taken to be necessary, inevitable
                    processes.” See Michel Foucault’s 1977-78 lectures at the Collége de
                    France, published as <em>Security, Territory, Population</em>.
                </p>
            </div>
            <div id='edn4'>
                <p>
                    <a href='#_ednref4' name='_edn4' title=''>[iv]</a> Social
                    coproduction is a term used by Hardt and Negri, who are autonomist
                    Marxists. Wikipedia provides a useful summary of autonomist values
                    from Katsiaficas, “In contrast to the centralized decisions and
                    hierarchical authority structures of modern institutions, autonomous
                    social movements involve people directly in decisions affecting
                    their everyday lives, seeking to expand democracy and help
                    individuals break free of political structures and behavior patterns
                    imposed from the outside.”
                </p>
            </div>
            <div id='edn5'>
                <p>
                    <a href='#_ednref5' name='_edn5' title=''>[v]</a> But not all
                    cryptocurrencies aim at depoliticization. For example, according to
                    Varoufakis (2021), Central Bank Digital Currencies are an attempt to
                    repoliticize money.
                </p>
            </div>
            <div id='edn6'>
                <p>
                    <a href='#_ednref6' name='_edn6' title=''>[vi]</a> Transaction Cost
                    Economics (TCE), which predicts the emergence of firms to minimize
                    transaction costs on an open market, is a popular model for
                    understanding DAOs. However, DAOs rarely seek to reduce costs. For
                    example, like most DAOs, MakerDAO contracts (no-bid) work streams
                    externally and pays client-requested, community-approved prices.
                </p>
            </div>
        </div>
   



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default dupont;
