import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function introduction() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Introduction</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Quinn DuPont, Paul Dylan-Ennis, and Donncha Kavanagh
            </h3>
            <div className="dsc">
                <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089001">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/278d5a44-7107-453c-91d5-c25d60657ebf">Preprint self-archived version</a>
            </div>
            <hr />

            <div>
            <p>The story of Web3 starts with the earliest “read only” Web where users jumped from static web page to static web page. In this Web 1.0 era (circa 1990-2000), we see the beginning of the Internet in the modern sense. At this stage, the Internet is accessible through the World Wide Web, but still somewhat peripheral in society. The action of the story is found in the Web 2.0 era (circa 2000 onwards). Web 2.0 is the dynamic “read-write” Web defined by the rise of the FAANGs (Facebook, Amazon, Apple, Netflix, Google). It is the era of ecommerce, social media and the entrenchment of the Internet into our everyday lives. It begins optimistically, promising an opening world of knowledge and shared ideas, but devolves in time into concerns around mental health, surveillance capitalism and fake news. Arguably, Web 2.0 never ended, but there was an attempt – distinct from the blockchain concept of Web3 – to introduce the term Web 3.0. Web 3.0 was meant to capture the rise of the semantic web, later machine learning and artificial intelligence, but it never quite stuck (though ChatGPT suggests perhaps it was simply too early).</p>
            <p>Today the term Web3 (as distinct from Web 3.0) exists as a challenger to name the overall space variously known as cryptocurrency, Crypto or blockchain. It has an especial appeal to the post-2021 generation swept in under a wave of innovation in blockchain primitives. These primitives – Decentralised Autonomous Organisations (DAOs), Decentralised Finance (DeFi) and Non-Fungible Tokens (NFTs) – captured the imaginations of a new kind of user, one not necessarily plugged into the cypherpunk or crypto-anarchist ideals of Bitcoin. The term appears to partially reflect a desire to move beyond Bitcoin’s strictly monetary focus. The targets for blockchain decentralisation increasingly became the tech giants of Web 2.0, whose reputation had plummeted in this era.</p>
            <p>At a more technical level, Web3 is associated with the nexus of blockchain technologies beginning with the invention of Bitcoin in 2008. At heart, a blockchain is a decentralised record-keeping system. Bitcoin, for example, is a blockchain that tracks ownership of a currency (bitcoins) designed to offer a decentralised alternative to traditional money systems. Blockchain cultures tend to be hostile to centralisation, which is thought to be associated with corruption and control, and they typically engage in experiments with decentralised alternatives. For instance, the next great experiment in decentralised blockchains after Bitcoin was Ethereum, which is not a money system like Bitcoin, but instead a decentralised world computer. Ethereum’s key innovation is the smart contract, the nuts and bolts that allow developers to build decentralised applications (sometimes known as dApps). Ethereum itself is intended to be a neutral infrastructure for anyone to build what they wish. For the Web3 community specifically, the attraction is the ability to build decentralised analogues to centralised forms of organisation (DAOs), finance (DeFi) or art and collectibles (NFTs). To some, this means building regenerative projects with positive externalities, and to others it means little more than accelerated financial speculation.</p>
            <p>Gavin Wood, one of the co-founders of Ethereum, coined the term Web3 in 2014, but it only caught on in 2021, which one might see as linked to the coronavirus lockdowns. Whatever the reason, the post-2021 generation was certainly presented with novel decentralised mechanisms, but also with their consequences, a chaotic lawlessness that can be both enthralling and intimidating. Manic financial nihilism reigns in Web3 in the form of influencer scams, social engineering, convoluted Ponzi schemes, digital muggings and CEOs acting as wildmen (FTX, Celsius, Terra Luna). The domain is exciting, but exciting like a dangerous downtown; it’s where the action is, but make sure you watch your wallet. The push and pull of Web3 is always between the affordances decentralisation offers, whether positive or negative. Everyone is always a little bit compromised in Web3, including us.</p>
            <p>The editors of the volume walk the line between Web3 enthusiasts and critics. We find in it much to support and encourage and much that makes us question our research choices. On the positive side, Web3’s emphasis on experimental decentralisation and non-hierarchical organising is intriguing, sometimes even appealing. We decided the best course of action would be to produce an experimental volume, one that involved some of the risk-taking characteristics of Web3 cultures. To this end, we decided that the book would also explore next-generation methods of publishing. While we typically associate Web 2.0 with Big Tech behemoths like Apple and Amazon, only five companies hold an equally dominant position in the smaller world of academic publishing. The largest of these, Elsevier, holds 16% of the market and has a profit margin approaching 40%, which is higher than that of Google and Microsoft. We saw our initiative as a counter to this dominance and a prefigurative experiment in a different form of academic publishing. In particular, we wanted to emulate the commitment to openness found in open source cultures, ensuring that the texts would be freely available to download online, as well as published traditionally. Regarding funding, we also wanted to explore novel funding methods using the native tools of Web3. This was achieved by securing a grant from Gitcoin, which is an open source project where anyone can request funds for a (non-profit) project that is useful to the wider Ethereum community.</p>
            <p>To truly embrace the Web3 world, we created a website that sought to reflect both the transgressive, fun side of Web3 and its chaotic, tumultuous nature. Inspired by early Internet art, flash games, MMORPG’s, and the emerging NFT culture, we sought to produce an experience that goes beyond the traditional page or screen by leveraging smart contracts, Non-Fungible Tokens (NFTs) and the metaverse, in order to appeal beyond the academy. We decided on the theme of the carnival because, as others have previously observed, Web3 exhibits aspects of the carnivalesque, such as a fascination with games and the ludic, masks and misrepresentation, scams, chancers, various forms of excess, transgression and exhibitionism, and a communal ethos that pushes against central authority. We are particularly fascinated by the curious mixture of the playful and the political that we see in both carnivals and Web3.</p>
            
            <h1>Chapter Summaries</h1>
            <h2>Part 1: Big Tent</h2>
            <p><em>Nathan Schneider</em> argues that Web3 can give us more and better democracy, since distributed ledger technologies enable, by design, shared ownership and control by the system’s participants. Unlike the conventional corporation, where the owners of capital and labour are clearly distinguished, the expectation in Web3 is that the user is also the owner. That, at least, is the hope. However, just as corporate power colonised the early internet, quashing the pioneers’ utopian dreams, a centralization power grab is already underway in Web3 creating new forms of feudalism and plutocracy. To counter this, Schneider argues that we need to study and learn from the shared ownership models that already exist in sectors such as credit unions, agricultural cooperatives and rural electrification. And the learning can go both ways: cooperatives can embrace some of the governance and financing practices emerging in DAOs, while some DAOs are already incorporating as cooperatives. But if the potential for a democratic renaissance is to be realised, then Web3’s infrastructures need to be explicitly designed to foster democratic governance.</p>
            <p><em>Kelsie Nabben</em> presents ‘infrastructuring’ as a useful analytical lens for understanding Web3. For her, Web3 is not so much an infrastructure but rather the playful, prefigurative and political practice of infrastructuring. The playfulness builds on the field’s hacker heritage, the deep embedding of game logics in the underlying technologies, and the anarchic and bohemian traditions that inform the communities’ practices and aesthetics. But that play ethos sits on top of quite serious modular pieces of cryptography, computing hardware and software that provide the underpinnings for the myriad collection of DApps, DeFi, NFTs and DAOs that constitute Web3. Moreover, these boring technologies are resolutely political in that they purport to offer a free market and a decentralised alternative to existing institutions. Nabben emphasises that the practice of infrastructuring is prefigurative as the communities enact a vision of a desired society through BUIDLing things that are new and valuable. But the ethos of experimentation means that much of the learning is through trial and error, which, she argues, is necessary as the community must discover ‘how to infrastructure.’</p>
            <p><em>Bernard Resch</em> proposes nests, stewardship, and weaving as three primary vectors to guide the design of new digital ecosystems in Web3. As a counterpoint to a modular worldview centred on firms and individuals, nests are small, interdisciplinary teams held together by collaborative community spirit, shared purpose and a nurturing of collective wellbeing. Nests are embedded in complex relationships that link pods (small groups of 5 to 7 people), nests (100-200 members) and flocks (c. 1500 people). DAOs have the potential to be organised thus, but their structures and practices must be carefully designed and managed to avoid the toxic behaviours that self-organising can sometimes facilitate. The logic of stewardship is centred on contributing, sharing, caring and commoning, which Resch contrasts with the externalities, exploitation and short-termism of the extractive commodity logic. Again, he believes that DAOs can, and should, embrace this logic of stewardship. Finally, he weaves into his metaphor a form of distributed leadership practice, fluid hierarchies (heterarchies), and wayfinding that DAOs should adopt as their preferred mode of organising.</p>
            <p><em>Quinn DuPont’s</em> wide-ranging chapter critically evaluates the political economy and polycentric governance of Web3. Building on extant critiques of Web3 – which often fail to recognise the domain’s diversity – he maps out multiple issues that a ‘progressive Web3’ must address. For instance, DAOs can turn everything they touch into a valuable digital asset, while Web3, with its reconfiguration of work and play, can produce problematic forms of commodity fetishism. And even though ‘smart contracts’ might appear to be fairer, they may actually exacerbate inequality. These concerns are present in Web3, which in some ways is ‘a petri dish for our future online lives’ that produce interesting, if sporadic, cases of digital, decentralised, polycentric governance. For DuPont, Elinor Ostrom’s ideas of polycentric governance need to be refashioned for Web3 where there are only artificial rather than material constraints. Likewise, trust is transformed rather than eliminated, contrary to the frothy talk about ‘trustless’ transactions. DuPont argues that ideas centred on scarcity, work and profit maximisation are ill-suited to understanding Web3, and so, instead he draws on Hardt and Negri’s model of social coproduction, social movement theory and sociological models of collective identity. He concludes with a programme of research into the polycentric governance of digital common pool resources (DCPRs).</p>
            <p>While also recognizing shared ownership as a pivotal characteristic of Web3, <em>Tara Merk and Rolf Hoefler</em> strategically diverged from concentrating on prevalent issues such as equality, trust, and collective identity, choosing instead to direct their academic scrutiny towards the neoinstitutional foundations that undergird Web3 organisations. Their investigation into how DAOs strive for self-governance led them to examine the effects of both competitive and institutional isomorphism. In their essay, Merk and Hoefler adopt a theoretical approach to decipher the formation of institutions, adapting previous studies to underline three varieties of institutional isomorphism: coercive, mimetic, and normative. The core premise here is that institutions emulate competitors to enhance their effectiveness and efficiency. They argue that coercive institutional isomorphism induces homogeneity among institutions due to external threats and opportunities. This mirrors the concept of competitive isomorphism, which is driven by factors such as regulatory scrutiny, legal threats, and market opportunities. Mimetic isomorphism, on the other hand, provides an explanation for DAO’s adoption of analogous technologies and operational strategies amidst significant market uncertainty, even when these practices seemingly conflict with other strategic priorities, such as using centralised services like Discord. Lastly, normative isomorphism shifts our view towards the formation of associations, trade groups, and communities of practice. These entities play crucial roles in establishing industry behaviours and norms. In conclusion, Merk and Hoefler argue that institutional isomorphism can significantly contribute to organisational legitimacy, a persistent concern for online organisations with high voice and exit dynamics.</p>
            <p><em>Paul Dylan-Ennis</em> tackles the thorny issue of institutional change. Specifically, he outlines how Web3 culture is framed by social imaginaries, which define a community’s goals, its behavioural norms, its shared history, and finally, its taboos. By zeroing in on the culture associated with the Ethereum platform, Dylan-Ennis offers the ‘Hash, Bash, Cash’ model of decentralised organisation, where he finds change flows through its technical (hash), social (bash), and financial (cash) spaces. To elucidate this model, Dylan-Ennis dissects the 'vampire attack' on Uniswap, which gave birth to a competitor, SushiSwap. While this level of transformative change is somewhat commonplace in the Web3 realm, scholarly attention devoted to these rapid organisational disruptions is sparse; the hash, bash, cash model offers one interpretative lens.</p>
            <h2>Part 2: Vaudeville</h2>
            <p>In his essay, <em>Geert Lovink</em> explores the rise of non-fungible tokens (NFTs), their aesthetic and market value, and their impact on the art world. NFT art emerged in the midst of (another) debate in contemporary art about the end of art. One position in that debate holds that the artistic idea is all that matters, and hence its material production is unimportant, as is the buying and selling of such productions. Crypto, Lovink argues, misses this key point when it envisages ‘art’ as just a digitised asset that can be bought and sold. Hence, NFTs do not truly represent a source of income for artists but instead simply contribute to the speculative nature of crypto investments, which is a world dominated by right-wing techno-libertarians. Consequently, Lovink concludes, debates about ‘crypto art’ are distracting and moot unless they engage with wider issues around power, race and gender in Web3.</p>
            <p><em>Eric Alston</em> asks Web3 to embrace the productive polycentrism of carnivals, from Lent to Burning Man. In his essay, Alston recognizes the governance challenges that emerge with large festivals and gatherings and how scaling often has a homogenising, detrimental effect. To counter this tendency, Alston recommends that producers seek greater personal power, especially by enhancing bargaining power. While reminiscent of Michael Porter’s Five Forces model of competition, Alston instead shifts our attention to the new bargaining opportunities in the metaverse (whenever or whatever it may be). Alston probes into the unknown of this anticipated metaverse in search of the driving forces behind innovation. Ultimately, however, he identifies enhanced ownership as a key component (technologically facilitated by NFTs). Alston concludes his essay in search of a more liberated metaverse.</p>
            <p><em>JP Vergne</em> takes on one of the most enduring utopian visions of Web3: decentralisation. He observes that decentralisation is not only the raison d'être for Web3 but that it also serves as a form of theatre, akin to political or security ‘theatre.’ In this theatrical context, Vergne unfolds the narrative of decentralisation through an eclectic ensemble of characters — Hamlet, Godot, Bucket, and Figaro — as archetypes embodying efforts at decentralisation in Web3. Vergne’s narrative method downplays the influences of technologies, corporate strategies, and neoclassical economic reasoning and instead accentuates the situatedness, or essentially contextual nature of decentralisation in Web3. This distinctive approach enables him to assert that the desired model of decentralisation in Web3 should be understood as a continuum, which requires setting system boundaries, and is a process that must be regarded as distinct from associated concerns like inequality or disintermediation. In his concluding argument, Vergne advances the concept of 'authority dispersion' as a candidate for achieving decentralisation. His insight adds a fresh perspective to the discourse on how to balance power within decentralized systems effectively.</p>
            <p>In their chapter, <em>Primavera de Filippi, Morshed Mannan and Wessel Reijers</em> examine Web3 through the lens of memes. For them, cryptocurrencies like Dogecoin and Shiba Inu are not only playful and absurdly self-referential they are also make-believe: they are ‘pretend’ currencies – a deliberate and knowing charade – and they are also subversive depictions of a possible alternative world, with different forms of money and authority. These memecoins, it is suggested, are digital instantiations of the trickster, an archetype with conflicting attributes: at once clever and foolish, serious and facetious, loyal and rebellious, selfish and kind, a sneak and a hero. They can also become an ‘egregore,’ a spirit that manifests itself in the collective imagination of a group of people – Santa Claus is a good example. Web3 has its own egregores, such as the mythical figure of Satoshi Nakamoto, memes like Dogecoin and Rare Pepes, and PFPs like the Bored Ape Yacht Club. However, countermovements can be inverted and captured by the system they seek to subvert, and egregores can be intentionally designed and marketed through using the power of communities to make worthless objects seem valuable. As in the carnival, what you see is often not what you get. Or want.</p>
            <p><em>Alesha Sereda</em> also sees the trickster as one of Web3’s primary archetypes, but her analysis of Crypto YouTube celebrities from 2020-22 widens the focus to jesters and adventurers too. Adopting an anthropological and narrative approach, Sereda finds in the myths of many cultures that the trickster is neither a deity nor a person but an outlier in a stable order, an agent of chaos, prone to causing harm to others, obsessed with sexual desires, while sexually ambiguous, the subject of unbelievable stories, and a discoverer of new things. The most-watched crypto personalities are not really tricksters, Sereda concludes, instead, they are jesters or maybe adventurers. The jester (a fool) has many of the trickster’s attributes, but, importantly, is a symbolic foil to the king who is expected to mock royal authority, and, by triggering carnivalesque laughter, can inspire the masses to imagine an alternative truth and new freedoms. But, Sereda points out, the face of crypto is boredom, not laughter, and so the jester is neither as pervasive nor as important as one might expect. More common is the adventurer, typically a person from the impoverished or lower middle class who infiltrates and dupes the upper class—someone who exhibits exotic tastes and conspicuous consumption, an agent for chaos, a seducer with dubious morals, and a magnet for misfortune. In the end, Sereda finds the Crypto YouTube community too tame today but points to the trickster, the jester, and the adventurer as opportunities for productive disruption.</p>
            <p><em>Donncha Kavanagh</em> makes the case that Web3 is part of a broader cultural shift towards games, a ‘ludic turn’ of recent decades. Marking out new theoretical grounds, he extends the analysis of games to the carnival and highlights the rich extent of the metaphor. In so doing, he explores anonymity, crime, myths, hierarchy, authority, regulation, and control as major themes. But, turning directly to Web3, Kavanagh finds scant evidence of these tensions. Rather, he finds a gentrified carnival, lacking the exciting rebelliousness of early Bitcoin culture and without any of the viscera of the carnival. Moreover, Kavanagh points out, neither Web3 nor Bitcoin have much ‘ilinix,’ a special form of play associated with vertigo and confusion, which carnivals specialise in.</p>
            <h2>Part 3: Dare Devils</h2>
            <p>Instead of the trickster or jester <em>Sandra Faustino</em> sees the gambler as Web3’s more significant archetype. She begins her narrative with the ‘casino capitalism’ that led to the 2008 financial crisis and the general distrust of financial institutions and states. The working through of that crisis resulted in states bailing out banks and then paying for it through austerity programmes that left citizens impoverished and alienated by the larger forces of macro-economics and global computational infrastructures. Just then, DAOs, NFTs, blockchains, cryptocurrencies, DeFI and Web3 arrived, offering a potential out and a belief that ‘if you cannot escape the casino, you might as well try to improve your odds’. Moreover, the code, algorithms, and gamified environments fit nicely with the experiences of post-work, digital natives. But the gambler is also something of a parasite, disrupting for its own self-interest, happy to play the game but unwilling to design new games or to critique the games the rest of us play.</p>
            <p><em>Jason Potts</em> focuses on a new type of economy emerging through the integration of digital technologies such as blockchain, smart contracts, and digital identity that have enabled digitally native institutions – money (cryptocurrencies), property (tokens, NFTs), organisations (DAOs), markets (DEXs) and security (consensus protocols). Not all readers will buy his argument that capitalism and technology, through substituting machines for people and capital for labour, have brought wealth and freedom, but Potts offers an unashamedly techno-utopian view that sees the process continuing and extending in Web3. This new economy, he argues, is “computable” by which he means that it operates according to a system of rules that produces outputs that become part of the world and so can become, in turn, inputs to the same process. The computable economy, then, is an integrated, algorithmic process of computation that constructs, implements and connects all economic actions (transactions). The computable economy is not just like a computer; it is a computer. And this computer is not a tool we use but rather an environment we live in. Computable economies, he argues, will be massively cheaper to set up and run, and so he envisages a world of ‘pop-up,’ configurable economies built for particular purposes and at different scales.</p>
            <p><em>Diane-Laure Arjaliès and Samuel Compain-Eglin</em> situate Web3 in the universe of games by addressing the players themselves. In their essay, Web3 is the disruptor in an already established video gaming industry. Arjaliès and Compain-Eglin go on to describe how players in North America and Europe resisted Web3’s financialization of their play, whereas poorer, Asian-Pacific gamers embraced the movement as an alternative source of income. More than just an output of research, Arjaliès and Compain-Eglin experiment with the narrative form by establishing a dialogue between them, part professional and part personal, to discuss the aesthetics of play. Their discussion revolves around the Crow Queen, a digital artwork produced by Compain-Eglin and offered to readers as a free NFT, which reveals the aesthetic tensions of Web3.</p>
            <p><em>Victoria Lemieux</em> also extends the analysis of Web3 to games, accounting for the popularity of more extreme elements of Web3. She focuses on carnivalesque practices that produce degenerate, pleasurable, and borderline erotic feelings, what she calls jouissance. By moving her analysis away from technological features and functions, Lemieux is able to focus on the symbolic structures that bring people together to experience collective lifeworlds. Distinctively, Web3 reflects back these inner desires and, in the hands of Lemieux, mirrors the formation of a social epistemology. Like Dylan-Ennis (this volume), Lemieux invokes Charles Taylor’s notion of imaginaries to understand how utopian communities come together despite never fully realising the dream of Web3. Specifically, as people experience separation from the objects of their desire, the resulting feelings of jouissance creates space for the rejection of normal rules and permits the debasement of the individual. Rather than focusing on how Web3 potentially or actually is ‘good,’ in her concluding analysis of meme coins and NFTs Lemieux demonstrates the importance of catharsis, allowing her to excavate the symbolic aspects of Web3. The result is a highly novel, critical reflection on the tensions between individuals and their communities.</p>
            <p><em>Bill Maurer’s</em> essay considers the symbolic elements of Web3 but drives his analysis into the heart of capitalism in questioning the role of fungibility, newly reinvented by NFTs. Whereas Lemieux built her argument on the foundation of Lacanian symbols, Maurer looks to the sign systems of Saussure and Peirce. Here, Maurer finds deep philosophical and historical connections between money—which can, famously, ‘stand for’ anything—and fungibility. This leads him to consider other, perhaps non-capitalist, systems of reckoning value. In turning to Web3, Maurer considers the DeFi practice of seeking ‘alpha’ and finds valiant efforts to use technologies to ‘hold down’ loosely coupled objects for valuation—to transform the fungible into the non-fungible. Economic ‘singularities’ are the result of these valuation processes. Taken to its capitalist extreme, Maurer thinks this is a foolish, illogical task. As a sign system, Web3 tries to fend off the decay and rot implicit in all processes, what the twentieth century called entropy. Intriguingly, Maurer finds new pockets of extropianism in these valuation processes, but concludes that it is a deficient ideology unable to address political and economic realities.</p>
            <p><em>Finn Brunton</em> anchors the edition with his playful analysis of waste. Like Vergne, Brunton introduces a cast of characters drawn from theatre. This play-acting explains how self destruction, waste, and gratuitousness emerge in crypto communities, what Brunton calls YOLO ideology. YOLO ideology is unique in that the past is meaningless and the future is opaque, so inhabitants remain stuck in a perpetual present. YOLO in finance is similar in that it has no stake in the future, a point Brunton drives home with a statement by the US Security and Exchange Commission, where rule 156 notes that “past performance does not guarantee future results.” Of course, the extreme of a finance with no future is destruction, which Brunton unpacks through his description of the K Foundation. Most famous for burning a million British pounds in 1994, the K Foundation was responsible for liberating the cash made by the successful pop band KLF. They found many ingenious ways of disposing their cash, but as Brunton describes, burning that much cash takes persistence. Since it ‘just wants to be a fire,’ Brunton concludes by reflecting on the password to a wallet of worthless crypto as a pure expression of theatre.</p>
            <h2>Acknowledgements</h2>
            <p>We wish to acknowledge <a href="https://www.gitcoin.co/">Gitcoin</a> and the wider Web3 community who generously supported our Gitcoin grant. We would also like to thank <a href="https://www.ucd.ie/">University College Dublin</a>, which provided additional funding. And finally, we would like to thank our contributors, who have been immensely patient and forgiving with our little experiment.</p>
        </div>
   



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default introduction;
