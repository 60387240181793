import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function alston() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>When Digital Carnival? Distributed Control of the Metaverse Asset Layer to Enable Creative Digital Expression to Flourish</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Eric Alston
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089009">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/bdb6856c-1ef5-4182-9a4f-e987226181d3">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            This essay argues that a coordinated network of independent producers is crucial in creating an immersive metaverse. A digital asset layer that is beyond the control of any single counterparty, along with reliable definition and exchange of fungible and non-fungible digital objects, is important for digital creative expression to flourish. Non-fungible digital objects are essential in creating an asset layer for a metaverse that approximates the full range of independent human creative expression. The development of NFTs alongside cryptocurrencies, governed by distributed and transparent control, can create an intermediation layer that is separate from the control of a platform intermediary.
            </p>
            <hr />
            <p>A carnival is a beautiful expression of the human tendency to celebrate on a massive scale. It involves complex individual and coordinated human expression that far exceeds that of similar human tendencies to party among friends. Governing these large celebrations or festivals involves coordinating independent activities, which can also influence the extent and form of the independent activity that is possible. By necessity, some amount of independent bargaining power is essential for creative artisanal production to flourish. That is, for any metaverse to be immersive like a carnival or festival, it will need to involve independent creative input from many producers .This essay discusses the importance of independent creative input from many producers to create an immersive metaverse like a carnival or festival. Pursuant to this aim, a digital asset layer that coordinates a network of independent producers should ideally be beyond the control of any single counterparty. This makes the reliable definition and exchange of fungible and non-fungible digital objects is necessary to enable digital creative expression to flourish.</p>
            <p>Furthermore, non-fungible digital objects will play a necessary role alongside fungible tokens in creating an asset layer for a metaverse that approximates the range of independent human creative expression in the real world. However, the current applications of NFTs have considerable problems in achieving their intended functions beyond signifying ownership within a group that values formal recognition. But within a networked platform context like a metaverse , the development of NFTs alongside cryptocurrencies creates a more complete asset layer for these same contexts. Together with governance innovations surrounding distributed and transparent control of this asset layer, this stack of institutions and technology can create an intermediation layer that is separate from the control of a platform intermediary.</p>
            <h1>Creative, large scale human celebrations</h1>
            <p><strong>Carnival</strong> - an instance of merrymaking, feasting, or masquerading (2a); an organized program of entertainment or exhibition (3b)</p>
            <p><strong>Festival</strong> - a time of celebration marked by special observances (1a); an often periodic celebration or program of events or entertainment having a specified focus (2a) <a href="#_ftn1"><strong>[1]</strong></a></p>
            <p>The formally celebrated Carnival is typically associated with countries that have an extensive history, including colonial, with Roman Catholicism. However, historians of the now-global phenomenon attribute the wanton celebration of carnival to earlier pagan traditions of Bacchanalia, Saturnalia, and Lupercalia. In a typical act of syncretism, Carnival came to be celebrated prior to the period of fasting associated with Lent in the Catholic tradition. But there is likely something much more universally human in this celebration. China, India, and the Arab world each have similar traditions of coming together to celebrate effusively, often until well after midnight. Whether it’s the Chinese New Year, Diwali, or Eid Al-Fitr, periods of effusive communal celebration are part and parcel of the human tradition. None of this is meant to belittle the fact that these distinct traditions are celebrating very different reasons or beliefs underlying the specific date of a given celebration. Instead, it is to suggest that the carnival tradition is an expression of something more integrally human. Where the Catholic tradition of carnival did not spread, county and state fairs, rodeos, and even festivals like Burning Man represent a similar effusive space for human interaction.</p>
            <p>What do carnival attendees around the world have in common? They tend to express their creativity individually and communally, resulting in a range of artistic output from music to food to dance to costuming and parades. This eruption of creative expression is frequently concentrated into a single context. It’s a necessarily chaotic synergy of individual and community expression into a greater ordered whole. The cultural ubiquity of celebration becomes exponential when spanning generations, cultural groups, and wide geographic regions.</p>
            <p>A proper festival involves exuberant, vibrant, creative production, both individual and cooperative. This can include lavish and ornate costumes, dance troupes, floats, and musicians. Moreover, these periodic acts of extensively coordinated yet independent human celebration contain an element of the irrepressible. For example, the tradition of Venetian masks during the carnival was associated with a period in which celebration was prohibited, and revellers therefore disguised their identity . The fact that carnivals have long involved pseudonymity and anonymity through costume and masking provides an interesting analogy to the current context of pseudonymity and anonymity that distributed networks afford their users.</p>
            <p>What do festival periods that convulse entire counties, countries, and cultures have in common? They tend to extend far beyond the level at which any one participant can be familiar with all others involved. In fact, in festivals that span cities or take place across an entire nation, the celebration exceeds any individual’s ability to participate in or even perceive all the human activity that a given celebration encompasses. A celebration thus involves the coordination of many intrinsically personal spheres of human interaction within and around one another. Institutionalized governance emerges in the space between the spheres of our relationships that are governed by personal knowledge and bonds.</p>
            <p>As the police in any city where a large festival has occurred can attest, certain kinds of crimes emerge from the sheer volume of individuals who have been brought together by the communal act of celebration. Therefore, the way that a given celebration of human creative expression and production is governed is a central determinant of the extent of this output that a given complex human social order can sustain. Governance can therefore be understood as a mechanism that operates as a scalar mechanism in human social orders, creating a role for governance in human celebrations as well.</p>
            <p>For example, Burning Man, which started as a voluntarist, communitarian, and even anarchist community, has come to involve considerable organizational definition. This includes defining rules for camp placement in subsequent years, selling tickets, accepting donations, and interfacing with federal and state authorities. The need for governance thus persists as human orders scale beyond the personal. And if we’re being honest, any good festival is one that has more than just your friends present – otherwise it's just a great party.</p>
            <p>Governance requires rules, and applying such rules can lead to unpopular decisions, often by those directly affected. While this is inevitable, it is not catastrophic (DuPont, this volume). Not everyone can have the best camp locations in Black Rock City, but different people may want different locations for various reasons. Resolving these demands, which can be complementary or conflicting at times, is the essence of governance. For festivals to occur at the scale they do, governance is necessary, particularly if the festival happens annually for days or even weeks.</p>
            <p>Governance raises the question of how to achieve the group’s objectives and who should make the decisions. If a camp loses its long-held location due to poor clean-up or other infractions, they must understand the reasons for the decision and be informed of the rules for cleaning up matter-out-of-place. These characteristics of notice and transparent process are hallmarks of governance in modern liberal societies. Individuals who wield power within a system are crucial, and if this power becomes too concentrated or unchecked, it can lead to various problems associated with more autocratic governance.</p>
            <h1>Polycentrism in festivals and carnivals</h1>
            <p>Festivals and carnivals are complex events that are influenced by various spheres of private and public authority, making them polycentric. Different stages at a single music festival are often managed separately, with each performing artist having their own manager. Food trucks, merchandise booths, and other outlets are also managed independently, allowing attendees greater choice at a particular event. However, for the average ticket holder, food truck vendor, and lesser-known artist, the terms of their contracts are fixed and standardized. These terms reflect the comparative scale of the parties involved in the transaction. Unless a rock star is involved, centrally managed events tend to reflect the relative scale of attendees and vendors in the terms offered. As a result, many stages of output become routineized , such as the use of brutalist metal grilles to channel crowd flows at large urban music festivals.</p>
            <p>Contractual terms and space management reflect the standardization that centralized governance at scale tends to produce. The prevalence of brutalist architecture in the former Soviet republics under limited budgets and centralized governments can be understood as a direct result of this reality. Centralized output at scale requires reliably uniform output and financing to obtain this scale tends to require centralized governance to credibly commit to creating this uniformity and scale of output. For example, the products in big box stores are incredibly uniform compared to the variance in products at a farmers’ market. While farmers' markets offer a breadth of choice and individual aesthetic variance, reliable uniformity of product tends to obtain at scale in economic production and governance, even if this reliability in production comes at the cost of variance in individual aesthetic.</p>
            <p>However, not all forms of governance tend towards centralization. Farmers’ markets are a great example of how individual production from many distinct individuals and groups is a necessary input to the successful execution of a market. If a single agricultural conglomerate tried to sell as wide a range of products as is available at a farmers’ market, it is unlikely they would attract customers who could just as easily obtain those goods (alongside many others) at a large grocery store chain. To put it simply, having a single producer would destroy the intended reasons for a farmers’ market in the first place. Some markets remain integrally local, while others scale to a global level, and have varying levels of production and supply chain arrangements.</p>
            <h1>Artisans, bargaining power, and creative production</h1>
            <p>Will creative production inevitably fall before the grist mill of production for a global economy? There are reasons to suspect otherwise. Consider the wine industry as an example of highly individualized artisanal production. Specific producers still command considerable price premiums relative to producers from the same region using techniques that, to the uninformed observer, look identical. In industries where refined creative output commands a large premium, such as wine or art, the bargaining power still resides with the producer. This may be less common today, given the increasingly global wine production of many countries and the ways in which the digital age has disrupted traditional art markets. However, certain wine producers in Bordeaux, Napa, and Piedmont command prices that far outstrip any competitors. While the producers at the very top of price lists may not produce a per-dollar amount of quality that justifies their price (compared to the need for Michelin-starred restaurants and rising billionaires to have the absolute best in their cellars), price reflects quality in locally specialized production of wine and art in ways that many other products no longer do.</p>
            <p>Is it possible to have both artisanal variation and aesthetic vision in a global market? The examples discussed so far suggest a path forward, but they rely on creative producers maintaining bargaining power and consumers having a choice among many independent producers, which creates beneficial governance incentives.</p>
            <p>Moreover, the examples mentioned so far assume a certain level of luxury, such as being able to shop at a farmer's market or patronize an art gallery regularly. Is there a way for individual creative expression to reach a global audience at an affordable level for the average consumer? Music, video, and other audio-visual techniques can be duplicated digitally, which is not possible for physical media. This is not to say that reproducing digital information is costless for digital artists or software producers, but it does emphasize the transformative ability of the digital age for a single creator to reach an audience of millions.</p>
            <p>If the existing models of digital marketplaces do not provide a clear path forward, how can individual artistic expression be maximally facilitated in the digital context? A partial answer lies in the means of reliably securing unique digital objects, which becomes economically valuable. In most economically significant contexts for producing scarce digital objects (or NFTs), the predominant economic value has not derived from securing a digital asset layer in a system of distributed governance.</p>
            <p>For instance, in video games, players collect in-game items or experiences that are valuable because they are rare. However, if every player has all the items or experiences, then there is no reason for players to keep playing or make more purchases. This means that game developers may not need a distributed network to prove the rarity of their in-game assets, as other players can see how rare they are by observing the game's communication and interaction (see also Arjalies and Compain, this volume).</p>
            <p>NFTs have initially been applied to secure ownership recognition of a particular piece of digital art, whether it is an image, a video, or an audio file. However, the term “recognition” here displays the intrinsic limitation of NFTs. While they can secure a piece of art, it will ultimately be reproduced locally, and likely at high fidelity if the purchaser cares enough about digital art to spend hundreds or thousands of dollars on the work. The technology does not secure against unauthorized reproduction of the digital art — it only prevents replication of the token that signifies ownership of that art for users of a particular network.</p>
            <p>In the first case, decentralized governance of in-game purchases of scarce digital objects is not integral due to the alignment of the game producer's incentives to create sufficient scarcity in fungible currency and non-fungible digital objects like skins or other character-specific attributes. In the latter case, the NFT only signifies ownership, rather than securing it, which does not ameliorate many of the problems inherent in ensuring ownership of reproducible digital objects.</p>
            <h1>Bargaining power in the metaverse</h1>
            <p>So why did I start talking about a carnival if I only came to throw cold water all over it? If the initial applications of unique digital objects (individually tokenized) do not solve human coordination problems within our increasingly digital organizations, then what’s the point? The reason is that the combination of these two classes of digital objects (fungible and non-fungible) creates the basis for a digital asset layer beyond the control of any one counterparty in a digital environment with many independent producers.</p>
            <p>Currently, platforms control access to the ecosystems of applications and content that will one day become the metaverse. If the same single producers (or conglomerates of content, IP, and finance) control the contractual terms for independent creatives’ production, this will be a metaverse whose creative margins are predominantly determined by the central platform authority. Although a user may get to choose their hair color and outfit, all avatars are still constrained to a certain uniformity of dimensions.</p>
            <p>A metaverse that surpasses the dystopian visions of virtual reality to date is one in which independent producers maintain a healthy share of bargaining power against the singular network on which they all coordinate and compete. When a consumer devotes their attention and/or tokens to one digital boutique or gallery, they do not do so for another competing boutique. However, the independence of these boutiques and their ability to pursue competing creative visions depends, in part, on an asset layer around which they can all coordinate without a single actor capturing the lion’s share of the rents. An open metaverse is one in which individual creators will still find it costly to compete for global attention. Nevertheless, independent producers of some of the finest aspects of human creative expression manage to do so globally today. In a digital context, any metaverse that hopes to encourage the same levels of human creative expression must credibly enable that creative expression to flourish free from the strictures of centralized governance that result from market power imbalances between the network layer on which all independent producers must coordinate and the size of any one producer.</p>
            <p>While NFTs are still in their early stages, successful networks that recognize digital ownership as valuable to other members may persist in interesting ways. To view tokens with a unique digital identity tied to instances of human creative expression as limited to their current applications is to misunderstand the way in which fungible and non-fungible property instruments form the lifeblood of human economic interactions. In truth, human ownership claims exist on a continuum of fungibility, just as some NFTs are one-offs, while others entitle the holder to a claim among several to many other such claims. This variation has its precedent in artist prints and rare or limited sports cards.</p>
            <p>Innovation around reliably unique (or limited and tied to specific within-network privileges or content) tokens provides a necessary complement to the reliably scarce digital units of account that have given rise to networks fully devoted to cryptocurrency applications. Distributed governance of networks that facilitate the specification and exchange of fungible and non-fungible digital objects alike creates an asset layer for the future digital economy that is more like a farmers’ market than a Costco. For a metaverse to ever come close to realizing the unpredictable and beautiful cacophony of human creative expression that is a carnival, it will need a digital asset layer that facilitates coordination and preserves bargaining power in the hands of creators. While any such metaverse is further away than many advocates make it out to be, innovation in this space is a major prerequisite to realizing the digital artisanal vision I have briefly sketched herein.</p>
            <br clear='all'/>
            <hr align='left' size='1' width='33%' />
            <p><a href="#_ftnref1">[1]</a> Throughout, I use these words interchangeably to refer to large-scale human celebrations involving individual and coordinated creative expression among many independent groups. These definitions are taken from Merriam-Webster’s dictionary.</p>   



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default alston;
