import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import moloch from "../../assets/images/content/moloch.png";
import fuzzybuterin from "../../assets/images/content/fuzzybuterin.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function nabben() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Entering the Field of Web3: &#8216;Infrastructuring&#8217; and how to do it</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Kelsie Nabben
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089003">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/c6d30667-7c51-48a2-9c33-2552ce8a5460">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
                Web3 is a practice in participatory digital infrastructures through the
                ability to read, write, and own digital assets. Web3 is hailed as the
                alternative to the failings of big tech, offering a participatory mode of
                digital organization and shared ownership of digital infrastructure through
                algorithmic governance. This piece offers an introductory playbook to
                researchers entering the field of &#8216;Web3&#8217; by offering an
                analytical lens to approach the emergent field of Web3 as
                &#8216;infrastructuring&#8217;. I argue that Web3 can be understood as a
                collective, community exploration of &#8216;how to infrastructure&#8217;.
                Drawing on qualitative examples from digital ethnographic methods, I argue
                that the play, politics, and prefiguration that undergirds Web3 is one of
                creating the choice to &#8216;exit&#8217; existing institutional
                infrastructures. Thus, one of the fundamental challenges Web3 is grappling
                with through its governance experiments is &#8220;how to
                infrastructure?&#8221;.
            </p>
            <hr />
            <h1>
                Introduction
            </h1>
            <p>
                The decentralized, digital technologies that comprise &#8220;Web3&#8221;
                are born out of the idea that access to encryption technology is an
                essential right in the age of information and computers. Accessing and
                entering Web3 requires one to navigate the boundaries of deep online and
                offline community participation, including &#8220;sliding into the
                DMs&#8221; (direct messages) of Web3 personalities, relating with
                pseudonyms, and managing numerous coins, wallets, and &#8220;seed
                phrase&#8221; passwords.
            </p>
            <p>
                This piece demystifies Web3 for researchers by approaching it as
                &#8220;infrastructure&#8221;, identifying where it occurs, how to enter it,
                and how to understand it through cultural dynamics, politics, and master
                narratives. I present Web3 as composed of the following attributes of:
            </p>
            <ul class="content">
                <li>
                    Permissionless (to access)
                </li>
                <li>
                    Pervasive (participatory infrastructures and personal entanglement)
                </li>
                <li>
                    Prickly (characters)
                </li>
                <li>
                    Playful (aesthetics, such as memes)
                </li>
                <li>
                    Political (origins and ambitions)
                </li>
                <li>
                    Prefigurative (A bias towards action or &#8220;BUIDL&#8221;-ing
                    (meaning build) a new world from within).
                </li>
            </ul>
            <p>
                These themes of &#8216;permissionless&#8217;, &#8216;pervasive&#8217;,
                &#8216;prickly&#8217;, &#8216;playful&#8217;, &#8216;political&#8217;, and
                &#8216;prefigurative&#8217; are subtly and deliberately threaded throughout
                this piece as &#8220;easter eggs&#8221; in theoretical explorations and
                qualitative examples for the reader to uncover, apply, and make meaning of.
                By analyzing some of Web3&#8217;s key themes and politics, I argue that
                Web3 is about &#8220;exiting&#8221; existing institutions to build its own
                structures of self-governance from within the prevailing societal
                hierarchies and power structures. The verb &#8220;to infrastructure&#8221;
                denotes the activities, processes of integrated materials, tools, methods,
                and practices that make up and change an infrastructure (Star &amp; Bowker,
                2010). Good information infrastructures are those that are adaptive to
                changing circumstances and environments (Star &amp; Bowker, 2010). In
                conceptualizing Web3 as infrastructure, it becomes clear that the
                fundamental challenge to Web3&#8217;s ambition to build and create is to
                figure out what and how to build, or &#8220;how to infrastructure&#8221;.
            </p>
            <h1>
                Gaining access
            </h1>
            <p class="displayquotation">
                The secret of change is to focus all of your energy not on fighting the
                old, but on building the new. &#8211; Dan Millman in &#8220;Way of the
                Peaceful Warrior&#8221;. (1984).
            </p>
            <p>
                It was worth leaving the house for the local &#8220;WEB3 HACK&#8221; meetup
                that Thursday evening, despite my post-COVID social anxiety about being in
                crowds and having to make small talk. In reward for our interest and
                attention, each attendee was &#8220;airdropped&#8221; Zcash privacy coin to
                our digital wallet address. To receive the equivalent of 18 cents in the
                privacy coin, I chose the mobile wallet brand called
                &#8220;Nighthawk&#8221;. The transaction included a secret memo containing
                a &#8220;shielded love note&#8221;, which was a message from the sender to
                the receiver that was cryptographically hidden from external surveillance
                or snooping.
            </p>
            <p>
                I was late to provide my wallet address to register for the airdrop,
                somewhat desperately &#8220;DMing&#8221; (direct messaging) the well-known
                founder on Twitter in the middle of their talk to include me in their
                software script that would drop to all the addresses at once.
            </p>
            <code>
                Hi! &#128075;. Please add me to your bash script. Wallet address:
            zs1tegqkd6ll92ktc2gxr874ljy7qjau5aa7pspp6rrktnaeyl82fgrdh6zxlr5pjznsdgpjmyqaef.<a href="#_ftn1" name="_ftnref1" title=""><sup><sup>[1]</sup></sup></a> 
            Ty! &#128591;
            Also, I would love to interview you for a research article on the Cypherpunk&#8217;s and origins of decentralized technologies to test some assumptions.
            </code>
            <br />
            <p>
                To my surprise, they responded.
            </p>
            <code>
                Hi. Check your Shielded Love Notes!
            </code>
            <p>
                Opening up my NightHawk wallet, the transaction memo read:
            </p>
            <code>
                Hi, Kelsie, nice to meet you! How did you think the meetup went
                yesterday? What assumption are you trying to test?
            </code>
            <br />
            <p>
                With the tiny amount of coin I had received, I could still afford to send
                micro transactions of cryptocurrency with a memo attached each time. Each
                message exchanged was shielded by &#8220;zero knowledge&#8221; cryptography
                on the Zcash privacy coin blockchain.
            </p>
            <p>
                I thought to myself, &#8220;I&#8217;m interviewing a well-known
                cryptographer and privacy coin founder, mediated by cryptographically
                shielded love note transactions on the blockchain they invented!&#8221;, as
                I responded:
            </p>
            <code>
                There are not enough characters in the memo or Zcash in my
                wallet&#8230;Also, I need your approval to interview &#9786;
            </code>
            <p>referring to the ethics form I needed them to sign to consent to an
                interview to comply with University processes.</p>
            <h1>
                What is Web3? A Brief Background
            </h1>
            <p>
                The invention of the World Wide Web, known as &#8220;Web2.0&#8221;, offered
                a revolution in digital media. Anyone could find information via
                browser-based search engines, as well as share information through web
                pages, blogs, and eventually, social media platforms. &#8220;Web 3.0&#8221;
                is an evolution of the internet, with greater data portability to shift
                power away from corporates to individuals (Stevens, 2022). In contrast,
                Web3 is a general term used to refer to platforms that leverage blockchain
                technology to enable verifiable ownership of digital assets (including
                data). Web3 offers an alternative to the internet. If Web2.0 gave people
                the ability to read as well as &#8220;write&#8221; digital media on the
                World Wide Web (for example, blogs), then Web3 is a platform infrastructure
                to &#8220;read, write, and own&#8221;. For ownership, the cryptographic
                properties of private key management in cryptocurrency (fancy maths for
                secure communication over insecure channels), combined with the shared,
                distributed consensus of blockchain-based ledgers (basically, databases)
                provide the foundations for cryptocurrency tokens that represent property
                rights and ownership of decentralized digital assets and networks. As such,
                this piece largely focuses on blockchain technology as an enabling
                infrastructure for Web3, and blockchain communities as a field site by
                which to enter Web3.
            </p>
            <h2>
                Promises, Promises
            </h2>
            <p>
                The promise of Web3 is a decentralized infrastructural base, that anyone
                can build on, and everyone can own. Web3 provides a next generational,
                peer-to-peer network for participatory self-organizing, and a new
                infrastructural &#8220;archetype&#8221; (Stefik, 1996). The unique
                components of public blockchains are the combination of distributed
                computing, cryptography, and self-provisioning public infrastructure (such
                as financial networks) to coordinate without central intermediaries. This
                gives rise to what has been labeled as a new field of
                &#8220;cryptoeconomics&#8221;, which is the application of cryptography and
                economics for new forms of institutional infrastructure (Berg, et. al.,
                2019). Grounded in the purity and infallibility of mathematics,
                cryptoeconomic systems combine cryptography and economics to &#8220;produce
                new methods of communication, cooperation, and organization&#8221; (Cowen
                &amp; Tabarrok, 2022). Blockchains are touted as a technical expression of
                the politics of &#8220;exit, choice, and loyalty&#8221; (Hirschman, 1972).
                This means that individual freedom is pursued through optionality, with the
                choice to split the technology and the community if there is strong
                disagreement (known as &#8216;forking&#8217;) (Berg &amp; Berg, 2017). This
                infrastructure provides an alternative, free market response to the decline
                in organizations and nation-states.
            </p>
            <p>
                In response to these features, public blockchains have been described as
                creating &#8220;new institutional possibilities&#8221; and providing a
                &#8220;blueprint for a new economy&#8221; (Berg, et. al., 2019; Swan,
                2015). In blockchain systems, the rules of collective governance are
                designed and encoded for software to enact through ordered, distributed
                consensus. When applied to governance procedures, this purportedly
                minimizes the need for trust in institutions by relying on computational
                processes, especially transparent and verifiable software code and
                cryptography (Szabo, 2017). In theory, code reduces the need for
                third-party regulators, as the system can regulate itself (Wright &amp; De
                Filippi, 2015). This procedural &#8220;input-processing-output&#8221;
                perspective of the world is referred to as &#8220;blockchain
                thinking&#8221; (Swan, 2017). What can be built on these infrastructural
                foundations include Decentralized Autonomous Organizations (DAOs),
                Decentralized Finance (DeFi), Non-Fungible Tokens (NFTs), and
                &#8220;open&#8221; Metaverses (Nabben, 2021).
            </p>
            <p>
            <img src={moloch} class="img-fluid" alt="Figure 1: The Sword That Can Slay Moloch (the god of
                coordination failure), (Owocki, 2022)."/>
            </p>
            <p class="figure">
                Figure 1: &#8220;The Sword That Can Slay Moloch&#8221; (the god of
                coordination failure), (Owocki, 2022).
            </p>
            <h1>
                Researching Web3
            </h1>
            <p>
                Relatively little has been written about conducting research in the field
                of blockchain communities as an enabling component of &#8220;Web3&#8221;.
                Web3 is a multi-disciplinary construct, combining principles and practices
                from cryptography, software engineering, network security, and economics.
                Rella describes the ethnography of blockchain communities as digital,
                multi-sited, and &#8220;radically networked&#8221; (2021). Rennie
                highlights the cooperative, participatory nature of decentralized
            technologies, posing the question: &#8216;what would it be like to be    <em>part of</em> an autonomous robot or infrastructure that makes
                decisions?&#8217; (Rennie, 2021). Generating ethnographic connections and
                identifying patterns, especially in socio-technical systems, is analogous
                to human-machine entanglement between heterogenous, connected elements
                (Strathern, 2004; Murray-Rus, et. al., 2019). In this ethnographic
                engagement between constellations of people, artifacts, and algorithms, it
                is impossible not to get subsumed by the pervasive nature of crypto
                culture.
            </p>
            <p>
                As an ethnographer, entering the field of Web3 requires entanglement.
                Communities are relatively open and easy to access. This aligns with the
                ethos of &#8220;permissionlessness&#8221;, meaning &#8220;possible to
                participate in the use, development, and governance of that system or
                infrastructure without requiring permission from an authority, by adhering
                to publicly stated procedures&#8221; (Nabben &amp; Zargham, 2022).
                Participation consists of &#8216;lurking&#8217; in numerous online Discord
                application chats, community and company Slack groups, engagement in
                full-blown Discourse forum discussions, and voting in Decentralized
                Autonomous Organizations (DAOs). Yet, everyday immersion in the field of
                crypto is unrelenting and consuming. One early and long-term contributor
                and cypherpunk Vinay Gupta states: &#8220;You&#8217;ve never seen burnout
                &#8216;till you&#8217;ve seen blockchain burnout: 24h a day, 7 days a
                week&#8230;I remember working on the web: it was never *anything* like as
                intense as the blockchain space is&#8221; (2022). As evidence of this,
                well-regarded crypto legal professor and self-professed &#8220;Crypto
                Realist&#8221; Angela Walch recently announced a leave of absence, citing
                burnout (Walch, 2022).
            </p>
            <p>
                In entering the field, Web3 is inseparably intertwined across the digital
                and physical. Blockchain research has been described as both a theoretical
                and logistical pursuit (Rella, 2021). Researching Web3 occurs in and
                through information infrastructures, often those of Web3 itself. Amidst the
                COVID-19 pandemic, and for me in the city with the world&#8217;s longest
                lockdown, the moving circus of crypto conferences slowed but the online
                forums and sheer volume of conversation continued to rage. The intense
                cadence of discussions, proposals, votes, and market twists and turns that
                are maintained in these online spaces all form ethnographic research data
                to identify, keep track of, document, analyze, reflect on, and transform.
                Meanwhile, the moving feast of crypto conferences charged ahead throughout
                the pandemic where personal relationships are built for &#8220;high signal,
                low noise&#8221; communications that lubricate deals, projects, governance
                decisions, and development. In many cases catching COVID-19 was
                &#8220;proof of attendance&#8221;. Having just returned to my first crypto
                conferences in 3 years (3 in 2.5 weeks, naturally), I&#8217;m physically
                exhausted but intellectually invigorated.
            </p>
            <p>
                Researching decentralized technology communities uncovers both the
                inspiring aspirations of internet subcultures, as well as the dank corners
                of the Dark Web. Here, the worst of human nature can be displayed in a
                pseudonymous atmosphere of meritocracy based on intellectual prowess,
                extreme libertarianism (&#8216;anything goes&#8217;) and a value for
                hard-edged incentive alignment (&#8216;make the humans do what you want via
                money&#8217;). Crypto is a &#8220;repugnant market&#8221;, meaning one that
                is viewed unfavorably in the eyes of society due to the real or perceived
                harm to participants (Allen, et. al., 2021). The underbelly of
                crypto&#8217;s &#8220;dank meme culture&#8221; occasionally bubbles up and
                spills over from one of the &#8220;Chans&#8221; (i.e. &#8220;4Chan&#8221;
                or &#8220;8Chan&#8221;), an online platform that is government censored in
                numerous geographies but thriving. For example, the crypto craze around
                saying &#8220;Good morning&#8221; or &#8220;GM&#8221; for short is thought
                by many participants to be a way of welcoming others to a Discord chat or
                the Twittersphere as a way to kick off a day&#8217;s work, especially
                during the pandemic. With a little more digging, it seems that the morning
                ritual actually emerged from a known sex cult in America and perpetuating
                it in unknowing online communities was seen as funny (Wikipedia, n.d;
                Prabhu, 2018). In this field, I&#8217;ve seen many lose their identity,
                quit their job to become a &#8220;sovereign individual&#8221;, give up one
                residency to gain another in a tax haven, separate from their long-term
                partner and declare polyamory, and forego their human decency in the hope
                of &#8220;mooning&#8221; (getting rich quick).
            </p>
            <p>
                As time progresses, I too have become more entangled in Web3. People and
                projects seem genuinely interested in my research outputs. In such a
                fast-paced setting and with little sensemaking occurring in near real-time,
                I am invited to speak, write, participate, govern, steward, and advise
                (thank goodness my husband refuses to come to all the events, so I am
                forced to return home from the conference circuit and maintain a life on
                the outside). These projects too want to know &#8216;how does technology
                become resilient?&#8217;, &#8216;what are DAOs vulnerable to&#8217;, and
                &#8216;what is good governance?&#8217;. Why these questions are even
                relevant, is connected to the origins of Web3.
            </p>
            <h1>
                Where did Web3 come from?
            </h1>
            <p>
                In the study of infrastructures, one way to identify emerging ecosystems is
                by their master narratives (Star &amp; Ruhleder, 1996). Blockchain, and by
                extension Web3, can be known by its political imaginaries and expressions
                (Husain, et. al., 2020). Blockchains configure our social reality by
                configuring social relations and constituting new social realities (Reijers
                &amp; Coeckelbergh, 2016). Decentralized technologies offer an information
                infrastructure with unique attributes, in that it is participatory,
                open-source, and encrypted, which suggests the possibility for social
                coordination that is free from unwanted third-party interference. The
                combination of distributed computing, cryptography, and the idea of
                self-provisioned digital monies led to the invention of Bitcoin, the first
                functioning, &#8220;peer-to-peer digital cash&#8221; (Nakamoto, 2008). This
                concoction of an invention is largely credited to the
                &#8220;Cypherpunks&#8221;, a 1990s subcultural group of cryptographers,
                computer engineers, philosophers, and political extremists (Swartz, 2018;
                Maurer, et. al., 2013; Brunton, 2019). The Cypherpunks have been described
                as key political protagonists in the engineering of Web3 political
                economies (Brekke, 2020).
            </p>
            <p>
                Cryptocurrencies and Web3 emerge as an early reaction to the potential of
                computing to greatly enhance the surveillance state and surveillance
                capitalism (Nabben, 2021). The advent of public key cryptography enabled
                access to the ability for identities to communicate securely over insecure
                networks (Diffie &amp; Hellman, 1976). The Cypherpunk&#8217;s converged
                over the shared belief that public key cryptography was a powerful tool in
                re-structuring society. According to Timothy C. May, author of the Crypto
                Anarchist Manifesto and one of the co-founders of the Cypherpunks Mailing
                List, cryptography has the ability to &#8220;fundamentally alter the nature
                of corporations and of government interference in economic
                transactions&#8221; (although we know that public blockchains as an
                instantiation of this vision are indeed very traceable, with professional
                organizations offering this service to governments and other companies)
                (May, 1992). Such tools for communication, commerce, and self-organization
                were first embraced by repugnant markets meaning those that are viewed
                unfavorably in the eyes of society due to the real or perceived harms to
                participants (Allen, et. al., 2021), such as the &#8216;silk road&#8217;
                online drug marketplace.
            </p>
            <p>
                Web3 perpetuates computer subcultures of old by embracing the hacker ethic
                of participatory organizing. Hacking is about political re-ordering. The
                cultural politics of Web3 are in many ways a continuation of hacker
                traditions of political re-ordering, embracing political ambition through
                &#8216;playful tinkering&#8217; (Coleman, 2011). Although serious in its
                underlying ambition, development is conducted playfully and for the
                &#8220;lulz&#8221; (laughs) (Coleman, 2014), as both an intellectual
                challenge and a game. Yet, in Web3, hackers are not breaking, but the
                overarching memetic phrase (&#8220;meme&#8221;) known as
                &#8220;BUIDLing&#8221;.
            </p>
            <p>
                Web3 has emerged from encryption and anarchy, not for tearing down but for
                creating value networks that restructure society in ways where people have
                more control. For hackers, &#8220;code is speech&#8221;, meaning a sphere
                for free speech and protest (Coleman, 2009). The saying &#8220;code is
                law&#8221; in Bitcoin communities refers to the immutable, software code
                that acts as legal enforcement of the rules of the system (Lessig, 2000).
                In Web3, code is creation. Its playful origins that were once surmised in
                the meme phrase &#8220;HODL&#8221; (meaning, hold your tokens despite
                market bear and bull runs), are now described as large-scale infrastructure
                engineering projects, encapsulated in the surprisingly sticky meme
                &#8220;BUIDL&#8221; (meaning, build). Creation speaks of iterative
                &#8220;experiments&#8221; in new institutional infrastructures and societal
                possibilities for self-organization. Yet, the subliminal but fundamental
                question behind Web3 is, what is being built?
            </p>
            <h1>
                The Prefigurative Politics of Web3
            </h1>
            <p>
                The cultural politics of digital media is a seminal lens in its analysis
                (Coleman, 2010), as &#8220;no tool is neutral&#8221; (Star, 1999).
                Decentralized, public blockchains have been described as both a
                &#8220;theory of the larger social order&#8221; and a challenge to it
                (Swartz, 2018). The political philosophy of these infrastructures has been
                described as the self-provisioning of public goods via technological tools,
                free markets, and governance minimization (Swartz, 2019; Ennis, 2021). The
                Ethereum public blockchain project positions itself as the infrastructural
                backbone for distributed, global, coordination that will emerge from within
                the existing system.
            </p>
            <p>
                &#8220;&#8230;money is a fundamentally social thing in a much deeper way
                than, say, two-party encrypted communication,&#8221; Buterin [co-founder of
                the Ethereum blockchain] states. &#8220;You have to start thinking about
                governance, social contracts &#8230; common shared expectations in this
                community, how do changes get made, how do we decide how changes get made,
                how do we discuss things &#8230; These are all very political things&#8221;
                (as quoted by Morris, 2019).
            </p>
            <p>
                Whilst admiring the Cypherpunks&#8217; anarchist ideologies and creations,
                Ethereum&#8217;s politics have been gently redirected to emphasize a
                culture of software development, immersed in cultural diversity and
                belonging, and epitomized in memes and motifs of rainbows and unicorns. By
                taking a more subtle but still political path to societal change,
                &#8220;Ethereum is not in the business of countering the state&#8221;
                directly (Ennis, 2021). This has allowed the gradual advance of
                decentralized infrastructure for financial markets, governance, government,
                identity, and coordination between digital and physical assets into the
                mainstream.
            </p>
            <p>
                These concepts underpinning the political propensities of public
                blockchains are encapsulated in the concept of prefigurative politics.
            </p>
            <p>
                Prefiguration is a politics of creation in response and opposition to
                existing institutions to embody personal and anti-hierarchical values
                through participatory action to enact a vision of a desired society in
                place of what is (Boggs, 1977; Swain, 2019). Blockchain communities embrace
                a politics of prefiguration by embodying the politics and power structures
                that they want to enable in society (noting that they are heterogenous and
                fraught with political infighting and alliances). In parallel with existing
                forms of government, blockchain introduces a novel form of self-governance
                with its own power dynamics (Miscione &amp; Kavanagh, 2015). This
                disintermediation of centralized actors emerges from within existing
                structures in society, including that of communications, money, digital
                media, and recreation. What is not completely clear, is what society is
                being prefigured towards.
            </p>
            <p>
            <img src={fuzzybuterin} class="img-fluid" alt="Figure 1: 'This is the man that has all your money!' Co-founder of Ethereum blockchain Vitalik Buterin at ETHDenver, 2022. Image courtesy of RMIT Blockchain Innovation Hub"/>
            </p>
            <p>
                Figure 2: &#8220;This is the man that has all your money!&#8221;.
                Co-founder of Ethereum blockchain Vitalik Buterin at ETHDenver, 2022. Image
                courtesy of RMIT Blockchain Innovation Hub.
            </p>
            <p>
                If blockchain is about infrastructural experiments in re-ordering society,
                then Web3 is the configuration of these new social worlds. Of course, the
                risk of prefiguration is that rather than offering an escape from
                government, technological infrastructures just offer another, competing
                governance system with its own politics (Reijers &amp; Coeckelbergh, 2016).
                The reimagining of Web3 is ambitious, broad-scale, self-made societal
                improvement, where local actions, like a shielded love note, lead to global
                change. Thus, the question begging Web3 is not &#8220;what is good
                governance?&#8221; but &#8220;what is good infrastructure?&#8221;. In
                trying to design coordination infrastructures, the subliminal, unaddressed,
                question staring Web3 in the face is &#8220;how to infrastructure?&#8221;.
            </p>
            <h1>
                Web3 as Infrastructure
            </h1>
            <p>
                One lens through which to approach Web3 which synthesizes its prefigurative
                ambitions and the technical detail of its banal everyday operation is as
                infrastructure. Information infrastructures are the array of social and
                technical elements, as characterized by the attributes of people,
                processes, procedures, facilities, and technology that support the
                creation, use, transmission, storage, and destruction of information that
                is shaped by human organization (Akrich, 1992; Star &amp; Ruhleder, 1996).
                Infrastructures possess attributes, including &#8220;embedded, transparent,
                learned as part of membership, linked, embodied, built, modular&#8221;
                (Star, 1999). They are &#8220;shared, evolving, open, standardized,
                heterogenous&#8221; to provide an installed base on which to build (Hanseth
                &amp; Lyytinen, 2012). Bowker, et al. argue that when dealing with
                information infrastructures, we need to look at the whole array of
                organizational forms, practices, and institutions that accompany, make
                possible, and inflect the development of new technology, their related
                practices, and their distributions (2009). Institutional infrastructures
                are the sets of political, legal, and cultural institutions that form the
                backdrop for economic activity and governance, which enable or constrain
                operations to organize and configure societal relations (Hinings, et. al.,
                2017). In this context, Web3 is both technical infrastructure, and
                institutional infrastructure. As stated by complex systems (including Web3)
                engineer Michael Zargham, &#8220;We&#8217;re not building software,
                we&#8217;re on institution building duty!&#8221;. First, Web3 needs to
                learn how to infrastructure.
            </p>
            <p>
                Web3 is innovating in reaction to the much-anticipated failures of Web2.0
                and the &#8220;myth of digital democracy&#8221; (Hindman, 2009). The
                purpose of infrastructure is to support a particular activity (Zurkowski,
                1984). This is an inherently political undertaking. &#8220;The politics of
                technology are about ways of building order in our world&#8221; states
                infrastructure scholar Langdon Winner (1985). Proponents of Web3 want to
                create and build better information infrastructures. They just don&#8217;t
                know how yet.
            </p>
            <h1>
                How to Infrastructure
            </h1>
            <p>
                &#8220;To infrastructure&#8221; denotes the ongoing activities, processes,
                tools, methods, and practices that make up and change an infrastructure
                (Star &amp; Bowker, 2010). Good information infrastructures are those that
                are adaptive to changing circumstances and environments, yet, designing for
                flexibility in relation to a clear purpose is not an easy task (Star &amp;
                Bowker, 2010). Innovation that is predicated on past examples can lead to a
                lack of imagination (Star &amp; Bowker, 2010), or worse, degradation of
                &#8220;the layer of institutions that keeps individuals from eating each
                other&#8221; (Turner, 2019).
            </p>
            <p>
                Public blockchains enable social, political, and economic modes of
                self-organization through the ability for members of a network to enter and
                exit infrastructures, according to their values and preferences, and the
                rules of the infrastructure in which they shape and participate. This
                flexibility cannot always be planned in advance as changes in social,
                technical, and economic settings are often unpredictable. Already, public
                blockchains are composed of the &#8216;infrastructural legos&#8217; of
                cryptography, computing hardware, software, and ledger, and are being
                composed still of decentralized applications (DApps), decentralized finance
                (DeFi), non-fungible tokens (NFTs), and decentralized organizations (DAOs).
                How these can and should be combined, and to which emergent models of data
                governance they will ascribe (whether based on cooperative data governance
                models, more legalistic data trust structures, or by emphasizing personal
                data sovereignty) will greatly influence the social outcomes of Web3
                infrastructure.
            </p>
            <p>
                Furthermore, the work of creating and building infrastructure is secondary
                to the work of ongoing maintenance. In building coordination
                infrastructure, it&#8217;s all a coordination game. Public infrastructures
                traditionally emerge out of significant bureaucracy. In other networks,
                such as the Web, standards bodies and social arrangements make the
                development and ongoing maintenance of large-scale technological
                infrastructure possible. Although standards are essential in the
                development of large-scale infrastructures, they are a messy entanglement
                of social processes, across numerous layers of a technology stack, require
                practice, and evolve over time (Star &amp; Bowker, 2010). This highlights a
                potential contradiction to be planned and solved if Web3 is to fulfill its
                vision (there are many, as Buterin himself points out (2022)).
                Decentralized infrastructure cannot be governed by centralized
                bureaucracies in the long term, yet infrastructures require long-term
                maintenance strategies.
            </p>
            <p>
                Ultimately, it is the social consequences of infrastructures that matter
                (Edwards, et. al., 2009). The challenge of broad-scale infrastructures is
                the &#8220;gateway moments&#8221; when technical, political, legal, and
                social innovations link previously separate systems to form far more
                powerful, far-reaching networks. Here, infrastructures must &#8220;adapt
                to, reshape, or even internalize elements of their environment in the
                process of growth and entrenchment&#8221; (Edwards, et. al., 2009). As Web3
                scales in development, general interest, adoption, and the rise of
                challengers to it (such as nation-state-based &#8220;Central Bank Digital
                Currencies&#8221;), it must have a clear purpose to unite and guide its
                infrastructuring if it is to deliver on the hopes of its roots and its
                promises as a genuine alternative to Web2.0 failings. Perhaps Web3 needs a
                new meme, from &#8220;BUIDL&#8221; to &#8220;COORDINAET&#8221; (meaning,
                coordinate - also, this is why my Web3 job title is not &#8216;Dank Meme
                Lord&#8217;).
            </p>
            <h1>
                Web3 and the everyday
            </h1>
            <p>
                Web3 is the most hype-beasted field of what is essentially the mundane but
                fundamental aspects of human coordination. Societal coordination is what
                humans have spent generations trying to figure out. Web3 has the same goals
                but <em>new tools</em>, situating coordination as distributed amongst
                participants across physical and digital domains.
            </p>
            <p>
                I am convinced that many people don&#8217;t understand why they are here. A
                cause to believe in, a community, a &#8220;gm&#8221; to wake up for in the
                morning, and of course, an exit (either from existing social hierarchies,
                or financially to quit the &#8216;rat race&#8217;). Yet, where there is no
                vision, and often no knowledge of the history of where Web3 came from, devs
                adopt cypherpunk sentimentalities and hacker aesthetics to unknowingly
                forward the upwards tide of Web3. Others are masters of their craft, the
                subtle art of coordination games of &#8220;political goals, via
                technological means&#8221; (Nabben, 2020), and prefigurative &#8220;acting
                through building&#8221; (Coleman, 2011). The privacy coin airdrop and love
                note interaction were about hiding cryptographic communications in a
                modern-day smartphone to incentivize migration to an alternative
                infrastructure. This alternative infrastructure, &#8220;Web3&#8221;, is one
                undergirded by cryptography, powered by blockchain technology, and
                political in its affordances of creation towards &#8220;exit&#8221; from
                traditional infrastructures of communication, banking, and governance.
            </p>
            <p>
                These quite serious matters of governance, politics, autonomy and society
                are oftentimes a far cry from what I observe in the day-to-day Discord
                channels and memes of the crypto Twittersphere. Almost in contrast with the
                vast structural and political visions of Web3 projects is the way its
                materiality as a technological infrastructure is embedded in everyday
                encounters that shape our world. The everyday experiences of Web3 occur
                subtly, through regular columns in the local newspaper on &#8220;Web3 and
                me&#8221;, or &#8220;GameFi&#8221; decentralized applications where furry
                animals &#8220;breed&#8221; with one another and players are rewarded in
                tokens (Ihde, 2009; Axie Infinity, n.d.). Keen attention must be paid to
                the underlying processes, politics, material infrastructures, and social
                protocols that enable and direct Web3.
            </p>
            <p>
                This is not necessarily because Web3 is so special in the history of
                technological development, new media, and cultural adaptation but because
                wherever individuals and groups deploy and communicate with digital media,
                &#8220;there will be circulations, reimaginings, magnifications, deletions,
                translations, revisionings, and remakings of a range of cultural
                representations, experiences, and identities&#8221;, and the precise ways
                that these dynamics unfold can never be fully anticipated in advance
                (Coleman, 2010). Thinking about how to organize infrastructures that we can
                and want to live with, might help.
            </p>
            <h1>
                Conclusions
            </h1>
            <p>
                Web3 offers an &#8216;exit&#8217; from existing institutions by building
                infrastructures for self-governance from within prevailing societal
                hierarchies and power structures. This piece provides a theoretical
                framework for further research into Web3 as infrastructure. The historical
                origins, cultural aesthetics, and prefigurative politics of Web3
                demonstrate how Web3 is a process of community discovery in learning how
                &#8220;to infrastructure&#8221; in digital realms. The challenge for Web3
                is to determine how to create good information infrastructure &#8211;
                meaning infrastructure that provides a genuine alternative to the
                governance and ownership downfalls of Web2.0 to allow people to adapt and
                change to their environment in line with their values.
            </p>
            <p>
                Engaging with Web3 represents profound shifts in modes of social
                interaction, economic, and cultural life. By encountering Web3&#8217;s
                pursuit of collectively building better institutional infrastructure, I
                have presented a mirror of Web3, and a mirror to Web3 of &#8220;how to
                infrastructure&#8221;. This infrastructural lens helps frame
                &#8220;success&#8221; as neither getting in early on a Ponzi scheme or
                orchestrating a social movement, but as conscious design maintenance for
                the everyday experience.
            </p>
            <p>
                Yet, there is no reason to pre-suppose that researchers <em>should</em>
                engage with Web3. Indeed, academic bureaucracies epitomize the antithesis
                of Web3, built on sluggish hierarchies, with little incentive towards
                practical action, in favor of climbing &#8220;publish or perish&#8221;
                rankings (so I&#8217;m told, not my team). According to the logic of
                prefiguration that organizes Web3, open, permissionless, peer-to-peer
                systems playfully seek to re-order, mend, and re-create seemingly broken
                structures from within, to enable choice. Thus, this entire exercise in
                institutional re-imagining and building is not really about entering the
            field but true to the nature of cryptoeconomic systems, it is about    <em>exiting</em> it. This is in many ways the goal of this publication.
            </p>
            <h1>
                Acknowledgments
            </h1>
            <p>
                Thank you to RMIT University Blockchain Innovation Hub and BlockScience for
                ongoing research collaborations, and the editors of this special collection
                for the invitation to contribute.
            </p>
            <h1>
                References
            </h1>
            <p>Akrich, M. (1992). “The De-scription of Technical Objects.” In Shaping Technology/Building Society. Studies in Sociotechnical Change, (MIT Press), 205.</p>
            <p>Allen, D., C Berg, and S Davidson. (2021) “Repugnant Innovation”. Available at SSRN: <a href="https://ssrn.com/abstract=3932730">https://ssrn.com/abstract=3932730</a> . Accessed May, 2022.</p>
            <p>Axie Infinity. (N.d). Available online: <a href="https://axieinfinity.com/">https://axieinfinity.com/</a>. Accessed May, 2022.</p>
            <p>Berg, A., and Berg, C. (2017). “Exit, Voice, and Forking”. Cosmos + Taxis, vol. 8, no. 8+9, pp. 76-89. Available at SSRN: <a href="https://ssrn.com/abstract=3081291">https://ssrn.com/abstract=3081291</a> .</p>
            <p>Berg, C. Davidson, S. and Potts, J. (2019). “Understanding the Blockchain Economy”, Edward Elgar, Cheltenham, United Kingdom ISBN: 9781788974998.</p>
            <p>Berg, C., Davidson, S., Potts, J. (2019). “Blockchain Technology as Economic Infrastructure: Revisiting the Electronic Markets Hypothesis”. Frontiers in Blockchain 2 no. 22, 1-6; doi: 10.3389/fbloc.2019.00022.</p>
            <p>Boggs, C. (1977). “Marxism, prefigurative communism, and the problem of workers’ control,” Radical America, vol. 11, no. 6, pp. 99–122.</p>
            <p>Bowker, G.C., Baker, K., Millerand, F., Ribes, D. (2009). Toward Information Infrastructure Studies: Ways of Knowing in a Networked Environment. In: Hunsinger, J., Klastrup, L., Allen, M. (eds) International Handbook of Internet Research. Springer, Dordrecht. <a href="https://doi.org/10.1007/978-1-4020-9789-8_5">https://doi.org/10.1007/978-1-4020-9789-8_5</a></p>
            <p>Brekke, J. (2021). “Hacker-engineers and Their Economies: The Political Economy of Decentralised Networks and ‘Cryptoeconomics’”, New Political Economy, 26(4), 646-659, DOI: <a href="">10.1080/13563467.2020.1806223</a>.</p>
            <p>Brunton, F. Digital Cash: The Unknown History of the Anarchists, Utopians, and Technologists Who Created Cryptocurrency. Princeton University Press, 2019.</p>
            <p>Buterin, V. (vitalik.eth, @VitalikButerin). (2022). “Thread: Some Still Open Contradictions in My Thoughts and My Values, That I Have Been Thinking about but Still Don’t Feel like I’ve Fully Resolved.” Tweet. Twitter. <a href="https://twitter.com/VitalikButerin/status/1526378787855736832">https://twitter.com/VitalikButerin/status/1526378787855736832</a> . Accessed May, 2022.</p>
            <p>Coleman, G. (2014). Hacker, Hoaxer, Whistleblower, Spy: The Many Faces of Anonymous. Verso Books.</p>
            <p>Coleman, G. (2010) "Ethnographic approaches to digital media." Annual review of anthropology 39: 487-505. Doi: <a href="https://doi-org.ezproxy.lib.rmit.edu.au/10.1146/annurev.anthro.012809.104945">https://doi-org.ezproxy.lib.rmit.edu.au/10.1146/annurev.anthro.012809.104945</a></p>
            <p>Coleman, G. (2009). “CODE IS SPEECH: Legal Tinkering, Expertise, and Protest among Free and Open Source Software Developers.” Cultural Anthropology 24 (3): 420–54. <a href="https://doi.org/10.1111/j.1548-1360.2009.01036.x">https://doi.org/10.1111/j.1548-1360.2009.01036.x</a> .</p>
            <p>Coleman, G. (2011). “Hacker Politics and Publics”. Public Culture 1; 23 (3 (65)): 511–516. doi: <a href="https://doi.org/10.1215/08992363-1336390">https://doi.org/10.1215/08992363-1336390</a> .</p>
            <p>Cowen, T. and A Tabarrok. (2022). “Cryptoeconomics”. Available online: <a href="https://marginalrevolution.com/marginalrevolution/2022/05/cryptoeconomics.html">https://marginalrevolution.com/marginalrevolution/2022/05/cryptoeconomics.html</a> . Accessed May, 2022.</p>
            <p>Diffie, W. and M. Hellman, (1976). "New directions in cryptography," in IEEE Transactions on Information Theory, vol. 22, no. 6, pp. 644-654, , doi: 10.1109/TIT.1976.1055638.</p>
            <p>Edwards, P., G Bowker, S Jackson, and R Williams. (2009). “Introduction: An Agenda for Infrastructure Studies.” Journal of the Association for Information Systems, 10 (5). <a href="">doi: 10.17705/1jais.00200</a>, 369.</p>
            <p>Electronic Privacy Information Center (EPIC). 1992. “International Traffic in Arms Regulation”, Electronic Privacy Information Center, Available online: <a href="">https://epic.org/crypto/export_controls/itar.html</a>, Accessed July 2021.</p>
            <p>Ennis. P. J. (2021). “Ethereum’s Political Philosophy Explained”. CoinDesk.” Available online: <a href="https://www.coindesk.com/markets/2021/07/09/ethereums-political-philosophy-explained/">https://www.coindesk.com/markets/2021/07/09/ethereums-political-philosophy-explained/</a> . Accessed January, 2022.</p>
            <p>Gupta, V. (@leashless), (2022), Twitter. Available online: <a href="https://twitter.com/leashless/status/1504575667320537101">https://twitter.com/leashless/status/1504575667320537101</a> . Accessed May, 2022.</p>
            <p>Hanseth O., and Lyytinen K. (2012). “Design Theory for Dynamic Complexity in Information Infrastructures: The Case of Building Internet”. In Willcocks L.P., Sauer C., Lacity M.C. (eds.) Enacting Research Methods in Information Systems. (Palgrave Macmillan, Cham).</p>
            <p>Hindman, M. (2009). “The Myth of Digital Democracy”. Princeton University Press.</p>
            <p>Hinings, C.R.;D. M. Logue, and C. Zietsma. (2017). "Fields, institutional infrastructure and governance." (Chapter 6) in The Sage handbook of organizational institutionalism. Royston Greenwod, Christine Oliver, Thomas B. Lawrene, Renate E. Meyer (eds.).</p>
            <p>Hirschman, A O. (1972). Exit, Voice, and Loyalty. (Harvard University Press).</p>
            <p>Husain, S.O., Franklin, A. & Roep, D. (2020). The political imaginaries of blockchain projects: discerning the expressions of an emerging ecosystem. Sustain Sci <strong>15,</strong> 379–394. <a href="https://doi.org/10.1007/s11625-020-00786-x">https://doi.org/10.1007/s11625-020-00786-x</a> .</p>
            <p>Ihde, D. (2009). Postphenomenology and Technoscience. New York: Sunny Press.</p>
            <p>Lessig, L. (2000). “Code Is Law: On Liberty in Cyberspace”. Harvard Magazine.” Available online: <a href="https://www.harvardmagazine.com/2000/01/code-is-law-html">https://www.harvardmagazine.com/2000/01/code-is-law-html</a> . Accessed May, 2022.</p>
            <p>Maurer, B., Nelms, T. C., Swartz, L. (2013). ‘‘When perhaps the real problem is money itself!’: the practical materiality of Bitcoin’, Social Semiotics, doi: 10.1080/10350330.2013.777594, 2-17.</p>
            <p>May, T. (1992). “The Crypto Anarchist Manifesto”. Available online: <a href="">https://www.activism.net/cypherpunk/crypto-anarchy.html</a>. Accessed 15 Aug. 2020.</p>
            <p>Millman. D. (1984), Way of the Peaceful Warrior: A Book that Changes Lives. H J Kramer, Inc., Tiburon, California. Pp. 113.</p>
            <p>Miscione, G. and Kavanagh, D., (2015). “Bitcoin and the Blockchain: A Coup D'État through Digital Heterotopia?”. Humanistic Management Network, Research Paper Series, (23/15).</p>
            <p>Morris, D. (2019). “Vitalik Buterin is Embracing a New Role: Political Theorist”. Breaker Mag. Available online: <a href="https://web.archive.org/web/20210905043323/https://breakermag.breaker.io/vitalik-buterin-is-embracing-a-new-role-political-theorist/">https://breakermag.breaker.io/vitalik-buterin-is-embracing-a-new-role-political-theorist/</a> . Accessed Feb, 22.</p>
            <p>Murray-Rust, D., K Gorkovenko; D Burnett; and D Richards. (2019). “Entangled Ethnography: Towards a collective future understanding.” In Proceedings of the Halfway to the Future Symposium 2019 (HTTF 2019). Association for Computing Machinery, New York, NY, USA, Article 21, 1–10. <a href="https://doi-org.ezproxy.lib.rmit.edu.au/10.1145/3363384.3363405">https://doi-org.ezproxy.lib.rmit.edu.au/10.1145/3363384.3363405</a></p>
            <p>Nabben, K. & Zargham, M. (2022). Permissionlessness. Internet Policy Review, 11(2). <a href="https://doi.org/10.14763/2022.2.1656">https://doi.org/10.14763/2022.2.1656</a> . Accessed: May. 2022.</p>
            <p>Nabben, K. and Rennie, E. (2022). Ad hoc network. Internet Policy Review, 11(2). Available at: <a href="https://policyreview.info/glossary/ad-hoc-network">https://policyreview.info/glossary/ad-hoc-network</a> . Accessed: 30 May. 2022.</p>
            <p>Nabben, K. (2020). “‘An Ethnography of Decentralised Information Infrastructure’: Adopting Cypherpunk Nomenclature To Categorise the Unique Attributes of Decentralised Technologies.” Available at SSRN: <a href="https://ssrn.com/abstract=3752531">https://ssrn.com/abstract=3752531</a> .</p>
            <p>Nabben, K. (2021). “Building the Metaverse: ‘Crypto States’ and Corporates Compete, Down to the Hardware” SSRN. Available online: <a href="http://dx.doi.org/10.2139/ssrn.3981345.%20%20">http://dx.doi.org/10.2139/ssrn.3981345.</a> Accessed May, 2022.</p>
            <p>Nabben, K. (2021). Is a "Decentralized Autonomous Organization" a Panopticon? Algorithmic governance as creating and mitigating vulnerabilities in DAOs. In Proceedings of the Interdisciplinary Workshop on (de) Centralization in the Internet (IWCI'21). Association for Computing Machinery, New York, NY, USA, 18–25. <a href="https://doi-org.ezproxy.lib.rmit.edu.au/10.1145/3488663.3493791">https://doi-org.ezproxy.lib.rmit.edu.au/10.1145/3488663.3493791</a></p>
            <p>Nabben, K. (2022a). “A Political History of DAOs”. Friends With Benefits WIP. Available online: <a href="https://www.fwb.help/wip/cypherpunks-to-social-daos">https://www.fwb.help/wip/cypherpunks-to-social-daos</a></p>
            <p>Nabben, K. (2022b). “What Is Resilience?”. Substack (blog). Available online: <a href="https://kelsienabben.substack.com/p/what-is-resilience">https://kelsienabben.substack.com/p/what-is-resilience</a> . Accessed May, 2022.</p>
            <p>Nakamoto, S. (2008). Bitcoin: A Peer-to-Peer Electronic Cash System. Available online: <a href="">https://bitcoin.org/bitcoin.pdf</a>. Accessed 20/02/2019.</p>
            <p>Owocki, K. (@owocki). (2022). “The Sword That Can Slay Moloch”. Twitter. <a href="https://twitter.com/owocki/status/1486112437241745408">https://twitter.com/owocki/status/1486112437241745408</a> . Accessed May, 2022.</p>
            <p>Prabhu, Vaishnavi. 2018. “[VoxSpace Life] Nxivm : How A Sex Cult Worthy Of Influencing Power Was Formed In America.” VoxSpace (blog). August 25, 2018. Available online: <a href="https://www.voxspace.in/2018/08/25/nxivm-sex-cult/">https://www.voxspace.in/2018/08/25/nxivm-sex-cult/</a> . Accessed May, 2022.</p>
            <p>Reijers, W., Coeckelbergh, M. (2018). “The Blockchain as a Narrative Technology: Investigating the Social Ontology and Normative Configurations of Cryptocurrencies”. Philos. Technol. 31, 103–130. <a href="https://doi.org/10.1007/s13347-016-0239-x">https://doi.org/10.1007/s13347-016-0239-x</a> .</p>
            <p>Rella, L. (2021). “Assembling the Fieldless Field Site.” In Navigating the Field: Postgraduate Experiences in Social Research, edited by Mildred Oiza Ajebon, Yim Ming Connie Kwong, and Diego Astorga de Ita, 37–48. Cham: Springer International Publishing. <a href="https://doi.org/10.1007/978-3-030-68113-5_4">https://doi.org/10.1007/978-3-030-68113-5_4</a> .</p>
            <p>Rennie, E. (2021). “The Governance of Degenerates Part I: Emerging Dynamics”. Medium (blog). Available online: <a href="https://ellierennie.medium.com/the-governance-of-degenerates-part-i-emerging-dynamics-527e697aee36">https://ellierennie.medium.com/the-governance-of-degenerates-part-i-emerging-dynamics-527e697aee36</a> . Accessed May, 2022.</p>
            <p>Schneider, N. (2022). “Cryptoeconomics as a Limitation on Governance”. Available online: <a href="https://ntnsndr.in/Cryptoecon">https://ntnsndr.in/Cryptoecon</a>. Accessed May, 2022.</p>
            <p>Star, S. L. (1999). The Ethnography of Infrastructure. American Behavioral Scientist 43, 3: 377-391. doi:<a href="">10.1177/00027649921955326</a>.</p>
            <p>Star, S. L., and Ruhleder, K. (1996). “Steps Toward an Ecology of Infrastructure: Design and Access for Large Information Spaces.” Information Systems Research 7: 111–34.</p>
            <p>Star, S. L., and G. C. Bowker. (2010). "How to Infrastructure." In Handbook of New Media: Social Shaping and Social Consequences of ICTs, Updated Student Edition, 230-45. London: SAGE Publications Ltd, 2010. https://dx.doi.org/10.4135/9781446211304.n13.</p>
            <p>Stefik, M. J. (ed). (1996). Internet Dreams: Archetypes, Myths, and Metaphors. (Cambridge, MA, USA: MIT Press).</p>
            <p>Stevens, R. (2022). “What is Web 3 and Why is Everyone Talking About It?”. CoinDesk. Available online: <a href="https://www.coindesk.com/learn/what-is-web-3-and-why-is-everyone-talking-about-it/">https://www.coindesk.com/learn/what-is-web-3-and-why-is-everyone-talking-about-it/</a> . Accessed May, 2022.</p>
            <p>Strathern, M. (2004). Partial Connections: Updated Edition. Savage, MD: Rowman & Littlefield. 38.</p>
            <p>Swain, D. (2019). “Not Not but Not yet: Present and Future in Prefigurative Politics.” Political Studies, 67(1), 47–62. https://doi.org/<a href="">10.1177/0032321717741233</a>.</p>
            <p>Swan, M. (2015). Blockchain: Blueprint for a New Economy. (O’Reilly Media, Inc).</p>
            <p>Swan, M. (2017). “Blockchain Thinking : The Brain as a Decentralized Autonomous Corporation.” IEEE Technology and Society. Available online: <a href="http://technologyandsociety.org/blockchain-thinking-the-brain-as-a-decentralized-autonomous-corporation/">http://technologyandsociety.org/blockchain-thinking-the-brain-as-a-decentralized-autonomous-corporation/</a> . Accessed: 30 May. 2022.</p>
            <p>Swartz, L. (2018). “What was Bitcoin, what will it be? The techno-economic imaginaries of a new money technology”, Cultural Studies, 32 (4). 623-650, DOI: <a href="">10.1080/09502386.2017.1416420</a> .</p>
            <p>Szabo, N. (2017). “Money, blockchains, and social scalability.” Unenumerated Blogspot. Available online: <a href="">https://unenumerated.blogspot.com/search?updated-max=2017-02-23T23:48:00-08:00&amp;max-results=11&amp;start=1&amp;by-date=false</a> . Accessed July, 2021.</p>
            <p>Turner, F. (2019). “Machine Politics”. Harper’s Bazaar Magazine. Available online: <a href="https://harpers.org/archive/2019/01/machine-politics-facebook-political-polarization/">https://harpers.org/archive/2019/01/machine-politics-facebook-political-polarization/</a> . Accessed: 30 May. 2022.</p>
            <p>Walch, A. (@angela_walch). (2022). “Some Personal News from Me. I Will Be Taking a Leave of Absence from St. Mary’s for the 2022-2023 School Year.” Twitter. <a href="https://twitter.com/angela_walch/status/1523794404665823232">https://twitter.com/angela_walch/status/1523794404665823232</a> . Accessed May, 2022.</p>
            <p>Wikipedia, n.d; “NXIVM”. Wikipedia. Available online: <a href="https://en.wikipedia.org/wiki/NXIVM">https://en.wikipedia.org/wiki/NXIVM</a> . Accessed May, 2022.</p>
            <p>Winner, L. (1985). “Do Artifacts Have Politics?” In Daedalus, Proceedings of the American Academy of Arts and Sciences, 109:26–38. <a href="https://doi.org/10.4324/9781315259697-21">https://doi.org/10.4324/9781315259697-21</a> .</p>
            <p>Wright, A, and De Filippi, P. (2015). “Decentralized Blockchain Technology and the Rise of Lex Cryptographia.” SSRN. <a href="http://dx.doi.org/10.2139/ssrn.2580664">http://dx.doi.org/10.2139/ssrn.2580664</a> .</p>
            <p>Zurkowski, P. G. (1984). “Integrating America’s Infostructure.” Journal of the American Society for Information Science 35 (3): 170–78. <a href="https://doi.org/10.1002/asi.4630350310">https://doi.org/10.1002/asi.4630350310</a></p>
            <div>
                <div id="ftn1">
                    <p>
                        <a href="#_ftnref1" name="_ftn1" title="">[1]</a>
                        Alas, I have edited the figures so this is not my actual wallet
                        address.
                    </p>
                </div>
            </div>     



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default nabben;
