import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function maurer() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Blow That Mausoleum Down</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Bill Maurer
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089018">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/cb589cdf-d081-4722-9a29-4492dda46db0">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            Drawing on anthropological perspectives, this essay argues that the fungibility of objects and the ability to exchange them for money is a defining characteristic of capitalist markets. In contrast, other systems of reckoning value emphasize the unique relationships within which objects are embedded and their inability to stand for just any other thing. The essay further highlights the role of slavery in the origins and continued dominance of capitalism, and the existence of alternative systems such as cooperativism and sharing that are often overlooked. The essay then examines the Saussurean and Peircean semiotics underlying the concept of money as an abstract sign and argues that non-fungible tokens (NFTs) in blockchain technology contradict these theories by emphasizing pure uniqueness and rendering objects non-transformable or inconvertible. The essay concludes by warning against the dangers of a future where fungibility is absent, as it is necessary for life and the generation of new and different possibilities.
            </p>
            <hr />
            <p>Insofar as web3.0 imaginaries are propelled by the promise of decentralization and inspired by blockchain, they stand to make some very old mistakes. Granted, they might lead to inventions that “work,” that is, function or do whatever it is they seek to do. But just because something works in practice does not mean it is on any kind of solid epistemological, ontological, or moral footing. One huff, puff, and you blow the house down.</p>
            <p>Kind of like capitalism.</p>
            <p>In capitalism, the story goes, any thing can stand for any other thing. Money is the great leveler and equalizer, whose magic allows things as eternal as the land and ephemeral as a promise to enter into a great marketplace where anything can be exchanged for money. The very fact of this ability — of anything to be transacted — is what makes capitalist markets so unique, so vibrant, so violent.</p>
            <p>In anthropology we have long contrasted capitalism with other systems of reckoning value by spotlighting the instances, in those systems, where any thing cannot stand for just any other thing. In some systems, we say, it is the relationships within which a thing is embedded that make it unique; it can only be substituted for a thing that can insert itself into those same relations, taking its place or taking its part or playing its role. When a marriage takes place, and there is an exchange of pigs and yams and other items, it is not just any pig that can stand in relation to the parties to the marriage transaction, but this particular pig, which is held to take the same position in a system of relations as a human person (Strathern, 1991). We therefore often say that other economies are “human” economies (Hart, 2000) or “peopled” economies (Lofving, 2005) — which is ironic, of course, since most of the things involved are not humans, or are humans and pigs substituting for one another, for example. But we say they are “human” because of our own deeply held assumption that humans themselves are unique and nonfungible. When we do see actual, whole humans as the object of interchangeable exchange, any human for any other human, any human for money, we call it slavery, and we say that our system abhors it. Even though our system was founded upon it (Cooke 2003) and even though our system still uses slaves and does not honor human bodily autonomy. This is what I mean when I reference the huffing and puffing and blowing the house down: most of our stories of capitalism are made of sticks and straw.</p>
            <p>Capitalism was supposedly born of free labor, depending on the valuation of the unique skills or attributes of unique human beings and a marketplace to sort them out. Consider John Stewart Mill: “Nobody thinks it necessary to make a law that only a strong-armed man shall be a blacksmith. Freedom and competition suffice to make blacksmiths strong-armed men, because the weak armed can earn more by engaging in occupations for which they are more fit” (Mill 1869/2008, p. 28). Never mind that in his day smithing was a hereditary occupation (think surnames like Smith or Miller). Yet capitalism was built on a solid foundation of the objectification and violent abstraction of the human such that it (not him/her/them) could enter into a calculus of money and exchange. No chattel slavery and transatlantic slave trade, then no rise of capitalism on the industrial plantation model with its own modes of accounting, money, and insurance (see Rosenthal 2018, Ralph 2018, Baucom 2005). This is why we so politely say that it is the labor, not the actual human being, that is being exchanged. Which, of course, is silly. Marx’s insight was to insist that, as with the pigs, there are always human social relations underpinning those abstractions and exchanges: the ability of anything to be transacted is our fiction masking those real relations underneath.</p>
            <p>The recognition that slavery is at the origins and remains at the heart of capitalism (Robinson, 2000) also belies capitalism’s uniformity and dominance. Its hegemony may be due to the ideological, theoretical, legal, or political props or struts holding it up (liberal theory, property law) but it is never “one” system, or even that systematic, since it is shot through with other “systems” (slavery, indenture, cooperativism, sharing; Gibson-Graham, 2006) or it harnesses those systems to its own ends (see Meillassoux, 2008; Tsing, 2021). I have long argued that “alternatives” are mischaracterized as such, as alter or other, insofar as they lie right alongside the relations and rules we attribute to and hold as defining capitalist orders. They are not separate systems, bounded off or far away, but right here, albeit in different spatiotemporal phases from the stuff that we tell ourselves that we see all around us, even if we think of ourselves as having been disabused of the fetishism of the commodity: money, markets, labor, property.</p>
            <h1>The structure of abstraction and fungibility</h1>
            <p>To stick with the fiction, however: capitalism rests on the ability of anything to be abstracted from its own context and placed in the context of the market, where its valuation is determined by supply and demand, or the expropriation of labor, which adds a differential of value to raw materials by the capitalist. Money is its universal and universalizing sign. Money itself is also an abstract sign. It is not the physical dollars or coins or lumps of gold that matter; it is the idea that those pieces of paper and metal signify abstract value according to which all things are measured. Money, like all things in capitalism, is therefore fungible: any bit of money can stand in for any other bit because they all name just one relation, the market’s relation of valuation by price. I do not need to use “this” dollar or “that” euro to buy a particular thing; I can use any dollar or any euro, indeed, digital abstractions of the abstractions of money (i.e., crypto) will do just fine.</p>
            <p>This notion of money as a particular kind of sign that can stand for anything, and of money and all things as interchangeable and fungible insofar as they can all be measured according to one yardstick of value, rests on a special understanding of signs and symbols. In this rendering, the sign is arbitrary. It could be pennies, or euros, or digital messages, or tallies on a piece of wood, all signifying the abstract value of money. Ferdinand de Saussure (1916/1983, p. 115) made this point in his Course on General Linguistics :</p>
            <p>To determine what a five-franc piece is worth one must therefore know: (1) that it can be exchanged for a fixed quantity of a different thing, e.g. bread; and (2) that it can be compared with a similar value of the same system, e.g. a one-franc piece, or with coins of another system (a dollar, etc.).</p>
            <p>It’s worth remembering that Ferdinand Saussure’s theory of the sign as arbitrarily standing for a signified was developed in conversation with the economics of Vilfredo Pareto (Ponzio 1990). For Saussure, the system of signs, each in arbitrary relation to their signifieds yet, importantly, organized in relation to each other, revealed an underlying structure, which should be the proper object of linguistics. For Pareto, similarly, the relations among competitive agents in a marketplace revealed value through price and thereby an underlying structure despite the diversity of forms, forces, and fungible items in the world. That structure would be the proper object of economics.</p>
            <p>If Saussure was influenced by economists thinking about markets, another semiotician, Charles Peirce was influenced by … rocks, coastlines, gravity, and geography (Baker 2009). Developing his semiotics against the backdrop of seemingly unalterable (by humans) yet dynamic physical systems, Peirce famously emphasized the determinate qualities of things (with concepts like firstness and qualisign), their ontological interconnectedness or intertwining or, really, in his words, their partaking all of the same primordial protoplasmic substance, the appearance of order or structure being the result of habit (Peirce 1892). Things and signs are what they are, not because of some underlying structure, but because they have moved and flowed and been inflected in such and such a way for some time — their previous flows creating channels that constrain but do not determine their future movements. For rivers can overflow their banks; oceans can erode coastlines. And if everything is changeable, nothing is strictly speaking fungible with anything else because it already is everything else.</p>
            <p>But nothing is unique, singular, or transcendent, either. Again, since everything itself partakes of the same protoplasm and congeals into the manifold and manifest world through habit, everything is simultaneously (part of, composed of) everything else. This is a profoundly monist position. And, again, I’d argue that because his philosophy was developed in tandem with a deep appreciation of the physical world, this makes sense: the “I” that is me and my physical stuff sparking life to that “I” is carbon, nitrogen, oxygen, hydrogen, calcium, phosphorus, and a handful of other elements, common to just about every other thing in the physical universe. I am but a momentary congealing. I am held together by habit (Nöth, 2010).</p>
            <p>How odd, then, that we persist in believing in our own uniqueness, our own individuality. What is but an eddy in the cosmic flow we take to be something super-duper special and singular. Doing so helps us deal with the void. And helps us stomach the abstractions of capitalism: I am me, and me is mine; and I am selling my labor, not myself or my soul. Really, honestly, truly! Just my labor! Not my life!</p>
            <h1>Seeking alpha and judging singularities</h1>
            <p>Now let’s look at what’s going on with some imaginaries propeling Web3. Take decentralized identities. They’re belying both the Saussurean and the Peircean semiotics. No arbitrary sign but a nailed down “self-sovereign” identity, and self-sovereign identity for all! For people, and things, and objects of property whose relations are utterly obscured by code and ideological underpinnings that make every digital transaction unique. Everything will be tokenized, every physical and digital world thing, indeed, infinitely partible pieces of every such thing, rendered unique and unalterable and resolutely, purely, Itself. It is thus utterly non-arbitrary and utterly removed from the flow and flux of space-time-matter. No oneness, but infinite, separate, unique atoms.</p>
            <p>Now exactly how are you going to make meaning and money with that? The investors who are professionally committed to profiteering trade an elusive substance called “alpha,” which gives them the edge over the market. In traditional capital markets, alpha is a measurement of your ability to beat the all-knowing, perfectly efficient market. Usually, you can only get alpha from sociological events, information asymmetries, superior analysis, or market inefficiencies. For example, insider trading is very alpha. But in crypto markets, especially DeFi, alpha is more elusive (Rodriguez, 2021). First, there is no known way to meaningfully measure the crypto market. The closest parallels to the S&amp;P 500 are indexes based on each crypto’s “total market cap,” a number that has a mimetic resemblance to the sum price of traditional stock certificates but does not stand for value in the same way. Rodriguez recognizes this dilemma and suggests that the price of governance tokens might be a better measure of a crypto’s market value, but acknowledges that this too is a poor metric. So, they continue to hunt for alpha, maybe investing in mining to get their edge or join a shady “alpha group” that coordinates pump and dump schemes and supposedly trades advance and inside information.</p>
            <p>The alpha that professional investors seek is intangible and not just a measure of their financial success. Rather, alpha is the process of conferring judgment, and moreover, the passing of information about where alpha is, how to get it, and how to exploit it is how price gets set. Lucian Karpik’s (2010) economic theory offers an account of how the price of singularities (like artworks or NFTs) and other kinds of provenancial goods can be understood.</p>
            <p>Singular goods cannot be explained with the neoclassical model, Karpik explains, because each item is incommensurable: utterly unique, impossible to compare, and therefore no basis for a rational market. Rather, the market for singular goods requires supporting “judgment devices” (e.g. networks of friends, quality labels, rankings, expert opinions, etc.). Karpik outlines eight ‘regimes of coordination,’ each operating through particular kinds of devices, which fall into two broad categories: personal devices (which operate through personal networks, shared convictions and professional reputation) and impersonal devices that are either substantial and formal devices for small and large markets. That is, small markets are coordinated through authenticity and expert opinion , while large markets are coordinated through branding andcommon opinion.</p>
            <p>Perhaps a research project could inquire into how these devices operate in the context of Web3 and NFTs? Indeed Dallyn (2017) used Karpik’s ideas to think about how Bitcoin is valued, making the point that value cannot be reduced to price because that leaves open the question of how value is judged. This is aligned with research on how market devices work to produce prices and make markets work (Muniesa et al 2007; Neyland et al 2019; Çalışkan and Callon 2009, 2010). As always, crypto offers a test case for understanding the limits of heterodox economies.</p>
            <p>But, when I see investment strategies that return great alpha on market singularities, I think of St. Catherine of Siena, Oliver Plunkett, Jeremy Bentham, Lenin, Kim Il Sung… all those preserved heads and body parts of the saints, authoritarian rulers, and the occasional philosopher, whose gods and ideologies strive to hold back the inevitability of death and decay. But rot is transformation, that which such acts of eternal preservation seek to forestall. Rot is new life, the shifting of that protoplasmic substance into new congealings, alternative formations, pure possibility. This is what goldbugs and crypto alike would seek to deny by freezing singularities in a house of bricks.</p>
            <p>The wolf didn’t try hard enough: it’s time to blow that house down. Indeed the political ascendance of “forever” rulers and ideas and the denial of alternatives makes it ever more urgent to nurture alternative space-time-matters, now, right now! For no transformation, no life. No life, no future. Just all the work and energy required to maintain stasis in the mausoleum of Web3.</p>
            <h1>References</h1>
            <p>Baker, Victor R. 2009. Charles S. Peirce and the “Light of Nature”. In Gary D. Rosenberg (Ed.), The Revolution in Geology from the Renaissance to the Enlightenment . Geological Society of America Memoirs, Vol. 203. <a href="https://doi.org/10.1130/2009.1203(18)">https://doi.org/10.1130/2009.1203(18)</a></p>
            <p>Baucom, Ian. 2005. Specters of the Atlantic: Finance Capital, Slavery, and the Philosophy of History. Durham, NC: Duke University Press.</p>
            <p>Callon, Michel, Yuval Millo, and Fabian Muniesa. 2007. Market Devices . Blackwell Pub./The Sociological Review.</p>
            <p>Çalışkan, Koray, and Michel Callon. 2009. “Economization, part 1: Shifting attention from the economy towards processes of economization.” Economy and Society 38 (3): 369-398.</p>
            <p>Çalışkan, Koray, and Michel Callon. 2010. “Economization, part 2: A research programme for the study of markets.” Economy and Society 39 (1): 1-32.</p>
            <p>Cooke, Bill. 2003. “The Denial of Slavery in Management Studies.” Journal of Management Studies 40 (8): 1895-1918. (https://doi.org/10.1046/j.1467-6486.2003.00405.x).</p>
            <p>Dallyn, Sam. 2017. “Cryptocurrencies as market singularities: The strange case of Bitcoin.” Journal of Cultural Economy 10 (5): 462-473. (https://doi.org/10.1080/17530350.2017.1315541).</p>
            <p>Gibson-Graham, JK. 2006. A Postcapitalist Politics. Minnesota: University of Minnesota Press.</p>
            <p>Hart, Keith. 2000. Money in an Unequal World: Keith Hart and His Memory Bank . London: Texere.</p>
            <p>Karpik, Lucien. 2010. Valuing the Unique: The Economics of Singularities . Princeton University Press.</p>
            <p>Lofving, Staffan. 2005. Peopled Economies: Conversations with Stephan Gudeman . Uppsala: Interface.</p>
            <p>Meillassoux, Quentin. 2008. After Finitude: An Essay on the Necessity of Contingency . London: Continuum.</p>
            <p>Maurer, Bill. 2006. “The anthropology of money.” Annual Review of Anthropology. 35: 15-36.</p>
            <p>Muniesa, Fabian, Yuval Millo, and Michel Callon. 2007. “An Introduction to Market Devices.” The Sociological Review 55(2_suppl): 1-12. (https://doi.org/10.1111/j.1467-954X.2007.00727.x).</p>
            <p>Neyland, Daniel, Véra Ehrenstein, and Sveta Milyaeva. 2019. “On the difficulties of addressing collective concerns through markets: From market devices to accountability devices.” Economy and Society 48 (2): 243-267. (https://doi.org/10.1080/03085147.2019.1576432).</p>
            <p>Nöth, Winfried. 2010. “The Criterion of Habit in Peirce’s Definitions of the Symbol.” Transactions of the Charles S. Peirce Society 46 (1): 1-20.</p>
            <p>Peirce, Charles S. 1892. Man’s glassy essence. The Monist3(1): 1-22.</p>
            <p>Ponzio, Augusto. 1990. Man as a Sign: Essays on the Philosophy of Language . Boston: De Gruyter Mouton.</p>
            <p>Ralph, Michael. 2018. Value of life: insurance, slavery, and expertise. In Sven Beckert and Christine Desan (Eds.), American Capitalism: New Histories . New York: Columbia University Press., <a href="https://doi.org/10.7312/beck18524">https://doi.org/10.7312/beck18524</a> .</p>
            <p>Robinson, Cedric J. 2000. Black Marxism: The Making of the Black Radical Tradition . Chapel Hill: University of North Carolina Press.</p>
            <p>Rosenthal, Caitlin. 2018. Accounting for Slavery: Masters and Management . Cambridge, MA: Harvard University Press.</p>
            <p>Simmel, Georg. 1904/1971. “Fashion.” In Donald N. Levine (Ed.), On individuality and social forms: Selected writings [of] Georg Simmel . University of Chicago Press.</p>
            <p>Strathern, Marilyn. 1991. Partial Connections. Rowman & Little.</p>
            <p>Tsing, AL. 2021. The Mushroom at the End of the World: On the Possibility of Life in Capitalist Ruins . Princeton, NJ: Princeton University Press.</p>  
            <hr size='1' width='15%' />
            <h1>Acknowledgments</h1>
            <p>I would like to thank Quinn DuPont, Donncha Kavanagh, and Tom Boellstorff for comments on earlier versions of this essay, and Quinn DuPont especially for a final round of editing. This essay is based on research supported by the US National Science Foundation under grant number 1455859. Any opinions, findings, and conclusions or recommendations expressed in this material are those of the author and do not necessarily reflect the views of the National Science Foundation.</p>


        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default maurer;
