import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function defilippi() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>The Rise of Blockchain Egregores</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Primavera de Filippi, Morshed Mannan, and Wessel Reijers
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089011">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/2dc21838-79a3-4398-b7eb-9a7b0ddda800">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            This article explores the emergence of cryptocurrencies like Dogecoin and Shiba Inu in the “crypto carnival” and their ties to the Trickster archetype. It discusses the concept of tokens and the surge of tokenization in the crypto-summer of 2020-2021. The article explains how Shiba Inu became a purely recursive token with no external measure of value. It also explores the creation of egregores, which are created intentionally or unintentionally by groups of people who share a common belief or interest. Finally, the article discusses how digital assets born out of a countermovement may eventually fall prey to the same system they were trying to escape from, using the carnivalesque to trigger the emergence of an egregore that brings monetary value to worthless objects.
            </p>
            <hr />
            <p>
            Imagine a world in which money grows from cybernetic trees. Anyone can enter
            the digital land of plenty, plant a tree and go about handing money to all
            who want it — from the poor beggar to the richest person of the land. The
            latter might even use his or her giant virtual megaphone to spread the word
            amongst the people, from up high in his mighty castle. Yet, beware of the
            rogue who challenges your charm! He might dump all his money on the poor,
            accusing you of blasphemy. For, in which carnivalesque world do you think
            you live — you fool! — in which nothing is what it seems; even when the code
            is writ large on the walls.
            </p>
            <p>
            Though seemingly surreal, this imagined land is not all that different from
            actual reality, in which we are faced with a burgeoning “crypto carnival.”
            In the last heat wave of the crypto-summer (2020-2021), there was a surge of
            tokenization: the creation of digital tokens for the sake of generating
            economic value. For outsiders, the universe of tokens might seem simply
            bizarre. Take the events surrounding the crypto token “Shiba Inu.” Built on
            the Ethereum blockchain, Shiba Inu was presented by its anonymous founder
            Ryoshi as the “Dogecoin killer.” It takes its name from the Japanese dog
            breed that famously figured as the logo of Dogecoin.
        </p>
        <p>
            Dogecoin is a cryptocurrency created as a joke in 2013, in honour of the
            Doge meme featuring a Shiba Inu dog surrounded by broken English sentences.
            It quickly gained a cult following on the internet, especially after Elon
            Musk started ‘pumping’ the cryptocurrency on social media. Despite its
            facetious origins, Dogecoin today enjoys a large community of
            crypto-enthusiasts and is regarded as one of the most popular
            cryptocurrencies (in terms of fame, not market cap). The cryptocurrency is
            used as a tip currency on social media platforms and has been leveraged to
            raise funds for a number of charitable causes, including the construction of
            water wells in Kenya (Rizzo, 2014). Yet, Dogecoin does not have any
            intrinsic value, except that which the speculative crypto-market gives it.
        </p>
        <p>
            Just like Dogecoin, Shiba Inu is a purely recursive token: it does not refer
            to any external measure of value like a particular asset, it simply refers…
            to itself. One way in which its value has been raised is through so-called
            “airdrops”, which means that tokens are given out for free in order to
            generate a sense of attachment (or simple economic opportunism) amongst
            their new owners, which as a magical psychological ‘sauce’ increases the
            value of the coin. This highly volatile situation is fueled by speculations
            of rich and powerful people (influencers), in this case Elon Musk, whose
            comments on Dogecoin during a TV programme had a positive impact on the
            value of Shiba Inu. The Shiba Inu community also tried to raise its token’s
            value by giving half of the supply of tokens to Vitalik Buterin, the founder
            of Ethereum, the decentralised platform on which Shibu Inu operates.
            Buterin, however, did not want to hold on to his coins (in crypto language:
            HODL), but instead “burned” 90 percent of his SHIBU tokens (at the time
            worth $6.7 billion; Crawley, 2021) and donated a large sum of tokens to the
            India Covid Relief Fund in 2020 (at the time worth $1.2 billion; Seward,
            2021). He was unwilling to take on the personal power that came with his
            holding of tokens.
        </p>
        <p>
            There is much that is carnivalesque about this narrative, in particular to
            people who are unfamiliar with the crypto universe, its language, and its
            customs. First, there is something carnivalesque, or even absurd, about the
            sense of value involved in the Shiba Inu case, which seems on the one hand a
            mere figment of the imagination but on the other hand — at least
            theoretically — the equivalent of an amount of hard cash that for most
            people seems unfathomable. In this latter sense, Buterin’s donation was one
            of the largest charitable donations in history. Second, there is a great
            sense of make-believe in the Shiba Inu story. Its value is not tied to an
            asset perceived to have intrinsic value, but it is made through networks of
            communication, speculation, and storytelling, on platforms like Reddit and
            Twitter. In this flow of information, there is an unstable and fleeting idea
            of what is true and what is false. Shiba Inu’s white paper (called
            “Woofpaper”), for instance, speaks of lofty ideals like community-based
            decentralisation, a highly capitalist form of value creation through
            tokenization, and it calls Buterin a “friend” of Shiba Inu despite his
            actions at the same time leading to a sharp drop in its token’s value.<a href="#_ftn1" name="_ftnref1" title=""><sup><sup>[1]</sup></sup></a> Third, Shiba Inu’s tokenization is surrounded by a meme-fueled carnivalesque
            culture. By carnivalesque, we do not only refer to carnivals, i.e., either
            travelling amusement parks and fairs or the festivities that accompany
            significant dates in the Christian liturgical calendar. The term encompasses
            a wider array of collective activities where the established order is
            symbolically and playfully inverted and an opening is provided to imagining
            alternative orders (Godet, 2020, p.3). For instance, the Shiba Inu community
            (or “SHIBArmy”) generates a constant stream of memes that are seldomly taken
            seriously by the outside world, while some of the community members engage
            in social media pranks. They have posted fake “news” about celebrities
            (e.g., Elon Musk or Jack Dorsey) buying or advocating for Shiba Inu (Kiran,
            2022), and they have also created a series of petitions advocating for Shiba
            Inu to become an accepted form of payment by platforms such as Amazon or
            Paypal,<a href="#_ftn2" name="_ftnref2" title=""><sup><sup>[2]</sup></sup></a> or a tradable asset on platforms like Coinbase or Robinhood (the latter of
            which received over 480k signatures).
        </p>
        <p>
            The Shiba Inu story can be seen as a microcosm of the crypto world more
            broadly. In many ways, it is an extreme example of the carnivalesque — a
            world that is turned upside down and where the rules of the game are
            constantly being rewritten. It is a world where anything is possible and
            where, as one popular meme put it, “to the moon” is not just a dream, but a
            destination.
        </p>
        <p>
            While Dogecoin and Shiba Inu might have started as jokes, they have
            nonetheless managed to tap into a real community of people, who are looking
            for alternatives to the traditional financial system. Although many people
            may still choose to describe both of the cryptocurrencies as scams, the
            community that has emerged around them indicates that there is more to the
            story; just as there is more to the carnival experience than mere escapism.
            Indeed, while Dogecoin and Shiba Inu may not be traditional investment
            assets, they have nonetheless provided a way for people to explore the inner
            workings of the traditional financial system and discover new market
            manipulation techniques which are usually hidden from view, only available
            to the professional con men who present themselves to the world as
            financiers and qualified investors. And they manage to do so while
            participating in a community that is built on humour, creativity, and a
            shared love of dogs.
        </p>
        <h1>
            Shiba the Trickster
        </h1>
        <p>
            This is anathema to the traditional financial system. After all, money and
            finance are serious business, greased and underwritten by suited
            professionals. Unlike the carnival, where everything short of violence is
            permitted (Robinson, 2011), the monetary system corrals and limits the
            possibilities for alternatives beyond those offered by the system itself.<a href="#_ftn3" name="_ftnref3" title=""><sup><sup>[3]</sup></sup></a> The issuance of new forms of money and minting of coins are the prerogative
            of sovereign states or this monetary policy sovereignty is expressly
            mandated by states to a supranational body (Bodin and Franklin, 1992, p.78;
            Riet, 2016, p.3). In contrast, in the crypto carnival, as with earlier
            carnivals in which Jesters or Tricksters were elected king, this prerogative
            is subverted altogether. Unlike complementary currencies, which do not seek
            to replace legal tender but supplement them by catering to the needs of
            communities that are underserved by legal tender (Dini and Kioupkiolis,
            2019), cryptocurrencies allow for the imagining of alternative monetary
            orders. In the crypto-carnival, anyone can issue or mint new currencies —
            with the aspiration of it serving the functions of money — and not bow
            before any political sovereign. If anything, for certain cryptocurrencies
            like Dogecoin and Shiba Inu, it is the image and mental construct of the
            hunter dog that rules supreme in the communities that use them.
        </p>
        <p>
            In a sense, Dogecoin and Shiba Inu embody the Trickster archetype, one that
            is present in the mythology of many cultures and ages (Jung, 2003). A
            Trickster, in general terms, has several conflicting traits: they are in
            some respects clever, while still being oafish in other respects (e.g.,
            crude habits and proclivities). They can be self-serving, but still achieve
            a “culture-hero” status for their capacity to challenge the status quo, to
            create, and usher in new cultures (Carroll, 1981). A myth about the coyote
            as a Trickster provides an illustrative example: a coyote, after a hunt,
            wished to drink water but found that the water body was entirely owned by
            frogs. The frogs exclusively controlled access and control to the water as
            it was behind their dam. In exchange for a large shell that the coyote
            obtained during its hunt, the frogs allowed him to have a large drink of
            water. The frogs became increasingly worried that the coyote had his head
            beneath the water for an extended period of time and drank a large quantity
            of their water, asking him repeatedly why he was drinking so much. The
            coyote repeatedly said that it was merely thirsty. Once it had finished
            drinking, the dam collapsed — due to the fact that the coyote had been
            slowly dismantling it underwater! The frogs were furious that their water
            had been taken, to which the coyote responded: “It’s not right that one
            people have all the water. Now it is where everyone can have it” (Erdoes and
            Ortiz, 1984, pp. 335-356).
        </p>
        <p>
            As Salinas (2013) argues, this myth shows the dual nature of the Trickster:
            he is a hero for democratising access to a lifesaving resource, while also
            being flippant and sneaky. Dogecoin and Shiba Inu, similarly, embody the
            traits of a noble rogue (Hynes and Doty, 1997). They promise to democratise
            finance and achieve forms of community ownership that appear out of reach of
            most people (due to the vigilant efforts of suited frogs perhaps!), yet they
            do so in a manner that is humorous (e.g., the deliberate use of broken
            English, often in Comic Sans font) and occasionally duplicitous (e.g.,
            sharing fake news). Part of this humour plays on qualities attributed to the
            hunter dog, its charm, good nature, openness and seeming lack of guile,
            while still being bold (Holden, nd). It is still early days, but it is
            possible that the Shiba Inu dog might find itself among the coyote, the
            raven and the hare among the Trickster figures in the pantheon of North
            American mythology (Carroll, 1981). At the same time, the Trickster
            archetype also calls for the observer to look closer, to look at their
            deeper meaning behind their funny form or antics (Campbell and Moyers,
            1991).
        </p>
        <h1>
            Shiba the Egregore
        </h1>
        <p>
            Yet, things that were born in the carnivalesque realm — that of jokes,
            pranks and jests — may evolve into more serious things, with real stakes
            attached to them. This is when the tricksters take on a life of their own
            and evolve into an <em>egregore</em>, a spirit that manifests itself in the
            collective imagination of a group of people. The egregore, which has its
            origins in ancient Greece, is a “thought-form” that develops over time and
            can be described as a collective, unconscious entity that is shaped by the
            imaginations of the people in a group (i.e., a mental construct that is
            created through the power of thought). In some cases, an egregore can be
            created intentionally by a group of people in order to achieve a specific
            goal. For example, Satoshi Nakamoto — the mysterious entity that published
            the Bitcoin whitepaper in 2008 — is an egregore that was intentionally
            created by a person (or a group of persons) in order to steward the
            development of Bitcoin while retaining perfect anonymity. In other cases, an
            egregore can be created unintentionally by a group of people who share a
            common belief or interest, through memetic evolution and consolidation.
            Santa Claus — or to continue with the animal realm, the Easter Bunny — are
            examples of the latter. Santa Claus is a figure that is known and loved by
            millions of people around the world. He is a jolly, fat man who brings
            presents to good little girls and boys. Santa Claus is an egregore because
            he was created by the collective imagination of humanity. He is a product of
            our collective unconscious. Irrespective of whether egregores are created
            intentionally or unintentionally, their power is that they can be ‘invoked’
            in order to influence people’s behaviour in real life. “If you misbehave,
            Santa Claus will not bring you any presents this year” is perhaps one of the
            most common invocations of the Santa Claus egregore, whose mission is to
            convince kids to behave well.
        </p>
        <p>
            Becoming an egregore is the dream of every meme, joke, prank, trickster and
            prankster. This is the culmination of their career, as these conceptual
            entities manage to transpire into the real world, entering into the minds of
            people and acting through them. An egregore is fed by the attention and
            energy that people give to it. The more attention and energy an egregore
            gets, the stronger it becomes. And the stronger it becomes, the more
            influence it has over the people who believe in it.
        </p>
        <p>
            But how does a mere concept or an idea turn into an egregore? We are all
            familiar with the notion of the “meme” — a concept that was popularised by
            Richard Dawkins in his 1976 book “The Selfish Gene” to demonstrate how ideas
            develop and disseminate by contaminating the minds of people, and then using
            these people to spread further into other people’s minds. Memes turn into an
            egregore when they coalesce around a distinct and recognizable entity, which
            is collectively recognized by a larger community of people and, as such, can
            be used to influence in turn the thoughts and actions of these people. A
            meme is the atomic unit of culture, often taking the form of a single image
            or phrase that is passed from one person to another. Memes are typically
            created for the purpose of being funny or entertaining. Conversely, an
            egregore is a cultural entity that is created by a group of people, and that
            eventually acquires power over these people. The egregore is the collective
            consciousness of a group. It is an entity that is created and sustained by
            the collective beliefs, thoughts, and energy of that group.
        </p>
        <p>
            Perhaps the best example of a meme turning into an egregore is Dogecoin, as
            the figure of the Doge and the coin itself have taken on a life of their
            own. The Doge meme started out as a picture of a Shiba Inu, with the text
            “Doge” accompanied with a variety of broken-English sentences written in
            Comic Sans. While the point of the meme was merely to be goofy and funny,
            with Dogecoin, the meme has taken off and has become a force to be reckoned
            with, an egregore with a real impact in the world. While maintaining its
            carnivalesque features — perhaps even leveraging them — Dogecoin has become
            an instrument for people, like Elon Musk, to influence other people’s
            behaviour in order to accumulate power (and money). But was Elon Musk really
            the one in power, or was he himself being instrumentalized by the egregore
            of Dogecoin to expand its own reach?
        </p>
        <p>
            There are many other examples of carnivalesque entities and memes that have
            turned into egregores, also thanks to blockchain and cryptocurrencies. Take
            Pepe the Frog: a green frog taken from a comic book and turned into an
            Internet meme, which eventually became one of the most popular memes of
            4chan — home of many of the prominent Internet memes, such as lolcats, rage
            comics, and Wojaks. In 2016, the first digital collectibles of the meme were
            created, as a collection of rare Pepes were recorded on the Bitcoin
            blockchain. Soon after, the first crypto-art market platform was developed,
            specifically designed to trade and exchange these collectibles. The value of
            these Rare Pepes was initially very low, but quickly rose to significant
            amounts as the crypto-art community started recognizing the value of these
            digital assets. After one of these Rare Pepes was purchased for US $38,500
            in a first NYC-based live auction, and later sold 3 years later for US
            $312,000, more and more people started buying and holding these digital
            assets, to resell them at a later time. Today, in the aftermath of the
            second NFT craze, some of these Rare Pepes have reached a market value of
            over half a million dollars.
        </p>
        <p>
            While the egregore (and therefore the value) of Dogecoin and Rare Pepe
            emerged out of the love and enthusiasm of a distributed network of
            meme-lovers and geeks, other — more commercially oriented initiatives — took
            inspiration from these communities to develop a more ‘serious’ marketing
            strategy, leveraging the notion of community as the main lever by which to
            create value out of thin air — or rather, thin memes.
        </p>
        <p>
            If you haven’t heard of the Bored Apes Yacht Club (BAYC), you’re probably
            not enough of a “cool kid.” BAYC is a collection of 10,000 NFTs, each
            representing a somewhat different kind of bored ape. These NFTs can be
            purchased for several hundred thousand dollars (sometimes even a few million
            dollars) to become part of an exclusive community of cool kids, who get to
            hang out in some of the most glamorous venues together with their fellow
            apes. Since its launch in April 2021, the BAYC NFT collection has generated
            over 2.7 billion dollars of trade volume. And since 10,000 Bored Apes were
            not enough to satisfy the greed of the crypto-art world, derivative
            collections of these apes were created through memetic engineering. New NFT
            collections, such as the Mutant Ape Yacht Club or the Bored Ape Kennel Club,
            were made available for free to the owners of a Bored Ape, so that they
            could further disseminate to whoever they deem are the worthiest of these
            new apes. An uninterested gift as a generous sign of appreciation? More like
            a marketing campaign, as every sale of these NFTs collections comes along
            with a royalty payment to the initiators of the collection. Besides, giving
            these NFTs away to the Bored Apes owners contributed to increasing the value
            of the original Bored Apes, and automatically created a new market for all
            these derivative apes. Most importantly, this contributed to turning the
            Bored Apes into an egregore, a collective and autonomous entity, powered by
            the collective (un)consciousness of the crypto-art world.
        </p>
        <h1>
            Conclusion
        </h1>
        <p>
            This shows how, in this carnivalesque world, things that start as obvious
            jokes and potential scams might evolve into powerful egregors, turning
            frivolous jokes and facetious pranks into more serious entities with memetic
            power, which may eventually acquire real value because of the community that
            builds around them. Dogecoin and Rare Pepe are two example of how things
            born out of a countermovement, aimed at exploring alternative realities
            beyond the status quo, can eventually fall prey of the very same system that
            they were originally trying to escape from — using the carnivalesque as a
            tool to trigger the emergence of an egregore that will be able to bring
            monetary value to digital assets that have no value in and of themselves.
            Similarly, the Shiba Inu and Bored Ape Yacht Club initiatives are examples
            of how egregores can be intentionally designed — for the best or for the
            worst — using marketing strategies that take advantage of the power of
            communities to bring value to otherwise worthless objects. In both cases,
            the community, as memetic nodes which can comprise the networked
            instantiation of an egregore, is essential to create value where there was
            none before. Once launched into the wild, these egregors are no longer
            directly under our control, they will continue to evolve to please the
            crypto-finance and crypto-art world, from which they ultimately feed off.
        </p>

        <h1>
            References
        </h1>
        <p>Bodin J. and J.H. Franklin (1992). <em>Bodin: On sovereignty</em>. Cambridge University Press.</p>

        <p>Campbell J. and R. Moyers, (1991). <em>The Power of Myth</em>. Anchor Books.</p>

        <p>Carroll, M.P. (1981). “Levi-Strauss, Freud, and the trickster: a new perspective upon an old problem,” American Ethnologist, 8(2), pp. 301-313.</p>

        <p>Crawley, Jamie (2021). “Vitalik Buterin Burns $6B in SHIB Tokens, Says He Doesn't Want the ‘Power’.” Coindesk.
        <a href="https://www.coindesk.com/markets/2021/05/17/vitalik-buterin-burns-6b-in-shib-tokens-says-he-doesnt-want-the-power/"> https://www.coindesk.com/markets/2021/05/17/vitalik-buterin-burns-6b-in-shib-tokens-says-he-doesnt-want-the-power/</a>
        </p>

        <p>Dini P. and Kioupkiolis A. (2019). “The alter-politics of complementary currencies: The case of Sardex’ Cogent,” Social Sciences, 5(1), pp. 1-21.</p>

        <p>Erdoes R. and Ortiz A. (1984). <em>American Indian myths and legends</em>. Pantheon.</p>

        <p>Godet, A. (2020). “Behind the Masks, The Politics of Carnival,” Journal of Festive Studies, 2(1), pp. 1-31.</p>

        <p>Holden, Jacey (nd). “An Introduction to the Shiba Inu (part 1).”
        <a href="https://www.shibas.org/newstand/intro_to_shiba_part1.pdf"> https://www.shibas.org/newstand/intro_to_shiba_part1.pdf</a>
        </p>

        <p>Hynes W.J. and W.G. Doty, (1997). “Introducing the Fascinating and Perplexing Trickster Figure” in W.J. Hynes &amp; W.G. Doty (eds.) <em>Mythical Trickster Figures: Contours, Contexts, and Criticisms</em>. University of Alabama Press.</p>

        <p>Jung, C. (2003). <em>Four Archetypes</em>, Routledge pp. 165-166.</p>

        <p>Kelsey, D. (2017). “Media and Affective Mythologies: Discourse, Archetypes and Ideology” in Contemporary Politics.</p>

        <p>Kiran, Sahana (2022). “Did Elon Musk really attend a Shiba Inu live event? Here’s the Truth.” Watcher.Guru.
        <a href="https://watcher.guru/news/did-elon-musk-really-attend-a-shiba-inu-live-event-heres-the-truth"> https://watcher.guru/news/did-elon-musk-really-attend-a-shiba-inu-live-event-heres-the-truth</a>
        </p>

        <p>Riet, van A. (2016) “Safeguarding the euro as a currency beyond the state” ECB Occasional Paper Series No. 173, May.</p>

        <p>Rizzo, Pete (2014). “Dogecoin Foundation to Raise $50k for Kenya's Water Crisis.” Coindesk.
        <a href="https://www.coindesk.com/markets/2014/03/11/dogecoin-foundation-to-raise-50k-for-kenyas-water-crisis/"> https://www.coindesk.com/markets/2014/03/11/dogecoin-foundation-to-raise-50k-for-kenyas-water-crisis/</a>
        </p>

        <p>Robinson, Andrew (2011). “In Theory Bakhtin: Carnival against Capital, Carnival against Power.” Ceasefire.
        <a href="https://ceasefiremagazine.co.uk/in-theory-bakhtin-2/"> https://ceasefiremagazine.co.uk/in-theory-bakhtin-2/</a>
        </p>

        <p>Salinas, C. (2013). Trickster Dialogics: A Method for Articulating Cultural Archetypes from ‘Q’ to Performance Art (PhD Dissertation, Arizona State University).</p>

        <p>Seward, Zack (2021). “Vitalik’s Regift of Unsolicited DOGE Knockoffs Sends Memecoin Prices Plunging.” Coindesk.
        <a href="https://www.coindesk.com/markets/2021/05/12/vitaliks-regift-of-unsolicited-doge-knockoffs-sends-memecoin-prices-plunging/"> https://www.coindesk.com/markets/2021/05/12/vitaliks-regift-of-unsolicited-doge-knockoffs-sends-memecoin-prices-plunging/</a>
        </p>

        <div>
            <hr
            align="left" size="1" width="33%" />
            <div id="ftn1">
                <p>
                    <a href="#_ftnref1" name="_ftn1" title="">
                        <sup><sup>[1]</sup></sup>
                    </a>
                    See <a href="https://shibatoken.com/">https://shibatoken.com/</a>
                </p>
            </div>
            <div id="ftn2">
                <p>
                    <a href="#_ftnref2" name="_ftn2" title="">
                        <sup><sup>[2]</sup></sup>
                    </a>
                    See
                    <a href="https://www.change.org/t/shiba-inu-coin-en-us">
                         https://www.change.org/t/shiba-inu-coin-en-us
                    </a>
                </p>
            </div>
            <div id="ftn3">
                <p>
                    <a href="#_ftnref3" name="_ftn3" title="">
                        <sup><sup>[3]</sup></sup>
                    </a>
                    In this narrower sense, bankers can also be considered to have
                    traits of the Trickster archetype, as they practise their own
                    alchemy in creating new financial products and creatively exacting
                    rents from financial transactions. See Kelsey (2017).
                </p>
            </div>
        </div>


        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default defilippi;
