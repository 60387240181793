import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import crowqueen from "../../assets/images/content/crowqueen.png";
import deadspace from "../../assets/images/content/deadspace.png";
import cryptokitties from "../../assets/images/content/cryptokitties.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function arjalies() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Trying to sell the Crow Queen in Web3: On the resistance of video gamers to cryptocurrencies, NFTs and their financial logic</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Diane-Laure Arjalies and Samuel Compain-Eglin
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089016">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/2f66fbe0-da50-409d-9422-8b634ecd5a81">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            This essay is an encounter between an artist creating characters for video games and an academic studying how people and things are being financialized. Exchanging about the appearance of NFTs and cryptocurrencies – technologies associated with Web3 in the video game industry, the academic and the artist reflect on the place of playfulness, creation, and finance in our society. They observe that most North American and European players resisted NFTs and cryptocurrencies, while more Asian-Pacific ones embraced the latter. They conclude that those reactions were explained by the fact that gamers perceived cryptocurrencies and NFTs as institutional objects associated with a financial logic, whose presence threatened the gaming logic. As pragmatic friends, they nevertheless issued an NFT with this essay, a “<a href="/nftairdrop">Crow Queen</a>.” Time will tell if the Web3 society will praise this new form of digital joint academic/art production.
            </p>
            <hr />
            <h1>
                Encountering the video game world
            </h1>
            <p>
                It is estimated that 39% of the population actively plays video games, which
                means more than 3 billion human beings (Howarth 2023).<sup></sup>I
                (Arjaliès) do not. My relationships with the virtual world have always been
                limited to a few exchanges over WhatsApp and Facebook. I gave up on Twitter
                only two days after signing up: too fast, too complicated, not enough time.
                I never played a game on my phone, never enrolled in Netflix, and belong to
                those species at risk of parents with a no-screen policy. I am not against
                technological progress; I do not understand it. The Metaverse, NFTs and
                other cryptocurrencies are things that I have difficulty grasping, both
                cognitively and phenomenologically. I was probably the last person on earth
                who could find any interest in the world of avatars, warcraft, and other
                crow queens and god-fall warriors. But I did. And now that I understand why
                95% of our youth play video games, I will try to show you what you should
                care about them, too. Gaming has always been fundamental to forming
                communities (Mutch 2021), and video games are increasingly central in our
                cultural, psychological, and social lives (Daniel and Garry 2018, Vesa et
                al. 2017). Gamers’ reactions vis-à-vis new technologies such as Web3 matter
                to our society and should be studied more. This essay is an invitation to do
                so.
            </p>
            <p>
                But let’s not go there yet. A little return in history is needed. London,
                Ontario, 2018. A college town in the great lakes’ region. A historical fief
                of the loyalists to the British Empire, a classic of the classics known for
                well-tended gardens rather than its underground scene. I am shopping with my
                kids in an ugly mall on a Sunday morning when I hear a French Parisian
                accent, sounding just like mine. I am excited. I introduce myself. The
                mother and I agree to meet over a barbecue over the following weeks. I would
                learn it later, but the dad, Sam, felt I was weird: inviting people you meet
                once at a mall did not feel socially fit. Sam and I did not belong to the
                same worlds: he was a self-taught artist and lived in the virtual gaming
                realm; I was an academic evolving in the colonial legacy of a university
                campus. We should not have met, just as you should probably not read an
                essay on video games, NFTs and weird social behaviours. But as you will
                discover, this encounter changed my perspective on a fascinating world I was
                missing, where many people belong, at least in their avatar forms, and where
                the future society is being shaped. I had a lot to learn from gamers.
            </p>
            <p>
                While drinking in a sunny backyard, I asked Sam, “Why do you have a tattoo
                from your skull to your hand?” Sam explained, “It is to show that my
                creativity comes from my brain and travels toward my hand when I draw.” Sam
                was a lead character artist: he created characters that would populate video
                games. He never attended an art school or a university; he self-taught using
                anatomy, anthropology, and other science fiction books.
            </p>
            <p>
                “Teachers always told me that I would do nothing with my life. When I turned
                eighteen, I started working at McDonald's during the day and drawing the
                night. You can find software for creating characters that are free. So, I
                drew seven-eight hours per night, again and again. I posted my creations on
                my art station portfolio. Over the years, I became known in the video game
                world, and a company recruited me to create the characters for their game.
                It is not like in your world. Where you graduate from does not matter. What
                matters is your portfolio: showing what you can do.”
            </p>
            <h1>
                The Crow Queen: The Artistic logic
            </h1>
            <p>
                After settling our initial differences, Sam and I discussed our creative
                practices further. Amused by our commonalities and differences, we agreed to
                work on a joint chapter for a blockchain book on Web3. Sam was drawing. But
                we were both studying, coding, and decoding the same information repeatedly
                to get better and better at accounting for what we saw. As we discussed our
                respective work and passion, I found that we could relate in a way that
                surprised me. I could feel his creative process echoing mine, and he showed
                much interest in the research I conducted. It had been years since we had
                started speaking about a Crow Queen that he would draw, print, and hang in
                my living room. We decided this would be the art piece issued as an NFT – a
                non-fungible token or a digital art piece recognized through unique digital
                ownership, as an accompanying piece of this essay. I asked Sam to explain
                why he drew the Crow Queen as he did.
            </p>
            <p>
            <img src={crowqueen} class="img-fluid" alt="Figure 1: The Crow Queen"/>
            </p>
            <p>
                Figure 1:<a href="https://cryptocarnival.wtf/nftairdrop"> The Crow Queen</a>. Digital art piece issued as a free NFT accompanying this chapter. NB: Due to the low likelihood of earning money through the issuance encouraged us not to issue it on platforms like Opensea, where the NFT could be sold, as minting it would have cost us more than the selling price.
            </p>
            <p>
                Sam answered,
            </p>
            <p class="displayquotation">
                “The story of this character follows... I love the different cultures and
                the divine representations when humans try to communicate with spirits,
                gods, and overall peoples’ beliefs. So, I like all the imagery that comes
                off that. And in fact, we notice throughout the ages that this has always
                been necessary, whether in Christianity, Buddhism, Africa, or any other
                culture; there's always religious imagery that has been very strong. We can
                think of the Sistine Chapel, all the great artists of the Renaissance, and
                all the patrons who funded artists and allowed the development of new
                painting techniques. It was a real engine from an artistic perspective,
                spurring much creation. We were talking about the different types of games
                before and how they became fashionable; at one time, religion was
                fashionable, which led artists to create works to the glory of a God, to the
                glory of a hero, to the recognition of anything! So, I wonder a lot about
                religious symbolism. And how can we mix the codes from one religion to
                another? How can we bring principles, get other things, and so on? And I
                love everything voodoo and shamanic, so a couple of ideas came from it.
            </p>
            <p class="displayquotation">
                There is the priestess side. It is the priestess who can communicate with
                the dead, with the spirits, and the other world. So, some codes are
                apparent: the skull and the bones have a strong symbolism for the world of
                the dead. You can also see the raven feathers: crows are in many religions.
                That's quite astonishing because even in some countries that didn't
                necessarily communicate at the time, the crow remains a messenger between
                the world of the living and the world of the dead. And it is an iconography
                that comes back and is relatively constant through several civilizations.
                So, it helps to reinforce the image. And likewise, these are things we put
                in place which are rarely known by the people who will look at them. They
                will understand instinctively, but they may be unable to explain it. And
                it's all the pleasure of the artist's work behind. It's how you code in what
                you do to talk to people, even if they can't put their finger on it. They
                feel it; they live it. You see, you have a base of general civilization.
                It's part of the culture. And, if you manage to type in it, you'll be able
                to make people feel what you want, and you’ll be able to give emotion, an
                image, and so on.
            </p>
            <p class="displayquotation">
                And then you come back with the divine side. It is the same if you look at
                the representations of Christ and the angels in the European Christian
                culture. Well, you always have this kind of halo that gives the divine side,
                the angels, etc. And then, you use the same code. Except that it's
                extrapolated, it's with bones, with crow feathers. Raven feathers. But you
                have this kind of halo that is always present and radiates from the
                character's face. So that's also a code that you'll catch. You won't be able
                to put your finger on it because it's not a yellow halo that will shine.
            </p>
            <p class="displayquotation">
                But on the other hand, visually, it will go around the head, take, and
                surround it. In addition, all the lines will go toward the center of the
                face, directing the gaze toward the front of the priestess. And it will
                encircle the face. So, the front will be covered, and everything will put
                the emphasis really on the priestess's face, on her expression a little bit
                lost, eyes in the void, or a little bit between the two worlds. And then,
                you'll continue with another curve that will make the transition to the
                image with the crow's skull. In the image's composition, on the first third
                line, I have the woman's face and the skull on the second third. It creates
                communication and a balance between the two, like tarot cards.
            </p>
            <p class="displayquotation">
                If you print it to make the skull at an actual size and hang it on a wall
                with your eyes facing the empty orbits, you’ll lose sight of its open holes.
                Ultimately, you are not the priestess; you are only the mortal worshiping
                the gods and saints. And it's also this idea of having the human above the
                humans, and hell has always been represented below, and then the dead under
                the ground. So, it's all that too. When you think about composition, some
                codes are known, and you must use them to convey the right feelings and
                emotions and to direct the look. And above all, in the end, what is
                essential is that the player...that you manage to win enough elements that
                are part of this kind of broad culture base. For that, people understand
                your image and the meaning that you want to convey.”
            </p>
            <p>
                I was stunned. To draw one character – a priestess, Sam relied on thousands
                of years of religious symbolism, studied dozens of representations of skull
                anatomy, and related to a lineage of artists returning to the Chapelle
                Sistine and Greek mythology. All these efforts were geared toward conveying
                the right emotion to the gamers, to make them feel that the virtual world in
                which they evolved was as real as it could be, the “affective component”
                that encouraged continuous experimentation (Jagoda 2020, p. XV). Most
                European and North American gamers played to escape the mundane world to
                which they belonged and make this virtual world theirs. Games blur domain
                boundaries between life and work, private and public, virtual and physical,
                imaginary and real (Hulsey 2019). Sam’s job was to empower gamers to make
                their craziest dreams come true. The artistic production was beyond
                representation; it enabled gamers to identify with the virtual hero they
                incarnated. Gaming was a symbolic support to process individual and
                collective traumas, dreams, and societal past and future endeavours. This
                included re-living the crusades by playing Assassin’s Creed, psychoanalytic
                and cathartic death exercises, or supporting the QAnon conspiracy fantasy
                (Haiven et al. 2022).
                <a href="#_edn1" name="_ednref1" title="">
                    <sup><sup>[i]</sup></sup>
                </a>
                Sam explained:
            </p>
            <p class="displayquotation">
                “A video game is a universe you can move in, that is not material and where
                just your mind can escape. It's not something physical. It's something
                completely immaterial and in which you have all the powers. You are the hero
                in this world. And not just one human among millions. You are the hero of
                the story. That is the main idea. After that, it drifts because there are
                all kinds of video games. There are video games where you're going to
                collaborate with friends. You have some video games with social constructs
                all around them, where people get together on the Internet, and they can
                exchange. So, a game can be personal; but it can also be a community built
                around it. You cannot define it or pick up a controller and play. There are
                many, many possibilities. The video game has become something very vast.”
            </p>
            <h1>
                From creating to financing games
            </h1>
            <p>
                The more I learned about video games, the more interested I became. Sam and
                I started crossing each other’s worlds. I invited him as a guest speaker to
                a conference to reflect on the artistic and scientific production of
                knowledge. He invited me to his company – a cool open space with free food
                and games everywhere, and an online webinar of the art developer team for a
                game he was conceiving: Dead Space, a science fiction survival horror
                classic.
            </p>
            <p>
            <img src={deadspace} class="img-fluid" alt="Figure 2: Online community sharing around artistic production in a video
                game."/>
            </p>
            <p>
                Figure 2: Online community sharing around artistic production in a video
                game. Source: a screenshot of Dead Space/Crafting the Tension/Art Developer
                Livestream - 12 May 2022
            </p>
            <p>
                Figure 2 shows how 2,848 people worldwide joined to listen and
                enthusiastically comment on Sam’s explanations of his colour selection for
                the skin of his last zombie. This page alone was seen 135,000 times, and
                adding other platforms and the press, it probably crossed a million views. I
                would feel lucky if ten people attended one of my Zoom seminars. Few cared
                about my research, but many were passionate about video games. I felt I had
                missed a big part of the world as it unfolded.
            </p>
            <p>
                Play is essential to human beings (Huizinga 2014). Children learn and form
                their social practices through playing “as if.” (Vygotsky 1978)
                Psychoanalysis and psychological research have shown the critical role of
                play in our society, particularly video games (Cabeza-Ramírez et al. 2021,
                Granic et al. 2014). Beyond this CryptoCarnival project, research in social
                sciences on and involving playfulness is scarce. Except for media and
                cultural studies, few disciplines have studied the social practices
                associated with gaming and their impact on the industry and society more
                broadly (Corliss 2011, Johnson and Woodcock 2019). The video game industry
                is envisioned as a business (Shankar and Bayus 2003, Zackariasson and Wilson
                2012). This is particularly the case in management, although gaming as a
                strategy arises in the marketing and communication/information technology
                (Marchand and Hennig-Thurau 2013, Williams et al. 2007, Xi and Hamari 2020).
            </p>
            <p>
                We still need to learn about gamers and their world and how game-making is
                world-making; despite 95% of North American Gen Z and Millennials playing
                video games in some capacity, the most frequent gamers spending an average
                of five to seven hours a week (Ypulse 2022). Not studying this field is a
                mistake, particularly given the rise of new technologies linked to Web3 or
                the “full digital experience.” Soon, millions of avatars will move into a
                shared and persistent, 3D virtual realm – or the Metaverse.
                <a href="#_edn2" name="_ednref2" title="">
                    <sup><sup>[ii]</sup></sup>
                </a>
                Will this world be like the one offered by video games? What will the
                impacts of Web3 be on gaming practices? What will it tell us about the other
                industries? We do not know. Understanding how today’s gamers react to
                technologies such as NFTs and cryptocurrencies could help us understand what
                society could look like tomorrow.
            </p>
            <p>
                The video game industry has become a giant economic actor. The global gaming
                industry will be worth $321 billion by 2026. Social/casual gaming is
                expected to generate the highest revenue of US$242.7 billion, followed by PC
                games with US$42.2 billion, console games (US$31.5 billion), and integrated
                video game advertising (US$4.7 billion) (Read 2022). Morgan Stanley’s (2022)
                recent survey found that more than a quarter of those under 35 gamers
                believe gaming is a better social connection platform than social media
                platforms. The industry became bigger than movies and sports combined. Sam
                was an artist, but financial requirements also constrained him. When he
                designed his characters, he had to ensure the game would be successful.
            </p>
            <p class="displayquotation">
                “We have the initial idea. But quickly, we ask how much it will cost and how
                many people it will take to design it. It is about feasibility. We're going
                to have the profitability questions. Because the purpose of making a game is
                to raise enough money to make another game after. So, the questions we ask
                are: What player are you targeting? And as a result, how much money can you
                make? Likewise, when?
            </p>
            <p>
                When you create a game, you can have a great idea and everything, but you
                can only do what money allows. The day you present it to people who have
                money, can invest, come back with money to hire people, and pay for the
                software and office and marketing, they’ll see it in terms of return on
                investment. And really, how do you build an idea that is solid enough and
                where people with different viewpoints, needs, and opportunities want to
                fund it? When you think about a game, it’s not just about the art or
                gameplay, how you'll play, or what you'll do. There’s a vast layer next to
                it that's less creative but necessary to have something viable in the end.”
            </p>
            <p>
                Since nobody attended my seminars, I wondered whether launching a video game
                was a good alternative: was there a business interest in sustainability
                games? I asked Sam whether he believed we could use video games to explain
                that the planet was dying, and that people should care about it, as other
                scholars suggested (McGonigal 2011). He explained, “Video games are very
                dystopian. It is often a dark world where the planet has already been
                destroyed. But I wanted to create a game for my daughter where she would
                save the planet. But I don’t have the money for it.” I did not understand:
            </p>
            <p class="displayquotation">
                “You do not have to work with these big companies. I thought creating video
                games was very cheap, thanks to those free software and those online
                communities. I heard about NFTs, where you can ask people to pay for your
                digital art pieces. I mean, gamers are virtual people; this should speak to
                them, no?”
            </p>
            <p>
                Sam commented,
            </p>
            <p class="displayquotation">
                “From my experience, it does not work. I know companies that tried to launch
                NFTs and cryptocurrencies, but they received backlash. I mean, as an artist,
                I would love to be able to fund my art through NFTs, but I have never done
                it. Most people who used NFTs were not artists.”
            </p>
            <h1>
                Web3 and its resistance in North America and Europe
            </h1>
            <p>
                I was puzzled. If there was one community I envisioned supporting Web3 and
                its accompanying technologies – NFTs, cryptocurrencies; it was the gamers.
                After all, I assumed those individuals to be young, digital natives and
                likely to question established practices to support their crowd. I decided
                to investigate. I formed a small research team and reviewed the grey
                literature on the topic – mainly reports published on the gaming industry
                and online platforms where gamers exchange. Below is a summary of our
                findings (see also Anand et al. 2023).
            </p>
            <p>
                The defining feature of Web3 games is the financialization of in-game items.
                Studios that publish Web3 games can also monetize through older methods:
                selling a premium game and incorporating microtransactions. They also
                typically develop their blockchain marketplace platforms to monetize through
                transaction fees. Ubisoft became the first game developer to launch limited
                edition NFTs inside the military shooter game Ghost Recon Breakpoint. What
                was supposed to be a monumental shift was a commercial disaster. Players
                complained about 600 hours of playing time needed to earn one free item – a
                cosmetic helmet for in-game avatars. Attempts to drive revenue could have
                been better, as only 15 NFTs were purchased for $400 USD (Birnbaum, 2022).
            </p>
            <p>
                One use case of Web3 is decentralized ownership of in-game assets, meaning
                if someone acquires or purchases an item in-game, they own it even in use
                cases beyond the particular game. Consider the Web3 game Axie Infinity,
                which has no free method to play as of the time of writing. A prospective
                player must first own three Axie Infinity NFTs (“Axies”) to be able to play,
                representing an initial investment of potentially over $1,000 USD in August
                2021. This created sub-economies where lenders would provide Axies to
                prospective players in exchange for a cut of their earnings. Axies have
                differing gameplay values and can be bought and sold via a marketplace.
                Trading Axie’s currency ($AXS) directly for state-issued, fiat currency
                allows players to earn money by playing a game. Axie Infinity recorded a
                massive $1.3 billion USD in revenue throughout 2021 (Keller 2022). Since
                then, Axie Infinity’s popularity has decreased significantly, leading the
                sustainability of this earning potential to be seriously questioned.
            </p>
            <p>
                At this early stage of development in Web3 gaming, many players expressed
                their dislike for this growing trend through social media. “I just hate that
                they keep finding ways to nickel-and-dime us in whatever way they can,” said
                Matt Kee, 22, a gamer who took to Twitter in anger after Square Enix, which
                produces one of his favourite games, Kingdom Hearts, said it was pushing
                into NFTs. “I don’t see anywhere mentioning how that benefits the gamer and
                improves gameplay. It’s always about, ‘How can I make money off this?”
            </p>
            <p>
                In 2022, North American gamers that hold cryptocurrencies only represented
                15% of the global crypto gamers, with only 6% of regular gamers owning an
                NFT globally (Doll 2022). Web2 already involved monetization practices
                through micro-transactions or “play to earn” models. In 2017, EA released
                “Star Wars Battlefront II,” which included a system of loot boxes that
                players could purchase with real money to earn in-game advantages such as
                more potent weapons and skills. Players were outraged, believing the method
                gave an unfair edge to those prepared to spend more money on the game,
                pointing to how gameplay could be undermined by a competitive ethos,
                especially if linked to a profit motive (Davies 2020, Jagoda 2020). This
                scandal resulted in a massive backlash against EA, with fans boycotting the
                game and demanding government regulation of gaming microtransactions. But
                the resistance of gamers vis-à-vis Web3 appeared stronger. Unlike potent
                weapons and skills that could help improve the playing experience, NFTs and
                cryptocurrencies focused only on increasing financial flows for game owners.
                Many North American and European players felt those technologies were going
                against the industry's “gaming logic,” interpreting it as a betrayal of the
                community’s spirit. They did not want to be used as an additional source of
                revenue.
            </p>
            <h1>
                Web3 as a source of revenue for Asian-Pacific gamers
            </h1>
            <p>
                Meanwhile, many gamers from the Asia-Pacific regions had entirely different
                opinions. Despite low margins, the difference in exchange rates made crypto
                gaming a stable source of income. In Asian countries, such as Vietnam,
                Indonesia, and the Philippines, the relative weakness of local, state-issued
                currencies compared to cryptocurrencies like Bitcoin and Ethereum implies
                that even with meagre revenues, earnings from playing crypto/NFT games could
                generate a significant income.
            </p>
            <p>
                In these regions, crypto gamers started playing P2E games to make money to
                support their families in times of job loss and poor pay. Franco Villaflor,
                a 28-year-old DJ, plays poker in the metaverse on behalf of an American man
                for three hours a day, allowing him to make as much as $1,500 a month, the
                equivalent of what he makes as a DJ. “That’s after giving 40% of the
                proceeds to the owner of the NFT. My mother doesn’t understand how I can
                spend my time playing games instead of doing something fruitful,” Espeche,
                34, said. “But what’s the point of working hard if I can earn more like
                this?” (Doll 2022). Crypto gaming became an alternative source of income.
                For the first time, this resulted in the Asia-Pacific region overtaking
                North America in terms of revenue in the video gaming industry, standing at
                $157.3 million compared to $80.3 million as of 2020 (Kameke 2022).
            </p>
            <h1>
                The problem at play: Cryptocurrencies and NFTs as institutional objects
            </h1>
            <p>
                North American, European, and Asia-Pacific gamers all associated
                cryptocurrencies and NFTs with a financial logic. The main difference
                between the two groups was that the first one did not need an additional
                source of revenue as severely as the second one. Sam summarized,
            </p>
            <p class="displayquotation">
                “The main advantage of those new technologies is adding money flows. They do
                not add anything from a development perspective or improve the gaming
                experience. If I use an NFT, it is only to raise more funding. From a
                player's point of view, what's complicated is that I play because I want
                some entertainment. I work all day to earn some money to live. I play to
                escape this life. If I can make a little money playing, why not? But the
                primary impression today is that if I want to make $0,10 by playing, the
                company will get $1. There is this impression of being used as a cash cow,
                that you will work for them for free, and that everything you do will
                increase the company's wealth.
            </p>
            <p class="displayquotation">
                As developers, we are between a rock and a hard place. You must make money
                to live, pay people, and make the next game. So having other threads of
                money is attractive. But on the other hand, we don't want to look like a
                bunch of scrooges to the players. Because if a company has a bad image in
                the community, people will be less willing to buy these games. You must
                satisfy the financiers because you must make money. But it would be best if
                you also satisfied the players because they're the ones who will buy the
                products. As a developer, you must try to please everyone and act as a
                buffer between the two to ensure everyone is happy. That's the feeling I
                have; again, it's personal. It's not a value; it's not a universal truth.
                It's my feeling today about the world of video games, the way it works.”
            </p>
            <p>
                Cryptocurrencies and NFTs appear to be institutional objects – objects
                constitutive of an institutional logic – in this case, the financial logic.
                Most objects are not institutional; in the sense that their meaning and
                social significance vary according to the situation (Friedland and Arjaliès
                2021). One can look at some glasses, pens, or computers without considering
                particular social practices. In sharp contrast, institutional objects
                immediately evoke an “institutional logic,” like a crucifix is associated
                with religion or ballot papers with democracy. As we can see in this essay,
                there are three main institutional logics in the gaming industry: artistic,
                gaming, and financial. Players associate cryptocurrencies and NFTs with
                financial logics and interpret those technologies as endangering the gaming
                logic by penetrating gaming practices (e.g., through selling NFTs inside the
                game). This rejection was particularly strong when gamers played to escape
                the capitalistic world where they had to work to earn a living. In this
                context, anything that made them think they were being used to generate
                wealth for a company spurred massive reactions. Asian-Pacific gamers who
                welcomed those technologies were searching for additional revenues: they
                were not playing for play but to earn money.
            </p>
            <p>
                As a developer, Sam felt squeezed between the financial and gaming logics –
                struggling to meet both. As an artist, he also questioned the benefits of
                those technologies to support his artistic creation. NFTs were envisioned as
                an additional source of revenue for artists through the issuance of digital
                art pieces. Graphic designer, Mike Winkelmann, known in the digital art
                world as “Beeple,” was perhaps the most famous NFT digital artist.
                <em>
                    Everyday: The first 5,000 days
                </em>
                – a collection of all his digital works in one big piece – was auctioned off
                in early 2021 at Christie’s for almost $70 million USD. However, in 2021,
                most NFT sales were $200 USD or less (Kinsella 2021).<sup></sup>Additionally, 
                most NFTs were used to sell “collectibles” whose artistic added value was
                questionable. For instance, Crypto Kitties, launched in 2017, enabled
                buying, selling, and creating NFTs in the form of virtual cats. The game
                went viral; it made up 10% of transaction traffic and congested the entire
                Ethereum network, but eventually lost its interest among players (Serada et
                al. 2021). The artistic qualities of crypto kitties in Figure 3, compared to
                those of the Crow Queen in Figure 1, can be easily assessed. The
                multiplication of collectibles negatively impacts the unique digital
                artworks artists propose through NFTs, thus endangering the industry's
                artistic logic, in addition to the gaming one.
            </p>
            <p>
            <img src={cryptokitties} class="img-fluid" alt="Figure 3: Cryptokitties"/>
            </p>
            <p>
                Figure 3: Cryptokitties. Screenshot of https://www.cryptokitties.co/
            </p>
            <h1>
                Conclusion: Financialization in disguise?
            </h1>
            <p>
                What are the implications of our work for the study of gaming, Web3, and
                institutional logics more broadly? Firstly, our findings show the essential
                role of playfulness in our society. The video game industry has become a
                capitalistic empire, with millions of investors, professional players whose
                compensation exceeds regular jobs, and intense competition with fads, many
                losers and a few (temporary) iconic winners. Still, most gamers refused the
                inclusion of NFTs and cryptocurrencies in their games. As institutional
                objects associated with a financial logic, they threatened the gaming logic
                that needed to be preserved – at least on the surface – to garner gamers’
                support. The association of NFTs and cryptocurrencies with a financial logic
                is ironic, given that the first cryptocurrencies were launched as an
                alternative to financial institutions (Arjaliès 2021). The love and need of
                gamers to play in a world outside of the capitalistic realm might push NFTs
                and cryptocurrencies away from the industry, which is now at a crossroads.
                Faced with a backlash but seeing the growing Asia-Pacific market, the major
                North American and European companies hesitate. Although problematic from a
                business perspective for those companies, the fact that human beings are
                still fighting to keep playfulness in their life might appear reassuring.
                Financialization might not have (yet) corrupted all the layers of our
                societal and personal lives.
            </p>
            <p>
                Secondly, gamers’ reactions could help us envision the future of Web3. A
                subset of gamers – mainly from Southeast Asia – turned the gaming logic into
                a financial one. Using NFTs and cryptocurrencies as a source of revenue,
                they created a new constellation of practices within the industry. Whether
                those practices constitute a separate realm or have penetrated existing
                gaming communities remains unclear. Some games seem geared toward those
                financial practices openly, while others include possibilities to make
                money, although the latter remains marginal or even hidden. There might be
                gamers with different purposes (gaming or financial) within the same game.
                However, the penetration of Web3 objects might disturb the social and
                cultural practices of the gaming communities in the long term. It is
                unlikely that all objects associated with Web3 will be institutional. For
                instance, objects like artificial intelligence processing or virtual reality
                devices might be considered technical objects. But the subtle presence of
                financial institutional objects, like NFTs and cryptocurrencies, might be
                enough to spur the financialization process against which gamers fought
                above. From this perspective, Web3 bears the risks of becoming an
                incarnation of a form of capitalism 3.0.
            </p>
            <p>
                Lastly, our study offers novel insights into institutional theory. As
                explained above, the concept of institutional objects (Friedland and
                Arjaliès 2021) provides a theoretical and methodological apparatus to study
                practices grounded in objects. Web3, like other digital forms of organizing,
                such as the Decentralized Autonomous Organizations (DAOs) (Hsieh and Vergne
                2023), do not exhibit the same features as previous forms of organizing,
                including those of Web2 and Web1. They are distributed and constantly
                evolving, combining human and non-human actors and multiple institutional
                logics and goals. Their objects (e.g., codes) seem to be the most reliable
                source of information in their governance and institutional systems.
                Identifying the institutional objects in Web3 and their associated logics
                offers a way to understand the mechanisms at play in this digital new world.
                Institutionalizing those objects will likely shape the constellation of
                practices in place. Likewise, actors' resistance to those objects could
                prevent Web3 from catalyzing around specific logics. Our study shows that
                financialization in the form of cryptocurrencies and NFTs might not be part
                of all the layers of our future Web3 society. Some actors will probably keep
                resisting those new institutional (financial) objects (e.g., actors pursuing
                gaming and artistic logics) until maybe, their virtual practices – which
                they do not fabricate, are being infused with those objects. This could be
                especially true if capital owners (e.g., investors) impose NFTs and
                cryptocurrencies into economic and cultural practices (e.g., gaming) for
                financial reasons. If this happens, artists like Sam might become the
                ultimate barrier between capitalism and what seems to remain one of the
                latest realms outside of the latter: playfulness.
            </p>
            <h1>
                Acknowledgements
            </h1>
            <p>
                The first author (Arjaliès) received funding from the Scotiabank Digital
                Banking Lab, Ivey Business School, Western University, and would like to
                recognize the work of her three research assistants who spent many months
                surveying the gaming world: Harsheen Anand, Lakshay Kumar and Darren Guo.
                Both authors thank Sébastien de la Lande, Matteo Ronzani and the three
                editors Quinn Dupont, Paul Ennis and Donncha Kavanagh, for their feedback on
                earlier versions of the article. The interpretation is ours and should not
                be ascribed to others.
            </p>
            <h1>References</h1>
            <p>Anand H., Kumar L., and Arjaliès D-L., (2023). Blockchain, Cryptos and NFTs in the gaming industry: A tale of two worlds, Western University, London, Canada, DOI: 10.5206/iveypub.64.2023, available at <a href="https://ir.lib.uwo.ca/iveypub/64/"> https://ir.lib.uwo.ca/iveypub/64/</a>.</p>
            <p>Arjaliès DL (2021) "At the very beginning, there’s this dream.” The role of utopia in the workings of local and cryptocurrencies. Raghavendra R, Wardrop R, Zingales L, eds. Handb. Technol. Finance. 95–137.</p>
            <p>Birnbaum J (2022) Why Video Game Makers See Huge Potential In Blockchain—And Why Problems Loom For Their New NFTs. Forbes. <a href="https://www.forbes.com/sites/justinbirnbaum/2022/01/06/why-video-game-makers-see-huge-potential-in-blockchain-and-why-problems-loom-for-their-new-nfts/?sh=3e673d3e43d7">https://www.forbes.com/sites/justinbirnbaum/2022/01/06/why-video-game-makers-see-huge-potential-in-blockchain-and-why-problems-loom-for-their-new-nfts/?sh=3e673d3e43d7</a></p>
            <p>Cabeza-Ramírez LJ, Fuentes-García FJ, Muñoz-Fernandez GA (2021) Exploring the emerging domain of research on video game live streaming in web of science: State of the art, changes and trends. Int. J. Environ. Res. Public. Health 18(6):2917.</p>
            <p>Corliss J (2011) Introduction: The social science study of video games. Games Cult. 6(1):3–16.</p>
            <p>Daniel M, Garry C (2018) Video games as culture: Considering the role and importance of video games in contemporary society (Routledge).</p>
            <p>Davies W (2020) Post-liberal competitions? Pragmatics of gamification and weaponization. Stark D, ed. Perform. Complex Compet. Compet. Soc. Life . (Oxford University Press), 197–198.</p>
            <p>Doll IO (2022) Argentines Flock to Crypto Games as Economy Eats Up Salaries. Bloomberg. <a href="https://www.bloomberg.com/news/articles/2022-03-22/argentines-flock-to-crypto-games-as-economy-eats-up-salaries">https://www.bloomberg.com/news/articles/2022-03-22/argentines-flock-to-crypto-games-as-economy-eats-up-salaries</a></p>
            <p>Friedland R, Arjaliès DL (2021) Putting things in place: Institutional objects and institutional logics. Res. Sociol. Organ. 71:45–86.</p>
            <p>Granic I, Lobel A, Engels RC (2014) The benefits of playing video games. Am. Psychol. 69(1):66.</p>
            <p>Haiven M, Kingsmith A, Komporozos-Athanasiou A (2022) Dangerous Play in an Age of Technofinance: From the GameStop Hunger Games to the Capitol Hill Jamboree. TOPIA Can. J. Cult. Stud. 45:102–132.</p>
            <p>Hsieh Y, Vergne J (2023) The future of the web? The coordination and early‐stage growth of decentralized platforms. Strateg. Manag. J. 44(3):829–857.</p>
            <p>Huizinga J (2014) Homo Ludens: A Study of the Play-Element in Culture (Mansfield Centre, CT).</p>
            <p>Hulsey N (2019) Games in Everyday Life: For Play (Emeral Insight).</p>
            <p>Jagoda P (2020) Experimental games: Critique, play, and design in the age of gamification (University of Chicago Press).</p>
            <p>Johnson MR, Woodcock J (2019) The impacts of live streaming and Twitch. tv on the video game industry. Media Cult. Soc. 41(5):670–688.</p>
            <p>Kameke LR (2022) Gaming market in the Asia-Pacific region - statistics and facts. Statista. <a href="https://www.statista.com/topics/2196/video-game-industry-in-asia/">https://www.statista.com/topics/2196/video-game-industry-in-asia/</a></p>
            <p>Keller L (2022) Top NFT game Axie Infinity generated $1.3B in revenue last year. Yahoo! Finance. <a href="https://finance.yahoo.com/news/top-nft-game-axie-infinity-140000481.html">https://finance.yahoo.com/news/top-nft-game-axie-infinity-140000481.html</a></p>
            <p>Kinsella E (2021) Think Everyone Is Getting Rich Off NFTs? Most Sales Are Actually $200 or Less, According to One Report. Artnet News. <a href="https://news.artnet.com/market/think-artists-are-getting-rich-off-nfts-think-again-1962752">https://news.artnet.com/market/think-artists-are-getting-rich-off-nfts-think-again-1962752</a></p>
            <p>Marchand A, Hennig-Thurau T (2013) Value creation in the video game industry: Industry economics, consumer benefits, and research opportunities. J. Interact. Mark. 27(3):141–157.</p>
            <p>Morgan Stanley (2022) Into the metaverse: Why gaming could level up in 2022. <a href="https://www.morganstanley.com/ideas/video-gaming-industry-2022">https://www.morganstanley.com/ideas/video-gaming-industry-2022</a></p>
            <p>Mutch A (2021) Challenging Community: Logic or Context? Organ. Theory 2(2):26317877211004600.</p>
            <p>Howarth, J (2023) How Many Gamers are There? (new 2023 statistics). Exploding Topics. <a href="https://explodingtopics.com/blog/number-of-gamers">https://explodingtopics.com/blog/number-of-gamers</a></p>
            <p>Read, Simon (2022) Gaming is booming and is expected to keep growing. This chart tells you all you need to know. World Economic Forum. <a href="https://www.weforum.org/agenda/2022/07/gaming-pandemic-lockdowns-pwc-growth/">https://www.weforum.org/agenda/2022/07/gaming-pandemic-lockdowns-pwc-growth/</a></p>
            <p>Serada A, Sihvonen T, Harviainen JT (2021) CryptoKitties and the new ludic economy: how blockchain introduces value, ownership, and scarcity in digital gaming. Games Cult. 16(4):457–480.</p>
            <p>Shankar V, Bayus BL (2003) Network effects and competition: An empirical analysis of the home video game industry. Strateg. Manag. J. 24(4):375–384.</p>
            <p>Vesa M, Hamari J, Harviainen JT, Warmelink H (2017) Computer games and organization studies. Organ. Stud. 38(2):273–284.</p>
            <p>Vygotsky LS (1978) The role of play in development.</p>
            <p>Williams D, Caplan S, Xiong L (2007) Can you hear me now? The impact of voice in an online gaming community. Hum. Commun. Res. 33(4):427–449.</p>
            <p>Xi N, Hamari J (2020) Does gamification affect brand engagement and equity? A study in online brand communities. J. Bus. Res. 109:449–460.</p>
            <p>YPulse (2022) Gen Z, Millennials, and Gen Alpha’s Favorite Video Games of 2022. <a href="https://www.ypulse.com/article/2022/12/19/gen-z-millennials-and-gen-alphas-favorite-video-games-of-2022/">https://www.ypulse.com/article/2022/12/19/gen-z-millennials-and-gen-alphas-favorite-video-games-of-2022/</a></p>
            <p>Zackariasson P, Wilson TL (2012) The video game industry: Formation, present state, and future (Routledge).</p>
            <div>
                <br clear="all"/>
                <hr
                align="left" size="1" width="33%" />
                <div id="edn1">
                    <p>
                        <a href="#_ednref1" name="_edn1" title="">[i]</a> We thank Matteo
                        Ronzani for those important insights.
                    </p>
                </div>
                <div id="edn2">
                    <p>
                        <a href="#_ednref2" name="_edn2" title="">[ii]</a> In 2021, two of
                        my former students launched Flirtual, an open-source and
                        open-algorithm virtual reality social platform or in other words a
                        digital world where 45,000 avatars are already flirting.
                        <a href="https://flirtu.al/">
                            https://flirtu.al/
                        </a>
                        .
                    </p>
                </div>
            </div>     



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default arjalies;
