import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function kavanagh() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Web3: The gentrified carnival?</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Donncha Kavanagh
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089013">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/2d51bd00-42ef-462d-b7f2-9563061cefe3">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            Web3 reflects and instantiates the ‘ludic turn’ of the last few decades, where identity is increasingly forged through play and games rather than through work.  The ludic is especially evident in the Bitcoin ecosystem, where the elements of the carnivalesque – play, anarchy, dissimulation, vulgar language, and excessive consumption – are pervasive. These elements may also be divined in Web3, though they are less ubiquitous, which suggests that Web3 is perhaps best seen as a gentrified carnival.
            </p>
            <hr />
            <p class="displayquotation"> “Carnival, then, does not describe freedom, or even a new authority, but rather the process by which one authority replaces another through the temporary institution of madness in society”  (Dowling, 2020, p. 54)</p>
            
            <p>In his classic book, The Grasshopper, Bernard Suits (1978) argues that playing games is the supreme good, for the simple reason that in a Utopia we would play games rather than work. And while a workless, playful world might still be fanciful – despite talk about how AI will make work redundant (Danaher, 2019) – the latter part of the twentieth century has certainly seen a shift from a work to a play ethic. For instance, membership of trade unions has decreased as people increasingly build their identity on play rather than work (union membership in the US has dropped from over 20% of the civilian labour force in 1983 to 10% in 2021 (US Bureau of Labor Statistics, 2022)), while political parties, like the Labour party in the UK, have found it difficult to frame a political ideology centred on work and employment. In contrast, individuals are increasingly forging their identities around play rather than work: two thirds of US adults and three quarters of children under 18 play video games weekly (Entertainment Software Association, 2021); the principal web forum for board games, boardgamegeek, which was founded in 2000, has a database of some 140,000 table-top games; while the global sports market is estimated to be worth over US$500 billion (Wood, 2022). This empirical reality has, since 2000, been accompanied by a flood of journal articles and books about play and games, which contrasts with the whole of the twentieth century when relatively few books on the subject were published on the subject (for example, Caillois, 1961; Huizinga, 1949).</p>
            <p>Crypto reflects and instantiates this turn to the ludic. The mining of cryptocurrencies is designed as a competitive game of chance and resources, with the winner obtaining a prize (a block reward or coins of the cryptocurrency). Mining is also a cooperative game where the game design incentivises miners to work together to ensure the game is not hijacked by ‘bad’ actors. For many in the domain, cryptocurrencies are just speculative gambles, the latest instalment of what Strange (1986) calls ‘casino capitalism’ but with a novel, digital twist. Thus, a particular branch of crypto, DeFi, can be understood as just a development of conventional finance, which is itself infused with algorithms from game theory and advanced mathematical techniques for optimising rewards in games of chance.</p>
            <p>But crypto is about more than play and games. Hence, in an earlier paper I proposed that cryptocurrencies, but Bitcoin in particular, exhibit many of the attributes associated with the medieval carnival (Kavanagh & Miscione, 2019). However, Bitcoin and Web3 are not the same, and so the issue I address in this essay is the degree to which Web3 exhibits the same attributes of the carnival. In short, is Web3 a form of carnival and to what extent can the metaphor help us understand the nature of Web3? In some ways, Web3 is even more of a carnival than Bitcoin in that a vast panoply of very different games exist on the various blockchains that underpin Web3. Unlike Bitcoin, which was designed with a specific, but limited purpose, the Ethereum blockchain was designed as a digital infrastructure into which one could ‘plug-and-play’ many types of activities, with smart contracts prescribing the ‘rules of the game’. This diversity of forms of play and games, reflecting independent creative output from many producers, mirrors the diversity of the traditional carnival with its multiplicity of games and activities. Moreover, just as one must pay money – often in the form of tokens – to play many of the carnival games, so too it is in Web3’s token economy.</p>
            <p>In the carnival, things are often not what they appear to be. It is a place of make-believe, misrepresentation, and dissimulation: what you see is not what you get. Identity is also disguised, which is why the mask is the carnival’s iconic image. Hiding identity has been a long-standing ambition in the crypto world – though less so in Web3 – going back to the cypherpunk movement of the 1980s, which was dedicated to building anonymous systems on the Internet, including anonymous digital money (Lopp, 2016). Fittingly, the identity of Satoshi Nakamoto, who wrote the original Bitcoin white paper, is still unknown to this day. More broadly, cryptographic algorithms are the tool of choice as they ensure anonymity while their own inner workings are axiomatically unknowable.</p>
            <p>But facilitating anonymity has opened up the crypto world to all sorts of hustles, scams, rug pulls and trickery, usually designed to prey on the newcomer’s desire make a quick dollar (Mackenzie, 2022). Trickery and dissimulation are consistent with the ethos of the carnival, but in the crypto world it manifests as outright criminality on a significant scale. The deceit and duplicity go back at least to the Mt Gox collapse in 2011, with the loss of 850,000 bitcoins, worth more than $450 million at the time, but scams, hacks, ‘pump and dump’ and Ponzi schemes have continued unrelentingly since then. In 2021, cryptocurrency-based crime hit an all-time high when illicit addresses received $14 billion, up from $7.8 billion in 2020 (Grauer et al., 2022, p. 3).</p>
            <p>Even the carnival cannot tolerate this level of crime, and so there has been a concerted effort to address the criminal opportunities inherent in a system that valorises anonymity. Hence, the cypherpunk obsession with privacy is much less central to Web3 than it is to Bitcoin, and instead significant work has been put into creating Sybil-resistant identity systems, decentralised proof-of-personhood and reputation systems and new initiatives such as non-transferable soul-bound tokens (Weyl et al., 2022). However, as Ford (2020) has argued, these systems of digital personhood will be inadequate unless they are also coercion-resistant, which would require creating digital versions of the privacy booths used in in-person voting. Creating such systems presents technical and security/privacy challenges that will almost certainly conflict with the cypherpunk/Bitcoin desire for anonymity. These initiatives, which are still very much in progress, suggest that the carnival metaphor needs to evolve along with the evolution of the crypto world from Bitcoin to Web3. One way of conceptualising this evolution is to say that Web3 has gentrified the Bitcoin carnival.</p>

            <div class="divider">
                <span class="divider-line"></span>
                <span class="divider-icon">&#10038;</span>
                <span class="divider-line"></span>
            </div>

            <p>In his seminal book on play, Homo Ludens, Huizinga (1949) argued that play was foundational to both festival (as in carnival) and ritual. Indeed for Huizinga, ‘there is no formal difference between play and ritual, [and] so the ‘consecrated spot’ cannot be formally distinguished from the play-ground’ (1949, p. 10). History supports this position as the carnival has always had a strong religious dimension – carnival was the Catholic festive period before the liturgical and fasting season of Lent. If Lent was a period of abstinence, commemorating the 40 days Jesus spent fasting in the desert, carnival was a period of indulgence, excessive consumption, public entertainment, social satire, grotesque bodily displays, vulgarity and a general reversal of social norms – the word carnival is derived from the Latin phrase carne levare, which means ‘remove meat’ . Today, we tend to be unaware of or ignore the carnival’s association with religion, consumption and fasting, but it can still be useful as we grapple with defining Web3.</p>
            <p>In an insightful paper, Faustino, Faria and Marques (2021) describe and analyse the quasi-religious nature of the crypto-community towards blockchain technologies, and they explore how myth, faith, and ritual work in this context to translate contemporary anxieties around the financial crisis and the centralisation of power. For them, the mysterious creator of Bitcoin, Satoshi Nakamoto, is analogous to the legendary King Arthur, in that ‘Nakamoto embodies the reputation of a noble altruist in the crypto-community, offering salvation from a crisis by introducing decentralisation as a new ruling power: a round table of equals rather than of a despotic sovereign’ (2021, p. 6). Thus, Nakamoto’s (2008) white paper has now been accorded the status of a ‘sacred text’ from which Bitcoin maximalists seem loath to deviate, though in practice they have accepted innovations in the Bitcoin ecosystem.</p>
            <p>The Irish author, Brendan Behan, once quipped that the first item on the agenda of any new Irish political party was ‘the Split’. And so it has been with crypto, with its forks in existing blockchains, new blockchains, Civil Wars, and new labels for communities within an ecosystem (for example, solarpunks and lunarpunks). Crypto is certainly full of variety, but at a high level it is worth contrasting the cult of Bitcoin and the cult of Ethereum. If Nakamoto is Bitcoin’s mythical quasi-religious leader – giving birth to Bitcoin through a cryptographic version of immaculate conception – Ethereum’s mythological equivalent must be Vitalik Buterin, who authored the original Ethereum white paper and has continued to play a Christ-like role in the Ethereum community. Tellingly, when the first popular DAO – The DAO – came unstuck due to an exaggerated belief in the rational authority of smart contracts, the decision to fork the blockchain was largely due to his intervention and charismatic authority (DuPont, 2017). On the darker side, Moloch, an ancient Carthaginian demon, has been repurposed within Web3 as the ‘demon’ responsible for those coordination failures that occur when individual incentives are misaligned with globally optimum outcomes (the prisoners’ dilemma and the tragedy of the commons are good examples). Ethereum, its disciples argue, provides the means, through DAOs, to slay this god of coordination failures, Moloch (Hoffman, 2020; Soleimani et al., 2019).</p>
            <p>As well as having a pantheon of quasi-religious deities, crypto cults also appropriate other religious practices, such as rituals, sacred texts, shared worldviews, ethics, and the like. Here, I will just focus on the religious aspects of the carnival, specifically the carnival’s association with excessive consumption as a counter to the abstinence of Lent. In the case of Bitcoin, the excess is most obviously manifest in its consensus mechanism’s obscene consumption of energy (its annualised consumption was estimated at 95TWh in September 2022, which is more than what the Philippines consumes (Cambridge Centre for Alternative Finance, 2022). That excess contrasts with the engineered scarcity of bitcoins, where the number of bitcoins is deliberately limited. In contrast, Web3’s political economy is defined by surplus – as an endless number of tokens can be produced and there are no material constraints – rather than the scarcity of traditional capitalism. Moreover, Ethereum’s move to proof-of-stake means that the excessive consumption associated with proof-of-work has now been eliminated. So, with Bitcoin tokens are scarce and energy consumption is excessive, while Web3’s trajectory is in the opposite direction: there is an excess of tokens, while energy consumption is minimal.</p>

            <div class="divider">
                <span class="divider-line"></span>
                <span class="divider-icon">&#10038;</span>
                <span class="divider-line"></span>
            </div>

            <p>The carnival metaphor sheds further light on how Web3 differs from Bitcoin in terms of its attitude to authority. The medieval carnival exhibited two elements related to power structures. First, the carnival created a space and time where all participants are considered equal, where hierarchy and status are suspended, and where there is free contact between those who might normally be separated by profession, property, caste or age. This produces a temporary social unity, like the unity of an enclave. We find this ideology of equivalence in both Bitcoin and Web3 as both systems are designed around the idea of bringing people together in a digital world of equality. Moreover, this pursuit of equality underpins the commitment to privacy that is a feature of both systems, even if that commitment is stronger in Bitcoin.</p>
            <p>The carnival’s second power dimension centres on how it enables and encourages authority to be contested. We see this in the inversions – such as a commoner playing the role of a king – which can be seen as the lower orders challenging the dominant social hierarchy (Bakhtin, 1968, p. 109). Similarly, the carnival enables forms of illegal behaviour – such as prostitution, violence, drunkenness, stealing – to be temporarily allowed, even expected. But the challenge to authority is not always benign, and carnivals can also be a potential locus for revolt. This mix of fun, creativity and rebellion is well-captured in this stanza from The Humours of Donnybrook Fair , a song about an annual fair held in Donnybrook, Dublin, founded by charter of King John in 2014:</p>

            <p class="displayquotation">
            To Donnybrook steer, all you sons of Parnassus<br />
            Poor painters, poor poets, poor newsmen, poor knaves<br />
            To see what the fun is that all fun surpasses<br />
            The sorrow and sadness of green Erin's slaves</p>

            <p>The song dates from at least the early nineteenth century as Croker (1839, pp. 184–9) includes it in his collection. Parnassus is a metaphor for arts and learning, while the mention of ‘green Erin’s slaves’ alludes to the long history of Irish uprisings – especially the rebellions of 1798 and 1803 – against English rule. Donnybrook Fair was always associated with ‘rough merriment’ and indeed the word ‘donnybrook’ has entered the dictionary as meaning ‘rowdy brawl’ or ‘scene of chaos’. Bitcoin instantiates this rebellious version of the carnival, as it feeds on the cryptopunk/crypto-anarchist ideology that explicitly seeks to subvert the existing social order, especially the power of the state and banks.</p>
            <p>The authorities' response to the carnival can be ambivalent. One approach is to licence social protest as a way of containing potential rebellion and confirming the existing social order (Burkert, 1985; Sales, 1983). Alternatively, the carnival can be heavily regulated or subdued. For instance, from the early nineteenth century, the evangelicals sought to end Donnybrook Fair, which they saw as an affront to moral rectitude. Along with the evangelists, the state, the police and the temperance crusaders worked to abolish the fair, but it took their combined forces some sixty years to succeed, as the annual fair continued up to 1868, even though for most of the nineteenth century the fair was, ‘woefully quiet, almost respectable’ (D’Arcy, 1988).</p>
            <p>The authorities have been similarly ponderous in their attempts at regulating Bitcoin. Some thirteen years after the first bitcoins were minted, the regulatory bodies are still only getting to grips with how to govern cryptoassets and cryptocurrency markets. Initially, the authorities largely ignored cryptocurrencies, taking the reasonable view that they don’t regulate private gambling or sales of the Linden dollar, the virtual currency used in Second Life. In recent years they have become more active in response to the rising value of cryptoassets and the proliferation of scams, rug-pulls and the like. However, a comprehensive regulatory regime is still not in place, partly because the domain is so novel and dynamic and because the regulators want to foster innovation and, at the same time, ensure the field is robust, fair and resilient.</p>
            <p>At the same time, and not unlike the ‘civilization’ of Donnybrook Fair, much of Web3 has now forgotten, ignored or been unaware of the anti-state ideology that animated Web3’s origins in Bitcoin, the cypherpunks and crypto-anarchists. This may be because, notwithstanding the rhetoric of equality, what we have in Web3, is a gentrifiedcarnival, devoid of Bitcoin’s rebellious anti-statism but retaining the carnival’s playfulness and creativity (Ennis 2017). There are still scams and illegal behaviour, but these are best understood as individual money-making rackets rather than a collective attempt to create a new social order (Campbell-Verduyn & Hütten 2019).</p>

            <div class="divider">
                <span class="divider-line"></span>
                <span class="divider-icon">&#10038;</span>
                <span class="divider-line"></span>
            </div>

            <p>While Bitcoin exhibits many of attributes of the medieval carnival – perceived equality, dissimulation, token-based games, play, ritual, mythology, excess consumption, subversion of authority – in the case of Web3 it seems better to see the carnival as gentrified, denuded of the excesses, criminality and anti-statism that characterise Bitcoin. And, while I have not conducted a detailed content analysis of Web3 and Bitcoin discourse, my sense is that Web3 is not characterised by the vulgar and crude language that, in our earlier paper, we found in the online chatter about bitcoin and which Bakthin identified as a special, generative form of communication associated with the carnival (Kavanagh & Miscione, 2019). Instead, Web3 appears to debate issues in a more genteel manner, befitting its concern with funding public goods and maintaining the ecosystem’s infrastructure.</p>
            <p>Another important feature missing from the cryptocarnival – in its Bitcoin and Web3 manifestations – is ilinx, one of Caillois’s (1961) four forms of play. Ilinx or vertigo, is play that consists ‘of an attempt to momentarily destroy the stability of perception and inflict a kind of voluptuous panic upon an otherwise lucid mind’ (1961, p. 23). In the carnival, ilinx occurs any time there is a rapid whirling or falling movement that might cause dizziness, such as in the various vertigo-inducing contraptions that feature in fairgrounds and amusement parks. But Web3 has no swings or merry-go-rounds, no ilinx. More broadly, Web3 is a virtual environment, separate from the physical world and devoid of ilinx. The physical world is sometimes referred to as ‘meatspace’, and perhaps there is nothing more meaty than the carnival, given that the word ‘carnival’ is derived from the Latin carnis, meaning ‘meat’. Web3 is thus beyond the realm of what Loïc Wacquant terms ‘carnal sociology’ or a ‘sociology of flesh and blood’, which, at its core, contains a carnal conception of the agent, at odds with the neo-Benthamite, utility maximising agent (homo economicus) and the neo-Kantian symbolic animal of cultural anthropology (Wacquant, 2015). The challenge, then, for Web3 is to, em, flesh out its carnival, engaging much more with ‘meatspace’, the physical world. Indeed, this is already happening as we see in the regen movement and in new initiatives such as Chainlink (<a href="http://chain.link">chain.link</a>) which seeks to expand the capabilities of smart contracts by enabling access to real-world data.</p>

            <div class="divider">
                <span class="divider-line"></span>
                <span class="divider-icon">&#10038;</span>
                <span class="divider-line"></span>
            </div>
            
            <p>In this short essay I have explored how the carnival metaphor might help us answer the question, ‘What is Web3?’ The metaphor is useful in that so much happens in the carnival, and we can certainly identify many elements of the carnival in Bitcoin. But the metaphor seems to work less well in Web3, or at least it might be better to see Web3 as a gentrification of the Bitcoin carnival, as there is much less concern about overthrowing the state or the banking system and more focus on the genteel practice of building public goods and a decentralised, resilient digital infrastructure. Or maybe even that is going too far, as Web3 lacks the giddiness, the meatiness, the smells, the vulgarity, the pains, the grotesque and the visceral experiences of the carnival. But then again, that might be just another layer of trickery: misrepresenting Web3 as a cryptocarnival when it is anything but.</p>
            <h1>References</h1>
            <p>Bakhtin, M. (1968). Rabelais and his World. Indiana University Press.</p>
            <p>Burkert, W. (1985). Greek religion: Archaic and Classical. Harvard University Press.</p>
            <p>Caillois, R. (1961). Man, play and games. Thames and Hudson.</p>
            <p>Cambridge Centre for Alternative Finance. (2022). Cambridge Bitcoin Electricity Consumption Index (CBECI) . Cambridge Bitcoin Electricity Consumption Index. <a href="https://ccaf.io/cbeci/index/comparisons">https://ccaf.io/cbeci/index/comparisons</a></p>
            <p>Campbell-Verduyn M. and Hütten M. (2019) Beyond scandal? Blockchain technologies and the fragile legitimacy of post-2008 finance. Finance & Society 5(2): 126–44.</p>
            <p>Croker, T. C. (1839). Popular Songs of Ireland. Colburn.</p>
            <p>Danaher, J. (2019). Automation and utopia: Human flourishing in a world without work . Harvard University Press.</p>
            <p>D’Arcy, F. A. (1988). The Decline and Fall of Donnybrook Fair: Moral Reform and Social Control in Nineteenth Century Dublin. Saothar, 13 , 7–21.</p>
            <p>Dowling, C. (2020). Bakhtin and the Free State in At Swim-Two-Birds. In R. Borg & P. Fagan (Eds.), Flann O’Brien: Gallows Humour (pp. 48–60). Cork University Press.</p>
            <p>DuPont, Q. (2017). Experiments in algorithmic governance: A history and ethnography of “The DAO,” a failed decentralized autonomous organization. In M. Campbell-Verduyn (Ed.), Bitcoin and beyond: Cryptocurrencies, blockchains, and global governance (pp. 157–177). Routledge.</p>
            <p>Ennis P.J. (2017) The Gentrification of ICOs Is Underway. CoinDesk. Available at: <a href="https://www.coindesk.com/markets/2017/11/19/the-gentrification-of-icos-is-underway/">https://www.coindesk.com/markets/2017/11/19/the-gentrification-of-icos-is-underway/</a> (accessed 15 November 2022).</p>
            <p>Entertainment Software Association. (2021). <a href="https://www.theesa.com/wp-content/uploads/2021/08/2021-Essential-Facts-About-the-Video-Game-Industry-1.pdf">2021 Essential Facts about the Video Game Industry</a>. Entertainment Software Association.</p>
            <p>Faustino, S., Faria, I., & Marques, R. (2021). <a href="https://doi.org/10.1080/17530350.2021.1921830">The myths and legends of king Satoshi and the knights of blockchain</a>. Journal of Cultural Economy, 15(1), 1–14.</p>
            <p>Ford, B. (2020, November). <a href="https://bford.info/pub/soc/personhood/">Identity and Personhood in Digital Democracy: Evaluating Inclusion, Equality, Security, and Privacy in Pseudonym Parties and Other Proofs of Personhood</a>. Bryan Ford’s Home Page.</p>
            <p>Grauer, K., Kueshner, W., & Updegrave, H. (2022). <a href="https://go.chainalysis.com/2022-Crypto-Crime-Report.html">The Chainalysis 2022 Crypto Crime Report</a>. Chainalysis.</p>
            <p>Hoffman, D. (2020, September 17). <a href="https://newsletter.banklesshq.com/p/ethereum-slayer-of-moloch-">Ethereum: Slayer of Moloch 🔪</a>. Bankless.</p>
            <p>Huizinga, J. (1949). Homo Ludens: A Study of the Play Element in Culture. Routledge & Kegan Paul.</p>
            <p>Kavanagh, D., & Miscione, G. (2019). <a href="https://doi.org/10.1080/01972243.2019.1647321">Carnival in the global village: Re-imagining information infrastructures</a>. The Information Society, 35(5), 299–313.</p>
            <p>Lopp, J. (2016). <a href="http://www.coindesk.com/the-rise-of-the-cypherpunk">Bitcoin and the Rise of the Cypherpunks</a>. Coindesk.</p>
            <p>Mackenzie, S. (2022). <a href="https://doi.org/10.1093/bjc/azab118">Criminology towards the metaverse: Cryptocurrency scams, grey economy and the technosocial</a>. The British Journal of Criminology, azab118.</p>
            <p>Nakamoto, S. (2008). Bitcoin: A peer-to-peer electronic cash system. Consulted, 1(2012), 1–28.</p>
            <p>Sales, R. (1983). English Literature in History 1780–1830: Pastoral and Politics. Hutchinson.</p>
            <p>Soleimani, A., Bhuptani, A., Young, J., Haber, L., & Sethuram, R. (2019). <a href="https://github.com/MolochVentures/Whitepaper/blob/aaf5afb64b7cbb8bfee1a70690cc10cda8758086/Whitepaper.pdf">The Moloch DAO: Beating the tragedy of the commons using Decentralized Autonomous Organizations.</a> White Paper, 15, 2021.</p>
            <p>Strange, S. (1986). Casino capitalism. Blackwell.</p>
            <p>Suits, B. (1978). The grasshopper: Games, life and Utopia (B7918978). Scottish Academic Press.</p>
            <p>US Bureau of Labor Statistics. (2022). <a href="https://www.bls.gov/news.release/union2.nr0.htm">Union Members Summary</a>.</p>
            <p>Wacquant, L. (2015). <a href="https://doi.org/10.1007/s11133-014-9291-y">For a Sociology of Flesh and Blood</a>. Qualitative Sociology, 38(1), 1–11.</p>
            <p>Weyl, E. G., Ohlhaver, P., & Buterin, V. (2022). <a href="https://papers.ssrn.com/abstract=4105763">Decentralized society: Finding Web3’s soul (SSRN Scholarly Paper No. 4105763)</a>. Social Science Research Network.</p>
            <p>Wood, L. (2022, March 10). $350+ Billion Worldwide Sports Industry to 2031. <a href="https://www.globenewswire.com/news-release/2022/03/10/2400658/28124/en/350-Billion-Worldwide-Sports-Industry-to-2031-Identify-Growth-Segments-for-Investment.html">GlobeNewswire News Room</a>.</p>



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default kavanagh;
