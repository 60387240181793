import image1 from "../../assets/images/homeV3/joker.png";
import image2 from "../../assets/images/icons/steps.png";
import image10 from "../../assets/images/icons/One.svg";
import image11 from "../../assets/images/icons/two.svg";
import image12 from "../../assets/images/icons/three.svg";
import image13 from "../../assets/images/icons/earthBg.svg";
import image14 from "../../assets/images/icons/plus.svg";
import image15 from "../../assets/images/icons/munis.svg";
import image16 from "../../assets/images/icons/steps2.png";
import image17 from "../../assets/images/icons/galaxy_image.png";
import image18 from "../../assets/images/logo.png";
import React, { useState, useRef } from "react";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function Home() {
  const [city, setCity] = useState("On_going");
  const openV3_ProjectPool = (city) => {
    setCity(city);
  };
  // console.log(city);
  return (
    <div>
      <PageLoaderCircle delay={1500} />

      <div id="sc-banner" className="sc_banner_v3 banner-bg position-relative">
        <div className="container">
          <div className="banner-content v3_banner_content">
            <div className="row">
              <div className="col-md-6">
                <div className="sc_banner_v3_left">
                  <h2
                    className="wow fadeInUp"
                    data-wow-delay="0.4s"
                    data-wow-duration="0.6s"
                  >
                    <span>CRYPTOCARNIVAL</span>
                  </h2>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay="0.5s"
                    data-wow-duration="0.7s"
                  >
                    Crypto<span className="flipper flip_V">Carnival</span> is a special collection of 18 essays. Written at the height of the Web3 boom, these essays from top international scholars reflect on the carnivalesque nature of Web3 in 2022, and its subsequent bust in 2023. Additionally, you can connect your Web3 wallet to play a <a href="/dice">dice game</a> or <a href="/AirdropNFT">mint an NFT</a> to
                    enhance your reading experience. This is an experimental Web3 preprint platform. Read the published essays in <a href="https://www.emerald.com/insight/publication/doi/10.1108/S0733-558X202489">Research in the Sociology of Organizations (Emerald, 2024)</a>. Enjoy the CryptoCarnival.
                  </p>
                  <div className="gamfi_v2_hero_btns wow fadeInUp">
                    <a
                      href="#essays"
                      className="readon white-btn hover-shape view-project-btn"
                    >
                      <span className="btn-text">READ ESSAYS</span>
                      <span className="hover-shape1"></span>
                      <span className="hover-shape2"></span>
                      <span className="hover-shape3"></span>
                    </a>
                    <a
                      className="readon black-shape apply-project-btn"
                      href="/dice"
                    >
                      <span className="btn-text">WIN CRYPTO</span>
                      <span className="hover-shape1"></span>
                      <span className="hover-shape1"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sc_banner_v3_right">
                  <div className="animetion_avater">
                    <img src={image1} alt="img" className="img-fluid" />
                  </div>
                  <ul>
                    <li>
                      <h3>Word count</h3>
                      <h4 className="counter-number">
                        <span className="counter">79,000</span>{" "}
                      </h4>
                    </li>
                    <li>
                      <h3>Tokens Minted</h3>
                      <h4 className="counter-number">
                        <span className="counter">350,000,000</span>{" "}
                      </h4>
                    </li>
                    <li className="m-0">
                      <h3>Funding Raised</h3>
                      <h4 className="counter-number">
                        <span className="counter">1.1</span> eth
                      </h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="essays" className="gamfi-previous-section project_pools_v3 pb-90 md-pb-50">
        <div className="container">
          <div className="sec-inner align-items-center d-flex justify-content-between  mb-50">
            <div className="sec-heading">
              <div className="sub-inner mb-15">
                <span className="sub-title">EXPLORE</span>
                <img
                  className="heading-left-image"
                  src={image2}
                  alt="Steps-Image"
                />
              </div>
              <h2 className="title mb-0 xs-pb-20">Essays</h2>
            </div>
            <div className="gamfi-btn-area">
              <ul className="gamfi_v3_project_pools_btn">
                <li
                  className="v3_project_pools_tablinks m-0"
                  onClick={() => openV3_ProjectPool("On_going")}
                  id="V3_ProjectPool_Tab_defaultOpen"
                >
                  <button className="view-more black-shape">
                    <span className="btn-text">1: Big Tent</span>
                    <span className="hover-shape1"></span>
                    <span className="hover-shape2"></span>
                    <span className="hover-shape3"></span>
                  </button>
                </li>
                <li
                  className="v3_project_pools_tablinks"
                  onClick={() => openV3_ProjectPool("UPCOMING")}
                >
                  <button className="view-more black-shape">
                    <span className="btn-text">2: Vaudeville</span>
                    <span className="hover-shape1"></span>
                    <span className="hover-shape2"></span>
                    <span className="hover-shape3"></span>
                  </button>
                </li>
                <li
                  className="v3_project_pools_tablinks"
                  onClick={() => openV3_ProjectPool("ENDED")}
                >
                  <button className="view-more black-shape">
                    <span className="btn-text">3: Dare Devils</span>
                    <span className="hover-shape1"></span>
                    <span className="hover-shape2"></span>
                    <span className="hover-shape3"></span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="v3_project_pools_tab_content_sect">
              {city === "On_going" && (
                <div
                  id="On_going"
                  className="v3_project_pools_tab_content animate_opacity"
                >
                  <div className="col-md-12">
                    <div className="previous-mainmenu mb-15">
                      <ul className="menu-list">
                        <li className="list1">Title</li>
                        <li className="list3">Reading Time</li>
                        <li className="list4">Total Words</li>
                        <li className="list5">Tokens</li>
                      </ul>
                    </div>
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                              <a href="/introduction">Introduction</a></h4>
                            <div className="dsc">
                              Quinn DuPont, Donncha Kavanagh &amp; Paul Dylan-Ennis
                            </div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089001">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/278d5a44-7107-453c-91d5-c25d60657ebf">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>42 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>5,400</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="40"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "40%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                            <a href="/schneider">Web3 Is the Opportunity We Have Had All Along: Innovation Amnesia and Economic Democracy</a>
                            </h4>
                            <div className="dsc">Nathan Schneider</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089002">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/51dc7819-880c-4e41-a172-236e7088df22">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>25 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>3,300</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="89"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "89%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                            <a href="/nabben">How to Infrastructure: Entering the Field of ‘Web3.0’</a>
                            </h4>
                            <div className="dsc">Kelsie Nabben</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089003">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/c6d30667-7c51-48a2-9c33-2552ce8a5460">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>37 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>4,800</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="73"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "73%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                            <a href="/resch">Business without firms. A planetary design language for DAOs</a>
                            </h4>
                            <div className="dsc">Bernhard Resch</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089004">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/43f6ead3-154f-4af3-b054-61f3bf83e277">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>34 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>4,400</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="60"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "60%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                              <a href="/dupont">Progressive Web3: From Digital Polycentric
                              Governance to Social Coproduction</a>
                            </h4>
                            <div className="dsc">Quinn DuPont</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089005">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/f528db25-7ff0-4fed-a99c-65402442044e">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>53 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>6,900</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="53"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "53%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div
                    className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                              <a href="/merk">Institutional isomorphism in Web3: same, same but
                              different?</a>
                            </h4>
                            <div className="dsc">Tara Merk and Rolf Hoefer</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089006">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/3a5abd89-3484-46ca-8ae4-6aec6e10e7c3">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>32 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>4,200</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="58"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "58%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div
                    className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                             <a href="dylanennis">Hash, Bash, Cash: How Change Happens in
                              Decentralised Web3 Cultures</a>
                            </h4>
                            <div className="dsc">Paul Dylan-Ennis</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089007">Published version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>21 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>2,800</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="77"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "77%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                </div>
              )}
              {city === "UPCOMING" && (
                <div
                  id="UPCOMING"
                  className="v3_project_pools_tab_content animate_opacity"
                >
                  <div className="col-md-12">
                    <div className="previous-mainmenu mb-15">
                      <ul className="menu-list">
                        <li className="list1">Title</li>
                        <li className="list3">Reading Time</li>
                        <li className="list4">Total Words</li>
                        <li className="list5">Tokens</li>
                      </ul>
                    </div>

                    <div className="col-md-12">
                      <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                              <a href="lovink">The Political Economy of the Crypto-Art Craze</a>
                            </h4>
                            <div className="dsc">Geert Lovink</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089008">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/e0ac46c0-f7a6-4e6f-9ee4-6ce7ba23d30e">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>24 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>3,100</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="99"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "99%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                      </a>
                    </div>

                  </div>


                  <div
                    className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                              <a href="/alston">When Digital Carnival? Distributed Control of the
                              Metaverse Asset Layer as Enabling Creative Digital
                              Expression to Flourish</a>
                            </h4>
                            <div className="dsc">Eric Alston</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089009">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/bdb6856c-1ef5-4182-9a4f-e987226181d3">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>23 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>3,100</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="61"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "61%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                              <a href="/vergne">Web3 as Decentralization Theater?</a>
                            </h4>
                            <div className="dsc">JP Vergne</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089010">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/aef6a7d1-27e0-49e0-b415-db4b373aa3b2">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>18 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>4,300</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="71"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "71%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>


                  <div className="col-md-12">
                    <a href="/">
                    <div className="previous-item hover-shape-border hover-shape-inner">
                          <div className="previous-gaming">
                            <div className="previous-price">
                              <h4 className="mb-10">
                               <a href="/defilippi">The Rise of Blockchain Egregores</a>
                              </h4>
                              <div className="dsc">
                                Primavera de Filippi, Morshed Mannan, and
                                Wessel Reijers
                              </div>
                              <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089011">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/2dc21838-79a3-4398-b7eb-9a7b0ddda800">Preprint self-archived version</a>
                            </div>
                            </div>
                          </div>
                          <div className="previous-chaining">
                            <span>27 minutes</span>
                          </div>
                          <div className="previous-raise">
                            <span>3,500</span>
                            <div className="progress-inner">
                              <div className="progress">
                                <div
                                  className="progress-bar progress-bar-striped"
                                  role="progressbar"
                                  aria-valuenow="88"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: "88%" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <span className="border-shadow shadow-1"></span>
                          <span className="border-shadow shadow-2"></span>
                          <span className="border-shadow shadow-3"></span>
                          <span className="border-shadow shadow-4"></span>
                          <span className="hover-shape-bg hover_shape1"></span>
                          <span className="hover-shape-bg hover_shape2"></span>
                          <span className="hover-shape-bg hover_shape3"></span>
                        </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                              <a href="/sereda">Crypto Personalities as Carnivalesque Jesters</a>
                            </h4>
                            <div className="dsc">Alesha Sereda</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089012">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/c17d93ec-ef96-4045-902e-8a55ba1bd0d8">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>33 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>4,300</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="48"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "48%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                            <a href="/kavanagh">Web3: The Gentrified Carnival?</a>
                            </h4>
                            <div className="dsc">Donncha Kavanagh</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089013">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/2d51bd00-42ef-462d-b7f2-9563061cefe3">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>24 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>3,100</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="46"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "46%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                </div>
              )}
              {city === "ENDED" && (
                <div
                  id="ENDED"
                  className="v3_project_pools_tab_content animate_opacity"
                >
                  <div className="col-md-12">
                    <div className="previous-mainmenu mb-15">
                      <ul className="menu-list">
                        <li className="list1">Title</li>
                        <li className="list3">Reading Time</li>
                        <li className="list4">Total Words</li>
                        <li className="list5">Tokens</li>
                      </ul>
                    </div>

                    <div className="col-md-12">
                      <a href="/">
                        <div className="previous-item hover-shape-border hover-shape-inner">
                          <div className="previous-gaming">
                            <div className="previous-price">
                              <h4 className="mb-10"><a href="/faustino">The Gambler</a>
                              </h4>
                              <div className="dsc">Sandra Faustino</div>
                              <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089014">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/03ba73d3-3a42-4578-b6d6-5f6d5389e2d2">Preprint self-archived version</a>
                            </div>
                            </div>
                          </div>
                          <div className="previous-chaining">
                            <span>6 minutes</span>
                          </div>
                          <div className="previous-raise">
                            <span>1,400</span>
                            <div className="progress-inner">
                              <div className="progress">
                                <div
                                  className="progress-bar progress-bar-striped"
                                  role="progressbar"
                                  aria-valuenow="95"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: "95%" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <span className="border-shadow shadow-1"></span>
                          <span className="border-shadow shadow-2"></span>
                          <span className="border-shadow shadow-3"></span>
                          <span className="border-shadow shadow-4"></span>
                          <span className="hover-shape-bg hover_shape1"></span>
                          <span className="hover-shape-bg hover_shape2"></span>
                          <span className="hover-shape-bg hover_shape3"></span>
                        </div>
                      </a>
                  </div>

                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                            <a href="/potts">Web3 & The amazing computable economy</a>
                            </h4>
                            <div className="dsc">Jason Potts</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089015">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/252a9397-8cb9-482c-8cb5-b7329068db8b">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>31 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>4,100</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="37"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "37%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                              <a href="/lemieux">Blockchain and Web3: Mirrors, “Jouissance” and
                              Social & Personal Identity Formation</a>
                            </h4>
                            <div className="dsc">Victoria Lemieux</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089017">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/481e4e4d-93c4-43a5-9a18-a2f2bf83b853">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>27 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>3,500</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="84"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "84%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10">
                            <a href="/arjalies">Trying to sell the Crow Queen in Web3: On the resistance of video gamers to cryptocurrencies, NFTs and their financial logic</a>
                            </h4>
                            <div className="dsc">Diane-Laure Arjaliès and Samuel Compain-Eglin</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089016">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/2f66fbe0-da50-409d-9422-8b634ecd5a81">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>47 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>6,200</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="84"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "58%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                    <a href="/">
                      <div className="previous-item hover-shape-border hover-shape-inner">
                        <div className="previous-gaming">
                          <div className="previous-price">
                            <h4 className="mb-10"><a href="/maurer">Blow That Mausoleum Down</a>

                            </h4>
                            <div className="dsc">Bill Maurer</div>
                            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089018">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/cb589cdf-d081-4722-9a29-4492dda46db0">Preprint self-archived version</a>
                            </div>
                          </div>
                        </div>
                        <div className="previous-chaining">
                          <span>8 minutes</span>
                        </div>
                        <div className="previous-raise">
                          <span>2,000</span>
                          <div className="progress-inner">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped"
                                role="progressbar"
                                aria-valuenow="89"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "69%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-12">
                      <a href="/">
                        <div className="previous-item hover-shape-border hover-shape-inner">
                          <div className="previous-gaming">
                            <div className="previous-price">
                              <h4 className="mb-10">
                               <a href="/brunton">Immediate Gratuitousness, Squandered Energy, Useless Machines</a>
                              </h4>
                              <div className="dsc">Finn Brunton</div>
                              <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089019">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/7d292f31-66ee-44cf-b9d1-5c00fb202488">Preprint self-archived version</a>
                            </div>
                            </div>
                          </div>
                          <div className="previous-chaining">
                            <span>31 minutes</span>
                          </div>
                          <div className="previous-raise">
                            <span>4,000</span>
                            <div className="progress-inner">
                              <div className="progress">
                                <div
                                  className="progress-bar progress-bar-striped"
                                  role="progressbar"
                                  aria-valuenow="84"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: "84%" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <span className="border-shadow shadow-1"></span>
                          <span className="border-shadow shadow-2"></span>
                          <span className="border-shadow shadow-3"></span>
                          <span className="border-shadow shadow-4"></span>
                          <span className="hover-shape-bg hover_shape1"></span>
                          <span className="hover-shape-bg hover_shape2"></span>
                          <span className="hover-shape-bg hover_shape3"></span>
                        </div>
                      </a>
                    </div>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="how_to_participate_sect">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 wow fadeInLeft"
              data-wow-delay="0.3s"
              data-wow-duration="0.4s"
            >
              <div className="how_to_participate_left">
                <div className="sec-heading">
                  <div className="sub-inner mb-15">
                    <span className="sub-title">3 EASY STEPS</span>
                    <img
                      className="heading-left-image"
                      src={image2}
                      alt="Steps-Image"
                    />
                  </div>
                  <h2 className="title">How to Play Games</h2>
                </div>
                <div className="how_to_participate_left_content">
                  <p>
                    "Al-a-ga-zam. Here's a <a href="/faucet">ducat</a>&mdash;we
                    got plaster inside."
                  </p>
                  <div className="how_to_participate_left_btn">
                    <a className="view-more black-shape" href="/contest">
                      <span className="btn-text">Play games</span>
                      <i className="icon-arrow_right"></i>
                      <span className="hover-shape1"></span>
                      <span className="hover-shape2"></span>
                      <span className="hover-shape3"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="how_to_participate_right_content">
                <div
                  className="how_to_participate_right_card how_to_participate_right_card1 wow fadeInRight"
                  data-wow-delay="0.2s"
                  data-wow-duration="0.4s"
                >
                  <div className="how_to_participate_right_card_number">
                    <img src={image10} alt="img" className="img-fluid" />
                  </div>
                  <div className="how_to_participate_right_card_text">
                    <h4>Connect Wallet</h4>
                    <p class="displaytext">
                      Currently, we only support "injectable" wallets like{" "}
                      <a href="https://metamask.io/">Metamask</a> and{" "}
                      <a href="https://www.core.app/">Core</a>.
                    </p>
                  </div>
                </div>
                <div
                  className="how_to_participate_right_card how_to_participate_right_card2 wow fadeInRight"
                  data-wow-delay="0.3s"
                  data-wow-duration="0.5s"
                >
                  <div className="how_to_participate_right_card_number">
                    <img src={image11} alt="img" className="img-fluid" />
                  </div>
                  <div className="how_to_participate_right_card_text">
                    <h4>Get Tokens</h4>
                    <p class="displaytext">
                      Visit <a href="/faucet">our faucet</a> to receive your
                      daily allocation of free tokens.
                    </p>
                  </div>
                </div>
                <div
                  className="how_to_participate_right_card how_to_participate_right_card3 wow fadeInRight"
                  data-wow-delay="0.4s"
                  data-wow-duration="0.6s"
                >
                  <div className="how_to_participate_right_card_number">
                    <img src={image12} alt="img" className="img-fluid" />
                  </div>
                  <div className="how_to_participate_right_card_text">
                    <h4>Start Playing</h4>
                    <p class="displaytext">
                      You can <a href="/dice">play dice</a>, <a href="/nftairdrop">mint an NFT</a>,
                      or read a collected edition of essays.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="moon_img_bg">
          <img src={image13} alt="img" className="img-fluid" />
        </div>
      </div>
      <div className="gamfi-team-section gamfi_faq_sect gamfi_our_mentor_section pt-115 pb-85 md-pt-75 md-pb-42">
        <div className="container">
          <div className="sec-heading text-center mb-55">
            <div className="sub-inner  mb-15">
              <img
                className="heading-left-image"
                src={image16}
                alt="Steps-Image"
              />
              <span className="ml-10 mr-10 sub-title">QUESTIONS & ANSWERS</span>
              <img
                className="heading-right-image"
                src={image2}
                alt="Steps-Image"
              />
            </div>
            <h2 className="title">FREQUENTLY ASKED QUESTIONS</h2>
          </div>
          <div className="faq_content_sect">
            <div className="faq_questions">
              <h2 id="whatiscryptocarnival" className="accordion no-border">
                What is Cryptocarnival?
                <span>
                  <img src={image14} alt="" className="img-fluid icon-plus" />
                  <img src={image15} alt="" className="Img-fluid icon-minus" />
                </span>
              </h2>
              <div className="accordion-panel">
                <p>
                  Cryptocarnival is a collection of 18 essays that reflect on,
                  interrogate, and define Web3 in 2022. Quinn DuPont, Donncha Kavanagh, and Paul
                  Dylan-Ennis, are the editors. Preprints
                  can be read online at <a href="/">cryptocarnival.wtf</a> or in a dead-tree version published in the <a href="https://www.emerald.com/insight/publication/doi/10.1108/S0733-558X202489">Research in the Sociology of Organizations (ROSO) with Emerald</a>. Additionally, <a href="/faucet">tokens</a>, an on chain <a href="/dice">dice</a> game and a <a href="/nftairdrop">free NFT</a> can be received as a carnivalesque diversion.
                </p>
              </div>

              <h2 className="accordion">
                How do I invest in Cryptocarnival?
                <span>
                  <img src={image14} alt="" className="img-fluid icon-plus" />
                  <img src={image15} alt="" className="Img-fluid icon-minus" />
                </span>
              </h2>
              <div className="accordion-panel">
                <p>
                  Cryptocarnival is an experiment in web3 public goods funding.
                  We raised funds through a quadratic crowdfund mechanism on{" "}
                  <a href="https://gitcoin.co/grants/4719/defining-web3-an-off-chain-collective-research-pr">
                    gitcoin
                  </a>. These funds were used to design the Web3 website and to hire a software developer. You can receive free tokens to play
                  games, but you cannot buy or sell tokens and you cannot invest in the Cryptocarnival project.
                </p>
              </div>

              <h2 className="accordion">
                Who made Cryptocarnival?
                <span>
                  <img src={image14} alt="" className="img-fluid icon-plus" />
                  <img src={image15} alt="" className="Img-fluid icon-minus" />
                </span>
              </h2>
              <div className="accordion-panel">
                <p>
                  Cryptocarnival was conceived by the editors, Quinn DuPont, Donncha Kavanagh and
                  Paul Dylan-Ennis. The editors worked
                  closely with authors, who wrote essays reflecting on the
                  carnivalesque nature of crypto in 2022. Quinn DuPont designed
                  and built the website (cryptocarnival.wtf) and the editors prepared the
                  manuscript for <a href="https://www.emerald.com/insight/publication/doi/10.1108/S0733-558X202489">publication</a>; see the <a href="/about">colophon</a> for further details.
                </p>
              </div>

              <h2 id="tokenInfo" className="accordion">
                How do I buy and sell Cryptocarnival tokens?
                <span>
                  <img src={image14} alt="" className="img-fluid icon-plus" />
                  <img src={image15} alt="" className="Img-fluid icon-minus" />
                </span>
              </h2>
              <div className="accordion-panel">
                <p>
                  You cannot buy or sell cryptocarnival tokens. They are used
                  solely for access to the games on the <a href="/">cryptocarnival.wtf</a> website.
                </p>
              </div>

              <h2 className="accordion">
                How do I play Cryptocarnival games?
                <span>
                  <img src={image14} alt="" className="img-fluid icon-plus" />
                  <img src={image15} alt="" className="Img-fluid icon-minus" />
                </span>
              </h2>
              <div className="accordion-panel">
                <p>
                  You can play games on the <a href="/">cryptocarnival.wtf</a>{" "}
                  website. You will need a suitable Web3 wallet for your daily
                  allocation of tokens (available from our <a href="/faucet">faucet</a>). Once your wallet is connected
                  and authenticated with our website, you can use your free CCWTF
                  tokens to play a <a href="/dice">dice</a> game or mint a <a href="/nftairdrop">free NFT</a>.
                </p>
              </div>

              <h2 className="accordion">
                What is the Cryptocarnival contest?
                <span>
                  <img src={image14} alt="" className="img-fluid icon-plus" />
                  <img src={image15} alt="" className="Img-fluid icon-minus" />
                </span>
              </h2>
              <div className="accordion-panel">
                <p>
                  Details about the Web3 game contest, including how to enter and win prizes, are <a href="/contest">available here</a>.
                </p>
              </div>

              <h2 id="termsService" className="accordion">
                What about privacy and terms of service?
                <span>
                  <img src={image14} alt="" className="img-fluid icon-plus" />
                  <img src={image15} alt="" className="Img-fluid icon-minus" />
                </span>
              </h2>
              <div className="accordion-panel">
                <p>
                  This website is for intended for educational purposes and is
                  made available to the user "as is." Like everything in crypto,
                  the user assumes full responsibility for their actions. Caveat
                  emptor.
                </p>
              </div>
            </div>
            <h6>
              Want to know more ? <a href="about">Contact the editors !</a>
            </h6>
            <div className="FAQGalaxyImg">
              <img src={image17} alt="img" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>



            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
  );
}

export default Home;
