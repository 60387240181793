import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function brunton() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Immediate Gratuitousness</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Finn Brunton
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089019">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/7d292f31-66ee-44cf-b9d1-5c00fb202488">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            “Immediate Gratuitousness” puts cryptocurrency and its sister industries into a history of performances of extravagance, daring, and waste. My assertion is that the people to read at this point in the development of crypto are not Mazzucato or Galbraith, Minsky or Perez (or Hayek and Mises), but Antonin Artaud, playwright of the theater of self-destruction and gratuitous gestures. This account of crypto situates it in a context of value produced through performance and ruinous waste, from burning a million British pounds on the Isle of Jura in 1994 to the production of proof-of-burn minting mechanisms, and explains how to make sense of our carnivalesque moment through the logic of the extravagantly destructive.
            </p>
            <hr />
            <p class="displayquotation">
            “They kept fighting among themselves, picking up anything that could be used as a weapon, snatching each other’s spoils, taking possession of all they could lay hands on, even the most useless objects, then dropping them and running after some other plunder. What they could not take away they destroyed.” ~ Anna Kavan, <em>Ice</em>.
            </p>

            <h1>Artaud’s Carnival</h1>
            <p>Antonin Artaud — playwright, director, actor, writer, founder of the Theater of Cruelty, magus and sorcerer, semi-pro madman — had a profound insight into the gratuitous.</p>
            <p>The term has a double meaning, positive and negative, that’s shared in French and English alike. It is free, or given freely: gratuitous assistance, for which no money changes hands. This is the basis of calling a tip a gratuity because the tip is not part of the payment. You give it freely. This positive meaning of “gratuitous” has fallen into disuse outside of a few specialist communities like free and open-source software, who make precise distinctions between libre and gratis. (Libre is free in a more substantive sense, the sense of rights; gratis is merely free of charge [Suber, 2008].)</p>
            <p>Gratuitous is now more commonly a pejorative, reflecting its second meaning: unwarranted, done without good reason, sans raison, as one says of a movie having gratuitous violence or gratuitous nudity. We can describe a friend’s overreacting freakout as “so gratuitous.” In this way the meaning has begun to colloquially shift from something unjustified to something excessive.</p>
            <p>Searching for theater and performance in their purest forms, Artaud looked to the gratuitous in both meanings. He studied actions that escaped the conventional systems of utility and value, without any purpose beyond themselves, devoid of useful function, because they were the places where pure theatrical presentation could begin. Therefore, he studied people and events in which the civilizational order was overturned or pulled inside-out and became unwarranted and excessive.</p>
            <p>This may sound like the carnivalesque. Indeed, Artaud’s areas of theatrical interest share the key dynamics of the carnival: the profaning of the sacred, the collapse or inversion of hierarchies, the melding of traditionally distinct things, the expression of latent impulses. Artaud's carnival, though, went much further in the work of collapse and inversion.</p>
            <p>Not the language of the carnival, in Mikhail Bakhtin’s terms, earthy and joyfully rude; but the failure of language into glossolalia, screaming, muttering, vacuous statements, silence. Not the celebration of the body, food, sexuality; Artaud was interested in deliberate self-starvation, in castration and needless cruelty, incest, the body as a vector for viruses and black magic. Not the inversion of values, making the low high and the high low; instead, the collapse of all existing values in frenzy, squandering, waste, and ruin. Finally, not sacrifice but destruction, and with the destruction Artaud's perennial curtain-whisking big reveal: that there was nothing behind the gratuitous action — the acte gratuit — at all. It had been stupid and meaningless.</p>
            <p>On April 6, 1933, Artaud delivered a public lecture called “Theater and the Plague” (1958). Famously, this lecture was his kind of trainwreck, gradually shifting from informational content into acting out his onstage death from la peste (Nin, 1966 pp. 191-192). He began sweating and cramping as he talked, shaking, struggling to breathe and hold on to his papers. Amid excessively — gratuitously — detailed body-horror bubonic plague effects, he described the social process of an outbreak in Marseilles in the 18th century. He sketched the breakdown of civic order, the funeral pyres, the costumes of the doctors, the aristocratic retreat to fortified positions (where the Masque of the Red Death awaits the courtiers). The most important moment of all, for Artaud and for us in this chapter, happened amidst this tumult.</p>
            <p>“The dregs of the population,” he said, “apparently immunized by their frenzied greed, enter the open houses and pillage riches they know will serve no purpose or profit. And at that moment the theater is born. The theater, an immediate gratuitousness provoking acts without use or profit” (24).</p>
            <p>Draping yourself in the robes of a defunct extravagance, and seizing fistfuls of coins with no future and no one with whom to exchange, which you will not likely live to spend: this is, for Artaud, the purest theatrical gesture of all. At the intersection of plague, frenzy, and money, performance is born.</p>
            <h1>YOLO Ideology</h1>
            <p>“People live for the morrow, because the day-after-tomorrow is doubtful. All our road is slippery and dangerous, while the ice which still bears us has grown unconscionably thin: we all feel the mild and gruesome breath of the thaw-wind.” ~ Nietzsche, “Modern Gloominess”</p>
            <p>During the plague years of COVID-19, quarantined and isolated, the normies got crypto.</p>
            <p>Celebrity spokesmen pointed out what an emasculated loser you were for not putting your cash into cryptocurrency and its adjacent financial instruments. Credulous news content implied that this eccentric alpha-rollout technology was home to the real investment action, and belonged in the savvy portfolio. Civic buildings were renamed. Stunts proliferated. The mayor of New York City took paychecks in Bitcoin. The President of El Salvador commissioned architectural models of a gleaming, golden, Bitcoin-themed city.</p>
            <p>Artists found themselves in the non-fungible token business, a party-scene gold rush that worked like any one of a thousand Conceptual Art pieces from the 1970s. It could have been typed on an index card and pinned the gallery wall: you own the exclusive rights to a claim of ownership over an image of which countless perfect copies exist — but this one is yours, in a complex, esoteric, de jure fashion. “Web3” became a terminological fundraising tool, slapped on Twitter bios and slide decks, with the science fictional properties of the monster in The Blob, or the “grey goo” scenario in nanotechnology. Anything web3 touched dissolved and became a part of it, absorbed into its scenario — video games, VR, the vaporous Metaverse, social networks, email, gig labor.</p>
            <p>The enumeration of capital lost all meaning, in tandem with another explosively inflated bubble in tech stocks. Values of many millions, billions, tens of billions, hundreds of billions, were announced for tokens which were virtually never exchanged, for platforms on which almost no transactions took place, for systems which had no use case or killer app. These tokens, platforms, and systems in turn became the basis of millions, hundreds of millions, and billions “under management” by various funds and firms. Their operations had a recursive, self-referential meta-fraudulence. They would issue financial instruments backed by the assets of other firms — firms which, themselves, were in turn backed by the assets of the very firms they were backing, like Baron Munchausen pulling himself up out of the ocean by his own topknot. Everyone had improbably leveraged loans out to everyone else, a chain of dependencies in which each link had its own unique weaknesses.</p>
            <p>CEOs hustling investment cash traveled the world under “the flag of technology,” as venture capital culture-warrior Balaji Srinivasan identified the Bitcoin logo in a social media bio (Srinivasan, 2019). Some of these travelers were bitter Bitcoin maximalist holdouts, fuming about all the gatecrashers for whom this was a party rather than the righteous financial apocalypse: a revelation in which all the books would be opened, all insolvencies revealed, all debts settled forever in a deflationary Big Crunch. As grimly carnivorous prepper survivalists — “Bitcoin carnivores” are a thing, eating only beef and holding only BTC (Pearson, 2017) — this crew was not amused by new products of their milieu: the NFT hipsters, the comically insecure trading platforms, the hundreds of pumped-and-dumped tokens, the DAOs.</p>
            <p>This cohort and their predecessors were the primary subjects of my book Digital Cash(2019) . They were ideologically committed, with their roots in American libertarian political philosophy, Austrian economics, and a science fictional sensibility and philosophy of history. They had shared interests in signature libertarian projects like precious metal currencies, micronational, offshore, and seasteading schemes, private spaceflight companies, drug legalization, anti-surveillance technology, and access to firearms and weapons platforms. They had every right to feel perturbed by the lack of conviction on the part of the wave of late adopters taking up their technology — and the subsequent tsunami of rubes, suckers, and personal investors that the new crew onboarded.</p>
            <p>Bitcoin, and some of the major cryptos that spun off in its wake, is the most popular culturally libertarian (as opposed to neoliberal) project in history. Yet, as it grew, it shed its original agenda and model of history, those ideological structures early Bitcoin wore like a mech suit for surviving the collapse of statist civilization. The original technology was not meant to be a fun, tech-hobbyist speculation, part of a high-risk portfolio. Nor was it to be a mere payment system, like a debit card but worse — painfully slow, requiring considerable technical expertise to set up and use, unlikely to be available in any normie context, monstrously energy-hungry, and productive of an eternal, public transactional record.</p>
            <p>Instead, it was supposed to be the global reserve currency for a new emergent order. It was supposed to witness the default and demolition of the Federal Reserve Bank of the United States, of the European Central Bank, of the People's Bank of China; the fall of Wall Street, of the City of London, of the International Monetary Fund; and the abolition of every taxation and financial regulatory body. This was a technology investment in which the technology and the investment were one and the same and made the same bet. The bet was not on business cycles, disruptive innovation, or the tendency of the rate of profit to fall, but on a model of history. It was a bet on the inevitability of debasement, inflation, and failure of territorial currencies, the end of state intervention in markets, and the reordering of the global neoliberal consensus. In the titles of two prescient and influential libertarian investment books sharing this theory of history, it was the anticipation of Blood in the Streets (Davidson and Rees-Mogg, 1987) and the coming of The Sovereign Individual (Davidson and Rees-Mogg, 1997).</p>
            <p>The precedent for this monetary project was not some quant savant running a hedge fund with a new portfolio optimization algorithm, but something much older: the small and hardened crew of ideologically driven gold and silver commodity investors. Long before crypto, they had been sitting on their hoard, buying the dip, holding on, and waiting for “fiat currency” (their term) to crack. Not only before crypto, but before PayPal, they had launched successful digital gold currency (DGC) payment platforms – so successful that they were shut down for their abuse by money laundering operations (Mullan, 2016). This position is still present deep in parts of cryptocurrency and blockchain technology and culture, and it can explain some puzzling things about how they work, but it has been elided and in some cases wholly replaced in the technology's popular reception and adoption.</p>
            <p>I call what has replaced it YOLO ideology. What distinguishes YOLO ideology is that its model of history is vacant, and its future is opaque. The past does not have any meaningful data for us. What happens now makes no sense in terms of prior history; we are in uncharted waters. The future is too volatile to be predicted — or even hedged against. YOLO operates in a perpetual and absolute present.</p>
            <p>It offers not the joyous upset of the carnival, where the commoner can be crowned king of the day, but something more: an expectation of permanent upset. YOLO ideology therefore emphasizes action, the deed, as pure performance between a past emptied of significance and a future horizon that's only a few weeks out at best. Fittingly, it's a term for performance itself: a credo that's also a verb. You Only Live Once is something you can do, by “YOLOing” your next month’s rent into some random crypto. “It's either a Lambo or food stamps,” as the Wall Street Bets meme had it — “and I guess it's food stamps,” followed up one YOLOer as their rent money evaporated (Deeps, 2021).</p>
            <p>YOLO ideology is about the big swing, the ruinous bet, the sweeping financial gesture that is as productive of media and theater — of memes, clout, astounded “lol”s — as it is of any return on investment. It's an image macro of a screenshot of a banking or investment browser interface, documenting the buy, hashtagged YOLO, which will perhaps make you “stupid rich,” or dead broke and defaulting on a string of short-term loans taken out to buy the crypto dip. It embraces the frenzy, the bubble, the disbelief of the tedious index-funders, the willful and hilarious stupidity of many of its vehicles: the crude, ugly NFTs, the asinine in-joke crypto drops and DAO schemes. Its epitome is the “diamond hander” imperturbably holding on to his coins as they crater into worthlessness, “to no purpose or profit,” YOLOing to the end.</p>
            <p>YOLO is finance without the future, as the very name would suggest. You Only Live Once is a perfect inverse of the famous (and widely misunderstood) remark of John Maynard Keynes: “ In the long run we are all dead” (Keynes, 1929, p. 80). This lone sentence is often taken out of context as a kind of laissez-faire argument for present joys regardless of the future consequences. In 1923’s Tract on Monetary Reform, Keynes was in fact arguing for deliberate, aggressive state intervention in the economy in the present precisely to manage current uncertainty and secure the future. He was arguing against the hands-off economists whose premise was that the economy would return to equilibrium in “the long run,” if we were only sufficiently patient. “Economists set themselves too easy, too useless a task,” he continues from that famous sentence, “if in tempestuous seasons they can only tell us that when the storm is past the ocean is flat again.” In other words, the future demands planning and intervention from us. YOLO ideology is Keynes's misunderstood position realized: you only live once, in the long run we are all dead, so go for it, bro.</p>
            <p>In a financial environment where one has no meaningful stake in the future, the language of the Security and Exchange Commission’s rule 156 — “a statement that past performance does not guarantee future results” — takes on a new significance, particularly the idea of performance (SEC, b/2/ii/B) . If one’s experience is of wild volatility and disconnection from the fundamentals, punctuated by national and global financial crises, bubbles and busts, then the pretense of financial probity based in the lessons of the past seems like nothing so much as performance: play-acting, theater. From the perspective of the Bitcoin classicists and the hard-metal goldbugs who preceded them, the whole of state-issue currency is a performative act. It realizes the aphorism of Lichtenberg's: to imagine that a monarch announces, “on pain of death,” that everyone must agree that an ordinary stone is a diamond (Lichtenberg, 2012, 97). The fiat of fiat currency — let it be thus — should evoke for us not just the command that something pass as “legal tender,” but the whole class what J.L. Austin called “speech acts,” those performative utterances that make something happen by our shared understanding of the rules (Austin, 1962).</p>
            <p>Libertarians and YOLO actors agree on this fundamental theatricality, but their sense of what it means is distinct. For libertarians, this performance enacted a vast and secret transfer of power, granting statists, bureaucrats, and various Ayn Rand villains the ability to control the rules of reality itself: to set prices, manage markets, empower one group over another, and extract value through taxes and currency debasement. To defeat these foes would restore objective fact to a position of power. Behind the theater, there is reality; the end of the carnival brings with it a restoration of the “real order,” the proper hierarchy. For the YOLO theory, by contrast, there is nothing behind the performance. Their adversaries are not people with a different but coherent theory of power that must be overcome, but rather those who do not concede that all is theater, and have allowed their performance to become excessively serious, self-important, pompous, and staid. YOLO is looking for the purest expression of financial performance, to find out what is revealed by the most gratuitous action. The brokerage firms, hedge funds, and investment bankers are bourgeois repertory, doing Shakespeare or Molière for the thousandth time. YOLO is Artaud, seeking a new kind of reality inside the consensual illusion of the market. YOLO is the financial Theater of Cruelty.</p>
            <h1>“It just wants to be a fire”</h1>
            <p>On the 22nd of August, 1994, Bill Drummond, Jimmy Cauty, Alan Goodrick, and Jim Reid, a reporter, arrived on the Isle of Jura in Scotland's Inner Hebrides carrying one million British pounds in fresh fifty-pound notes (Reid, 1994).</p>
            <p>It was Drummond and Cauty’s money. Hybrid artist-musician-producer-impresarios, they had a string of dance-music hits as the KLF (among other names) in the late 1980s and early 1990s. They outlined their pragmatic and genially cynical approach to pop music production in their 1988 how-to book The Manual (How to Have a Number One the Easy Way) (Drummond and Cauty, 1999). The fortune from this success was given over to the K Foundation — also Drummond and Cauty — which began disbursing the money in a string of provocative and bizarre art projects conducted with impeccable seriousness. They gave anti-awards, identified bundles of cash as artworks and priced them under cost, and planned a world tour of a shipping container full of pound notes that would travel by train, ship, and truck to celebrate the end of cash.</p>
            <p>Getting the Foundation’s million pounds in cash was difficult. A private security firm had to be hired to collect it and supervise the transit from a NatWest facility. Once it was in Drummond and Cauty’s hands, they stowed it in two big suitcases. Drummond, Cauty, Goodrick and Reid took a chartered flight to Islay, and the ferry from there to Jura, with almost the entirety of the Foundation’s remaining money literally in hand.</p>
            <p>That night they made a fire in a disused boathouse and, bundle by bundle, burned all of it.</p>
            <p>Reid's account squares with the footage shot by Goodrick: burning a million quid is a tedious process, peeling off handfuls of crisp fifty pound notes and feeding them into the fire over the course of two hours or so. The bundles of fifty grand, substantial plastic-wrapped bricks, were too tightly packed to burn well. They came out “singed, charred, but perfectly legal” and had to be broken up and poked back into the fire. Some unknown tens of thousands of pounds blew away up the chimney before they could ignite. (Days later the Isle’s police began investigating numerous discoveries of charred money washing up on the beaches.)</p>
            <p>They passed a bottle of whisky. “I don't think people should find out about it,” Cauty said. “[I]t doesn't want to be a shocking thing; it just wants to be a fire.” After it was done being a fire, Reid wrote, “all that was left was the cold and the rain and the ashes.” On that August night, Drummond and Cauty inaugurated the YOLO ideology.</p>
            <p>They went on tour with their film of the destruction, asking viewers: what do you think about this? What does this mean? Why did we do it?</p>
            <p>The reactions were understandably by and large extremely strong: horrified, furious, vituperative. Seen from a macroeconomic perspective, all they had done was to make everyone else’s UK currency worth imperceptibly more, by pulling their small chunk of it from circulation. No one disputed that the money was theirs to do with what they wished; rather than spending it on a small mansion, a smaller Basquiat, or a big recording studio, they had incinerated it. Yet there was something obscene in the pure squandering, the waste, of a sum that few people would ever possess. The comments were collected in a document from the tour, K Foundation Burn a Million Quid (Brook, 1997), and taken together they express the fundamentally liquid character of money: what had been destroyed here was a mass of pure potential. It could have been housing for the indigent, assistance for the community, any given philanthropic possibility; it could have fed, grown, taught, aided; it could have got many of artistic projects off the ground; anything would have been better than this. Drummond and Cauty had opened a void in the future, a black hole into which possibilities kept falling.</p>
            <p>It was arguably the most genuinely shocking act in the history of popular music, so extreme it has been blocked out of broader memory. The most deliberately transgressive provocateurs and offenders of public taste understand that you never mess with the money. The KLF performed the Joker’s move from Christopher Nolan's Dark Knight film: having amassed his villainous fortune into an enormous pyramid of money filling a warehouse, he burns it — a demonstration, more significant than many murders, of his utterly alien value system. (The Joker is a favorite YOLO meme-and-media figure, along with characters like Leonardo DiCaprio’s version of Jordan Belfort, from The Wolf of Wall Street, throwing cash at a federal agent from the deck of a yacht.) It was not sacrifice, which translates things from one order of value into another, but waste: gratuitous, the state in which the possibility of theater can be realized.</p>
            <p>It was performance, but also real — a real loss of real possibilities, real as cash in hand. YOLO, likewise, gets its theatrical charge from the realities of the situation: taking the performance to the end of the line. Like the carnival, drawing transgressive power from the hierarchies and customs it overturns, YOLO's stoic, low-affect “lol” about the outcome of a major bet gets its power, its sting, from the reality of losing one's savings, wrecking relationships, moving back in with friends or parents, facing massive debt or tax burdens without hope of repayment, being the bagholder who ended up with little but “unrealized losses” in crypto and NFTs for which the market has gone. When the avant-garde director Peter Brook embarked on staging Artaud’s work and reviving the Theater of Cruelty, he had an actor with a pistol turn suddenly and fire at the audience; part of YOLO media is the promise of disaster as well as the possibility of wild success. The many disasters, the rug-pulls and rip-offs and financial destruction, are part of the show itself: lambos or food stamps.</p>
            <p>Artaud’s ultimate point in “Theater and the Plague” was a theory of theater as a kind of contagion – a “contagious delirium” (26), pulling you into its shared world, awakening fearsome drives, energies, and fantasies. It reveals new and different, and rather awful, appetites that are latent within the existing order: “the action of theater, like that of plague, is beneficial, for, impelling men to see themselves as they are, it causes the mask to fall, reveals the lie, the slackness, baseness, and hypocrisy of our world” (31). None of it ever added up. The lies of the current situation are disclosed by the mortality of the plague, the fantasies of the theater, the contagions of finance. You only live once, and what matters is the gratuitous nature of the act, reckless, gestural, and impulsive, costumed in the finery of the plague-abandoned house, carrying coins and jewels no one needs – the password to a wallet of worthless crypto: not carnival, but pure theater at last.</p>
            <h1>References</h1>
            <p>Artaud, Antonin. 1958. “The Theater and the Plague.” In The Theater and Its Double , translated by Mary Caroline Richards. Grove.</p>
            <p>Austin, J. L. 1962. How to Do Things With Words. Clarendon.</p>
            <p>Brook, Chris. 1997.K Foundation Burn a Million Quid. Ellipsis.</p>
            <p>Brunton, Finn. 2019. Digital Cash: The Unknown History of the Anarchists, Technologists, and Utopians Who Created Cryptocurrency . Princeton.</p>
            <p>Davidson, James Dale, and William Rees-Mogg. 1987. Blood in the Streets: Investment Profits in a World Gone Mad . Simon & Schuster.</p>
            <p>Davidson, James Dale, and William Rees-Mogg. 1997. The Sovereign Individual: Mastering the Transition to the Information Age . Touchstone.</p>
            <p>Deeps. [@JayCeeTrades.] 2021. “I bought the dip. Lambo or food stamps, there’s no in between #dogecoins.” Twitter, May 19. <a href="https://twitter.com/JayCeeTrades/status/1395112209726935041">https://twitter.com/JayCeeTrades/</a>.</p>
            <p>Drummond, Bill, and Jimmy Cauty. 1999. The Manual (How to Have a Number One the Easy Way) . Ellipsis.</p>
            <p>Keynes, John Maynard. 1929. A Tract on Monetary Reform. MacMillan & Co.</p>
            <p>Lichtenberg, Georg Christoph. 2012. Philosophical Writings. State University of New York.</p>
            <p>Mullan, Philip C. 2016. A History of Digital Currency in the United States: New Technology in an Unregulated Market . Palgrave.</p>
            <p>Nin, Anaïs. 1966. The Diary of Anaïs Nin Volume 1: 1931-1934. Swallow Press.</p>
            <p>Pearson, Jordan. 2017. “Inside the World of the 'Bitcoin Carnivores': Why a small community of Bitcoin users is eating meat exclusively.” Vice, September 29. <a href="https://www.vice.com/en/article/ne74nw/inside-the-world-of-the-bitcoin-carnivores">https://www.vice.com/en/article/ne74nw/inside-the-world-of-the-bitcoin-carnivores</a> .</p>
            <p>Reid, John. 1994. “Money to Burn.” The Observer, September 25.</p>
            <p>Securities and Exchange Commission. 2019. 17 CFR § 230.156 - Investment company sales literature. <a href="https://www.law.cornell.edu/cfr/text/17/230.156">https://www.law.cornell.edu/cfr/text/17/230.156</a> .</p>
            <p>Srinivasan, Balaji. [@balajis.] 2019. “Bitcoin will become the flag of technology.” Twitter, October 29. <a href="https://twitter.com/balajis/status/1189270395712626689">https://twitter.com/balajis/status/1189270395712626689</a> .</p>
            <p>Suber, Peter. 2008. “Gratis and libre open access.” SPARC Open Access Newsletter 124. <a href="https://dash.harvard.edu/bitstream/handle/1/4322580/suber_oagratis.html">https://dash.harvard.edu/</a> .</p>   



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default brunton;
