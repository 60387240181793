import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import cryptoyoutuber from "../../assets/images/content/cryptoyoutuber.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function sereda() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Crypto Personalities as Carnivalesque Jesters</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Alesha Serada
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089012">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/c17d93ec-ef96-4045-902e-8a55ba1bd0d8">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            In this chapter, I discuss the personalities of several  representative crypto YouTube celebrities and Bitcoin aficionados, such as George Tung from CryptosRUs, Mike Jenkins (Guy) from Coin Bureau, Lark Davis, Andreas M. Antonopoulos, and Richard Heart. I look closely at their online performances to reveal the character of a trickster, the ambivalent mythological figure that symbolizes chaos, creativity and disruption. Based on the core literature on the subject, I trace how the figure of a trickster has transformed into the jester in medieval times, and then into the adventurer in the new modern times, and how this transformation is relevant for today. A variety of examples demonstrates that the jester, the trickster, and the adventurer all reveal themselves in different facets of crypto influencers’ performances. However, the most popular YouTubers are also comparatively tame: they produce educational content on cryptocurrencies and blockchain, as compared to ‘get rich quick’ schemes, which are typically presented with a higher degree of tricksteriness. There are also crucial differences and omissions: crypto influencers do not demonstrate queerness, which is inherent to the trickster, and they do not balance praise with abuse, as jesters do. The modern type of an adventurer is more fitting, although it is mostly found in the virtual Wild West of blockchain entrepreneurship, rather than on the most popular YouTube channels.
            </p>
            <hr />
            <p>
            <img src={cryptoyoutuber} class="img-fluid" alt='Figure 1: 2022 AI generated “crypto youtuber”'/>
            </p>
            <p class="figure">
                Figure 1: 2022 AI generated “crypto youtuber”
            </p>

            <h1>Trickster, Jester, Adventurer: How to Diagnose a Crypto Personality</h1>
            <p>My intention here is to bring up a curious case that fits a variety of interpretations of tricksteriness, that is, crypto YouTubers in 2020-2022. This chapter is more of a ‘proof of concept;’ the particularities of differences between different traditions related to tricksters are up to debate. In different contexts, crypto celebrities can be interpreted as tricksters, jesters or adventurers, which provides slightly different angles for understanding their role in contemporary society.</p>
            <p>Firstly, I refer to Radin’s characterisation of the Winnebago Trickster, which is one of the oldest recorded traditions coming from Native Americans (Radin, 1956). For a more specific Western angle, I use the conceptualization of a carnivalesque jester by Mikhail Bakhtin, which he crafted by studying the culture of Western Europe against his personal background of the tumultuous Soviet ordinary life. I refer to the English translation of the book (Bakhtin, 1984), but my main source is the expanded Russian edition (Бахтин, 1990). To introduce the contemporary version of the modernist trickster, I turn to the figure of the European adventurer (French: 'l'aventurier'), or opportunist, exemplified in the celebrity persona of Giacomo Casanova, and I turn to Alexandre Stroev for in-depth cultural analysis (Stroev, 1997; Строев, 1998). I refer to the authorized Russian edition of his book which was first published in French and then, slightly extended, in Russian.</p>
            <h2>The Trickster, Now On Blockchain</h2>
            <p>A trickster can be found in many cultures. The educated Western audience is best familiar with the Winnebago Trickster Cycle, described in the classical ethnographic research by Paul Radin (Radin, 1956)<a href="#_ftn1">[1]</a>. The myth about the trickster may be universal, due to the similar challenges that humanity encountered in its development across continents (Radin, 1956, p. 134). In the myths of Native Americans, as well as in other cultures, a trickster is the agent of chaos, but also the discoverer of new things. He causes harm to others but also always brings it on himself. The original Trickster is obsessed with sexual desires: “lust is his primary characteristic” (Radin, 1956, p. 136). He (or them, as they can be whoever, physically, and procreate with all genders) is inherently queer, bisexual, and gender-fluid. The Trickster’s "...sex life, indeed, his whole physical life, is for him still something of a wild phantasmagoria" (Radin, 1956, p. 138), similar to "Rabelaisian humor." For instance, the original Winnebago Trickster Wakdjunkaga cannot even place his genitals correctly on his own body, and he requests assistance from forest animals to make his penis the right size (Radin, 1956, p. 141). Speaking of crypto YouTubers, we may hear the echo of this ancient tricksteriness in Richard Heart’s comparison of his penis to a Red Bull can (Heart, 2022).</p>
            <p>The Trickster embodies the becoming of a human: he is neither a deity nor a fully formed person. He is the outlier in a stable order and the exception in any categorization. Finally, the Trickster is the subject of unbelievable stories and hilarious anecdotes for people’s enjoyment and entertainment. This may be everything a crypto YouTube personality would want to be.</p>
            <p>However, most watched crypto personalities do not qualify for original tricksters: their performance is typically safely straight. Excessive mating behavior, prominent in the original Trickster, is also absent, if we leave out the most adventurous ones such as the late John McAfee or the very much alive Richard Heart. Those blessed ones who are not immersed in the blockchain discourse as a part of their job may be surprised to find out that the majority of top crypto YouTubers, for the most part, create the impression of incredibly smart and humorous people, fun to be around, at least, as virtual entertainers. They keep up with the latest news beyond their trade, make light-hearted jokes that are never too risky, and carefully maintain the facade of cool self-awareness. A regular crypto YouTuber is surprisingly discreet, albeit always ready for a little transgression. For example, George Tong from CryptosRUS jokes about creating an account at OnlyFans, and Guy from Coin Bureau complains about the perils of dating. Behind this facade we may even catch a hint of an ‘authentic’ and, in a good sense, unremarkable life (Coin Bureau, 2022a) - in sharp contrast with Richard Heart (Heart, 2022).</p>
            <p>There is one mantra, though, that breaks the immersion for a critically thinking audience member: every crypto YouTuber will tell you that Bitcoin shall go to the moon, no reasonable explanation needed. Bitcoin aficionados retain their beliefs in certain properties of cryptocurrencies even when reality proves otherwise (Knittel et al., 2019; Vidan & Lehdonvirta, 2019). It may be that these inconsistencies in the worldview of a ‘true Bitcoiner’ are rooted in the well-familiar archetype of a trickster, who embraces absurdity and controversy.</p>
            <p>“Bear markets are tough. But don't give in to the crazy bears who say that everything is going to go to zero. We probably have a lot more pain and sideways action to come for the crypto, but crypto is not going to zero - it is not going away. And, just to show my belief in that, I bought some more Bitcoin today. I keep averaging in every single week” (Davis, 2022).</p>
            <p>There is one important feature that is specific to blockchain-based entrepreneurship, as opposed to the tech startup scene in general, or even generic ‘not-financial advice’ on YouTube: tricksteriness is an inherent quality of the crypto discourse, even in its tamest forms. Generally, the job of crypto personalities is to generate trust in Bitcoin and blockchain, and to maintain this trust despite the many crushes of the market and daily broken promises of the technology. This task is made almost impossible by the fragility of their own status and the extreme volatility of their subject. By the nature of their job, a crypto personality is someone “who dupes others and who is always duped himself” (Radin, 1956, p. ix), just as the classical North American trickster.</p>
            <p>This carnivalesque spirit still dwells even in the least chaotic of crypto personalities. We may find such an example in Andreas M. Antonopoulos, a notable ‘bitcoiner’ and a top crypto YouTuber (probably one of the most popular ideologists of Bitcoin on YouTube, which generally favors more practical content over ideology). His presentation rarely takes the elaborate forms of a staged and edited YouTube video; Antonopoulos mostly records and publishes talks in a more traditional form of on-stage performance. And yet, he also has the Trickster in him. The Trickster performs the "two-fold function of benefactor and buffoon" (Radin, 1956, p. 124). He brings chaos and destruction, but he may also, mostly accidentally, bring culture to the people.</p>
            <p>"International wire transfers have always been kind of an Achilles heel of traditional finance. They are slow, insecure, expensive, full of delays and uncertainty, you never know if it's going to get there in an hour or three weeks. And you stumble up across all of this patchwork of regulation anytime you try to do an international wire transfer. And this is the area where cryptocurrency shines" (Biz Jet TV, 2020).</p>
            <p>In the real world, everything that Antonopoulos said about wire transfers is true for cryptocurrencies, while wire transfers, in fact, "shine" and mostly do their job as they should<a href="#_ftn2">[2]</a>. However, Antonopoulos is still a trickster (this is how he can perform a complete inversion of the real world's state of things, wide-eyed and with a straight face). Being the trickster that he is, he accidentally makes a surprisingly reasonable claim that blockchain can be useful to create immutable records of maintenance and flight logs in aviation.</p>
            <p>"The ability to immutably record maintenance logs and, perhaps, even flight logs, because these are documents that are often critical in post-accident forensic investigation" (Biz Jet TV, 2020) <strong>.</strong></p>
            <p>Not everything that a trickster says is colorful exaggeration and (self-)deception. This is how tricksters gain trust, but also, this is why they are not taken seriously. Misunderstood and punished tricksters have been a staple of every mythology, and the reason to punish them was almost always 'not knowing their place'. In sheer numbers, probably the most popular character of this kind is the legendary Chinese monkey trickster Sun Wukong, known in popular culture for causing “havoc in Heaven” and being locked up for that. The European Loki would come second, recently also popularized in the US by Marvel Comics and their TV series. We should, however, critically re-evaluate the role of a trickster in the current times, when material safety is in much shorter supply than utopian disruption.</p>
            <h2>The Cryptocarnivalesque Jester</h2>
            <p>While other authors of this project have already covered the concept of cryptocarnival, here I will focus on the concept of a jester. Radin, who is also familiar with the European tradition, suggests that the medieval European jester has inherited many of the Trickster’s traits (Radin, 1956, p. ix): a jester is disruptive, he is comical, he turns the world upside down. The jester and the king are two interchangeable symbolic figures of the carnival, where one inevitably turns into another: "The king's attributes are turned upside down in the clown; he is the king of a world, "turned inside out"" (Bakhtin, 1984, p. 370). Performing ‘the king of the world’ is very common on celebrity YouTube; however, many popular and trusted crypto YouTubers present themselves as down-to-earth, ordinary people with a bit of tricksteriness up their sleeve. They generate trust by alternating between a charming madcap and even more relatably as ‘just your average Guy’. This is why George Tung from CryptosRUs starts his streams with a catchphrase: “I am George, we’re all George”.</p>
            <p>“I try to be modest, I try to live a modest lifestyle, but I've attained that life-changing wealth. What I've been telling you guys about dollar cost average, and hodling, and achieving life-changing wealth, — I have achieved it. And I have achieved it not through some prestigious job or some prestigious business, - I've attained it by buying low and hodling” (Tung, 2021).</p>
            <p>The jester is an inherently democratic figure: he is out in public for everyone to see, and he performs for the poor and the rich alike. Same as jesters of the past, crypto YouTubers constantly mock the authorities, speak against oppression of the impoverished and the unbanked (which is just a rhetorical figure in this context), and provide surprisingly competent emotional support in the times of fear when the market is ‘bearish’. This becomes a routine performance, as the cryptocurrency market undergoes a major crash almost every year.</p>
            <p>“I'm not selling. I'm not selling at all. Some people have claimed that I run the biggest 'hopium' channel, and I only talk about what's coming, and I never really focus on the downs and stuff. I do, I do tell you guys that the best time to buy is when the things are down, right? but people claim that 'hey, you know what, you've been wrong'. I have been wrong. I am a human. I am not God. If I was God, I would have all the money in the world already, or I wouldn't even need money - that's really silly, right?” (Tung, 2021).</p>
            <p>Folk masses would eagerly trust the jester because he was the opposite of power, oppression and fear. He heralded the alternative kind of truth, joyous and improper, that helped to resist the hypocrisy of the powerful through carnivalesque laughter (Bakhtin, 1984, pp. 93–94). The liberating potential of folk laughter recognized no authority, and nothing was sacred. In fact, the main purpose of the carnival is to swap around the sacred and the profane. This is how trust was gained at the medieval marketplace, at least, in Bakhtin's carnivalesque: the unofficial folk truth treats seriousness as a sign of deception.</p>
            <p>In this scheme of the world, the figure of a jester (fool) is closely related to the concept of "praise-abuse": a public verbal performance that simultaneously praises and verbally abuses its subject. For instance, in Gargantua's prologue, it is passionately described as "the cry of the barker, the quack, the hawker of miracle drugs, and the bookseller" (Bakhtin, 1984, p. 167) - a subversive sales pitch that could be heard at the medieval marketplace.</p>
            <p>We may find a rather tame equivalent of ‘praise-abuse’ in the performance of Crypto Guy, the character created by Mike Jenkins. Every video on his “The Coin Bureau” channel starts with the announcement that Guy is not a financial advisor, which is legally required in the media that discuss financial issues. Early in his career, Jenkins started embellishing disclaimers to avoid repetition; sometimes he rhymes them, as a jester would do; in other cases, he creates a different comical character who delivers the ‘praise-abuse’. For instance, in one of such sketches, the character based on Tony Montana from Scarface (1983) mocks Guy for being unqualified as a financial advisor, but praises him for delivering high quality educational content. Both characters are played by Jenkins.</p>
            <p>(In the character of Tony Montana): “‘F you came here for financial advice, ‘en forget it. You little friend Guy ain’t no financial advisor, he’s just some punk on the internet, making his videos for education and entertainment…” (Coin Bureau, 2022b) <strong>.</strong></p>
            <p>One might point at the slightly cringeworthy hyperperformativity of this character; Jenkins is obviously clowning, not acting, in this role. The other character of Guy is not so obviously carnivalesque, but it is used as a persona that Jenkins wears even in his unscripted performances, when he keeps his inner jester concealed. In this regard, being a celebrity persona in general is very much like the job of a jester: "...they were not actors playing their parts on a stage ... but remained fools and clowns always and wherever they made their appearance” (Bakhtin, 1984, p. 8). This also begs the question whether we may treat one's performance in a video as ordinary life. However, in 2020-2022, to many of us, and especially YouTube celebrities, streaming has been the prevailing form of performing social interaction, and the line between mediated and unmediated life has almost disappeared.</p>
            <p>Especially in the times of COVID-19, mediated online performance of a crypto jester can be compared to the later form of carnivalesque, the Romantic grotesque that was mostly mediated through literature: "an individual carnival, marked by a vivid sense of isolation" (Bakhtin, 1984, p. 37). In this derivative, and hopelessly modern, form, mediated through Romantic literature, "laughter was cut down to cold humor, irony, sarcasm" (Bakhtin, 1984, p. 38). Irony is relatively rare in crypto discourse in general (although its level increases during ‘crypto winters’, at least, judging by the amount of self-deprecating memes on Twitter). Top crypto YouTube personalities rarely take risks by making ironic statements, which can easily be misinterpreted by their fan base, but certain elements of the grotesque carnivalesque reveal themselves in the professionally scripted, staged and cut broadcasts of The Coin Bureau. Its host Guy still retains some of his joyousness as a crypto trickster; it is hard to tell, however, how ‘authentic’ this joyousness is. Again, to a critical viewer, this performance becomes melancholic, almost tragic, when Guy reluctantly makes his obligatory joke about not being a financial advisor at the beginning of each video. It feels almost as if he swore to be funny and original about unfunny and mundane things twice a week until the end of his crypto career.</p>
            <h2>The Blockchain Aficionado as an Adventurer</h2>
            <p>For the goals of our study, the main difference between the trickster, the jester and the adventurer can be found in how much courage and weirdness would be required for them to signify radical social disruption. The degree of recklessness and absurdity seems to diminish, as society moves from more conservative and traditional to open and innovative regimes of operation. Despite its many failures, contemporary Westernized society has provided the highest level of social mobility so far; in the meantime, everyone can become a ‘visionary’ and an ‘innovator’, even if just for fifteen minutes on YouTube. In comparison, traditional society would require turning the world upside down, symbolically, for someone to even imagine changing one’s social status. The figure of the European adventurer is representative of such transformation of the social system from rigid and closed to fluid and open; this is also the most recognizable type among blockchain entrepreneurs in real life. On YouTube, we may find the most iconic example in Richard Heart, and the most obvious sign of his adventurer-like personality is his ‘largest cut diamond’ that he showcases. Let us find out why.</p>
            <p>As feudal society was disintegrating in Europe throughout the XVII century, so did the folk laughter culture (Bakhtin, 1984, p. 33). New capitalist relationships were emerging across Europe, and going ‘from rags to riches’ was now, at least, imaginable, if not particularly probable. This is how the concept of ‘life-changing wealth’ entered fictional and real life narratives, which typically featured the character of an adventurer, during the Enlightenment. Initially, a French 'l'aventurier' signified social mobility, which was unthinkable in a traditional society.</p>
            <p>An adventurer is a person of unknown social status, but most likely, the impoverished lower middle class, who is able to infiltrate the upper class by all means necessary. A typical adventurer exhibits exotic tastes and conspicuous consumption; he may be covered with jewelry, even though some of it is fake (Строев, 1998, p. 19). In high society, he is the agent of chaos and a magnet for misfortune, which he is still able to use to his own benefit (Строев, 1998, p. 18). Same as the Winnebago Trickster, the adventurer “...possesses no values, moral or social, is at the mercy of his passions and appetites, yet through his actions all values come into being” (Radin, 1956, p. ix). The best example here is the famous adventurer Giacomo Casanova, best remembered for his mastery as a pickup artist with the impressively long list of women (and men) whom he seduced.</p>
            <p>While the adventurer may take up the role of the ‘king of the carnival’ in some narratives of its epoch, he is different from a carnivalesque jester. Unlike jesters, adventurers would rarely perform in public. European adventurers of the XVIII century had the luxury of a private life when they could be their ‘authentic selves’ (even in prison). They mostly addressed their painstakingly crafted performances directly to the elites, especially the aristocracy and the nobility, from whom they extracted money by borderline unlawful means. Today, a crypto adventurer performs for investors, rather than for the public, like crypto jesters.</p>
            <p>The ethos of an adventurer and a blockchain aficionado is generally the same. They both support their lifestyle by collecting investments into elaborate financial schemes and innovative technologies. There is one particular shared belief, acquired in early modernity, that is almost uncanny: both the adventurer and the blockchain adopter passionately wish to delegate governance to the machine. Alexandre Stroev dissects this passion of the late XVIII century adventurers in the chapter "The Ideal State of the Lottery". The adventurer "turns the Fortune of Fortune into the tool for selection of people and redistribution of wealth, in order to build the ideal society... (Stroev, 1998, p. 191, my translation from Russian).</p>
            <p>Casanova was not even the first believer in the 'code is law' principle, This honor belongs to the influential Enlightenment thinker Diderot, who proposed the project of the state lottery that would serve as progressive taxation and presumably decrease inequality in French society. Eventually, none other than the adventurous Casanova established the state lottery in France, motivated not so much by profit (which appeared to be quite substantial), as by his fascination with cryptography and mathematics (Строев, 1998, p. 200). Casanova also practiced less conventional sciences: for instance, he staged alchemic rituals in attempt to help his ‘friend with benefits’, the fellow adventurer Madame d’Urfé, to be reborn in the body of a young boy (Строев, 1998, p. 174) — speaking of suppressed queerness on the startup scene almost three centuries ago. Tragically, we have yet to find a crypto YouTube celebrity that queer (please send tips).</p>
            <h1>Conclusion: Crypto, You Are Not Being Weird Enough</h1>
            <p>Is cryptocarnival the true carnival, at least, as represented by its YouTube spokespersons? Even though YouTube lacks the sociality and physical presence of the historical carnival culture, crypto influencers still utilize several important elements of this culture. These elements include disruption of social order, reversion of social roles and meanings, and creation of the utopian second world where everything is possible. However, this is not enough to employ the carnivalesque to the full extent. At large, the crypto community lacks in laughter, queerness, and grotesque. This is why the face of crypto is boredom, not laughter. Its community is united not by the visceral joy of collective celebration, but by the populist sentiment of ‘us against them’. Such illusion may create the illusion of overcoming fear and doubt, but also can be used to cultivate fanaticism and idolatry. Sadly, these are the exact things against which the trickster and the jester were fighting.</p>
            <p>The stories of a trickster, a jester and, to some extent, of an adventurer, prove that productive social disruption is incompatible with financial motivation. In the latter case, instead of joyful and inherently humane carnival, we observe a ‘society of the spectacle’ (Debord, 1983), an illusion produced by circulation of the already accumulated capital. The purpose of this spectacle is to extract value from the consumers, rather than liberate them from the consumerist ways of living. At the beginning of the current ‘crypto winter’, Richard Heart promises salvation to all his fans, which, of course, comes at the price of buying into his own Ethereum-based coin HEX. The examples of Heart and McAfee demonstrate how tricksteriness transforms into adventureness when a crypto personality has a financial interest in the form of their own project or a coin. Such examples have mostly remained out of scope of this brief review, which has focused on the most popular crypto YouTubers. It is notable, however, that the most popular videos on crypto YouTube are still basic explanations of how Bitcoin and blockchain works, which demonstrates that their popularity is driven by curiosity and information foraging of the most general audience. Crypto YouTubers who are also entrepreneurs and/or scammers typically have smaller but more devoted audiences, and their performance is far more chaotic, but this should be the subject for another research.</p>
            <p>How can we escape the rule of the old Capital and launch our own Carnival? In order to qualify as fully carnivalesque, crypto personalities should be cursing Bitcoin just as much as they are praising it. The jester is allowed to speak freely, delivering ‘praise-abuse’, even at the king’s court. Of course, this regime of truth is not without consequences: it can also be directed back to the jester himself. To add injury to insult, verbal abuse would turn easily into physical abuse at the real life carnival. Naturally, this kind of disruption creates an occupational hazard for the Jester-King. According to Bakhtin, "...The king is the clown. He is elected by all the people and is mocked by all the people. He is abused and beaten when the time of his reign is over..." (Bakhtin, 1984, p. 197). In the crypto-adjacent community, the best example of the sacrificial jester is the late John McAfee; in addition to being a notorious trickster, he had been involved in a truly Rabelaisian sex scandal (I will leave the essence of the scandal for curious readers to research themselves). This eccentric episode still makes sense when viewed through the prism of the carnivalesque: it perfectly embodies "the destroying and renewing force of the material bodily lower stratum" (Bakhtin, 1984, pp. 266–267), transgressing the boundaries between the body and the earthly matter. Regardless of the true cause of his death, McAfee was just too much of a trickster for this world.</p>
            <h1>References</h1>
            <p>Bakhtin, Mikhail M. Rabelais and His World. Indiana University Press, 1984.</p>
            <p>Biz Jet TV. "Bitcoin, Blockchain & Private Aviation." YouTube, July 25, 2020. <a href="https://www.youtube.com/watch?v=-usR_cqBMxQ">https://www.youtube.com/watch?v=-usR_cqBMxQ</a> .</p>
            <p>Coin Bureau. "Behind The Scenes: Scarface Disclaimer! 👑." YouTube, February 13, 2022. <a href="https://www.youtube.com/watch?v=1UmYQBEpjZY">https://www.youtube.com/watch?v=1UmYQBEpjZY</a> .</p>
            <p>Coin Bureau. "Macey Discusses Her Portfolio!! 🚀." YouTube, January 21, 2022. <a href="https://www.youtube.com/watch?v=GP6CrZnQddc">https://www.youtube.com/watch?v=GP6CrZnQddc</a> .</p>
            <p>Davis, Laurence. "Here’s Why Bitcoin, Ethereum, & Crypto Are Crashing Today." YouTube, June 13, 2022. <a href="https://www.youtube.com/watch?v=zZIY9wlw6uc">https://www.youtube.com/watch?v=zZIY9wlw6uc</a> .</p>
            <p>Debord, Guy. Society of the Spectacle. Black & Red, 1983.</p>
            <p>Heart, Richard. "PLAYBOY MODEL LEARNS ABOUT FINANCE WITH RICHARD HEART. HOW TO GET RICH IN THE BULL MARKET AND BEAR!" YouTube, May 17, 2022. <a href="https://www.youtube.com/watch?v=p_xj2SlZZ7A">https://www.youtube.com/watch?v=p_xj2SlZZ7A</a> .</p>
            <p>Knittel, Michael, Sasha Pitts, and Ryan Wash. “The Most Trustworthy Coin”: How Ideological Tensions Drive Trust in Bitcoin. Proceedings of the ACM on Human-Computer Interaction 3, no. CSCW (2019): 36:1-36:23. <a href="https://doi.org/10.1145/3359138">https://doi.org/10.1145/3359138</a> .</p>
            <p>Radin, Paul. The Trickster: A Study in American Indian Mythology. Philosophical Library, 1956.</p>
            <p>Rohr, John. "Smart Contracts in Traditional Contract Law, Or: The Law of the Vending Machine." CLEVELAND STATE LAW REVIEW 67 (2019): 23.</p>
            <p>Stroev, Andrei. Les Aventuriers des Lumières. FeniXX, 1997.</p>
            <p>Tung, George. "BITCOIN CAPITULATION IS COMING - WHY I’M NOT SELLING." CryptosRUs, May 30, 2021. <a href="https://www.youtube.com/watch?v=EYWO-CIo0OY">https://www.youtube.com/watch?v=EYWO-CIo0OY</a> .</p>
            <p>Vidan, Gideon, and Vili Lehdonvirta. "Mine the gap: Bitcoin and the maintenance of trustlessness." New Media & Society 21, no. 1 (2019): 42–59. <a href="https://doi.org/10.1177/1461444818786220">https://doi.org/10.1177/1461444818786220</a> .</p>
            <p>Бахтин, Михаил. Творчество Франсуа Рабле и народная культура Средневековья и Ренессанса . Художественная литература, 1990.</p>
            <p>Строев, Андрей. “Те, кто поправляет фортуну”: Авантюристы Просвещения . Новое литературное обозрение, 1998.</p>
            <br clear='all'/>
            <hr align='left' size='1' width='33%' />
            <p><a href="#_ftnref1">[1]</a> This trope has been popularized by Carl Jung, who described the Trickster as one of cultural archetypes, although Jung’s work should not be treated as credible or scientific, but rather a cultural appropriation, conveniently adapted for Western readers.</p>
            <p><a href="#_ftnref2">[2]</a> Here I could speak about the countries under sanctions and the unbanked, sadly, from my personal experience, but to put it very, very shortly - cryptocurrencies are the absolute last means that the unbanked would use, precisely because they are extremely "slow, insecure, expensive, full of delays and uncertainty" as compared to other relatively legal underground money networks. If sanctioned by the state, they are also very prone to state-level corruption.</p>  



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default sereda;
