import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import googlengram from "../../assets/images/content/googlengram.png";
import vergne1 from "../../assets/images/content/vergne1-4.png";
import vergne2 from "../../assets/images/content/vergne5-6.png";
import vergne3 from "../../assets/images/content/vergne7-8.png";
import vergne4 from "../../assets/images/content/vergne9-10.png";
import vergne5 from "../../assets/images/content/vergne11-12.png";
import vergne6 from "../../assets/images/content/vergne13-14.png";
import vergne7 from "../../assets/images/content/vergne15-16.png";
import vergne17 from "../../assets/images/content/vergne17.png";
import vergne8 from "../../assets/images/content/vergne18-21.png";

import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function vergne() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Web3 as decentralization theater? A framework for envisioning decentralization strategically</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            JP Vergne
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089010">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/aef6a7d1-27e0-49e0-b415-db4b373aa3b2">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            Web3’s raison d’être is decentralization. Quite problematically, however, few industry analysts can articulate what “decentralization” really entails; whether it differs at all from the notion of “distribution”; and how either construct can be measured with observable data to enable a meaningful analysis of the industry’s core promise. Instead, Web3 is akin to a decentralization theater in which archetypical characters, who resonate with the likes of Hamlet and Godot, enact decentralization based on fictitious narratives. After critically reassessing these narratives about decentralization, this paper offers a fresh perspective to evaluate, less theatrically and hopefully more rigorously, future claims about “being decentralized.” I argue that the crucial issue lurking behind the decentralization narrative is the dispersion of authority within blockchain platforms, which consists of two fundamental dimensions, namely the dispersion of information and of decision-making. The value proposition of Web3 will not be taken seriously until the industry can provide reliable indicators of authority dispersion and demonstrate that the latter affects strategic outcomes for blockchain platforms, including innovation, growth, and value creation.
            </p>
            <hr />
            <h1>Introduction</h1>
            <p>The Web3 community is characterized by a collective obsession with the notion of “consensus” and, at the same time, by a lack of consensus around what Web3 actually refers to. The term “Web 3.0,” spelled with a dot followed by a zero, used to refer the “semantic Web,” a standardization initiative aimed at making Web data readable by machines. This concept is only loosely related to the current “Web3” moniker — typically spelled without a space between “Web” and “3”. Over the years, the term “Web3” has gradually supplanted “Web 3.0” in the industry buzzword race, as suggested by the Ngram search below, to eventually achieve dominance during the second half of 2021:</p>
            <p>
            <img src={googlengram} class="img-fluid" alt="Figure 1: Google NGram chart"/>
            </p>
            <p class="figure">
            Figure 1: Google NGram chart
            </p>

            <p>In the following, I critically examine Web3’s claim to fame, namely that Web3 provides a “decentralized” alternative to existing web platforms. To illustrate my arguments, I leverage short blogs posted by Web3 builders, funders, analysts, and critics on “crypto Twitter”, which often acts as the main stage for Web3’s decentralization theater.</p>

            <h1>Web3 = The decentralized Web?</h1>
            <p>Defining Web3 can be about as controversial as using pineapple as a pizza topping. Some attempts at capturing its essence include the following:</p>

            <p>
            <img src={vergne1} class="img-fluid" alt="Figure 2: What is Web3?"/>
            </p>
            <p class="figure">
            Figures 2-5: What is Web3? (<a href="https://twitter.com/Syntropynet/status/1534899649420701697">Twitter</a>) (<a href="https://twitter.com/dabit3/status/1496609480905302024">Twitter</a>) (<a href="https://twitter.com/matwg/status/1518229095540314112">Twitter</a>) (<a href="https://twitter.com/ArthurB/status/1444614479078170628">Twitter</a>)
            </p>

            <p>
            <img src={vergne2} class="img-fluid" alt="Figures 6-7: What is Web3?"/>
            </p>
            <p class="figure">
            Figure 6-7: What is Web3? (<a href="https://twitter.com/cdixon/status/1476443819323236357">Twitter</a>) (<a href="https://twitter.com/ArthurB/status/1444614479078170628">Twitter</a>)
            </p>

            <p>A common thread across definitions is the idea that (1) Web3 is both decentralized and distributed; (2) Web3 enables users to co-own blockchain platforms and to exchange value peer-to-peer using self-managed (cryptocurrency) wallets. Both features are premised on the ability to design a network of nodes that can seamlessly connect any user to any other user, without Web traffic having to be routed through trusted intermediaries. If blockchain is to form the backbone of Web3, the decentralized software applications (“dApps”) whose informational outputs populate the chain’s blocks shall become its flesh. Decentralization has been hailed by the Web3 community as both a mission and organizing principle, which is why we need more than a superficial understanding of decentralization to make sense of this emerging industry.</p>
            <h1>Decentralization is the new disruption</h1>
            <p>To achieve respectability in the golden age of Web 2.0, a startup had to “disrupt” some industry. Cofounders sometimes accompanied this mission statement with an injunction for the startup to “disrupt itself.” Nowadays, to achieve respectability in the age of Web3, a founding team has to “decentralize” some industry. Founders often accompany this mission statement with an injunction for the project to “decentralize itself.” The meaning of these guidelines remains vague enough to prevent rigorous evaluation of whether the project has ultimately succeeded. Has WeWork disrupted the office leasing industry? Has Bitcoin decentralized banking? Questions of this type appear to attract “wrong answers only”—a popular prompt used on Twitter to encourage one’s followers to come up with absurd, humorous replies to a difficult question.</p>
            
            <p>
            <img src={vergne3} class="img-fluid" alt="Figures 8-9: What is Web3? (Wrong answers only) "/>
            </p>
            <p class="figure">
            Figures 8-9: What is Web3? (Wrong answers only) (<a href="https://twitter.com/CNFT_Oni/status/1474052798224351236">Twitter</a>) (<a href="https://twitter.com/MonetaryGrid/status/1475901470000226307">Twitter</a>)
            </p>

            <p>Before this article offers a fresh perspective on decentralization, it takes stock of prior attempts at delineating the concept. In keeping with the spirit of this publication, I propose to view the Web3 community as a decentralization theater , namely, a fictitious space whose performers are enjoined to enact decentralization to preserve the coherence of the play. In Web3’s decentralization theater, the standard script is known to all — take a traditional digital business that is commonly believed to be “centralized” and build a “decentralized” version of it. Since decentralization allegedly brings valuable benefits (e.g., privacy, openness, security), it should also confer a competitive advantage—or, put differently, a decentralized version of a digital business is just a better business. If examples of this gameplay abound, as illustrated below, very few have reached the tipping point of becoming mass-market as of the time of writing.</p>
            
            <p>
            <img src={vergne4} class="img-fluid" alt="Figures 10-11: Decentralized Business Models"/>
            </p>
            <p class="figure">
            Figures 10-11: Decentralized Business Models (<a href="https://twitter.com/SuhailKakar/status/1531161044428673025">Twitter</a>) (<a href="https://twitter.com/SuhailKakar/status/1531161044428673025https://twitter.com/crypt_guko/status/1522252897123278848">Twitter</a>)
            </p>

            <p>To decentralize traditional business models, Web3 entrepreneurs are expected to rely on a toolkit that includes decentralized cryptocurrency (e.g., Ether), decentralized blockchains (e.g., Ethereum), decentralized protocols (e.g., 0x), decentralized applications (e.g., The Sandbox), decentralized marketplaces (e.g., LooksRare), decentralized digital assets (e.g., ape NFTs), and decentralized governance (e.g., Aragon). But how do we know exactly that any given Web3 ecosystem is decentralized—or at least, more decentralized than the Web2 model it is meant to outcompete? (Schneider, 2019). This is where the rubber does not meet the road quite yet.</p>
            <p>Some see decentralization as the middle ground between centralization and distribution, as was initially proposed by Paul Baran (see Figure 12) (Baran, 1964). Others see distribution as the middle ground between centralization and decentralization, as proposed by Ethereum community contributors (see Figure 13; essentially the same as Figure 12, only after swapping the terms “distributed” and “decentralized”). Yet others see the terms “decentralized” and “distributed” as synonymous. Notwithstanding a potential nuance between decentralization and distribution (Vergne, 2020), very few industry insiders can provide a clear definition of either term when prompted. A predictable outcome, given these divides, is that the two terms are poorly defined, let alone measurable with any sort of consistency. How can we tell, then, whether Bitcoin is more (or less) decentralized (or distributed) than Ethereum? Or that Audius is indeed a more decentralized (or distributed) music platform than Spotify?</p>

            <p>
            <img src={vergne5} class="img-fluid" alt="Figures 12-13: Depictions of centralization and distribution"/>
            </p>
            <p class="figure">
            Figures 12-13: Depictions of centralization and distribution
            </p>

            <h1>Popular characters in the decentralization theatre</h1>
            <p>In the decentralization theater, popular characters embody different ways to think about the decentralization of Web3. This section portrays four of them, namely Hamlet, Godot, Bucket, and Figaro — and discuss the limitations of their act.</p>
            <h2>The “Hamlet” of decentralization: To be or not to be… decentralized</h2>
            <p>According to proponents of a Hamlet definition of decentralization, an entity is either centralized or decentralized, period. One typically relies on a simplistic criterion to arrive at such a binary determination. For instance, if an entity has a CEO, it is centralized; if it doesn’t have one, then it is decentralized.</p>
            
            <p>
            <img src={vergne6} class="img-fluid" alt="Figures 14-15: Depictions of centralization and distribution"/>
            </p>
            <p class="figure">
            Figures 14-15: Commentators on corporate control and decentralization (<a href="https://twitter.com/CryptoHustle/status/906521152289419269">Twitter</a>) (<a href="https://twitter.com/CoinSeer/status/1499741734670110722">Twitter</a>)
            </p>

            <p>Hamlet definitions certainly have the benefit of simplicity, but they overlook crucially important dimensions. Can’t an organization run by a CEO be located along a continuum of centralization, depending, for instance, on the extent to which decisions are delegated to middle managers? Wouldn’t the dispersion of ownership — and owners’ ability to swiftly remove a poorly performing CEO change the “centralization” the story? And what if an organization is not incorporated, does not have a CEO, yet depends on just a handful of agents to operate? Would that necessarily make it more decentralized than a CEO-run corporation with dispersed ownership, frequent CEO turnover, and substantial amounts of authority (Kavanagh & Ennis, 2020) delegated across hundreds of middle managers? (see Bakos et al., 2021, for related arguments in the context of permissioned vs. permissionless ledgers).</p>
            
            <h2>The “Godot” of decentralization: To wait indefinitely for an attack to succeed</h2>
            <p>For some, a system is decentralized when it has so many points of failure that it simply cannot fail amid adverse circumstances, for any attacker would have to simultaneously attack on too many fronts to be successful. From this perspective, decentralization makes a system resilient in perpetuity— and adversaries end up waiting indefinitely for their attacks to reach their objective (like the characters in Beckett’s Waiting for Godot , who end up waiting indefinitely for the elusive Godot). A limitation of this view is that it is often trivial to identify a meta-system that encompasses most of the focal system’s agents (or nodes) and could therefore represent a central point of failure. Take a computer that runs an arbitrary large number of independent virtual machines; sadly, the hardware that hosts them all represents a single point of failure. Similarly, a state can decentralize political authority across an arbitrarily large number of elected representatives; but when they all meet in person to pass laws inside the parliament building, that building represents a single point of failure (e.g., a bomb could kill them all at once).</p>
            <p>Some blockchain networks get criticized for heavily relying on Infura and Amazon Web Services to host nodes, thereby creating, de facto, central points of failure. One might argue that heavy reliance on computer chips manufactured by Intel or on rare earth materials from China might also represent single points of failure for any computing network (Gochhayat et al., 2020). If we pushed this line of reasoning to the limit, any system whose nodes are located on Earth has a single point of failure, for a massive solar flare could cause an electromagnetic superstorm and knock out all computers in one go. Likewise, in at-risk countries, unfettered climate change could lead most businesses to fail, however decentralized they claim to be.</p>
            
            <p>
            <img src={vergne7} class="img-fluid" alt="Figures 16-17: Commentators on Web3’s reliance on centralized infrastructure"/>
            </p>
            <p class="figure">
            Figures 16-17: Commentators on Web3’s reliance on centralized infrastructure (<a href="https://twitter.com/TolulopeAjayii/status/1517572747781627905">Twitter</a>) (<a href="https://twitter.com/Arthur_MacLeod_/status/1462204338961620996">Twitter</a>)
            </p>

            <p>Any system can be argued to be part of a broader meta-system which, out of logical necessity, will represent a single point of failure, but to avoid the risk of infinite regress that comes with this perspective, it is crucial to limit our analyses of decentralization to the boundaries of the focal system. The blockchain software run by node operators, for instance, clearly fall within the system’s boundaries; the location where node operators store their off-chain data, possibly; but who supplies the rare earths for the computers that run node software, probably not.</p>
            <h2>The “Bucket” of decentralization: To decentralize is to remove inequality</h2>
            <p>Charlie Bucket, before visiting Willy Wonka’s chocolate factory, lived in extreme poverty. But this kind of economic inequality would not exist in a truly decentralized system, according to proponents of Bucket definitions of decentralization. The latter argue that only in a centralized system can cryptocurrency tokens be concentrated in the hands of a happy few. Yet, by equating economic inequality with token ownership concentration and, by extension, with centralization, Bucket definitions can be quite misleading. First, the wealth of any given individual is determined by the sum of the value of all the assets they own — not just by the value of their holdings in one specific asset (here, cryptocurrency). Thus, to measure the dispersion of, say, bitcoin cryptocurrency across users and call it a measure of inequality is about as informative as counting the dispersion of Lamborghinis across the residents of Italy to evaluate inequality in the country. As it turns out, some wealthy individuals may have a strong preference for holding real estate rather than luxury cars, or for driving Ferraris rather than Lamborghinis. Put differently, the dispersion of Lamborghinis (or of bitcoin cryptocurrency) does not say as much about wealth inequality as it does about wealthy individuals’ preferences for holding certain assets over others . A second issue, perhaps even more problematic, is the underlying assumption, made in Bucket conceptualizations, that the dispersion of Lamborghini ownership is a reasonable proxy to capture the decentralization of the Lamborghini car company. Which amounts to conflating the internal organization of a corporation with the size of its product’s user base. As such, to claim that bitcoin is not decentralized because the top 1% bitcoin holders own most of the bitcoin network’s supply is missing the point altogether.</p>
            <p>There is one situation, however, where the dispersion of token ownership is very informative—and that is when token ownership gives substantial decision rights over the system. But the tokens themselves, in such a context, are just how the dispersion of decision rights can be observed. In other situations, it may be that the dispersion of computing power, the existence of backdoors and master passwords, or the presence of supernodes with special privileges end up mattering much more than token ownership concentration. Put simply, we should not conflate the construct (decision-making dispersion) with its context-specific measurement (token ownership concentration).</p>
            <h2>The “Figaro” of decentralization: To decentralize is to disintermediate</h2>
            <p>Figaro was first featured in three plays by Beaumarchais, and later in countless opera performances, perhaps most famously in Mozart’s Marriage of Figaro . In the story, feisty Figaro acts as the quintessential intermediary—between prospective lovers, between aristocratic families, and between social classes. In the Beaumarchais trilogy, every aspect of the plot unfolds thanks to the introductions and connections that Figaro is making between other characters. Quite ironically, Figaro likes to be called “Anonymous” by others, in order to keep his intermediating role as discreet as possible. In Web3, decentralization is commonly associated with disintermediation, namely, with the removal of all the trusted Figaros that would normally intermediate transactions between agents. But what makes an intermediary? Do anonymity and pseudonymity alter the fundamental nature of intermediaries (Caliskan, 2020; Swartz, 2018)?</p>
            <p>An ongoing controversy in Web3 is whether network validators, such as miners in proof-of-work blockchains, represent intermediaries. Since miners cannot screen users to allow or disallow access to a blockchain network, they do not constitute traditional financial intermediaries (and should not be regulated as such). However, a block of transactions cannot be added to the main chain without a miner proposing to add the block. At the same time, miners are equivalent and substitutable to the extent that they enforce the same rules written in open-source code and do not have to exercise subjective judgment. So, are they intermediaries at all? The answer depends on the level of analysis. A transaction between two Bitcoin users on the main chain does require a miner—in that sense, there is intermediation when considering the transaction level. However, at the level of the Bitcoin network, it makes sense to regard miners as falling within the boundaries of the organization — in fact, miners are just a special type of users, rather than a third party. The exchange of data within the organization’s boundaries does not represent, strictly speaking, a form of intermediation — or else we would be claiming that the network is intermediating itself. Importantly, the above discussion emphasizes a crucial difference between disintermediation and decentralization. The former really is a matter of role definition (third-party intermediary or not?), whereas the latter regards the dispersion of the role’s performance (many miners available to perform the task or just a handful?) (Nabben, 2021). As a result, the decentralization theater should take distance from Figaro’s character and instead refocus on theorizing what matters more fundamentally to the dispersion of authority within system boundaries.</p>
            <h1>The decentralization of what?</h1>
            <p>Our brief (and incomplete) review of the characters that commonly embody decentralization in the Web3 theater has highlighted three desirable features that a robust conceptualization of decentralization should have:</p>
            <ul class="content">
            <li>Decentralization is not a one or zero, but is best conceptualized alongside a continuum</li>
            <li>Discussing decentralization presupposes identifying system boundaries</li>
            <li>Decentralization must be distinguished from related notions, such as inequality and disintermediation</li>
            </ul>
            <p>Besides, a robust definition of decentralization must focus on the what of decentralization before discussing the how of its measurement. Lengthy debates, indeed, have focused on whether decentralization would be best captured with a Gini coefficient of inequality, a Herfindahl index of concentration, or a Shannon measure of entropy. All these measurements capture, in their own valid ways, the dispersion of something—but of what exactly? That is the question.</p>
            <p>To tackle the “what?” question upfront, I argue that decentralization concerns authority dispersion within an organization, a system, a digital platform, or a network (typically, blockchains are these four things all at once; Davidson et al., 2018). For our purposes, I envision authority as consisting of two components: (1) The ability to access information, and (2) The ability to contribute to decision-making. Indeed, it is easy to see why an agent without any access to information nor any say in decision-making would have little authority. Perhaps less intuitive is the recognition that information access and decision-making do not necessarily go hand in hand. Blockchain data may be accessible to agents but do not give them decision rights. Likewise, agents with decision rights sometimes do not have access to the information that is relevant to making a given decision. As a result, it is wise to consider as two separate dimensions the dispersion of information access, on the one hand, and of decision-making, on the other, when unpacking authority dispersion.</p>
            <p>To flesh out this two-dimensional framework, consider the following illustration. In the United States, the entire population (335 million) has access to information regarded as “public”; about 3.5 million people have access to information classified as “confidential” and about 1.5 million to information classified as “top-secret” (owing to the supply of specific security clearances). Given these data, how dispersed is information access in the United States? One can intuitively understand that information dispersion would be minimal if only one person (e.g., the President) could access non-public information and maximal if everyone could access all three types of information. It follows that dispersion should increase when more people are granted access to confidential and/or top-secret information. Intuitively, one can also see why maximizing information dispersion is not necessarily desirable, nor an end in itself; rather, information dispersion is a system property whose optimal value depends on system objectives (DuPont & Maurer, 2015), among other things (e.g., if the objective is “national security”, when might it be optimal to give everyone access to top-secret information?). Importantly, one does not need to look at the precise mathematical definition of dispersion indices (e.g., Gini’s, Herfindahl’s, Shannon’s) to make these determinations. Provided that a dispersion index correctly captures the minimum, the maximum, and what exactly needs to be measured, it will do a good-enough job at measuring information dispersion, independently of the exact shape of the underlying function. <a href="#_edn1">[i]</a> Any good-enough index can be used to compare similar systems (e.g., countries, organizations, blockchains) and indicate which ones have more information dispersion than others. As well, it should be usable to capture the evolution of dispersion over time.</p>
            <p>Importantly, a good-enough index of information dispersion should be deployed within appropriate system boundaries. Picture a blockchain with 300 special nodes who, unlike regular nodes, can see all the information. How dispersed would information be in that system? Well, it depends entirely on the total number of nodes. If there are 300 nodes in total, then every node is “special” and information is maximally dispersed. However, as the user base grows to, say 1,000 users and later to 1,000,000 users, the ratio of special-to-total nodes shrinks rapidly (down from 100% to 30% and later 0.03%). Any organization that grows its user base while holding constant the number of agents with special privileges is bound to become more “centralized” over time. A good-enough index of dispersion should thus be implemented in a way that accounts for this reality—by always considering the number of frontline users as the base layer in the measurement breakdown (just like we acknowledged, earlier, that all the 335 million U.S. residents can access “public” information).</p>
            <p>To capture the dispersion of decision-making, which represents the second component of authority, a very similar process can be implemented. In the United States, every citizen over 18 years old can contribute to deciding who their representatives shall be through voting (roughly 200 million U.S. citizens); about 500 representatives then decide on the law (Congressmen and women) and one person only can decide to implement executive orders (the President). If choosing representatives, passing new laws, and implementing executive orders were the only three types of decisions that mattered, then we could use the same metric for measuring the dispersion of decision-making as we used for information dispersion. With a standardized metric, every organizational system under scrutiny could be placed on a map of the kind depicted below.</p>
            <p>
            <img  src={vergne17} class="img-fluid" alt="Figure 18: Chart of network types positioned on a decision-making dispersion and information dispersion plane."/>
            </p>
            <p class="figure">
            Figure 18: Chart of network types positioned on a decision-making dispersion and information dispersion plane.
            </p>

            <h1>Taking decentralization seriously and strategically</h1>
            <p>Our proposed perspective need not star Hamlet, Godot, Bucket, or Figaro to tell the story. Instead, it moves beyond decentralization as an elusive narrative to offer authority dispersion as a tractable construct that can be defined and measured rigorously with real-world data. To take this perspective to the next level, we should delineate the types of information and decisions that matter to blockchain platforms and evaluate, for each type, what proportion of nodes are actually involved. For example, with Bitcoin, hosted wallets can only access information about unspent transactions outputs; SPV nodes can, additionally, access information about block header history; but only full nodes can access, on top of that, the entire history of transactions contained in prior blocks. The number of wallets, SPV nodes, and full nodes will thus affect the extent of information dispersion. If, over time, the number of wallet users increases but the number of full nodes does not, it should (in theory) and will (in practice) decrease authority dispersion.</p>
            <p>Web3 entrepreneurs, investors, and developers need to take “decentralization” seriously if they are to leverage it strategically and not just rhetorically (Faustino, 2019; Vergne & Swain, 2017)—often as part of clumsy PR claims that risk getting increasingly ridiculed by skeptics. As long as Web3 community managers make baseless claims about their network being “very decentralized” or “more decentralized” than others, the industry will face major roadblocks. Furthermore, Web3 builders need to gain a clear understanding of what level of authority dispersion they need to achieve and why, given their platform’s objectives. It is likely that most individual users do not care about “decentralization” any more than they care about privacy. However, to be successful, a digital platform must match users to complementors (e.g., dApp developers), who bear a platform-specific risk when they commit resources to building on top on an architecture they do not control (e.g., recall how facebook.com killed video-sharing platform Vine by suddenly revoking its network access). A crucial implication is that a platform’s authority dispersion mitigates risk for third-party complementors, thereby making the platform relatively more appealing to them than its more “centralized” counterparts.</p>
            <p>The strategic value of authority dispersion is perhaps best understood when taking a historical perspective. For instance, in the early days of radio broadcasting, state monopolies, such as the BBC, were able to prevent the free diffusion of content by adding locks to radio receivers (Johns, 2010). Competition emerged (illegally) with pirate radio, which sought to promote diversity and creativity in programming. Unlike state monopolies, who favored live broadcasting, pirate radio pre-recorded chunks of content to enable others to design modular programming (decision-making dispersion). These programs were then aired from multiple locations onto unlocked receivers, whose designs were shared widely, so anyone could build them and listen in (information dispersion). Avant-garde record labels, specializing in jazz and rock ‘n’ roll, found pirate radio appealing owing to authority dispersion and censorship resistance (quite literally, as state monopolies would often censor non-religious radio programming before the 1960s). Eventually, the superiority of the pirate model ended the radio monopolies (e.g., the BBC’s ended in 1967) and innovations introduced by pirate radio diffused rapidly in the industry afterwards (Durand & Vergne, 2013).</p>
            <p>Authority dispersion may well be a powerful draw for complementors and, as a result, a powerful driver of network effects for digital platforms (e.g., complementors attract users, who attract more complementors, and so on). Once we measure rigorously both information and decision-making dispersion across blockchains, we will finally be able to unpack the elusive notion of “decentralization” and study its effects on a variety of strategic outcomes, including innovation (Wang & Vergne, 2017), growth (Chen et al., 2020), and value created (Hsieh et al., 2017). This will likely reveal a contrasted picture and encourage entrepreneurs and venture capitalists to use more nuance in their discourse. Without a more pragmatic comprehension of authority dispersion going forward, Web3’s decentralization theater runs the risk of becoming a circus.</p>
            <p>
            <img  src={vergne8} class="img-fluid" alt="Figures 19-22: Twitter commentators on decentralization theatre."/>
            </p>
            <p class="figure">
            Figures 19-22: Twitter commentators on decentralization theatre.
            </p>

            <h1>References</h1>
            <p>Bakos, Y., Halaburda, H., & Mueller-Bloch, C. (2021). When Permissioned Blockchains Deliver More Decentralization Than Permissionless. Communications of the ACM , 64(2), 20–22.</p>
            <p>Baran, P. (1964). On Distributed Communications: I. Introduction to Distributed Communications Networks. RAND Corporation, No. RM3420 . https://doi.org/10.7249/RM3420</p>
            <p>Caliskan, K. (2020). Data money: The socio-technical infrastructure of cryptocurrency blockchains. Economy and Society, 49(4), 540–561. https://doi.org/10.1080/03085147.2020.1774258</p>
            <p>Chen, Y., Pereira, I., & Patel, P. C. (2020). Decentralized Governance of Digital Platforms.</p>
            <p>Journal of Management, XX(X), 1–33. https://doi.org/10.1177/0149206320916755 Davidson, S., De Filippi, P., & Potts, J. (2018). Blockchains and the economic institutions of</p>
            <p>capitalism. Journal of Institutional Economics, 14(4), 639–658. https://doi.org/10.1017/S1744137417000200</p>
            <p>DuPont, Q., & Maurer, B. (2015). Ledgers and law in the blockchain . https:/<a href="http://www.kingsreview.co.uk/essays/ledgers-and-law-in-the-blockchain">/www.kingsreview.co.uk/essays/l</a>e <a href="http://www.kingsreview.co.uk/essays/ledgers-and-law-in-the-blockchain">dgers-and-law-in-the-blockchain</a></p>
            <p>Durand, R., & Vergne, J.-P. (2013). The Pirate Organization: Lessons from the Fringes of Capitalism . Cambridge (MA): Harvard Business Review Press.</p>
            <p>Faustino, S. (2019). How metaphors matter: an ethnography of blockchain-based re-descriptions of the world. Journal of Cultural Economy , 12(6), 478–490. https://doi.org/10.1080/17530350.2019.1629330</p>
            <p>Gochhayat, S. P., Shetty, S., Mukkamala, R., Foytik, P., Kamhoua, G. A., & Njilla, L. (2020).</p>
            <p>Measuring decentrality in blockchain based systems. IEEE Access, 8(November), 178372–178390. https://doi.org/10.1109/ACCESS.2020.3026577</p>
            <p>Hsieh, Y.-Y., Vergne, J.-P., & Wang, S. (2017). The internal and external governance of blockchain-based organizations: Evidence from cryptocurrencies. In Bitcoin and Beyond: Cryptocurrencies, Blockchains, and Global Governance . https://doi.org/10.4324/9781315211909</p>
            <p>Johns, A. (2010). Death of a Pirate: British Radio and the Making of the Information Age . New York: W.W. Norton.</p>
            <p>Kavanagh, D., & Ennis, P. J. (2020). Cryptocurrencies and the emergence of blockocracy.</p>
            <p>Information Society, 36(5), 290–300. https://doi.org/10.1080/01972243.2020.1795958 Nabben, K. (2021). Blockchain Security as “People Security”: Applying Sociotechnical Security</p>
            <p>to Blockchain Technology. Frontiers in Computer Science, 2 , 62. https://doi.org/10.3389/FCOMP.2020.599406/BIBTEX</p>
            <p>Schneider, N. (2019). Decentralization: an incomplete ambition. Journal of Cultural Economy , 12(4), 265–285. https://doi.org/10.1080/17530350.2019.1589553</p>
            <p>Swartz, L. (2018). What was Bitcoin, what will it be? The techno-economic imaginaries of a new money technology. Cultural Studies, 32 (4), 623–650. https://doi.org/10.1080/09502386.2017.1416420</p>
            <p>Vergne, J.-P. (2020). Decentralized vs. Distributed Organization: Blockchain, Machine Learning and the Future of the Digital Platform. Organization Theory , 1(4), 263178772097705. https://doi.org/10.1177/2631787720977052</p>
            <p>Vergne, J. P., & Swain, G. (2017). Categorical anarchy in the UK? the British media’s classification of bitcoin and the limits of categorization. In Research in the Sociology of Organizations(Vol. 51). https://doi.org/10.1108/S0733-558X20170000051005</p>
            <p>Wang, S., & Vergne, J.-P. (2017). Buzz Factor or Innovation Potential: What explains cryptocurrencies’ returns? PLoS ONE, 12(1). https://doi.org/10.1371/journal.pone.0169556</p>
            <br clear='all'/>
            <hr align='left' size='1' width='33%' />
            <p><a href="#_ednref1">[i]</a> Indeed, pairwise correlations among 10 different dispersion metrics examined by Gochhayat et al. (2020) across 7 scenarios range between 0.83 and 0.99, indicating substantial convergence between Fairness, Gini, Entropy, Distance, and Cosine Similarity metrics (see Table 3 in their paper).</p>   



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default vergne;
