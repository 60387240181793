import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import potts1 from "../../assets/images/content/potts1.png";
import potts2 from "../../assets/images/content/potts2.png";
import potts3 from "../../assets/images/content/potts3.png";
import potts4 from "../../assets/images/content/potts4.png";
import potts5 from "../../assets/images/content/potts5.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function potts() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Web3 & The amazing computable economy</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Jason Potts
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089015">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/252a9397-8cb9-482c-8cb5-b7329068db8b">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            This essay introduces the concept of a “computable economy” and discusses how it relates to the emergence of Web3, or the new type of economy that has arisen from the integration of digital technologies such as blockchain, smart contracts, and digital identity. A “computable economy” is one where those computational rule systems are integrated into a connected graph, allowing for decentralized cooperation and distributed coordination. This essay traces the trajectory of innovation in the economy from the development of industrial production technologies to the rise of ICT and the digital economy. It argues that the shift to a “computable economy” is a consequence of the transformation of analog economic institutions into natively digital institutions. This results in a “full stack” digital economy where all economic actions can be digitally constructed and implemented. The essay concludes by discussing the potential of Web3 to create a new type of economy that is “techno-utopian” and characterized by human flourishing, as the incursion of machines and computation leads to a new era of economic growth and transformation.
            </p>
            <hr />
            <p>Web3 continues the innovation trajectory of the internet from web2 (social media) and web1 (email). And web1-2-3 builds on the macro-techno-historical innovation trajectory of satellites and computers and telephone networks and transistors and silicon and glass and wire and electricity and logic and math.</p>
            <p>Look what we’ve built with sand and lightning!</p>
            <p>The 3 in Web3 is the supercluster of new digital technologies (crypto, blockchain, smart contracts, DiD, NFTs, DAOs, …) that make the internet a new type of economy. Web1 made the internet (which was built for the US military!) an open communication tool that became natively navigable. Think HTTP, search and email. It was a new type of information processor. Web2 made the internet social. You could go there with friends, and do things like shop, play games, and tell stories. But you had to bring much of the old world with you, such as banks and governments. It was a new place to visit. But you couldn't live there. It was still not yet self-sovereign.</p>
            <p>Everything changes with Web3. The internet — these computers all strung together with machine language — becomes a new type of economy with digitally native money (crypto), digitally native agreements and law (smart contracts), digitally native property (tokens, NFTs), digitally native identity (DiDs), digitally native markets (DEXs), digitally native organisation (DAOs), and digitally native security, truth and expectations (consensus protocols). Web3 is a further surge along the evolutionary innovation trajectory of the internet and computing and communication technologies.</p>
            <p>Web3 is a world of human flourishing because of, not in spite of, the incursion of machines and computation. Over the past few hundred years, the discovery and application of new knowledge and technology, working with economic institutions that facilitated investment and trade, has lifted humanity from the normal miserable state of mass poverty. Industrial capitalism has given the world wealth and freedom. Now of course this induced second-order effects (externalities), including inequality and environmental pollution. But these are not my focus here. Rather, I want to follow the first order-effects through into their next generation. That will make this essay techno-utopian; so be it. You have been warned, and I leave critical reflections as an exercise for the reader. In any case, the world changing industrial innovation and economic growth and transformation occurred, in large part, by substituting machines for people, capital for labour, on every margin possible. Web3 continues this process but extends from work to computation.</p>
            <p>Web3 is in this way the beginning of a new type of economy, something that grew out of a powerful trajectory in the industrial economy — the development of ICT — but is new because it is rebuilding economic institutions: money, contracts, markets, registries, organisations, and law and courts, i.e., economic infrastructure. That is what is radically fundamentally new with Web3.</p>
            <h1>Web3 = computable economy</h1>
            <p>Before I go further, let me elucidate what is meant by ‘computable’, for it is an important concept in my argument here, yet one easily misunderstood. Computable does not mean ‘made of or reduced to number’, which could imply soullessness and reductivism. I do not mean a computable economy as one stripped of humanity and empathy and the sublime and the soaring inexplicable imaginative leaps of faith and belief that have carried us up the mountain and through the valleys of our collective history. Rather, computable, which is an adjective, meaning capable of being computed, is meant as a verb: ‘to compute’. To compute does not mean to reduce to number, but to follow a system of rules to an output, which then becomes a new part of the world, which can then be an input to new things. A computable economy is one where we find more and more ways to do that. And every time we do, the space of humanity gets bigger, just as with investment in capital goods.</p>
            <p>So web3 can be defined as a new type of economy, as a transition from an industrial economy to a digital economy. It's a new institutional thing (digital economy, see Goldfarb and Tucker 2019), caused by new technology (web3). Yet we still need to unpack what is specifically ‘industrial’ about an ‘industrial economy’ and what is newly digital about a ‘digital economy’. What’s under the tent?</p>
            <p>The concept of an industrial economy includes industrial production technologies:</p>
            <ul class="content">
            <li>iron, steel, minerals, engines, trucks, rockets, etc</li>
            <li>oil, synthetic materials, petrochemicals, pharmaceuticals, etc</li>
            <li>electrification, railways, satellites, etc</li>
            <li>transistors, lasers, computers, microelectronics, ICT, etc</li>
            </ul>
            <p>An industrial economy also refers to industrial institutions:</p>
            <ul class="content">
            <li>the factory system and private corporations</li>
            <li>industrial sectors, industry policy, venture finance</li>
            <li>public economic institutions to support markets, e.g. money, banks, property registries (including IPR), courts, regulation, roads, ports, universities …</li>
            </ul>
            <p>Industrial economies have profoundly changed the global history of humanity. As Figure 1 shows, they are the singular cause of modern human flourishing.</p>
            <p>
            <img src={potts1} class="img-fluid" alt="Figure 1: Long run economic growth"/>
            </p>
            <p>
                Figure 1: Long run economic growth
            </p>
            <p>You will observe among the industrial production technologies a particular innovation trajectory, usually called ICT (Information and Communication Technology). Those digital technologies are the consequence of industrial innovation and industrial production. And so, computers and iPhones and so on are produced in factories, protected by intellectual property, financed with capital markets, and sold in consumer markets. Industrial economies grow by producing digital things ever more cheaply and at ever greater scale.</p>
            <p>
            <img src={potts2} class="img-fluid" alt="Figure 2: Falling cost of ICT"/>
            </p>
            <p>
                Figure 2: Falling cost of ICT
            </p>
            <p>But that industrial growth in the new digital or ICT sector is not what we mean by a digital economy. The digital economy is nowhere on those diagrams. It's not just the inevitable cumulative result of more or faster or cheaper computers, or more digital jobs or technology firms. Rather, the digital economy revolution specifically refers to the disruption and regrowth of analog economic institutions in digital format.</p>
            <p>The industrial economy is the proximate cause of the digital economy revolution, by advancing innovation and indeed industrial mass production of digital technology. But the epochal transition from an industrial economy to a digital economy, which is the specific significance of web3, is a consequence of the transformation of industrial economic institutions (money, identity, contracts, registries, and economic organisation, coordination and governance in general) into natively digital institutions. The result is a ‘full stack’ digital economy.</p>
            <p>This is a statement to clarify where the digital disruption is occuring. It is not just the surface-industrial production layers that are being digitised. It is also occurring in the deeper institutional layers too, i.e., ‘full stack’. The distinction is captured by the idea of a fully ‘computable economy’. So, what is meant by ‘computable’?</p>
            <h1>Computable</h1>
            <p>‘Computer’ is a word from the 17th century, referring to a person who performs mathematical calculations by following rules. A computer, in other words, was an occupation not a thing. The picture in Figure 3 below from Wikipedia is titled “1954, NACA computer working with microscope and calculator”. Note that the ‘computer’ in the picture below is the one sitting in the chair holding the pen.</p>
            <p>
            <img src={potts3} class="img-fluid" alt="Figure 3: A ‘computer’"/>
            </p>
            <p>
                Figure 3: A ‘computer’
            </p>
            <p>Compare this with Figure 4 below, which is of an ‘electronic computer’, in which the operations of the computer have been replaced with a network of vacuum tubes, diodes, relays and resistors. This is our modern definition of a computer as an ‘electronic machine for performing calculations’ that processes data using rules.</p>
            <p>
            <img src={potts4} class="img-fluid" alt="Figure 4: An ‘electronic computer’"/>
            </p>
            <p>
                Figure 4: An ‘electronic computer’
            </p>
            <p>But the deeper abstraction behind ‘computer’ as occupation or tool is the idea of computability.</p>
            <p>Consider the concept of a computable number (a.k.a. recursive numbers or computable reals), introduced by Alan Turing (1936) in a paper that gave rise to the concept of a Turing machine, so named by the logician Alonzo Church, in review of Turing’s paper. That abstract machine implied by the mathematical model of computation is today simply known as a ‘computer’.</p>
            <p>Marvin Minsky (1967) defines a computable number as “one for which there is a Turing machine which, given n on its initial tape, terminates with the nth digit of that number [encoded on its tape].”</p>
            <p>An uncomputable number is one that cannot be computed by a finite procedure (i.e., a universal machine, or ‘Turing machine’), i.e., for which no such algorithm exists. Most numbers, it turns out, are uncomputable. And so, the concepts of computable numbers, a universal state machine and the formulation of the ‘halting condition’ as a solution concept (decidability) are the foundations of modern computer science. Interestingly, another great genius of the early/mid twentieth century, and who immediately built the next floor on those foundations, was John von Neumann with his concepts of the stored program architecture and automata theory.</p>
            <h1>Computable economy</h1>
            <p>The concept of a computable number (or Turing machine, or state machine, or automata) is also a useful way to think about the meaning and definition of a ‘digital economy.’ Indeed, what is referred to as the information economy or digital economy can be elucidated with the idea of a ‘computable economy.’</p>
            <p>Just as computable and non-computable numbers exist, the corollary is that so do both computable and non-computable economies (and complexes of both).</p>
            <p>An economy is computable in the same way, namely there exists a set of rules (i.e. institutions, technologies) that generates the economic output as a finite set of operations. Analogously, a fully computable economy is a ‘Turing complete economy’.</p>
            <p>A computable economy implies a field called computable economics(Tesfatsion 2006), which Velupillai defines as follows (1999: 2)</p>
            <p class="displayquotation">“Computable economics is about basing economic formalisms on recursion theoretic fundamentals. This means we will have to view economic entities, economic actions and economic institutions as computable objects or algorithms.”</p>
            <p>What do we gain from seeking such a quasi-formal definition (computable economy) rather than an empirically descriptive label (digital economy)?</p>
            <p>The empirical observation is about the class and cluster of technologies. They are digital. They are generally and correctly described as ICT: i.e., as information and communications technologies. But they also compute . And my claim is that it is the computing that is the core economic function in creating economic value.</p>
            <p>The industrial concept of a digital economy usually means number of computers or jobs that use them, or investment in digital technology or some such input or output measure or spending, investment or production (Carlsson 2004, Mesenbourg 2001). These are coherent and often useful empirical concepts, but not what I mean here.</p>
            <p>A computable economy is one where economic action (i.e., transactions) can be digitally connected as part of an algorithmic process. A ‘Turing complete economy’ is one where all economic actions can be digitally constructed and implemented.</p>
            <p>The formal concept of a computable economy is not new. Even by the late 1950s and 1960s, Vernon Smith was developing algorithmic models of the market process, and Herbert Simon was developing algorithmic models of human (and machine!) decision-making using the logic of bounded rationality or satisficing, which is a operationalisation of the halting condition. The theory of a computable economy has been subsequently anticipated or developed by Scarf (1989), Gode and Sunder (1993, 1997), Leijonhufvud (1993), Velupillai (1996, 1999), Richter and Wong (1999), and Mount and Reiter (2002), among others.</p>
            <p>Nor is the general model of computation as the lingua francaof science a new idea (see e.g., von Neumann 1966, Wolfram 2004, Hidalgo 2015).</p>
            <p>The concept of the economy as a process of computation is also not at all new. Philip Mirowski (2002) furnishes an exhaustive scholarly account of the computer metaphor in economic theory in his magisterial Machine Dreams , where he characterises the trend of orthodox economic theory in the second half of the 20th century as that of ‘recasting the economic agent as an information processor.’ He writes:</p>
            <p class="displayquotation">“It goes without saying that the wartime development of the computer and its subsequent diffusion into nearly every sphere of intellectual discourse had quite a bit to do with what has been the most significant reorientation of the economics discipline in the last century.” Mirowski (2007)</p>
            <p>The ‘computer’ is a compelling scientific metaphor for the operations of the economy. But the issue is not whether it is a useful metaphor — i.e., how is an economy ‘like’ a computer. The issue is more fundamental, or ontological, to what extent is an economy actually a computer , i.e., performs computation?</p>
            <p>The computable economy thesis implies that computers are not tools we use, but an environment we live in. We don't use computers; computers use us!</p>
            <h1>How economies compute</h1>
            <p>The issue of how economies compute goes to the heart of perhaps the most important debate in economics during the 20th century, the so-called ‘socialist calculation debate’ (Boettke 1998). This debate was nominally about the efficacy of economic planning, but it was really about whether markets were properly to be understood as computers. What makes this debate even more esoteric, is that it began before (!) the invention of the modern electronic computer.</p>
            <p>The debate took place between two factions of economic theorists: the neoclassicals (the orthodox view, along with the Marxists), and the ‘Austrians’ (the heterodox view).</p>
            <p>The neoclassicals argued that given the raw economic data relating to the technical conditions of economic production, the supplies of resources and preferences over goods, then, as Hayek (1945) explained, ‘the [economic] problem which remains is purely one of logic.’ As such, then the rational and optimal solution can be calculated by a sufficiently intelligent planner, ideally supported by sufficiently powerful calculating machinery.</p>
            <p>Ludwig von Mises (an ‘Austrian’) argued that Socialism was impossible because it was fundamentally unable to calculate. His point was that the data the neoclassical economists assumed to exist (viz. preferences, prices, uses of resources, etc) did not actually exist in an ex ante state of nature. Rather, that data was itself a product of the economic system.</p>
            <p>Moreover, that data was produced through a stack of institutions and mechanisms: including property rights, exchange mechanisms to transfer property rights through bidding processes (markets), the broader social processes to legitimate those exchanges, as well as mechanisms for calculating value including accounting systems.</p>
            <p>The outcome was a system of publicly observable prices, which could serve as common information to guide individual economic action, variously bringing more supply to market when prices were high, or economising on consumption, or vice versa when prices fell. Prices were information for distributed coordination of economic actions. Indeed, Mises (1949, p. 679) argued that:</p>
            <p class="displayquotation">“Human cooperation under the system of the social division of labor is possible only in the market economy. Socialism is not a realizable system of society’s economic organization because it lacks any method of economic calculation.”</p>
            <p>In a striking paragraph, Peter Boettke (2001: 33) outlines the depth and range of Mises argument:</p>
            <p class="displayquotation">‘To the economically illiterate, Mises had to explain how private property engenders incentives which motivate individuals to husband resources efficiently. To the more informed, but still economically uninformed, he had to explain how the exchange ratios established in a market allow individuals to compare alternatives by summarizing in a common denominator the subjective assessment of trade-offs that individuals make in the exchange and production process. To the trained economist, Mises had to explain how the static conditions of equilibrium only solved the problem of economic calculation by hypothesis, and that the real problem was one of calculation within the dynamic world of change, in which the lure of pure profit and the penalty of loss would serve a vital error detection and correction role in the economic process. And, finally, to scholars, activists, and political leaders, Mises warned that the suppression of private property leads to political control over individual decisions and thus the eventual suppression of political liberties to the concerns of the collective.’</p>
            <p>Hayek then built on Mises' calculation arguments by emphasising the problem of knowledge. Hayek argued that the market mechanism was an ingenious device — a complex social technology not invented by anyone, but rather that had evolved — that acted to gather and process distributed ‘local information’ and compute them into ‘prices.’ These computed price signals — as outputs of the market computation — then worked to efficiently and effectively guide economic activity at scale.</p>
            <p>The price system — itself built on institutions of private property, market mechanisms, profit and loss accounting — is a process of computation. The inputs into economic calculation, i.e., the economic information for rational planning, does not exist outside of the market process, but is created by it. Market mechanisms are distributed mechanisms that perform complex economic computation on knowledge, information and resources.</p>
            <p>In this view, an economy is clearly a type of computer.</p>
            <p>A common error is to mistake the inputs or outputs of an economy for an economy itself, which is a computational process. The goods and services produced by an economy - the factors of production: land, labour, capital, and resources, and so forth — are the inputs and outputs of an economy (including sets of things such as goods produced, or measures such as GDP). They are no more the economy than a set of input prompts or output files is a computer. An economy, like a computer, is a complex system of rules (algorithms, procedures, decision heuristics, institutions, administrative procedures, etc) that performs a ‘state’ processing function.</p>
            <p>
            <img src={potts5} class="img-fluid" alt="Figure 5: Diagram of computable economy"/>
            </p>
            <p>
                Figure 5: Diagram of computable economy
            </p>
            <p>Economics comes from the Greek root οἶκος, or house. But an economy is not a thing; it is not a pile of valuable objects. Rather, an economy is a process that is produced by a complex system of rules.</p>
            <p>An economy is a process- structure of rules that compute an output that is a system of instructions that organise the coordination of resources to enable plans to mesh together. An economy is a rule-system for economic computation.</p>
            <p>These generic rules are separable and composable (Dopfer and Potts 2008). Within each economic agent are cognitive rulesfor thinking and behavioural rulesfor action. There are social rules for coordinating people (e.g., firms, markets, institutions). Social rules can be tacit and informal, such as norms, or formal and legal, such as regulations and legislation. And there are technical rules that organise matter-energy (i.e., technologies). An economy is a complex system of rules, embedded and embodied in different types of substrates, operating by different mechanisms at different scales, and with different architectures and different properties. Together, these rules perform economic computation. An economy is a massively parallel and open dynamical system of rules — for decentralised cooperation and distributed coordination — for social computation.</p>
            <p>A computable economy is one in which those computational rule systems are integrated (i.e., joined up) into a connected graph (Potts 2000), and thus have a form of completeness (i.e., all parts are ‘computable’). The evolutionary process by which all parts become computable is the basic logic of the digital economy revolution.</p>
            <h1>A computer made of computers</h1>
            <p>Von Mises and Hayek’s notion of the market or price mechanism as a form of economic computation, and therefore of a market economy as a computer, was an idea that the Austrian economists developed in the 1930s. This was broadly contemporary with Turing’s and von Neumann’s theoretical development of the mathematical model of computation and then the electronic instantiation of that — as the electronic computer.</p>
            <p>By the 1970s, actual marketplaces had become actual (electronic) computers, as many major financial exchanges began a process of digital automation (e.g., NASDAQ launched in 1971, although futures only traded fully electronically in 1992 on CME Globex, see McCabe et al. 1991).</p>
            <p>Economic historian Philip Mirowski (2007) proposes a theory of markets as evolving computational entities, or ‘market automata’, which characterises a marketplace as an algorithm that perform the following functions:</p>
            <ul class="content">
            <li>Data dissemination and communications, plus rules of exclusion.</li>
            <li>Order routing through time and space.</li>
            <li>Order queuing and execution.</li>
            <li>Price discovery and assignment.</li>
            <li>Custody and delivery arrangement.</li>
            <li>Clearing and settlement, including property rights assignment.</li>
            <li>Record-keeping.</li>
            </ul>
            <p>The evolution of a computable economy occurs as more parts of the economy become computable, i.e., able to process and execute information algorithmically.</p>
            <p>Computable economic agents evolve with the adoption of machine-assisted or automated decision-making in which software algorithms can be analytically conceptualised as both substitutes (on some margins) and complements for human decision-making. This ranges across the domain of bots and apps, smart wallets and DAOs.</p>
            <p>Computable money and finance arrives with cryptocurrencies and tokens that enable smart contracts to be wrapped around existing financial assets. Indeed, token-wrapped assets and non-fungible tokenized assets (NFTs) — facilitate the development of computable capital (Banon et al 2022). This digital wrapping (or ‘skin’, or ‘twin’) then interacts with other computable objects.</p>
            <p>Computable capital is searchable, programmable, integrates with any economic agent (including IoT devices, algorithms, oracles, i.e., tools that sense or emit information), and works at global scale. Computable capital creates the ability to bring resources, as inputs into production (i.e., the definition of capital), into computable decision-making that can be executed in market contexts. These include searching for opportunities, verification of rights, assurance of the safety and efficacy of the exchange of property rights, or other mutually agreed upon exchange and agreements in the space of possible contracts. Moreover, as agents and capital become computable then so too does entrepreneurship and innovation become potentially computable as embedded information and knowledge in databases and toolkits becomes machine accessible, e.g., for discoverable trading opportunities for arbitrage or even the creation of new products and services (Potts 2019).</p>
            <h1>Economies of the future</h1>
            <p>Web3 is the beginning of the future for new types of economies that can be built with natively digital institutions and infrastructure. Computable economies massively lower the cost of operating economies — i.e., lower transaction, coordination, monitoring and governance costs (Catalini and Gans 2020) — but they also lower the cost of starting new economies, and of experimentally designing them. If economies are much cheaper to build, we will build many more of them.</p>
            <p>This opens a new world of ‘start-up’ or ‘pop-up’ economies that are easily instantiated and spun up for particular purposes at different scales as needed. They will have configurable institutional and governance parameters; i.e., their own administrative and contract systems, money and identity etc, that can be selected and designed as easily as it would be to say build a website. These ‘no-code economies’ are the economies of the future.</p>
            <p>On the whole, this is likely to be a very good thing for all of humanity. It creates a clear path to a truly native global economy by adding computability to everything. But it also creates safety and security by allowing any group to exit to their own economies by building their own infrastructure. The great disasters of the 20th century that trapped millions and billions of people inside broken and corrupt economic systems, causing untold human misery, suffering and poverty, will hopefully be consigned to history.</p>
            <p>There are major challenges. Many will resist due to investments in skills and capital or privileges that will be eroded by these new technologies. They will fight through political attempts to block the future, and if that fails then perhaps through violence. This is a sad but common theme of economic history (Juma 2016).</p>
            <p>There are also considerable education and access barriers to overcome. A digital economy requires high levels of digital literacy and access to digital technologies and infrastructure. There is a clear and urgent role for governments to provide these public goods.</p>
            <p>But computable economies are not science fiction. They are the future that is already here in the guise of web3. What we need to do is to deepen our understanding and appreciation of them, and to accelerate our use of them.</p>
            <h1>References</h1>
            <p>Banon, J., Berg, C., Davidson, S. Potts, J. (2022) ‘How web3’s ‘programmable commerce layer’ will transform the global economy’ World Economic Forum.</p>
            <p>Boettke, P. (1998) ‘Economic Calculation: The Austrian contribution to political economy’ Advances in Austrian Economics, 5: 131–158.</p>
            <p>Boettke, P. (2001) Calculation and Coordination. Routledge.</p>
            <p>Carlsson, B. (2004) ‘The digital economy: what is new and what is not?’ Structural Change and Economic Dynamics, 15(3): 245–264.</p>
            <p>Catalini, C., Gans, J. (2020) ‘Some simple economics of the blockchain’ Communications of the ACM, 63(7): 80-90.</p>
            <p>Dopfer, K., Potts, J. (2008) The General Theory of Economic Evolution . Routledge.</p>
            <p>Gode D., Sunder S. (1993) ‘Allocative efficiency of markets with zero-intelligence traders’ Journal of Political Economy 101: 119-137.</p>
            <p>Gode D., Sunder S. (1997) ‘What makes markets allocatively efficient?’ Quarterly Journal of Economics 105: 603–630.</p>
            <p>Goldfarb A., Tucker C. (2019) ‘Digital economics’ Journal Economic Literature 57(1): 3–43*.</p>
            <p>Hayek, F.A. (1945) ‘Use of knowledge in society’ American Economic Review , 35(4): 519–530.</p>
            <p>Hidalgo, C. (2015) Why information grows: The evolution of order, from atoms to economies . Basic Books.</p>
            <p>Juma, C. (2016) Innovation and its enemies: Why people resist new technologies . Oxford University Press.</p>
            <p>Leijonhufvud, A. (1993) ‘Toward a not too rational macroeconomics’ Southern Economic Journal 60(1): 1–12.</p>
            <p>McCabe, K., Rassenti, S., Smith, V. (1991) Smart computer-assisted markets. Science, 254(5031), 534–538.</p>
            <p>Mesenbourg, T. (2001). Measuring the Digital Economy. U.S. Bureau of the Census.</p>
            <p>Miller, M., Drexler, K. E. (1988) ‘Markets and computation: Agoric open systems’ The Ecology of Computation, B. Huberman (ed.) Elsevier Science Publishers/ North-Holland.</p>
            <p>Minsky, M. (1967) ‘The computable Real numbers’ Computation: Finite and infinite machines . Prentice-Hall.</p>
            <p>Mirowski, P. (2002) Machine Dreams: Economics Becomes a Cyborg Science . Cambridge University Press, New York.</p>
            <p>Mirowski, P. (2007) Markets come to bits: Evolution, computation and markomata in economic science’ Journal of Economic Behavior and Organization , 63(2): 209–242.</p>
            <p>Mount, K., Reiter, S. (2002) Computation and Complexity in Economic Behavior and Organization . Cambridge University Press.</p>
            <p>Potts, J. (2000) The New Evolutionary Microeconomics. Edward Elgar.</p>
            <p>Potts, J. (2019) Innovation Commons: The Origin of Economic Growth. Oxford University Press.</p>
            <p>Reiter, S. (1977) Information and performance in the (new) welfare economics. American Economic Review 67, 226–234.</p>
            <p>Richter, M., Wong, K.C. (1999) Computable preference and utility’ Journal of Mathematical Economics 32(3): 339–354.</p>
            <p>Scarf, Herbert E. "Computation of general equilibria." General Equilibrium (1989): 84-97.</p>
            <p>Tesfatsion, Leigh. "Agent-based computational economics: A constructive approach to economic theory." Handbook of Computational Economics 2 (2006): 831-880.</p>
            <p>Turing, Alan M. "On Computable Numbers, with an Application to the Entscheidungsproblem." Proceedings of the London Mathematical Society . Series 2 42, no. 1 (1936): 230-265.</p>
            <p>Velupillai, K. Vela. Computable Economics. Oxford: Oxford University Press, 2000.</p>
            <p>Velupillai, K. Vela. "The computable alternative in the formalization of economics." Kyklos49, no. 3 (1996): 251-272.</p>
            <p>Von Neumann, John. "Theory of self-reproducing automata." Mathematics of Computation 21, no. 98 (1966): 745.</p>
            <p>Wolfram, Stephen. A New Kind of Science. Champaign, IL: Wolfram Media, 2004.</p>    



        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default potts;
