import image2 from "../../assets/images/icons/steps.png";
import image16 from "../../assets/images/icons/steps2.png";
import image18 from "../../assets/images/logo.png";
import "./temp.scss";
import PageLoaderCircle from "src/components/PageLoaderCircle";

function lovink() {
  return (
    
<div class="container-fluid">
<PageLoaderCircle delay={1500} />
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-12">
        
            <h1
            className="wow fadeInUp"
            data-wow-delay="0.4s"
            data-wow-duration="0.6s"
            color="#A3FF12"
            >
            <span>Political Economy of the Crypto-Art Craze</span>
            </h1>
            <h3
            className="wow fadeInUp"
            data-wow-delay="0.5s"
            data-wow-duration="0.7s"
            >
            Geert Lovink
            </h3>
            <div className="dsc">
                              <a href="https://www.emerald.com/insight/content/doi/10.1108/S0733-558X20240000089008">Published version</a> | <a href="https://researchrepository.ucd.ie/entities/publication/e0ac46c0-f7a6-4e6f-9ee4-6ce7ba23d30e">Preprint self-archived version</a>
                            </div>
            <p>
                TL;DR
            </p>
            <p class="tldr">
            This essay explores the rise of non-fungible tokens (NFTs) and their impact on the art world. By examining the aesthetics and political economy of crypto art and its relationship to online pop culture, it questions the value of NFTs and whether they truly represent a source of income for artists or simply contribute to the speculative nature of crypto investments. The essay concludes by asserting that the world of crypto art is dominated by right-wing libertarians and black box algorithms, and that a radical redistribution of wealth is necessary to address the issues of power, race, and gender within the industry.
            </p>
            <hr />
            <p class="displayquotation">“Every time I think I’ve sorted out my life, capitalism collapses.” ~ Juliet </p>

            <p class="displayquotation">“One character asks the other what he likes. The answer is money. ‘I can’t believe you like money too!’ the first character says without irony, ‘We should hang out!’” ~ Idiocracy </p>

            <p class="displayquotation">“My ultimate dream job is to trade labor for capital.” ~ Darcie Wilder</p>

            <p class="displayquotation">“Which artist was the wealthiest in Renaissance Florence? Neri di Bicci. Second wealthiest? Andrea di Giusto Manzini. Heard of either of them?” ~ @hravg</p>

            <p class="displayquotation">“If you have a problem, use blockchain. Now you have two problems and a right-libertarian fan club.” ~ Aral Balkan</p>

            <p class="displayquotation">“Money is a meme and now that memes are money we don't need money, we just need memes.” ~ @punk4156 </p>

            <p class="displayquotation">“If you don’t get it, have fun being poor.” ~ Bitcoin sayings.</p>
            
            <p>When the Homer Pepe NFT sold for $223,000, Barry Threw cheered: “The art world is a software problem now.” Let’s return to the early 2021 crypto art wave. Here, newly created speculative “assets” <a href="#_edn1">[i]</a> are financed with lavish amounts of “funny money,” superfluous cash that is spent on even more speculative ventures. The choice of the young crypto-millionaires not to stash their profits away in investment funds or real estate is the most likely explanation for the Non-Fungible Token (NFT) boom. <a href="#_edn2">[ii]</a> In line with 1990s start-up values, the newly acquired wealth should not be invested in fine art paintings or laudable charities, but continue to circulate inside the tech scene itself. The only aim of the virtual is to further expand the virtual: a boom that fuels the next boom. What else should one purchase after the Miami condo and the Lambo? It’s easy, épater les bourgeois : refuse to be patrons. Unlike colonial and carbon money, the IT rich are not interested in living forever and becoming esteemed supporters of avant-garde fine arts, architecture, or classical music. The surprising twist here is their sudden interest in digital arts.</p>
            <p>D. Gerard (2021) explains how the hype, commonly known as Web3, started:</p>
            <p>“Tell artists there’s a gusher of free money! They need to buy into crypto to get the gusher of free money. They become crypto advocates and make excuses for proof-of-work. Few artists really are making life-changing money from this! You probably won’t be one of them.”</p>
            <p>As Brett Scott (2021) explains, crypto-hype promises artists they are destined for market greatness. But such hype, driven by males, can only take off if everyone buys into it. “Far from saving you from bullshit jobs, trading is a bullshit job, and the only way to temporarily win at it is not to throw yourself into battle against ‘the market.’ It’s to collaborate in swarms.” The moment the hype takes off is the ultimate reward for the struggling pioneer. As Chelsea Summers (2021) quips: “There is nothing as sweet as professional vindication after years of people rejecting your work, underestimating your value, and generally dismissing you. And by you I mean me. And by professional vindication I mean money.”</p>
            <p>Take Hashmasks and Cryptopunk, fixed number storage places that make it easy to buy, sell, and compare similar images (“collectables”). Here the speculative game is most obvious. The top ten images sold on Cryptopunk during the February–April 2021 hype period were priced between $1.46 and $7.57 million, proving Mazzucato’s thesis that value had become subjective. <a href="#_edn3">[iii]</a> It is telling that online details about the transactions fail to mention a description of the works, let alone their aesthetics, reducing the artworks to numbers such as #7804 or #3396. While “famous” NFT artworks may be handpicked by Sotheby’s experts, gallerists, critics, and curators, it is not (yet) clear where buyers can go to resell such works. While the details are lacking, the overall logic is clear: artworks are stored value, that value is expected to rise, and these staggering prices will continue to be designated in fiat currency. While BTC or ETH may rise and fall and the blockchain could vanish altogether, the scheme assumes this will never happen and that these “rare” digital artworks will keep their value.</p>
            <p>“Art history will be seen as before and after NFTs,” says @Hans2024. What is art at the crossroads of the game, VR, bitcoin and social media experience? For some there is an element of magic in buying a piece of “rare art.” For others, like Sybil Prentice, NFTs have a garage sale vibe: leftovers of an accelerated online life in which image production and consumption happens at a relentless pace (@nightcoregirl, 2021). Yet whether positive or negative, it’s clear that crypto art and non-fungible tokens alter the landscape of art. As Derek Schloss and Stephen McKeon (2020) state, “in a natively digital medium, art takes on a more expansive role, intersecting with virtual worlds, decentralized finance, and the social experience.” Before digging into the tale of NFTs, let’s look at the broader narrative around blockchain, crypto, and the future of digital money.</p>
            <p>We can define crypto art as rare digital artworks, published onto a blockchain, in the form of non-fungible tokens. Crypto art is not just any image, but comes with a distinct style, a particular aesthetic that goes with the territory. Let’s therefore study the aesthetics of the pioneers back in 2014. The common cultural references here are not New York’s Artforum or Berlin’s Texte zur Kunst but the online pop culture of imageboards: memes, anime, Pokémon cards, and the like. Jerry Saltz (2021): “Most NFT so far is either Warhol Pop-y; Surrealism redux; animated cartoon-y; faux-Japanese Anime; boring Ab-Ex abstraction; logo swirling around commercial; cute/scary gif; glitzy screensaver; late Neo-conceptual NFT about NFT-ism. All Of those are NFT Zombie Formalism.” In short, what’s striking is the overall retrograde style, as if we’re stuck in a loop, forever repeating Groundhog Day. The settings are copy-pasted from American science-fiction films and comic books and projected into today’s games environment.</p>
            <p>NFTs are based on the assumption that scarcity is a good thing that needs to be reintroduced. Scarcity creates the possibility of value skyrocketing and speculation, which may in turn attract investors. The fatal destiny of the artwork is to become unique. Ours is an anti-Benjamin moment when digital art in the age of technical reproduction makes its big leap back into the 18 th century. At the same time we need to remind ourselves that “decentralized finance” is an alternative reality, driven by libertarian principles, fed by a temporary abundance of specific free money and a religious belief in technology that will save humankind. Digital reproduction and “piracy” are no longer the default. Those who disagree with this premise would need to become hackers (again), cracking the code in order to enjoy the artwork. Is this the only way of making a living, to become “rare” again?</p>
            <p>Back to the screen; @lunar_mining’s tweets are refreshing. “I love stormy days when the market is dumping. Feels healthy and cathartic—as natural as the seasons.” I contacted @lunar_mining because of a critical tweet:</p>
            <p>“NFTs =! digital art. NFTs replicate the mechanics of the art marketplace, but I’ve yet to see an NFT with meaning or soul or abyssal depth. There’s an emptiness to NFT trading, a nihilism: it is a marketplace without ideas.”</p>
            <p>The person behind the address is the artist and former Coindesk editor Rachel-Rose O’Leary. I asked her opinion about crypto art. Rachel-Rose: “There’s a low-level boyish signaling out there but also some nuance that is emerging. For example, you can see the early stages of meme culture fusing with collectibles, which is potentially a powerful combination.”</p>
            <p>For Rose, art ended with Sol Lewitt, who said that the idea becomes the machine that makes the art. This realization contains the key to art’s exit from itself. Crypto is an idea turned into a machine. It is the crystallization of ideas into architecture. By binding narrative to a machine, crypto has the potential not simply to describe the world, but to actively shape reality. “In a world of unprecedented political urgency, contemporary art has retreated into subjective delusions. In contrast, crypto offers a powerful regenerative vision. That’s why I’m expending my energy here rather than the art world.” She observes a hollowing out of culture in general. “Art on the art market shares the same overpriced, pop-culture feel as the current NFT market, related to a declining culture industry and the behavior of markets than NFTs or crypto culture itself.”</p>
            <p>Is the essence of art that it should appeal to (crypto)investors? “NFT platforms aren’t competing with the auction house or gallery — they’re competing with Patreon,” tweets Tina Rivers Ryan. Are these patrons the medieval cardinals of today? Here we see how the perverse logic of the art market can become internalized into the mind of the artist. According to Robert Saint Rich (2020), “the influence of social media has created a perspective in artists that they need to produce masterful quality works in a large enough quantity so that they can be shared on an almost daily basis. This is an impossible standard that forces artists to create uninspired work.”</p>
            <p>Artists create banal artwork for banal tastes, hoping to give their pieces popular appeal. But paradoxically, the aim of investors was never to collect art pieces. The crypto art system is a financial system, putting creators into peer-to-peer contact with the nouveau riche of today: the crypto millionaires. While we may critique this situation, it cannot be undone. A return to “normal” is no longer on the cards. This is a digital world; we’ve passed the point of “newness.” Crypto art belongs to that moment in the history of contemporary arts when both painting and conceptual art forms are both becoming impossible.</p>
            <p>Let’s define what we encounter here as “admin art” that solely exists on a ledger. While there was no Excel art that we know of as a genre, there was net.art, video art, new media art, sound art, electronic arts... It is in fact the ledger that defines the art form, not cryptography as such. Admin art fits into that cloud of temporary buzzwords. However, for the sake of the argument, let’s stick to this misnomer called crypto art: meta-tagged online images, defined by their desire to become a record, to obtain a timestamp, to embody a digital unit of value. The value is inscribed inside the work, readable by machines. Crypto art is retrograde in that it wants to put the genie back in the bottle and create digital originals. Its innovation claims to fix past structural mistakes. What it shares with the avant-garde is its initial unacceptability. The discontent of crypto arts lies in the perverse obsession to get onto the ledger.</p>
            <p>Admin art is what art becomes when it is defined by geeks. These geeks dress up as notaries and play-act at being lawyers, fastidiously policing “authenticity records.” At the center of all this is the notion of ownership and the promise of being “securely stored on the blockchain” (Connor, 2021). How is an artwork purchased and ownership transferred? First you need to setup a digital wallet on a smartphone and purchase some cryptocurrency (usually Ethereum in the case of crypto art). Then we visit the website with the art pieces. The interface is structured in typical fashion, with “top sellers” and “top buyers” conforming to the established “most viewed” influencer logic. We then have recently added works, followed by a section where you can “explore” the cheapest or highest priced NFTs. As in the case with any platform these days, the user design is profile-centric. Only after creating a profile one is able to purchase and “mint” an artwork. Either there will be an instant sale price or one set by an auction.</p>
            <p>The most obvious issue one has to deal with is connectivity to the internet. But alongside this, there is a stack of necessities, from cloud services to browsers, operating systems, the Ethereum currency, the blockchain, the wallet, and platforms like Opensea and Foundation, each with their own layers of services, networks, and transaction fees. So where exactly, in this soup of platforms and protocols, is the token ID?</p>
            <p>If the production and distribution costs of digital art works tend toward zero, what does it feel like to produce worthless works? What are the mental costs, the “creative deficit” if one can freely copy-paste, steal motifs, and quote without consequences? While some celebrate the infinite number of possible combinations, others lament the culture of indifference this entails. This culture is driven by the promise of opportunity, the excitement of being in on the game. We’re on the payment train. Instead of underground aesthetics that need to be experienced first-hand, the focus of the artistic endeavor has shifted to storing value.</p>
            <p>So the excitement for crypto art is fed by an unspoken desire to disrupt the old Silicon Valley order and its outdated obsession with free things and free ideas. However, what remains is the central role of the market, which is run by a handful of platforms and managed by curators. Jargon like “hotbids” and “drops” seem to come from nowhere. This is by no means a move away from post-digital art (or post-internet art values, for that matter). Crypto art is material as hell. It cannot exist outside of data centers, undersea cables, servers, wallets and the handheld devices of its traders. A substantial number of crypto artists draw on paper. The maker’s ideology and practice is never far away.</p>
            <p>In the debates, NFTs claim to be possible sources of income that fight mass precarity amongst artists and creative workers. Selling crypto art as unique works (or in limited series) is claimed to be an additional income stream for most producers. The demand of a Universal Basic Income will remain in place though. Highflying artists Beeple and Pak are the exception to the rule. Overinflated prices during a short hype cycle merely say something about the Tulipomania state of crypto, the wealthy that need to stash their money somewhere, creating a new right-wing libertarian class of under 30 crypto millionaires as a spin-off. Marketplaces for crypto art are, all too often, ways to temporarily store “funny money” elsewhere. What’s prevented at all times is a sustainable financial solution. The ideology of speculative cryptocurrency hoarding (HODL) and the demand of artists for a living wage are inherently incompatible.</p>
            <p>Ledger technologies are, at their core, boring, administrative procedures. There is no artistic potential in them, other than the “art and money” engagement described by Max Haiven in his Art after Money (2018) book, which plays around with concepts such as the gift, exchange value, and symbolic exchange. The blockchain is, in essence, a follow-up to the excel sheet. There’s no Excel Art — and for that the world can be thankful. We suffer enough from the bookkeeper logic. In the end, the NFT saga centred around a brief encounter between art and crypto-start-ups. While in the past decades it was mostly real estate and other technologies that were used as objects of speculation, in this rare case it was image board game-like images that were the privileged victims. For the blockchain crowd art was just a test scenario. The platforms end up doing it for technology and money sake, without any interest in the life and works of artists — let alone in place of NFTs in art history.</p>
            <p>Early 2021 the Italian art critic Domenico Quaranta wrote an NFT book, in Italian. A year later (2022) he wrote an interesting update for the English translation, Surfing with Satoshi — Art, Blockchain and NFTs. The book is surprisingly balanced due to his insider’s view and historical awareness of the burning issue how electronic artists are going to be paid in the digital age. In the historical chapter, he provides early examples of artists that experimented with certificates such as Yves Klein, Piero Manzoni, Sol LeWitt and the Artist’s Reserved Rights Transfer and Sale Agreement from 1971, drawn up by Seth Siegelaub and Robert Projansky. Via Hito Steyerl’s Duty Free Art (2017), Quaranta arrives at issue how the market for new media/digital artworks is (not) operating. Despite the large sums paid in overvalued crypto-currencies (reaching its highest valuation early November 2021), the author questions if there is such a thing as ‘crypto art’ — a safe bet after the crypto ‘market’ lost two-third of its value.</p>
            <p>This leaves us with the question of 99% of works circulating on exchanges like Nifty Gateway, SuperRare, Foundation, OpenSea and others. Quaranta provides the reader with a quick overview of NFTs prehistory and early days of Jennifer and Kevin McCoy, rare Pepes, CryptoKiddies and CryptoPunks that found their use in the Metaverse avatar profile market. But what about the rest? The long tail remains largely unresearched. What can be observed are ties to dreamy, dark anime aesthetics and earlier image boards, varying from 4Chan and Reddit, Flickr, LiveJournal, Imgur to Pinterest. Beginning NFT artists sense they have to cater to the young male crypto programmers and investors that hang out in game and metaverse environments. The shared culture consists of social media, memes, image boards, not the contemporary arts gallery.</p>
            <p>“Bitcoin is what you get when you’d rather see the world burn than trust another human being ever again,” says Aral Balkan. Black box algorithms and right-wing libertarians are hegemonic givens here. Despite all the “democratic” promises, the crypto business is anything but decentralized and deeply dominated by racist right-wing techno-libertarians. This is out there, in the open, for everyone to see. Unless this is properly addressed, nothing will move on, certainly not in the art world. If the crypto community cannot discuss its own power, race and gender issues, then why bother? The world is not in need of more billionaires, but is instead crying out for a radical redistribution of wealth. Unless crypto starts to sabotage its own speculative dream machines such as DeFi, DAOs, Web3 and its unholy alliance with metaverse will inevitably collapse, time and again.</p>
            <h1>References</h1>
            <p>Gerard, David. "NFTs: Crypto grifters try to scam artists again." David Gerard, March 11, 2021, <a href="https://davidgerard.co.uk/blockchain/2021/03/11/nfts-crypto-grifters-try-to-scam-artists-again/">https://davidgerard.co.uk/blockchain/2021/03/11/nfts-crypto-grifters-try-to-scam-artists-again/</a> .</p>
            <p>Haiven, Max. Art After Money, Money After Art: Creative Strategies Against Financialization . Pluto Press, 2018.</p>
            <p>Kraus, Chris. "Another New World: NFTs aren’t just for cats anymore. What do they mean for digital art?" Rhizome, March 3, 2021, <a href="https://rhizome.org/editorial/2021/mar/03/another-new-world/">https://rhizome.org/editorial/2021/mar/03/another-new-world/</a> .</p>
            <p>Mazzucato, Mariana. The Value of Everything: Making and Taking in the Global Economy . PublicAffairs, 2018.</p>
            <p>@nightcoregirl. "NFTs have a #GARAGESALE vibe rn." Twitter, April 18, 2021, <a href="https://twitter.com/nightcoregirl/status/1383654340674547715">https://twitter.com/nightcoregirl/status/1383654340674547715</a> .</p>
            <p>Quaranta, Domenico. Surfing with Satoshi: Art, Blockchain and NFTs. Aksioma, 2022.</p>
            <p>Rich, Robert. "the influence of social media has created a perspective in artists that they need to produce masterful quality works in a large enough quantity so that they can be shared on an almost daily basis. this is an impossible standard that forces artists to create uninspired work…" Twitter, December 26, 2020, <a href="https://web.archive.org/web/20210520084016/https:/twitter.com/fatherrich_/status/1343000927448535043">https://twitter.com/fatherrich_/status/1343000927448535043</a> .</p>
            <p>Scott, Brett. "The real lesson of the GameStop story is the power of the swarm." The Guardian, January 30, 2021, <a href="https://www.theguardian.com/commentisfree/2021/jan/30/gamestop-power-of-the-swarm-shares-traders">https://www.theguardian.com/commentisfree/2021/jan/30/gamestop-power-of-the-swarm-shares-traders</a> .</p>
            <p>Summers, Chelsea. "Confessions of an Angry Middle-Aged Lady." Oldster, October 7, 2021, <a href="https://oldster.substack.com/p/confessions-of-an-angry-middle-aged">https://oldster.substack.com/p/confessions-of-an-angry-middle-aged</a> .</p>
            <p>@jerrysaltz. "Most NFT so far is either Warhol Pop-y; Surrealism redux; animated cartoon-y; faux-Japanese Anime; boring Ab-Ex abstraction; logo swirling around commercial; cute/scary giff; glitzy screensaver; late Neo-conceptual NFT about NFT-ism. All Of those are NFT Zombie Formalism. D.O.A." Twitter, April 15, 2021, <a href="https://twitter.com/jerrysaltz/status/1379056028969488390">https://twitter.com/jerrysaltz/status/1379056028969488390</a> .</p>
            <p>Medina, Victor. "You're Sleeping on Crypto Art." Collab+Currency, September 10, 2020, <a href="https://medium.com/collab-currency/youre-sleeping-on-crypto-art-7df920ec038e">https://medium.com/collab-currency/youre-sleeping-on-crypto-art-7df920ec038e</a> .</p>
            <br clear='all'/>
            <hr align='left' size='1' width='33%' />
            <p><a href="#_ednref1">[i]</a> As Finn Brunton describes it, “it is a project of making the future into an object of knowledge.” In: Digital Cash, Princeton, Princeton University Press, 2019, p. 12.</p>
            <p><a href="#_ednref2">[ii]</a> Read about the possible collapse scenarios for crypto art hype here:</p>
            <p><a href="https://twitter.com/DCLBlogger/status/1365651253422776321">https://twitter.com/DCLBlogger/status/1365651253422776321</a>. Market data can be found here: <a href="https://cryptoart.io/data">https://cryptoart.io/data</a></p>
            <p><a href="#_ednref3">[iii]</a> According to Mariana Mazzucato (2018), value became what consumers were prepared to pay. “All of a sudden, value was in the eye of the beholder. Any goods or services being sold at an agreed market price were by definition value-creating.” A particular NFT may sell at auction for 100 USD or 100,000 USD, both valuations are real.</p> 

        </div>
            {/* FOOTER */} 
            <div className="gamfi-footer-section">
                    <div className="footer-area">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52">
                                <img
                                className="heading-right-image"
                                src={image16}
                                alt="Steps-Image"
                                />
                                <span id="shareSocial" className="sub-title white-color">
                                Share on Social
                                </span>
                                <img
                                className="heading-left-image"
                                src={image2}
                                alt="Steps-Image"
                                />
                            </div>
                            <div className="footer-listing text-center mb-100 md-mb-70">
                                <ul className="footer-icon-list">
                                    <li>
                                    <a href="https://twitter.com/intent/tweet?text=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://t.me/cryptocarnivalz">
                                        <i className="icon-telegram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://mail.google.com/mail/u/0/?view=cm&to&su=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf&body=https%3A%2F%2Fcryptocarnival.wtf%0A&bcc&cc&fs=1&tf=1">
                                        <i className="icon-medium"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=cryptocarnival.wtf">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=cryptocarnival.wtf&quote=Read%20crypto%20essays%20and%20play%20games%20on%20cryptocarnival.wtf">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-logo text-center mb-45">
                                <a href="/index">
                                    <img src={image18} alt="Footer-logo" />
                                </a>
                            </div>
                            <div className="footer-mainmenu text-center mb-20">
                                <ul>
                                    <li>
                                    <a href="/index#Whatiscryptocarnival">How it works</a>
                                    </li>
                                    <li>
                                    <a href="/essays">Read essays</a>
                                    </li>
                                    <li>
                                    <a href="/dice">Play games</a>
                                    </li>
                                    <li>
                                    <a href="/faucet">Receive tokens</a>
                                    </li>
                                    <li>
                                    <a href="/NFT">Receive NFT</a>
                                    </li>
                                    <li>
                                    <a href="/about">About</a>
                                    </li>
                                    <li>
                                    <a href="/about#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-area text-center mb-0">
                                <p className="dsc mb-37">
                                Copyright © 2022. All Rights Reserved by
                                <a target="_blank" className="gafi" href="#">
                                    {" "}
                                    the Authors
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* END FOOTER */}

    </div>
</div>
  );
}

export default lovink;
